import useDuplicateGoal from '@/composables/goal/duplicate-goal';
import useGoals from '@/composables/goal/goals';

export default function useImportGoals(planning) {
  const goalsSvc = useGoals();

  const planningModifier = (goal) => ({
    ...goal,
    publishedAt: null,
    planning: { uid: planning.uid },
  });

  const duplicateSvc = useDuplicateGoal();

  const importGoals = ({ goalIds }) => {
    const goalsToImport = goalsSvc.selectMultiple(goalIds);

    duplicateSvc.goalModifiers.setSelectedCycles(planning.goalCycles);
    const preparedGoals = duplicateSvc.prepareDuplicate(goalsToImport).map(planningModifier);
    return goalsSvc.createMultiple(preparedGoals);
  };

  return {
    importGoals,
    importLoading: goalsSvc.createLoading,
  };
}
