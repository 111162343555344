import { computed } from 'vue';
import { goal as goalConfig } from 'shared/api/query/configs.json';

export default function useCustomBuckets(customBucket, resultRaw, groupBy) {
  const defaultAmountOfBuckets = 5;
  const calculateBuckets = (min, max, bucketSize) => {
    const buckets = [
      { min: undefined, max: min, label: `< ${min}` },
    ];
    if (bucketSize > 0) {
      for (let i = min; i < max; i += bucketSize) {
        buckets.push({
          min: i,
          max: i + bucketSize,
          label: `${i} - ${i + bucketSize}`,
        });
      }
    }
    buckets.push({
      min: buckets[buckets.length - 1].max,
      max: undefined,
      label: `> ${buckets[buckets.length - 1].max}`,
    });
    return buckets;
  };

  const calculatePercentile = (data, percentile) => {
    const values = data.map((item) => item.val).sort((a, b) => a - b);

    const index = Math.ceil(percentile * values.length) - 1;

    return Math.round(values[index]);
  };

  const buckets = computed(() => {
    if (customBucket.value.bucketType === 'automatic') {
      if (groupBy.value.edge === goalConfig.edges.cachedCalculatedCurrent) {
        return calculateBuckets(0, 100, 20);
      }
      if (resultRaw.value.res === null) {
        return calculateBuckets(0, 100, 20);
      }
      const lowerBound = calculatePercentile(resultRaw.value.res[0]['@groupby'], 0.10);
      const upperBound = calculatePercentile(resultRaw.value.res[0]['@groupby'], 0.9);
      return calculateBuckets(lowerBound, upperBound, Math.min(resultRaw.value.res[0]['@groupby'].length - 2, Math.round((upperBound - lowerBound) / (defaultAmountOfBuckets - 2))));
    }
    return calculateBuckets(customBucket.value.min, customBucket.value.max, customBucket.value.bucketSize);
  });

  const findBucket = (value) => {
    if (value < buckets.value[0].max) {
      return buckets.value[0];
    }
    if (value > buckets.value[buckets.value.length - 1].min) {
      return buckets.value[buckets.value.length - 1];
    }
    return buckets.value.find((bucket) => value >= bucket.min && value <= bucket.max);
  };

  const findBucketLabel = (value) => {
    const bucket = findBucket(value);
    return bucket?.label;
  };

  return { buckets, findBucket, findBucketLabel };
}
