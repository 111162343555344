<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="43"
      height="43"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.4745 9.2V34.8H36.9634V9.2H34.4745ZM32.1634 6C31.6724 6 31.2745 6.39797 31.2745 6.88889V37.1111C31.2745 37.602 31.6724 38 32.1634 38H39.2745C39.7654 38 40.1634 37.602 40.1634 37.1111V6.88889C40.1634 6.39797 39.7654 6 39.2745 6H32.1634Z"
      fill="#288AA8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.2522 25.2V34.8H22.7411V25.2H20.2522ZM17.9411 22C17.4502 22 17.0522 22.398 17.0522 22.8889V37.1111C17.0522 37.602 17.4502 38 17.9411 38H25.0522C25.5432 38 25.9411 37.602 25.9411 37.1111V22.8889C25.9411 22.398 25.5432 22 25.0522 22H17.9411Z"
      fill="#BDBDBD"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.03002 18.0889V34.8H8.51891V18.0889H6.03002ZM3.71891 14.8889C3.22799 14.8889 2.83002 15.2868 2.83002 15.7777V37.1111C2.83002 37.602 3.22799 38 3.71891 38H10.83C11.3209 38 11.7189 37.602 11.7189 37.1111V15.7777C11.7189 15.2868 11.3209 14.8889 10.83 14.8889H3.71891Z"
      fill="#BDBDBD"
    />
  </svg>
</template>

<script>
export default { name: 'VerticalBarChart' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
