import { aggregationMethod, goalProgressMeasurement } from 'shared/constants.json';
import { camelCase } from 'lodash-es';
import { useI18n } from 'vue-i18n';

export default function useProgressMeasurement() {
  const { t } = useI18n();
  const progressMeasurementOptions = [
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.continuous)}`),
      value: goalProgressMeasurement.continuous,
      icon: 'line-chart',
    },
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.alignedItems)}`),
      value: goalProgressMeasurement.alignedItems,
      icon: 'apartment',
    },
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.threshold)}`),
      value: goalProgressMeasurement.threshold,
      icon: 'threshold',
    },
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.binary)}`),
      value: goalProgressMeasurement.binary,
      icon: 'check-square',
    },
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.none)}`),
      value: goalProgressMeasurement.none,
      icon: 'stop',
    },
  ];

  const aggregationMethodOptions = [
    {
      text: t(`metricForm.aggregationMethod.${camelCase(aggregationMethod.relative)}`),
      value: aggregationMethod.relative,
      // icon: '',
    },
    {
      text: t(`metricForm.aggregationMethod.${camelCase(aggregationMethod.absolute)}`),
      value: aggregationMethod.absolute,
      // icon: '',
    },

  ];

  const allowedProgressMeasurementOptions = (option) => {
    if (option === null) {
      return progressMeasurementOptions;
    }

    if (option.allowedMeasurementTypes.length === 0) {
      return progressMeasurementOptions;
    }

    return progressMeasurementOptions.map((o) => ({
      ...o,
      disabled: !option.allowedMeasurementTypes.includes(o.value),
    }));
  };

  const allowedAggregationMethodOptions = () => aggregationMethodOptions;

  const goalStart = (goal) => {
    switch (goal.progressMeasurement) {
      case goalProgressMeasurement.continuous:
        return goal.start;
      case goalProgressMeasurement.alignedItems:
        if (goal.aggregationMethod === aggregationMethod.absolute) {
          return goal.start;
        }
        return 0;
      case goalProgressMeasurement.threshold:
      case goalProgressMeasurement.binary:
      case goalProgressMeasurement.none:
      default:
        return 0;
    }
  };

  const goalEnd = (goal) => {
    switch (goal.progressMeasurement) {
      case goalProgressMeasurement.continuous:
        return goal.end;
      case goalProgressMeasurement.alignedItems:
        if (goal.aggregationMethod === aggregationMethod.absolute) {
          return goal.end;
        }
        return 100;
      case goalProgressMeasurement.threshold:
      case goalProgressMeasurement.binary:
        return 1;
      case goalProgressMeasurement.none:
      default:
        return 0;
    }
  };

  const goalMetric = (goal) => {
    switch (goal.progressMeasurement) {
      case goalProgressMeasurement.continuous:
      case goalProgressMeasurement.threshold:
        return goal.metric;
      case goalProgressMeasurement.binary:
        return '%';
      case goalProgressMeasurement.none:
        return null;
      case goalProgressMeasurement.alignedItems:
        if (goal.aggregationMethod === aggregationMethod.absolute) {
          return goal.metric;
        }

        return '%';
      default:
        return null;
    }
  };

  return {
    progressMeasurementOptions,
    allowedProgressMeasurementOptions,
    allowedAggregationMethodOptions,

    goalStart,
    goalEnd,
    goalMetric,
  };
}
