import camelCase from 'lodash-es/camelCase';
import upperFirst from 'lodash-es/upperFirst';

import MAlert from 'shared/components/base/MAlert.vue';
import MAvatar from 'shared/components/base/MAvatar.vue';
import MBadge from 'shared/components/base/MBadge.vue';
import MBtn from 'shared/components/base/MBtn.vue';
import MBtnGroup from 'shared/components/base/MBtnGroup.vue';
import MCard from 'shared/components/base/MCard.vue';
import MCardGroup from 'shared/components/base/MCardGroup.vue';
import MCardItem from 'shared/components/base/MCardItem.vue';
import MCheckbox from 'shared/components/base/MCheckbox.vue';
import MChip from 'shared/components/base/MChip.vue';
import MConfirm from 'shared/components/base/MConfirm.vue';
import MContent from 'shared/components/base/MContent.vue';
import MContextMenu from 'shared/components/base/MContextMenu.vue';
import MDatePicker from 'shared/components/base/MDatePicker.vue';
import MDefaultFormActions from 'shared/components/base/MDefaultFormActions.vue';
import MDialog from 'shared/components/base/MDialog.vue';
import MDialogActions from 'shared/components/base/MDialogActions.vue';
import MDivider from 'shared/components/base/MDivider.vue';
import MDraggable from 'shared/components/base/MDraggable.vue';
import MDrawer from 'shared/components/base/MDrawer.vue';
import MDropdown from 'shared/components/base/MDropdown.vue';
import MEmoji from 'shared/components/base/MEmoji.vue';
import MEndlessScrollList from 'shared/components/base/MEndlessScrollList.vue';
import MFeatureGate from 'shared/components/base/MFeatureGate.vue';
import MFocusable from 'shared/components/base/MFocusable.vue';
import MFormItem from 'shared/components/base/MFormItem.vue';
import MHoverMenu from 'shared/components/base/MHoverMenu.vue';
import MIcon from 'shared/components/base/MIcon.vue';
import MIconDisplay from 'shared/components/base/MIconDisplay.vue';
import MInput from 'shared/components/base/MInput.vue';
import MInputNumber from 'shared/components/base/MInputNumber.vue';
import MInputPassword from 'shared/components/base/MInputPassword.vue';
import MItemWrapper from 'shared/components/base/MItemWrapper.vue';
import MLayout from 'shared/components/base/MLayout.vue';
import MLink from 'shared/components/base/MLink.vue';
import MPagination from 'shared/components/base/MPagination.vue';
import MRadio from 'shared/components/base/MRadio.vue';
import MRadioGroup from 'shared/components/base/MRadioGroup.vue';
import MSection from 'shared/components/base/MSection.vue';
import MSelect from 'shared/components/base/MSelect.vue';
import MSelectItem from 'shared/components/base/MSelectItem.vue';
import MSelectable from 'shared/components/base/MSelectable.vue';
import MSlider from 'shared/components/base/MSlider.vue';
import MSlotsContainer from 'shared/components/base/MSlotsContainer.vue';
import MSnackbar from 'shared/components/base/MSnackbar.vue';
import MSpacer from 'shared/components/base/MSpacer.vue';
import MSpinner from 'shared/components/base/MSpinner.vue';
import MSubheader from 'shared/components/base/MSubheader.vue';
import MSwitch from 'shared/components/base/MSwitch.vue';
import MTab from 'shared/components/base/MTab.vue';
import MTable from 'shared/components/base/MTable.vue';
import MTag from 'shared/components/base/MTag.vue';
import MTagList from 'shared/components/base/MTagList.vue';
import MTextField from 'shared/components/base/MTextField.vue';
import MTextarea from 'shared/components/base/MTextarea.vue';
import MTimePicker from 'shared/components/base/MTimePicker.vue';
import MTimeZonePicker from 'shared/components/base/MTimeZonePicker.vue';
import MToolbar from 'shared/components/base/MToolbar.vue';
import MToolbarTitle from 'shared/components/base/MToolbarTitle.vue';
import MTooltip from 'shared/components/base/MTooltip.vue';
import MTransitionExpand from 'shared/components/base/MTransitionExpand.vue';

const components = { MAlert, MAvatar, MBadge, MBtn, MBtnGroup, MCard, MCardGroup, MCardItem, MCheckbox, MChip, MConfirm, MContent, MContextMenu, MDatePicker, MDefaultFormActions, MDialog, MDialogActions, MDivider, MDraggable, MDrawer, MDropdown, MEmoji, MEndlessScrollList, MFeatureGate, MFormItem, MHoverMenu, MIcon, MInput, MInputNumber, MInputPassword, MLayout, MLink, MPagination, MRadio, MRadioGroup, MSection, MSelect, MSelectItem, MSelectable, MSlider, MSlotsContainer, MSnackbar, MSpacer, MSpinner, MSubheader, MSwitch, MTab, MTable, MTag, MTagList, MTextField, MTextarea, MTimeZonePicker, MToolbar, MToolbarTitle, MTooltip, MFocusable, MItemWrapper, MTransitionExpand, MIconDisplay, MTimePicker };

export const init = (app) => {
  for (let i = 0; i < Object.keys(components).length; i++) {
    const component = components[Object.keys(components)[i]];
    const componentName = upperFirst(camelCase(Object.keys(components)[i]));
    app.component(componentName, component);
  }
};
