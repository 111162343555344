<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="43"
      height="43"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.2907 3C5.00353 3 5.5814 3.57787 5.5814 4.2907V37.4186H38.7093C39.4221 37.4186 40 37.9965 40 38.7093C40 39.4221 39.4221 40 38.7093 40H5.15116C3.96311 40 3 39.0369 3 37.8488V4.2907C3 3.57787 3.57787 3 4.2907 3Z"
      fill="#BDBDBD"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.569 15.9847C37.2389 16.2283 37.5845 16.9689 37.3409 17.6388L36.1279 17.1977C37.3409 17.6388 37.341 17.6385 37.3409 17.6388L37.3396 17.6424L37.337 17.6495L37.328 17.6737L37.2952 17.7612C37.2668 17.8362 37.2254 17.9439 37.1716 18.0797C37.0641 18.3511 36.9067 18.7357 36.7046 19.1958C36.3016 20.1129 35.7139 21.3455 34.9804 22.5869C34.2528 23.8182 33.3495 25.1137 32.301 26.1166C31.2692 27.1035 29.9415 27.9535 28.3837 27.9535C26.8178 27.9535 25.64 27.1513 24.7512 26.199C23.9045 25.2918 23.2001 24.1176 22.5826 23.0882L22.5444 23.0245C21.8804 21.9178 21.3056 20.9707 20.664 20.2833C20.047 19.6222 19.5039 19.3488 18.9186 19.3488C18.3079 19.3488 17.5301 19.6979 16.6118 20.506C15.7179 21.2926 14.8468 22.3775 14.0693 23.5178C13.2978 24.6494 12.6501 25.7885 12.1937 26.649C11.9663 27.0779 11.7881 27.4343 11.6676 27.6817C11.6074 27.8053 11.5617 27.9015 11.5316 27.9657L11.4982 28.0373L11.4905 28.054L11.489 28.0574C11.1939 28.7061 10.4287 28.9932 9.77987 28.6983C9.13093 28.4033 8.84398 27.6381 9.13895 26.9892L10.314 27.5233C9.13895 26.9892 9.13882 26.9895 9.13895 26.9892L9.14053 26.9857L9.14369 26.9788L9.15469 26.9549L9.19508 26.8682C9.23006 26.7938 9.28099 26.6866 9.34683 26.5514C9.47844 26.2812 9.67001 25.8981 9.91314 25.4396C10.3979 24.5255 11.0947 23.2983 11.9365 22.0636C12.7724 20.8376 13.7835 19.5563 14.9064 18.5681C16.0048 17.6015 17.3782 16.7674 18.9186 16.7674C20.4845 16.7674 21.6623 17.5697 22.5511 18.522C23.3979 19.4292 24.1022 20.6033 24.7198 21.6328L24.7579 21.6964C25.422 22.8031 25.9968 23.7503 26.6383 24.4377C27.2554 25.0987 27.7985 25.3721 28.3837 25.3721C28.9771 25.3721 29.693 25.039 30.5166 24.2512C31.3235 23.4794 32.0873 22.4086 32.758 21.2736C33.4227 20.1487 33.9644 19.0151 34.3412 18.1574C34.529 17.7302 34.6741 17.3752 34.7716 17.1291C34.8204 17.0061 34.8571 16.9104 34.8812 16.8468L34.9078 16.7759L34.9138 16.7596L34.9149 16.7566C34.915 16.7565 34.9149 16.7567 34.9149 16.7566C35.1587 16.0869 35.8992 15.7411 36.569 15.9847Z"
      fill="#288AA8"
    />
  </svg>
</template>

<script>
export default { name: 'LineChartColored' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
