import { UserScopeTreeHasher } from '@/lib/user-scope-tree_hasher';
import {
  accessPolicy as accessPolicyConfig,
} from 'shared/api/query/configs.json';
import { cmpAccessRight, copyAccessPolicy, copyUserScopeTree, getHighestAccessRight } from '@/lib/access-policy';
import { isNullOrUndefined } from 'shared/lib/object/object';

export const isUninitialized = (link) => isNullOrUndefined(link.patch);

const fillTreeHash = (scopes) => scopes.map((aps) => {
  if (aps.scope.treeHash !== undefined) {
    return aps;
  }
  return { ...aps, scope: { ...aps.scope, treeHash: (new UserScopeTreeHasher()).treeHash(aps.scope) } };
});

export const supplementAccessPolicy = (ap) => ({ ...ap, scopes: fillTreeHash(ap.scopes) });

export const composedAccessPolicy = (link) => {
  const linkCopy = supplementAccessPolicy(copyAccessPolicy(link.link));
  if (isUninitialized(link)) {
    return linkCopy;
  }

  if (!isNullOrUndefined(link.deactivatedAt)) {
    return supplementAccessPolicy({ ...link.patch });
  }

  const patchCopy = supplementAccessPolicy(copyAccessPolicy(link.patch));
  return mergeAccessPolicies(linkCopy, patchCopy);
};

const mergeAccessPolicies = (link, patch) => ({
  account: link.account,
  space: link.space,
  accountAccess: mergeAccessRight(link.accountAccess, patch.accountAccess),
  spaceMemberAccess: mergeAccessRight(link.spaceMemberAccess, patch.spaceMemberAccess),
  spaceOwnerAccess: mergeAccessRight(link.spaceOwnerAccess, patch.spaceOwnerAccess),
  scopes: mergeScopes(link.scopes, patch.scopes),
});

const mergeAccessRight = (link, patch) => {
  if (patch === undefined && link === undefined) {
    return undefined;
  }

  if (patch === null && link === null) {
    return null;
  }

  return getHighestAccessRight([link, patch]);
};

const mergeScopes = (linkScopes = [], patchScopes = []) => {
  const fromLink = linkScopes.map((aps) => {
    let accessType = aps.accessType;

    const partner = patchScopes.find((patchAPS) => aps.scope.treeHash === patchAPS.scope.treeHash);
    if (partner !== undefined) {
      accessType = mergeAccessRight(aps.accessType, partner.accessType);
    }

    return {
      ...aps,
      accessType,
    };
  });

  const withMissingLink = patchScopes.filter((aps) => {
    const partner = linkScopes.find((linkAPS) => aps.scope.treeHash === linkAPS.scope.treeHash);
    return partner === undefined;
  });

  return [...fromLink, ...withMissingLink];
};

const writeHighest = (onto, src) => {
  if (cmpAccessRight(onto.accountAccess, src.accountAccess) < 0) {
    onto.accountAccess = src.accountAccess;
  }

  if (cmpAccessRight(onto.spaceOwnerAccess, src.spaceOwnerAccess) < 0) {
    onto.spaceOwnerAccess = src.spaceOwnerAccess;
  }

  if (cmpAccessRight(onto.spaceMemberAccess, src.spaceMemberAccess) < 0) {
    onto.spaceMemberAccess = src.spaceMemberAccess;
  }

  const scopes = onto.scopes.map((aps) => {
    const partner = src.scopes.find((linkAPS) => linkAPS.scope.treeHash === aps.scope.treeHash);

    if (partner !== undefined && cmpAccessRight(aps.accessType, partner.accessType) < 0) {
      return { ...aps, accessType: partner.accessType };
    }
    return aps;
  });

  const missingScopes = src.scopes.filter((aps) => onto.scopes.find((patchAPS) => patchAPS.scope.treeHash === aps.scope.treeHash) === undefined).map((aps) => ({
    accessType: aps.accessType,
    scope: copyUserScopeTree(aps.scope),
    scopeUsers: [...aps.scopeUsers],
  }));

  return {
    ...onto,
    scopes: [...scopes, ...missingScopes],
  };
};

export const resetLink = (link) => ({ uid: link.uid, deactivatedAt: null, patch: null });

export const applyPatch = (link, inputAP) => {
  if (link.deactivatedAt !== null) {
    return { uid: link.uid, patch: inputAP };
  }

  return buildPatch(link, inputAP);
};

// this only handles patching
const buildPatch = (link, inputAP) => {
  const supplementedLink = supplementAccessPolicy(copyAccessPolicy(link.link));
  let patch = { account: link.link.account, space: link.link.space, scopes: [] };
  if (!isUninitialized(link)) {
    patch = { ...link.patch };
    patch.scopes = fillTreeHash(patch.scopes);
  }

  const merged = mergeAccessPolicies(supplementedLink, patch);
  const hasInputDiff = (inputAT, mergedAT) => cmpAccessRight(inputAT, mergedAT) !== 0;

  const scalarKeys = [
    accessPolicyConfig.edges.accountAccess,
    accessPolicyConfig.edges.spaceOwnerAccess,
    accessPolicyConfig.edges.spaceMemberAccess,
  ];
  for (let i = 0; i < scalarKeys.length; i++) {
    const key = scalarKeys[i];
    if (hasInputDiff(inputAP[key], merged[key])) {
      if (cmpAccessRight(inputAP[key], supplementedLink[key]) >= 0) {
        return { uid: link.uid, patch: { ...patch, [key]: inputAP[key] } };
      }
      return { uid: link.uid, deactivatedAt: new Date(), patch: { ...writeHighest(patch, supplementedLink), [key]: inputAP[key] } };
    }
  }

  if (isNullOrUndefined(inputAP.scopes) || inputAP.scopes.length === 0) {
    return null;
  }

  const inputAPS = inputAP.scopes.find((inputAPS) => {
    const mergedPartner = merged.scopes.find((mergedAPS) => inputAPS.scope.treeHash === mergedAPS.scope.treeHash);
    return hasInputDiff(inputAPS.accessType, mergedPartner.accessType);
  });
  if (inputAPS === undefined) {
    return null;
  }

  const partner = supplementedLink.scopes.find((linkAPS) => inputAPS.scope.treeHash === linkAPS.scope.treeHash);
  let patchPartner;
  if (!isUninitialized(link)) {
    patchPartner = patch.scopes.find((patchAPS) => inputAPS.scope.treeHash === patchAPS.scope.treeHash);
  }

  if (partner === undefined || cmpAccessRight(inputAPS.accessType, partner.accessType) >= 0) {
    const otherScopes = patch.scopes.filter((aps) => aps.scope.treeHash !== inputAPS.scope.treeHash);
    if (patchPartner === undefined) {
      return { uid: link.uid, patch: { ...patch, scopes: [...otherScopes, { ...partner, accessType: inputAPS.accessType }] } };
    }

    return { uid: link.uid, patch: { ...patch, scopes: [...otherScopes, { ...patchPartner, accessType: inputAPS.accessType, deletedAt: inputAPS.deletedAt }] } };
  }

  const withHighest = writeHighest(patch, supplementedLink);
  const otherScopes = withHighest.scopes.filter((aps) => aps.scope.treeHash !== inputAPS.scope.treeHash);
  if (patchPartner === undefined) {
    return {
      uid: link.uid,
      deactivatedAt: new Date(),
      patch: { ...withHighest, scopes: [...otherScopes, { ...partner, accessType: inputAPS.accessType }] },
    };
  }
  return {
    uid: link.uid,
    deactivatedAt: new Date(),
    patch: { ...withHighest, scopes: [...otherScopes, { ...patchPartner, accessType: inputAPS.accessType }] },
  };
};
