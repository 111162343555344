<template>
  <div
    v-show="showSuggestions"
    class="_overlay"
    @click="reset"
  >
    <m-card
      v-if="showSuggestions"
      ref="card"
      :style="style"
      no-padding
      class="_list"
    >
      <m-content
        :padding-x="6"
        :padding-y="6"
      >
        <div class="_inner">
          <div
            v-for="(emoji, index) in filteredEmojiList"
            :ref="`item_${index}`"
            :key="index"
            :title="emoji.name"
            :class="['_emoji', index === focusedIndex ? '-focused' : '']"
            @click="selectEmoji(emoji.content)"
            @mouseover="focusedIndex = emoji.index"
          >
            {{ emoji.content }}
          </div>
        </div>
      </m-content>
    </m-card>
  </div>
</template>

<script>
import { ContextMenuExtension } from '@/components/editor/context-menu-extension';
import { emojis } from 'shared/lib/emojis';
import { insertText } from '@/tiptap/commands/commands';

export default {
  name: 'MEmojiSuggestion',
  props: {
    editor: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      query: null,
      suggestionRange: null,
      x: 0,
      y: 0,
      bottom: 0,
      focusedIndex: 0,
      emojisPerRow: 10,
    };
  },
  computed: {
    emojiList() {
      return Object.keys(emojis).reduce((acc, key) => {
        Object.keys(emojis[key]).forEach((k) => {
          if (key === 'frequentlyUsed') {
            return;
          }
          acc.push({
            name: k,
            content: emojis[key][k],
          });
        });

        return acc;
      }, []);
    },
    filteredEmojiList() {
      return this.emojiList.filter((e) => e.name.includes(this.query.toLowerCase())).map((e, index) => ({ ...e, index }));
    },
    style() {
      if (this.bottom !== 0) {
        return {
          width: '34.1rem',
          position: 'fixed',
          left: `${this.x}px`,
          bottom: `${this.bottom}px`,
          zIndex: 1070,
        };
      }
      return {
        width: '34.1rem',
        position: 'fixed',
        left: `${this.x}px`,
        top: `${this.y + 20}px`,
        zIndex: 1070,
      };
    },
    showSuggestions() {
      return this.query !== null && this.query.length > 0 && this.filteredEmojiList.length > 0;
    },
    selectedEmoji() {
      const f = this.filteredEmojiList.filter((e) => e.index === this.focusedIndex);
      if (f.length === 0) {
        return '';
      }
      return f[0].content;
    },
    lastIndex() {
      return this.filteredEmojiList[this.filteredEmojiList.length - 1].index;
    },
  },
  methods: {
    blur() {
      this.reset();
    },
    setScrollPosition(val, oldVal) {
      if (typeof this.$refs[`item_${val}`] === 'undefined' || typeof this.$refs[`item_${val}`][0] === 'undefined' || typeof this.$refs.card === 'undefined') {
        return;
      }

      const currentItem = this.$refs[`item_${val}`][0];
      const card = this.$refs.card.$el;

      if (oldVal < val && currentItem.clientHeight + currentItem.offsetTop > card.clientHeight) {
        card.scrollTop = currentItem.clientHeight * (Math.floor(val / 10) + 1) - card.clientHeight + 15;
        return;
      }
      if (currentItem.offsetTop < card.scrollTop) {
        card.scrollTop = currentItem.clientHeight * Math.floor(val / 10);
      }
    },
    reset() {
      this.query = null;
      this.focusedIndex = 0;
      this.suggestionRange = null;
    },
    newEmoji() {
      return new ContextMenuExtension({
        matcher: {
          char: ':',
          allowSpaces: false,
          startOfLine: false,
        },
        onEnter: ({ query, range, virtualNode }) => {
          this.query = query;
          this.suggestionRange = range;
          this.renderPopup(virtualNode);
        },
        onChange: ({ query, range, virtualNode }) => {
          this.query = query;
          this.suggestionRange = range;
          this.renderPopup(virtualNode);
        },
        onExit: () => {
          this.reset();
        },
        onKeyDown: ({ event }) => {
          if (event.key === 'ArrowUp') {
            event.stopPropagation();
            event.preventDefault();
            this.moveFocusUp();
            return true;
          }
          if (event.key === 'ArrowDown') {
            event.stopPropagation();
            event.preventDefault();
            this.moveFocusDown();
            return true;
          }
          if (event.key === 'ArrowRight') {
            event.stopPropagation();
            event.preventDefault();
            this.moveFocusRight();
            return true;
          }
          if (event.key === 'ArrowLeft') {
            event.stopPropagation();
            event.preventDefault();
            this.moveFocusLeft();
            return true;
          }
          if (event.key === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            this.enterHandler();
            return true;
          }
          return false;
        },
      });
    },
    moveFocusDown() {
      if (this.focusedIndex + this.emojisPerRow > this.lastIndex) {
        const oldVal = this.focusedIndex;
        this.focusedIndex = this.lastIndex;
        this.setScrollPosition(this.focusedIndex, oldVal);
        return;
      }

      const oldVal = this.focusedIndex;
      this.focusedIndex += this.emojisPerRow;
      this.setScrollPosition(this.focusedIndex, oldVal);
    },
    moveFocusUp() {
      if (this.focusedIndex - this.emojisPerRow < 0) {
        const oldVal = this.focusedIndex;
        this.focusedIndex = 0;
        this.setScrollPosition(this.focusedIndex, oldVal);
        return;
      }

      const oldVal = this.focusedIndex;
      this.focusedIndex -= this.emojisPerRow;
      this.setScrollPosition(this.focusedIndex, oldVal);
    },
    moveFocusRight() {
      if (this.focusedIndex + 1 > this.lastIndex) {
        return;
      }

      const oldVal = this.focusedIndex;
      this.focusedIndex += 1;
      this.setScrollPosition(this.focusedIndex, oldVal);
    },
    moveFocusLeft() {
      if (this.focusedIndex - 1 < 0) {
        return;
      }

      const oldVal = this.focusedIndex;
      this.focusedIndex -= 1;
      this.setScrollPosition(this.focusedIndex, oldVal);
    },
    enterHandler() {
      this.selectEmoji(this.selectedEmoji);
    },
    selectEmoji(emoji) {
      const node = this.editor.schema.nodes.emoji.create({ emoji });
      const transaction = this.editor.view.state.tr.replaceRangeWith(this.suggestionRange.from, this.suggestionRange.to, node);
      this.editor.view.dispatch(transaction);
      insertText(' ')(this.editor.view.state, this.editor.view.dispatch);
    },
    renderPopup(node) {
      if (this.popup) {
        return;
      }

      const dimensions = node.getBoundingClientRect();
      this.x = dimensions.x;
      this.y = dimensions.y;
    },
  },
  watch: {
    showSuggestions(show) {
      if (show) {
        this.$nextTick(() => {
          if (typeof this.$refs.card !== 'undefined' && this.y + this.$refs.card.$el.clientHeight > document.body.clientHeight) {
            this.bottom = document.body.clientHeight - this.y + 5;
          }
        });
        return;
      }

      this.bottom = 0;
    },
    query() {
      this.focusedIndex = 0;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: var(--viewport-height-100);

    ._list {
      max-height: 40rem;
      overflow: auto;

      ._inner {
        display: flex;
        flex-wrap: wrap;

        ._emoji {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.1rem;
          height: 3.1rem;
          font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, sans-serif;
          font-size: 2.2rem;
          cursor: pointer;
          border-radius: $border-radius-sm;

          &.-focused {
            background-color: $hover-color-dark;
          }
        }
      }
    }
  }
</style>
