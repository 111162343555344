<template>
  <div class="number-scope">
    <m-select
      v-if="!hideOp"
      :value="selectedType"
      :items="intervalTypes"
      :read-only="readOnly"
      :disabled="disabled"
      class="_op"
      @input="updateSelectedType"
    />
    <m-input-number
      :value="number"
      :read-only="readOnly"
      :disabled="disabled"
      :min="0"
      :formatter="formatter"
      :parser="parser"
      class="_input"
      @input="updateNumber"
    />
  </div>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { intervalType } from 'shared/constants.json';
import { isEqual } from 'lodash-es';
import { numberFormatter, numberParser } from '@/lib/props/number';

export default {
  name: 'NumberScope',
  props: {
    value: {
      type: Object,
      required: true,
    },
    numberFormat: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideOp: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  emits: ['input', 'update:value', 'change'],
  data() {
    return {
      number: 0,
      selectedType: intervalType.eq,
      intervalTypes: [
        { text: '<', value: intervalType.lt },
        { text: '≤', value: intervalType.le },
        { text: '=', value: intervalType.eq },
        { text: '≥', value: intervalType.ge },
        { text: '>', value: intervalType.gt },
      ],
    };
  },
  computed: {
    formatter() {
      return numberFormatter(this.numberFormat, this.userLang);
    },
    parser() {
      return numberParser(this.numberFormat, this.userLang);
    },
    rangeKey() {
      if ([intervalType.lt, intervalType.le].includes(this.selectedType)) {
        return 'maxType';
      }

      return 'minType';
    },
    numberKey() {
      if ([intervalType.lt, intervalType.le].includes(this.selectedType)) {
        return 'max';
      }
      return 'min';
    },
    numberRange() {
      return {
        [this.numberKey]: this.number,
        [this.rangeKey]: this.selectedType,
      };
    },
  },
  methods: {
    updateSelectedType(value) {
      this.selectedType = value;
      this.emitUpdate({ ...this.value, numberRange: this.numberRange });
    },
    updateNumber(value) {
      this.number = value;
      this.emitUpdate({ ...this.value, numberRange: this.numberRange });
    },
    setValues(numberRange) {
      if ([intervalType.lt, intervalType.le].includes(numberRange.maxType)) {
        this.selectedType = numberRange.maxType;
        this.number = numberRange.max;
        return;
      }

      this.number = numberRange.min;
      this.selectedType = numberRange.minType;
    },
    updateValues() {
      if (this.value === null
        || typeof this.value.numberRange === 'undefined'
        || this.value.numberRange === null
      ) {
        this.emitUpdate({ ...this.value, numberRange: this.numberRange });
        return;
      }
      this.setValues(this.value.numberRange);
    },
    emitUpdate(val) {
      this.$emit('input', val);
      this.$emit('update:value', val);
      this.$emit('change', val);
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value === null || this.value === undefined) {
          return;
        }

        if (isEqual(this.value.numberRange, this.numberRange)) {
          return;
        }

        this.updateValues();
      },
      deep: true,
    },
  },
  created() {
    this.updateValues();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .number-scope {
    display: flex;
    width: inherit;

    ._op {
      margin-right: .8rem;
    }

    ._input {
      width: inherit;
    }
  }
</style>
