import useDebounce from '@/composables/debounce';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import usePublishedViewLogin from '@/composables/published-view/login';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { constrainWidth } from '@/lib/side-peek';
import { personalAppSettings as personalAppSettingsConfig } from 'shared/api/query/configs.json';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { goalViewMode as viewMode } from 'shared/constants.json';

const MIN_CONTENT_WIDTH = 430;
const SIDEBAR_WIDTH = 240;

export default function useSidebarSvc(loggedInUser) {
  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const { debounce } = useDebounce();
  const overwriteDesktopSidebarHidden = ref(false);
  const { isPublishedView } = usePublishedViewLogin();

  const desktopSidebarHidden = computed(() => {
    if (overwriteDesktopSidebarHidden.value || store.state.breakpoint.smAndDown) {
      return true;
    }
    return personalAppSettings.value.desktopSidebarHidden;
  });

  const sidebarWidth = computed(() => {
    if (desktopSidebarHidden.value || isPublishedView.value) {
      return 0;
    }
    return SIDEBAR_WIDTH;
  });

  const { personalAppSettings, updateSingle } = usePersonalAppSettings(loggedInUser);

  const updateHideDesktopSideBar = (value) => {
    let updateValue = value;
    if (overwriteDesktopSidebarHidden.value || (!overwriteDesktopSidebarHidden.value && desktopSidebarHidden.value)) {
      const query = { ...route.query };
      delete query.goalId;
      router.push({ ...route, query });
      updateValue = false;
    }

    updateSingle({
      uid: personalAppSettings.value.uid,
      [personalAppSettingsConfig.edges.desktopSidebarHidden]: updateValue,
    });
  };

  const sidePeekActive = computed(() => personalAppSettings.value?.goalViewMode === viewMode.sidePeek && route.query.goalId !== undefined);
  const sidePeekWidth = computed(() => {
    if (!sidePeekActive.value) {
      return 0;
    }
    if (personalAppSettings.value?.goalSidePeekWidth === undefined) {
      return 0;
    }
    return constrainWidth(personalAppSettings.value.goalSidePeekWidth, windowWidth.value);
  });
  const windowWidth = ref(window.innerWidth);
  const padding = computed(() => {
    if (store.state.breakpoint.smAndDown) {
      return 0;
    }
    return 96 * 2;
  });

  const contentWidth = computed(() => windowWidth.value - sidebarWidth.value - padding.value);
  const containerWidthWatch = computed(() => windowWidth.value - SIDEBAR_WIDTH - sidePeekWidth.value);

  const containerWidth = computed(() => windowWidth.value - sidebarWidth.value - sidePeekWidth.value);

  const setWindowWidth = () => {
    const update = () => {
      windowWidth.value = window.innerWidth;
    };
    debounce(update, 100);
  };

  onMounted(() => {
    window.addEventListener('resize', setWindowWidth);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', setWindowWidth);
  });

  watch(containerWidthWatch, (width) => {
    if (width < MIN_CONTENT_WIDTH) {
      overwriteDesktopSidebarHidden.value = true;
      return;
    }
    overwriteDesktopSidebarHidden.value = false;
  });

  watch(sidePeekActive, (active) => {
    if (!active) {
      overwriteDesktopSidebarHidden.value = false;
    }
  });

  return {
    desktopSidebarHidden,
    updateHideDesktopSideBar,
    containerWidth,
    contentWidth,
  };
}
