<template>
  <div class="space-navigation">
    <m-content
      :padding-x="8"
      :padding-y="9"
    >
      <div class="_search">
        <m-text-field
          v-model:value="search"
          class="_input-field"
          auto-focus
          :placeholder="$t('spaceNavigation.searchPlaceholder', { title })"
          has-background
          :input-style="{ backgroundColor: $colors.grey.lighten8 }"
        />
      </div>
    </m-content>
    <m-divider none />
    <div class="_items">
      <space-tree-item
        v-for="item in spaces"
        :key="item.uid"
        :space="item"
        :indentation="0"
        :all-spaces="allSpaces"
        :property="property"
        :language="userLang"
        :pins="pins"
      />
    </div>
  </div>
</template>

<script>
import SpaceTreeItem from '@/components/navigation/SpaceTreeItem.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import useProperties from '@/composables/property/property';
import useSelectedViews from '@/composables/saved-views/selected-views';
import useSpaces from '@/composables/space/spaces';
import { findInArray } from 'shared/lib/array/array';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'SpaceNavigation',
  props: {
    pins: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['space-created'],
  setup() {
    const { getSelectedViewViewIdForApplication } = useSelectedViews();
    const { accountHasFeature, userHasRight } = useAccess();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { personalAppSettings } = usePersonalAppSettings(loggedInUser);
    const { spaceProperty } = useProperties();
    const spacesSvc = useSpaces();
    return {
      getSelectedViewViewIdForApplication,
      accountHasFeature,
      userHasRight,
      account: loggedInUserAccount,
      personalAppSettings,

      property: spaceProperty,
      allSpaces: spacesSvc.spaces,
      spacesSvc,
      userLang,
    };
  },
  components: { SpaceTreeItem },
  data() {
    return {
      search: '',
      expanded: true,
      textByLang,
    };
  },
  computed: {
    spaces() {
      const mapChildren = (c) => {
        const item = findInArray({ haystack: this.allSpaces, needle: c.uid });
        if (item === null) {
          return c;
        }

        const children = this.allSpaces.filter((s) => s.parents.filter((p) => p.uid === item.uid).length > 0);
        const match = this.search === '' || item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1;

        if (children.length === 0) {
          return {
            ...item,
            match,
            hasChildrenMatches: false,
            children: [],
          };
        }

        return {
          ...item,
          match,
          children: children.map(mapChildren),
        };
      };

      const hasMatch = (res, next) => {
        if (next.match) {
          return true;
        }

        if (next.children.length === 0) {
          return res;
        }

        return next.children.reduce(hasMatch, res);
      };

      const reduceMatches = (res, next) => {
        if (next.children.length === 0 && !next.match) {
          return res;
        }

        if (next.match || next.children.reduce(hasMatch, false)) {
          res.push({
            ...next,
            children: next.children.reduce(reduceMatches, []),
          });
        }

        return res;
      };

      return this.allSpaces.filter((o) => o.parents.length === 0).map(mapChildren).reduce(reduceMatches, []);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .space-navigation {
    ._disclaimer {
      position: relative;
      background-color: map_get($grey, 'lighten-6');

      ._disclaimer-inner {
        display: flex;

        ._icon {
          margin-top: .5rem;
          margin-right: 1rem;
        }

        ._text {
          width: calc(100% - 5rem);

          ._bottom {
            color: $font-color-secondary;
          }
        }

        ._close {
          position: absolute;
          top: .6rem;
          right: .6rem;
        }
      }
    }

    ._items {
      max-height: 70vh;
      padding: .4rem 0;
      overflow: auto;

      @media (max-width: $screen-size-md) {
        max-height: var(--viewport-height-100);
      }
    }
  }
</style>
