<template>
  <full-screen-spinner
    v-if="dataSourceLoading"
    :height="48"
  />
  <div
    v-else
    class="data-source-editor"
  >
    <div
      v-if="localEntity.accessToken === '' || expired"
      class="data-source-editor"
    >
      <div class="_text">
        {{ disconnectedHint }}
      </div>
      <m-btn
        @click="initOAuth"
      >
        <m-icon
          :type="dataSourceIcon"
          class="_icon"
        />
        {{ $t(`dataSourceEditor.connect`, {provider: $t(`dataSources.${camelCase(localEntity.type)}`) }) }}
      </m-btn>
    </div>
    <div
      v-else
      class="data-source-editor"
    >
      <m-alert
        v-if="showSuccessMessage"
        :message="$t('dataSourceEditor.successfullyConnected')"
        type="success"
        class="_alert"
      />
      <div class="_item">
        <div class="_label">
          {{ $t('dataSourceEditor.nameLabel') }}
        </div>
        <m-text-field v-model:value="localEntity.name" />
      </div>
      <m-alert type="info">
        {{ shareHint }}
      </m-alert>
      <div class="_item">
        <m-switch
          v-model:value="localEntity.shared"
          :label="$t('dataSourceEditor.sharedLabel')"
        />
      </div>
      <div class="_actions">
        <div class="_btns">
          <m-btn
            class="_btn"
            @click="$emit('close')"
          >
            {{ $t('general.cancel') }}
          </m-btn>
          <m-btn
            color="primary"
            class="_btn"
            :loading="updateDataSourceLoading"
            @click="save"
          >
            {{ $t('general.save') }}
          </m-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import useDataSources from '@/composables/integrations/datasources';
import { camelCase } from 'lodash-es';
import { connectUrl, dataSourceSignInIcon } from '@/lib/data-source';
import { copy } from 'shared/lib/copy';
import { dataSourceInstallStatus } from '@/constants.json';
import { dataSourceType } from 'shared/constants.json';
import { initOAuthInstall } from '@/lib/oauth-redirect';

export default {
  name: 'DataSourceEditor',
  props: {
    entity: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['close'],
  components: { FullScreenSpinner },
  setup() {
    const { fetchDataSource, updateDataSource, updateDataSourceLoading } = useDataSources();
    return {
      fetchDataSource,
      updateDataSource,
      updateDataSourceLoading,
    };
  },
  data() {
    return {
      localEntity: { name: '', shared: false, healthy: true, dead: false },
      showSuccessMessage: false,
      dataSourceLoading: false,
      dataSourceType,
      camelCase,
    };
  },
  computed: {
    connectUrl() {
      return connectUrl(this.entity);
    },
    dataSourceIcon() {
      return dataSourceSignInIcon(this.entity);
    },
    shareHint() {
      if ([dataSourceType.excel, dataSourceType.sheets].includes(this.entity.type)) {
        return this.$t('dataSourceEditor.shareHintDocs');
      }
      return this.$t('dataSourceEditor.shareHint');
    },
    expired() {
      return !this.localEntity.healthy || this.localEntity.dead;
    },
    disconnectedHint() {
      if (this.expired) {
        return this.$t('dataSourceEditor.refreshHint', { provider: this.$t(`dataSources.${camelCase(this.localEntity.type)}`) });
      }
      return this.$t('dataSourceEditor.connectHint', { provider: this.$t(`dataSources.${camelCase(this.localEntity.type)}`) });
    },
  },
  methods: {
    initOAuth() {
      this.dataSourceLoading = true;
      initOAuthInstall(this.connectUrl).then((event) => {
        switch (event.data.status) {
          case dataSourceInstallStatus.error:
          case dataSourceInstallStatus.notAllowed:
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            break;
          case dataSourceInstallStatus.success:
            this.$showSnackbar({ color: 'success', message: this.$t('dataSource.successfullySynced') });
            this.fetchDataSource({ uid: this.entity.uid, type: this.entity.type }).then((dataSource) => {
              this.localEntity = copy(dataSource);
            }).catch(() => {
              this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            });
            break;
          default:
            break;
        }
      }).finally(() => {
        this.dataSourceLoading = false;
      });
    },
    save() {
      this.updateDataSource(this.localEntity).then((dataSource) => {
        this.localEntity = copy(dataSource);
        this.$emit('close');
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
  mounted() {
    if (this.entity !== null) {
      this.localEntity = copy(this.entity);
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .data-source-editor {
    ._icon {
      margin-right: 1.2rem;
    }

    ._label {
      color: $font-color-secondary;
    }

    ._item {
      margin-bottom: 2rem;
    }

    ._text {
      margin-bottom: 2rem;
      color: $font-color-secondary;
    }

    ._actions {
      display: flex;

      ._btns {
        display: flex;
        margin-left: auto;

        ._btn {
          margin-left: .4rem;
        }
      }
    }

    ._alert {
      margin-bottom: 2rem;
    }
  }
</style>
