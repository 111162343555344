<template>
  <div class="heatmap">
    <div class="_main">
      <div
        ref="left"
        class="_left"
      >
        <div class="_header">
          <div class="_left-area">
            <div class="_header-title">
              {{ headerTitle }}
            </div>
            <div
              v-if="hasFactors"
              class="_actions"
            >
              <m-btn
                hide-border
                small
                icon="expand-minus"
                icon-size="18"
                class="_btn _icon"
                @click="setRows(false)"
              />
              <m-btn
                hide-border
                small
                icon="expand-plus"
                icon-size="18"
                class="_btn _icon"
                @click="setRows(true)"
              />
            </div>
          </div>
          <div class="_score">
            <div class="_inner">
              <div class="_label">
                {{ $t('heatmap.all') }}
              </div>
            </div>
          </div>
        </div>
        <div class="_rows">
          <div
            v-for="row in rows"
            :key="row.itemId"
            class="_row-container"
          >
            <m-link
              :no-pointer="row.to === ''"
              :to="row.to"
              inherit-color
            >
              <div
                :class="['_row',row.class]"
              >
                <m-btn
                  v-if="row.children.length > 0"
                  class="_expand"
                  hide-border
                  light
                  small
                  :icon="expandIcon(row.showChildren)"
                  fab
                  @click.prevent="row.showChildren = !row.showChildren"
                />
                <div class="_title">
                  {{ row.name }}
                </div>
                <div :class="['_all-value',row.class]">
                  {{ formatValue(getValue(row.itemId, 0), true) }}
                </div>
              </div>
            </m-link>
            <m-transition-expand>
              <div
                v-if="row.showChildren"
                class="_children"
              >
                <m-link
                  v-for="child in row.children"
                  :key="child.itemId"
                  :to="child.to"
                  :no-pointer="child.to === ''"
                  inherit-color
                >
                  <div
                    :class="['_row',child.class]"
                  >
                    <div class="_expand" />
                    <div class="_title">
                      {{ child.name }}
                    </div>
                    <div class="_all-value">
                      {{ formatValue(getValue(child.itemId, 0), true) }}
                    </div>
                  </div>
                </m-link>
              </div>
            </m-transition-expand>
          </div>
        </div>
      </div>
      <div class="_right">
        <div class="_header">
          <div
            v-for="column in columns"
            :key="column.optionId"
            :class="['_column', '-header', column.class]"
          >
            <div class="_label">
              {{ column.name }}
            </div>
          </div>
        </div>
        <div
          v-for="row in rows"
          :key="row.itemId"
          class="_row-container"
        >
          <div class="_row">
            <div
              v-for="column in columns"
              :key="column.optionId"
              :class="['_column', '-tile', column.class, row.class, loading ? '-loading': '']"
              :style="{backgroundColor: colorOfValue(getValue(row.itemId, column.optionId))}"
              @click="goToTile(row, column)"
            >
              {{ formatValue(getValue(row.itemId, column.optionId)) }}
            </div>
          </div>
          <m-transition-expand>
            <div
              v-if="row.showChildren"
              class="_children"
            >
              <div
                v-for="child in row.children"
                :key="child.itemId"
                class="_row"
              >
                <div
                  v-for="column in columns"
                  :key="column.optionId"
                  :class="['_column', '-tile', column.class, child.class, loading ? '-loading': '']"
                  :style="{backgroundColor: colorOfValue(getValue(child.itemId, column.optionId))}"
                  @click="goToTile(child, column)"
                >
                  {{ formatValue(getValue(child.itemId, column.optionId)) }}
                </div>
              </div>
            </div>
          </m-transition-expand>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useForm from '@/composables/form/form';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import { DELTA } from '@/lib/constants';
import { UserFilterHandler } from '@/lib/filter/user/handler';
import { appendOptions } from '@/lib/filter/property-option/filter';
import { dataAggregation } from 'shared/num_constants.json';
import { doGetChartData } from '@/api';
import { fieldType } from 'shared/constants.json';
import { fieldsOfFactor, fieldsWithoutFactor } from '@/lib/form';
import { getColorForDelta, getColorForPercentage } from '@/lib/charts/colors';
import { getFormattedValue } from '@/lib/charts/format';
import { textByLang } from 'shared/lib/language';

const FACTOR = 'factor';
const ALL_ID = 0;

export default {
  name: 'Heatmap',
  props: {
    filterTree: {
      type: Object,
      default: () => null,
    },
    intervalConfig: {
      type: Object,
      default: () => null,
    },
    groupBy: {
      type: Object,
      default: () => null,
    },
    viewMode: {
      type: String,
      default: DELTA,
    },
    filterByNewestAnswer: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { userProperties } = useProperties();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { formId, formAvailable, form, lang } = useForm();
    return { userProperties, account: loggedInUserAccount, formId, formAvailable, form, lang };
  },
  emits: ['update-filter'],
  data() {
    return {
      loading: true,
      chartData: {},
      aggregation: dataAggregation.favorability,
      rows: [],
      FACTOR,
    };
  },
  computed: {
    gqlFilter() {
      const handler = new UserFilterHandler(true);
      return handler.Translate(this.filterTree).filterTree;
    },
    headerTitle() {
      if (this.hasFactors) {
        return this.$t('formResults.factorsAndItems');
      }
      return this.$t('formResults.items');
    },
    hasFactors() {
      return this.form.formFactors.length > 0;
    },
    groupByQueryArray() {
      if (this.groupBy === null) {
        return [];
      }
      return [{
        property: this.groupBy,
        selectedOptions: this.groupBy.options,
      }];
    },
    columns() {
      if (this.groupBy === null) {
        return [];
      }
      const optionColumns = this.groupBy.options.map((o) => ({
        name: textByLang(o.label, this.lang),
        optionId: o.uid,
      }));
      return [...optionColumns];
    },
    showDelta() {
      return this.viewMode === DELTA;
    },
  },
  methods: {
    expandIcon(expanded) {
      if (expanded) {
        return 'caret-down';
      }
      return 'caret-right';
    },
    setRows(expandAll) {
      const fieldRow = (field, classes) => ({
        name: field.title[this.lang],
        itemId: field.uid,
        to: { ...this.$route, query: { fieldId: field.uid } },
        type: field.type,
        class: classes,
        children: [],
      });

      if (this.form.formFactors.length === 0) {
        this.rows = this.form.orderItems.map((item) => fieldRow(item.field)).filter((i) => [FACTOR, fieldType.opinionScale].includes(i.type));
        return;
      }

      let res = this.form.formFactors.reduce((acc, next) => [
        ...acc,
        {
          name: next.title[this.lang],
          itemId: next.uid,
          to: { ...this.$route, query: { factorId: next.uid } },
          class: '-heading',
          type: FACTOR,
          children: fieldsOfFactor(this.form, next).map((field) => fieldRow(field, '-small')).filter((i) => [FACTOR, fieldType.opinionScale].includes(i.type)),
          showChildren: expandAll,
        },
      ], []);

      const ungroupedFields = {
        name: this.$t('heatmap.ungrouped'),
        itemId: 0,
        to: '',
        class: '-empty -heading',
        children: fieldsWithoutFactor(this.form).map((field) => fieldRow(field, '-small')).filter((i) => [FACTOR, fieldType.opinionScale].includes(i.type)),
        showChildren: expandAll,
      };
      if (ungroupedFields.children.length > 0) {
        res = [...res, ungroupedFields];
      }
      this.rows = res;
    },
    goToTile(row, column) {
      let to = { ...this.$route, query: { fieldId: row.itemId } };
      if (row.type === FACTOR) {
        to = { ...this.$route, query: { factorId: row.itemId } };
      }

      let updatedFilter = appendOptions({
        filterTree: this.filterTree,
        property: this.groupBy,
        propertyOptions: [{ uid: column.optionId }],
        account: { uid: this.account.uid },
      });
      if (column.optionId === 0) {
        updatedFilter = this.filterTree;
      }
      this.$emit('update-filter', updatedFilter);

      this.$router.push(to);
    },
    getValue(itemId, optionId) {
      if (typeof this.chartData[itemId] === 'undefined') {
        return null;
      }
      const filtered = this.chartData[itemId].series.filter((i) => i.groupedByOptionId === optionId);
      if (filtered.length === 0) {
        return null;
      }
      const val = filtered[0].data[0].value;
      if (optionId === ALL_ID || !this.showDelta) {
        return val;
      }

      const compareValue = this.getValue(itemId, ALL_ID);
      if (val === null || compareValue === null) {
        return null;
      }
      return val - compareValue;
    },
    formatValue(value, isAll = false) {
      const f = getFormattedValue(value, this.aggregation);
      if (value !== null && value > 0 && this.showDelta && !isAll) {
        return `+${f}`;
      }
      return f;
    },
    colorOfValue(value) {
      if (this.showDelta) {
        return getColorForDelta(value);
      }
      return getColorForPercentage(value);
    },
    chartsByForm(form) {
      return [
        ...form.formFactors.map((factor) => this.chartByFactor(factor)),
        ...form.orderItems.map((item) => this.chartByField(item.field)),
      ];
    },
    chartByFactor(factor) {
      return {
        uid: factor.uid,
        factor,
        aggregation: this.aggregation,
        groupBy: this.groupByQueryArray,
        filterByNewestAnswer: this.filterByNewestAnswer,
      };
    },
    chartByField(field) {
      return {
        uid: field.uid,
        dataFields: [field],
        aggregation: this.aggregation,
        groupBy: this.groupByQueryArray,
        filterByNewestAnswer: this.filterByNewestAnswer,
      };
    },
    getResultData() {
      if (!this.formAvailable) {
        return;
      }

      this.setLoading(true);
      doGetChartData({
        formId: this.formId,
        charts: this.chartsByForm(this.form),
        userScopeTree: this.filterTree,
        intervalConfig: this.intervalConfig,
      }).then((response) => {
        this.setLoading(false);
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.chartData = response.data;
      });
    },
    setLoading(isLoading) {
      this.loading = isLoading;
      this.$store.commit('FORM_RESULT_SET_LOADING', isLoading);
    },
  },
  watch: {
    formId() {
      this.getResultData();
    },
    gqlFilter() {
      this.getResultData();
    },
    intervalConfig() {
      this.getResultData();
    },
    groupBy() {
      this.getResultData();
    },
  },
  created() {
    this.getResultData();
    this.setRows(true);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  /* stylelint-disable no-descending-specificity */
  $width: 1070px + 53px;

  .heatmap {
    ._main {
      display: flex;

      ._left {
        position: sticky;
        left: 0;
        z-index: 1;
        flex-shrink: 0;
        width: 40rem;
        min-width: 30rem;
        padding-left: 2.4rem;
        background-color: white;

        &:hover {
          ._header {
            ._left-area {
              ._actions {
                opacity: 1;
              }
            }
          }
        }

        ._header {
          position: sticky;
          top: 0;
          z-index: 1;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          height: 9rem;
          padding-left: 1rem;
          background-color: white;

          ._left-area {
            display: flex;
            flex-grow: 1;
            align-items: center;
            margin-bottom: .8rem;

            ._header-title {
              font-size: $font-size-2;
              font-weight: $font-weight-semibold;
              color: $font-color-secondary;
              text-transform: uppercase;
            }

            ._actions {
              display: flex;
              margin-left: 1.2rem;
              opacity: 0;
              transition: opacity .2s ease;

              ._btn {
                margin-right: .2rem;
              }

              ._icon {
                color: $font-color-secondary;
              }
            }
          }

          ._score {
            position: relative;
            width: 5rem;
            height: 9rem;

            ._inner {
              position: absolute;
              bottom: 3.6rem;
              left: .2rem;
              z-index: 2;
              display: flex;
              align-items: flex-end;
              width: 10rem;
              height: 4rem;
              background-color: white;
              transform: rotate(-45deg);
            }

            ._label {
              overflow: hidden;
              font-weight: $font-weight-bold;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        ._row {
          display: flex;
          align-items: center;
          height: 5rem;
          padding-left: 1rem;
          border-radius: $border-radius-sm;

          ._expand {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            width: 3rem;
            margin-bottom: -.2rem;
            margin-left: -.8rem;
            color: $font-color-secondary;
          }

          ._title {
            display: -webkit-box;
            margin-right: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          &:hover {
            background-color: $hover-color;
          }

          &.-heading {
            ._title {
              font-size: $font-size-5;
              font-weight: $font-weight-semibold;
            }
          }

          &.-small {
            font-size: $font-size-3;
          }

          ._all-value {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            width: 5rem;
            height: 5rem;
            font-size: $font-size-4;
            font-weight: $font-weight-bold;

            &.-empty {
              display: none;
            }
          }
        }
      }

      ._right {
        padding-right: calc((100vw - #{$width}) / 2);
        margin-left: .2rem;

        @media screen and (max-width: $width) {
          padding-right: 2.4rem;
        }

        ._row {
          display: flex;
          align-items: center;
          min-height: 5rem;
        }

        ._column {
          flex-shrink: 0;
          width: 5rem;

          &.-tile {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 5rem;
            border: 1px solid white;

            &.-empty {
              display: none;
            }

            &.-loading {
              animation: pulse 1s infinite;

              @keyframes pulse {
                0% {
                  background-color: map_get($grey, 'lighten-4');
                }

                70% {
                  background-color: map_get($grey, 'lighten-2');
                }

                100% {
                  background-color: map_get($grey, 'lighten-4');
                }
              }
            }

            &:hover {
              cursor: pointer;
              opacity: .5;
            }
          }
        }

        ._header {
          position: sticky;
          top: 0;
          display: flex;
          align-items: center;
          background-color: white;

          ._column {
            position: relative;
            height: 9rem;

            ._label {
              position: absolute;
              bottom: 4rem;
              left: .8rem;
              width: 10rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }

    /* stylelint-enable no-descending-specificity */
  }

</style>
