<template>
  <div class="aligned-item">
    <div class="_left">
      <m-checkbox
        :value="isChecked"
        :disabled="disabled"
        :light="disabled"
        @change="handleCheckboxChange"
      >
        <m-tooltip
          class="_title"
          placement="bottom"
          :disabled="!isTitleTruncated"
          :mouse-enter-delay=".5"
        >
          <template #title>
            {{ goal.title }}
          </template>
          <item-title
            v-model:is-title-truncated="isTitleTruncated"
            :title="goal.title"
          />
        </m-tooltip>
      </m-checkbox>
    </div>
    <div
      v-if="parent.progressMeasurement === goalProgressMeasurement.alignedItems && parent.aggregationMethod === aggregationMethod.absolute"
      class="_right _metric"
    >
      {{ metric }}
    </div>
    <div
      v-if="showWeights"
      class="_right"
    >
      <div class="_share">
        {{ share }}%
      </div>
      <div class="_select">
        <m-select
          :disabled="disabled || !isChecked"
          small
          :description="$t('alignedItem.weightDescription')"
          btn
          show-description
          :items="items"
          :value="weight"
          @input="handleWeightChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ItemTitle from '@/components/ItemTitle.vue';
import useProgressMeasurement from '@/composables/goal/progress-measurement';
import { aggregationMethod, goalProgressMeasurement } from 'shared/constants.json';
import { ref } from 'vue';

export default {
  name: 'AlignedItem',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
    weight: {
      type: Number,
      required: true,
    },
    totalWeight: {
      type: Number,
      required: true,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showWeights: {
      type: Boolean,
      default: false,
    },
  },
  components: { ItemTitle },
  setup() {
    const { goalMetric } = useProgressMeasurement();
    const isTitleTruncated = ref(false);
    return { aggregationMethod, goalProgressMeasurement, goalMetric, isTitleTruncated };
  },
  emits: ['weight-change', 'is-checked-change'],
  data() {
    return {
      items: [
        {
          text: '10x',
          value: 10,
        },
        {
          text: '9x',
          value: 9,
        },
        {
          text: '8x',
          value: 8,
        },
        {
          text: '7x',
          value: 7,
        },
        {
          text: '6x',
          value: 6,
        },
        {
          text: '5x',
          value: 5,
        },
        {
          text: '4x',
          value: 4,
        },
        {
          text: '3x',
          value: 3,
        },
        {
          text: '2x',
          value: 2,
        },
        {
          text: '1x',
          value: 1,
        },
        {
          text: '0.5x',
          value: 0.5,
        },
        {
          text: '0.25x',
          value: 0.25,
        },
        {
          text: '0x',
          value: 0,
        },
      ],
    };
  },
  computed: {
    share() {
      if (this.weight === 0) {
        return 0;
      }
      return Math.round((this.weight / this.totalWeight) * 100);
    },
    metric() {
      const metric = this.goalMetric(this.goal);
      if (metric === null) {
        return '';
      }
      return metric;
    },
  },
  methods: {
    handleWeightChange(value) {
      this.$emit('weight-change', value);
    },
    handleCheckboxChange(value) {
      this.$emit('is-checked-change', value);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .aligned-item {
    display: flex;
    align-items: center;
    width: 100%;

    ._left {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      ._title {
        line-height: normal;
        padding: 0;
        max-width: calc(100% - 2.4rem);
      }
    }

    ._right {
      display: flex;
      flex: 0 0 10rem;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;

      ._share {
        color: $font-color-secondary;
      }

      ._select {
        margin-left: .8rem;
      }

      &._metric {
        color: $font-color-secondary;
      }
    }
  }
</style>
