<template>
  <teleport
    v-if="showMenu"
    to="#overlay"
  >
    <div
      v-if="showMenu"
      ref="overlay"
      :class="['m-context-menu', $attrs.class,'_dropdown-overlay', showMenu ? '-visible' : '']"
      @click="hide"
      @mousedown.stop
    >
      <div
        ref="inner"
        :style="style"
        class="_inner"
        @click="handleContentClick"
      >
        <slot />
      </div>
    </div>
  </teleport>
</template>

<script>
import { modalSizes } from 'shared/modal-sizes';

export default {
  name: 'MContextMenu',
  props: {
    relocateKey: {
      type: [String, Number, Boolean],
      default: '',
    },
    placement: {
      type: String,
      default: 'right',
    },
  },
  emits: ['hide'],
  data() {
    return {
      position: { x: 0, y: 0 },
      showMenu: false,
      innerWidth: 0,
      innerHeight: 0,
    };
  },
  computed: {
    top() {
      let top = this.position.y;
      if (top + this.innerHeight > window.innerHeight) {
        top = this.position.y - this.innerHeight;
      }
      if (top < modalSizes.margin) {
        top = modalSizes.margin;
      }

      return top;
    },
    left() {
      let left = this.position.x;
      if (left + this.innerWidth > window.innerWidth) {
        left = this.position.x - this.innerWidth;
      }
      if (left < modalSizes.margin) {
        left = modalSizes.margin;
      }

      return left;
    },
    style() {
      const style = {
        position: 'fixed',
        left: `${this.left}px`,
        top: `${this.top}px`,
        maxWidth: `${window.innerWidth - (modalSizes.margin * 2)}px`,
        maxHeight: `${window.innerHeight - (modalSizes.margin * 2)}px`,
      };
      if (this.placement === 'center') {
        return {
          ...style,
          transform: 'translateY(0%) translateX(-50%)',
        };
      }
      return style;
    },
  },
  methods: {
    show(event) {
      this.position = {
        x: event.pageX,
        y: event.pageY,
      };
      this.showMenu = true;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.calculateInnerSize();
        });
      });
    },
    calculateInnerSize() {
      if (typeof this.$refs.inner === 'undefined' || this.$refs.inner === null) {
        return;
      }
      this.innerHeight = this.$refs.inner.clientHeight;
      this.innerWidth = this.$refs.inner.clientWidth;
    },
    hide() {
      this.$emit('hide');
      this.showMenu = false;
    },
    handleContentClick(event) {
      event.stopPropagation();
    },
  },
  watch: {
    relocateKey() {
      // Since we use vue-portal, $refs are only available in the second flush of the rendering queue: https://github.com/LinusBorg/portal-vue/issues/119
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.calculateInnerSize();
        });
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._dropdown-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: var(--viewport-height-100);
    overflow: hidden;

    &:focus {
      outline: none;
    }

    &.-visible {
      .__inner {
        display: block;
        opacity: 1;
        transform: scale(1);

        &:focus {
          outline: none;
        }
      }
    }
  }
</style>
