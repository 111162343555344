<template>
  <div class="fullscreen-stepper">
    <div class="_side">
      <div
        class="_steps"
      >
        <template
          v-for="(step, index) in steps"
          :key="step.key"
        >
          <div
            :class="['_step', index < current ? '-done' : '', current === index ? '-active' : '']"
          >
            <div class="_indicator">
              <div
                v-if="index >= current"
                class="_number"
              >
                {{ index + 1 }}
              </div>
              <div
                v-if="index < current"
                class="_icon"
              >
                <m-icon
                  type="check"
                  size="11"
                  color="white"
                />
              </div>
            </div>
            <router-link

              :to="step.to"
              :class="linkClasses(index)"
            >
              {{ step.title }}
            </router-link>
          </div>
          <div
            v-if="steps.length !== (index + 1)"
            :key="`connector_${index}`"
            class="_connector"
          />
        </template>
      </div>
    </div>
    <div class="_body">
      <div class="_content">
        <slot />
      </div>
      <div class="_footer">
        <m-content
          boxed-small
          padding
        >
          <slot name="footer" />
        </m-content>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullscreenStepper',
  props: {
    steps: {
      type: Array,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
  },
  computed: {
    currentStep() {
      const filtered = this.steps.filter((_, index) => index === this.current);
      if (filtered.length !== 1) {
        return null;
      }

      return filtered[0];
    },
  },
  methods: {
    linkClasses(index) {
      return [
        '_link',
        index === this.current ? '-active' : '',
        index < this.current ? '-done' : '',
        index > this.current ? '-upcoming' : '',
      ];
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/padding";

  $footer-height: 6.4rem;

  .fullscreen-stepper {
    display: flex;
    height: inherit;
    background-color: white;

    ._side {
      width: 30rem;
      background-color: map_get($grey, 'lighten-6');
      border-right: 1px solid $border-color;

      ._steps {
        ._step {
          display: flex;
          align-items: center;

          ._indicator {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.2rem;
            height: 2.2rem;
            margin-right: 1rem;
            background-color: white;
            border: 2px solid map_get($grey, 'darken-4');
            border-radius: 1.1rem;

            ._number {
              display: flex;
              align-items: center;
              height: 2rem;
              margin-top: .1rem;
              line-height: 1.6;
              text-align: center;
              vertical-align: center;
            }
          }

          &.-done {
            ._indicator {
              background-color: map_get($grey, 'darken-4');
            }
          }

          &.-active {
            ._indicator {
              color: white;
              background-color: map_get($grey, 'darken-4');
              border-color: map_get($grey, 'darken-4');
            }
          }
        }

        ._connector {
          width: 1px;
          height: 2rem;
          margin: .8rem 1rem;
          background-color: map_get($grey, 'lighten-2');
        }
      }

      @include paddingPageHeader();

      ._link {
        font-weight: $font-weight-medium;

        &:focus {
          text-decoration: none;
        }

        &.-active {
          color: $font-color-primary;
        }

        &.-done {
          color: $font-color-secondary;
        }

        &.-upcoming {
          color: $font-color-secondary;
        }
      }
    }

    ._body {
      width: 100%;
      height: calc(var(--viewport-height-100) - #{$side-bar-collapsed-width});
      overflow: hidden;

      ._content {
        height: calc(var(--viewport-height-100) - #{$side-bar-collapsed-width} - #{$footer-height});
        overflow: auto;
      }

      ._footer {
        height: $footer-height;
        border-top: 1px solid $border-color;
      }
    }
  }
</style>
