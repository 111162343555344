<template>
  <div class="mobile-bottom-menu">
    <div class="_btn">
      <m-btn
        hide-border
        icon="search"
        large
        icon-size="20"
        @click="openSearch"
      />
    </div>
    <div class="_btn -inbox">
      <m-btn
        hide-border
        large
        @click="openNotifications"
      >
        <div class="_inner">
          <m-icon
            type="inbox"
            size="20"
          />
          <m-badge
            v-if="unreadCount > 0"
            class="_badge"
            :number="unreadCount > 50 ? '50+' : `${unreadCount}`"
            :background-color="$colors.red.base"
          />
        </div>
      </m-btn>
    </div>
    <div class="_btn">
      <m-btn
        hide-border
        icon="plus"
        large
        icon-size="20"
        @click="openMenu"
      />
    </div>
  </div>
</template>

<script>
import useInbox from '@/composables/inbox/inbox';
import { EventBus } from '@/lib/event-bus';

export default {
  name: 'MobileBottomMenu',
  setup() {
    const { unreadCount } = useInbox();
    return { unreadCount };
  },
  methods: {
    openNotifications() {
      EventBus.$emit('show-notifications');
    },
    openSearch() {
      EventBus.$emit('show-search');
    },
    openMenu() {
      EventBus.$emit('show-new-menu');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .mobile-bottom-menu {
    position: fixed;
    top: calc(var(--viewport-height-100) - 5rem);
    bottom: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 5rem;
    background-color: white;
    border-top: 1px solid $border-color;

    ._btn {
      &.-inbox {
        ._inner {
          position: relative;

          ._badge {
            position: absolute;
            top: -.6rem;
            right: -.6rem;
          }
        }
      }
    }
  }
</style>
