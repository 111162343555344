import { goalTypeOption } from '@/composables/goal/allowed-sub-items';
import { intersection } from 'lodash-es';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { propertyType } from 'shared/constants.json';

export const restrictNonWritableProperties = (nonWritableProperties) => (goal) => {
  goal.properties = goal.properties.filter((pv) => isNullOrUndefined(pv.property) || !nonWritableProperties.map((p) => p.uid).includes(pv.property.uid));
  return goal;
};

export const restrictForeignEntityProperties = ({ peers, myTeamsIds, hasForeignEntityReference }) => (goal) => {
  if (hasForeignEntityReference.value) {
    return goal;
  }

  const peerIds = peers.value.map((u) => u.uid);
  for (let i = 0; i < goal.properties.length; i++) {
    if (goal.properties[i].property.type !== propertyType.user) {
      continue;
    }

    goal.properties[i].users = goal.properties[i].users.filter((u) => peerIds.includes(u.uid));
  }

  for (let i = 0; i < goal.properties.length; i++) {
    const property = goal.properties[i].property;
    if (property === undefined) {
      continue;
    }
    if (property.type !== propertyType.space) {
      continue;
    }

    goal.properties[i].spaces = goal.properties[i].spaces.filter((o) => myTeamsIds.includes(o.uid));
  }

  return goal;
};

export const enforceAlignmentRule = (goalTypeProperty, select) => (goal) => {
  if (goal.parents.length === 0) {
    return goal;
  }
  const parents = select(goal.parents.map((p) => p.uid));
  const allowedOptions = parents.reduce((res, next) => {
    const type = goalTypeOption(next, goalTypeProperty);
    if (type === null) {
      res.push(...goalTypeProperty.options);
      return res;
    }

    const tmp = [];
    goalTypeProperty.options.forEach((op) => {
      if (op.canBeChildOf.length === 0 || op.canBeChildOf.map((c) => c.uid).includes(type.uid)) {
        tmp.push(op.uid);
      }
    });
    res.push(tmp);
    return res;
  }, []);
  const allowedGoalTypeOptionIds = intersection(...allowedOptions);

  const goalType = goalTypeOption(goal, goalTypeProperty);
  if (goalType === null) {
    return goal;
  }

  if (allowedGoalTypeOptionIds.length === 0 || allowedGoalTypeOptionIds.includes(goalType.uid)) {
    return goal;
  }

  for (let i = 0; i < goal.properties.length; i++) {
    if (!goal.properties[i].property.isGoalType) {
      continue;
    }

    goal.properties[i].selectedOptions = [{ uid: allowedGoalTypeOptionIds[0] }];
  }
  return goal;
};
