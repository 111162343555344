export const stripEntity = (entity) => {
  if (typeof entity === 'undefined' || entity === null || typeof entity.uid === 'undefined') {
    return entity;
  }

  const res = {};

  if (entity.rid !== undefined) {
    res.rid = entity.rid;
  }

  if (entity.uid !== undefined) {
    res.uid = entity.uid;
  }

  return res;
};

export const stripPropertyValue = (p) => ({
  ...p,
  property: stripEntity(p.property),
  users: p.users.map(stripEntity),
  spaces: p.spaces.map(stripEntity),
  selectedOptions: p.selectedOptions.map(stripEntity),
});

export const stripGoal = (goal) => ({
  ...goal,
  creator: stripEntity(goal.creator),
  goalCycle: goal.goalCycle.map(stripEntity),
  properties: goal.properties.map(stripPropertyValue),
  parents: goal.parents.map(stripEntity),
  paysOnto: goal.paysOnto.map((g) => ({ ...stripEntity(g), 'paysOnto|weight': g['paysOnto|weight'] })),
  subscriptions: goal.subscriptions.map((s) => ({
    ...s,
    user: stripEntity(s.user),
  })),
});
