<template>
  <m-dropdown
    ref="input"
    v-model:value="showNumberDropdownInput"
    :title="title"
    class="number-input"
    placement="onTopLeft"
    :block="fullWidth"
    :disabled="disabled || readOnly"
    :match-trigger-width="matchTriggerWidth"
    @hide="hide"
    @click.stop
  >
    <m-focusable
      :hide-border="hideBorder"
      :hide-hover="hideHover"
      type="clickable"
      :read-only="readOnly"
      :full-width="fullWidth"
      :m-style="mStyle"
      :small="small"
      :show-placeholder="!hidePlaceholder && number === null"
      :placeholder="placeholder"
      :placeholder-icon="placeholderIcon"
    >
      <div
        v-if="number !== null"
        :style="resolveStyles(mStyle)"
      >
        {{ formatter(number) }}
      </div>
    </m-focusable>
    <template #overlay>
      <m-card
        no-padding
        border-radius="small"
      >
        <m-input-number
          v-model:value="number"
          :placeholder="placeholder"
          :allow-clear="clearable"
          :hide-border="hideBorder"
          full-width
          hide-hover
          hide-placeholder
          auto-focus
          nullable
          :formatter="formatter"
          :parser="parser"
          @keydown.enter="showNumberDropdownInput = false"
        />
      </m-card>
    </template>
  </m-dropdown>
</template>

<script setup>
import useDebounce from '@/composables/debounce';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';
import { numberFormat as numberFormatConstant } from 'shared/constants.json';
import { numberFormatter, numberParser } from '@/lib/props/number';
import { onMounted, ref, toRef, watch } from 'vue';

const props = defineProps({
  ...mStyleProps,
  title: {
    type: String,
    default: '',
  },
  value: {
    type: Number,
    default: null,
  },
  placeholder: {
    type: String,
    default: '',
  },
  small: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  hideBorder: {
    type: Boolean,
    default: false,
  },
  hideHover: {
    type: Boolean,
    default: false,
  },
  hidePlaceholder: {
    type: Boolean,
    default: false,
  },
  placeholderIcon: {
    type: String,
    default: '',
  },
  matchTriggerWidth: {
    type: Boolean,
    default: false,
  },
  autoFocus: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  numberFormat: {
    type: String,
    default: numberFormatConstant.number,
  },
});

const input = ref(null);

const { userLang } = useLoggedInUser();

const parser = numberParser(props.numberFormat, userLang.value);
const formatter = numberFormatter(props.numberFormat, userLang.value);

const number = ref(props.value);

const { debounce, cancelAll } = useDebounce();
watch(number, () => {
  debounce(changeNumber, 500);
});

const hide = () => {
  emit('blur');
  cancelAll();
  changeNumber();
};

const showNumberDropdownInput = ref(false);
const open = () => {
  showNumberDropdownInput.value = true;
};

const emit = defineEmits(['change', 'blur']);

const changeNumber = () => {
  if (number.value === props.value) {
    return;
  }
  emit('change', number.value);
};

onMounted(() => {
  if (props.autoFocus) {
    open();
  }
});

const localValue = toRef(props, 'value');
watch(localValue, (value) => {
  number.value = value;
});

defineExpose({ open });
</script>

<style scoped lang="scss" type="text/scss">

</style>
