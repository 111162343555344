<template>
  <div
    class="new-form-template-preview-content"
    padding
  >
    <page-header
      class="_title"
      heading="h2"
      :title="$t('newFormTemplatePreviewContent.templatePreview')"
    />
    <m-content
      padding
      class="_form-wrapper"
    >
      <div
        ref="preview"
        class="_preview"
      >
        <preview
          ref="form"
          :form="formTemplate"
          :width="width"
          :footer-bottom="footerBottom"
          :footer-right="footerRight"
          :lang="lang"
          class="_form"
          :show-language-selection="false"
        />
      </div>
    </m-content>
  </div>
</template>

<script>
import PageHeader from 'shared/components/PageHeader.vue';
import Preview from 'shared/components/Form.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { DEFAULT_LANGUAGE } from '@/lib/language';

export default {
  name: 'NewFormTemplatePreviewContent',
  props: {
    formTemplate: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  components: { PageHeader, Preview },
  data() {
    return { width: '0', footerRight: '0', footerBottom: '0' };
  },
  computed: {
    lang() {
      if (this.languages.includes(this.userLang)) {
        return this.userLang;
      }
      if (this.languages.includes(DEFAULT_LANGUAGE)) {
        return DEFAULT_LANGUAGE;
      }
      return this.languages[0];
    },
    languages() {
      if (this.formTemplate === null || this.formTemplate.orderItems.length === 0) {
        return [this.userLang];
      }
      return Object.keys(this.formTemplate.orderItems[0].field.title);
    },
  },
  methods: {
    updatePreviewPositioning() {
      // minus 10 due to scrollbar
      this.width = `${this.$refs.preview.clientWidth - 10}px`;
      this.footerBottom = `${window.innerHeight - (this.$refs.preview.offsetTop + this.$refs.preview.offsetHeight)}px`;
      this.footerRight = `${window.innerWidth - (this.$refs.preview.offsetLeft + this.$refs.preview.clientWidth)}px`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.updatePreviewPositioning();
    });
    window.addEventListener('resize', this.updatePreviewPositioning);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updatePreviewPositioning);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";

  .new-form-template-preview-content {
    ._title {
      margin-top: -5.9rem;
      margin-bottom: 4rem;
    }

    ._form {
      position: relative;

      @include box_shadow(1);

      height: calc(var(--viewport-height-100) - 16.5rem);
    }
  }
</style>
