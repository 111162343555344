<template>
  <div
    :class="['grid-page-tile-goal-list', needFullscreenTarget ? '-fullscreen' : '']"
  >
    <goals-list-wrapper
      :header-slots="headerSlots"
      :can-create-goals="canCreate"
      show-create-view
      :allowed-view-types="allowedViewTypes"
      show-views-selector
      :selected-cycles="selectedCycles"
      :change-selected-goal-cycles="changeSelectedGoalCycles"
      :header-portal-target="headerPortalTarget"
      show-errors
      :base-filter="publishedAtFilter"
      select-area-class="dashboard-details"
      stretch-content
      :default-props="defaultProps"
      :read-only="props.readOnly"
    />
  </div>
</template>

<script setup>
import GoalsListWrapper from '@/components/goal/GoalsListWrapper.vue';
import useAccess from '@/composables/access/access';
import useGoalDefaultProps from '@/composables/saved-views/goal-default-props';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersistedGoalCycle from '@/composables/goal-cycle/persisted-goal-cycle';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useViewGoalCycle from '@/composables/goal-cycle/view-goal-cycle';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND } from '@/lib/filter/scope-tree';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { accessGroupFlag, viewApplication, viewType } from 'shared/constants.json';
import { computed, provide } from 'vue';
import { useI18n } from 'vue-i18n';

const { loggedInUserAccount } = useLoggedInUserAccount();

const props = defineProps({
  gridPageTile: { type: Object, required: true },
  readOnly: { type: Boolean, default: false },
});

const viewServiceInitService = useViewServiceInit();
const i18n = useI18n();
const { userHasRight } = useAccess();
const { publishedAtFilter } = usePublishedAtFilter();
const defaultPropsSvc = useGoalDefaultProps();

const goalCycleSvc = usePersistedGoalCycle();

const viewApp = viewApplication.gridPageTileGoal;
const defaultView = {
  title: i18n.t('list.cascade'),
  viewType: viewType.cascade,
  gridPageTile: { uid: props.gridPageTile.uid },
  viewApplication: viewApp,
  params: {
    filter: { account: { uid: loggedInUserAccount.value.uid }, children: [], op: AND },
    applyFilterOnFirstLevelOnly: false,
    order: [],
    props: [],
  },
  goalCycles: goalCycleSvc.selectedGoalCycles.value,
  unassignedGoalCycleSelected: goalCycleSvc.unassignedGoalCycleSelected.value,
};

let config = viewServiceInitService.defaultConfig();

const defaultProps = defaultPropsSvc.defaultProps;

config = viewServiceInitService.withGridPageTile(config, { uid: props.gridPageTile.uid });
config = viewServiceInitService.withDefaultView(config, defaultView);
config = viewServiceInitService.withDefaultProps(config, defaultProps);
config = viewServiceInitService.withViewApplication(config, viewApp);
config = viewServiceInitService.withReadOnly(config, props.readOnly);

const viewSvc = viewServiceInitService.gridPageViewService(config);
provide(VIEWS_SERVICE, viewSvc);

const headerSlots = [{ name: SLOTS.CYCLE_SELECTOR }, { name: SLOTS.FILTER }, { name: SLOTS.SORTER }, { name: SLOTS.STRING_FILTER }, { name: SLOTS.PROPERTIES }, { name: SLOTS.COMPARE_TO }, { name: SLOTS.PROGRESS_DISPLAY_OPTION }];

const canCreate = computed(() => userHasRight([accessGroupFlag.createGoal]));

const allowedViewTypes = computed(() => [viewType.cascade, viewType.list]);

const headerPortalTarget = computed(() => `preSelectArea_${props.gridPageTile.uid}`);

const { changeSelectedGoalCycles, composedSelectedCycles: selectedCycles } = useViewGoalCycle(viewSvc);

const needFullscreenTarget = computed(() => viewSvc.currentView.value.viewType === viewType.tree);

</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .grid-page-tile-goal-list {
    height: inherit;
    overflow: auto;
    padding-left: 1.6rem;

    :deep(.goals-list-wrapper-header) {
      padding: .6rem 1.6rem 0;
    }

    :deep(._view-header-portal-target) {
      padding-left: 1.6rem;
    }

    &:not(.-fullscreen) {
      :deep(.goals-list-wrapper) {
        ._padded-container {
          padding: 0 1.6rem;

          &:not(.-pre-select-area) {
            padding-bottom: .8rem;
          }
        }
      }
    }

    &.-fullscreen {
      :deep(.goals-list-wrapper) {
      // FIXME: there has to be a better way to fit the viewport to the card (this one is not even sufficient)
      height: inherit;

      .goal-tree {
        height: calc(100% - 3.4rem);
      }
      }
    }
  }
</style>
