<template>
  <div
    class="form-results"
  >
    <m-sider
      collapsed
      class="_nav"
      bordered
    >
      <icon-nav-items
        :items="sideNavItems"
        show-tooltip
      />
    </m-sider>
    <div
      id="form-results-scroll-container"
      class="_content"
    >
      <m-content
        padding
        class="_header"
      >
        <page-header
          no-padding
        >
          <template #title>
            <breadcrumbs
              class="_breadcrumbs"
              :breadcrumbs="breadcrumbs"
            />
          </template>
          <template #actions>
            <div

              class="_actions"
            >
              <m-btn
                v-if="writeAccess"
                class="_action"
                hide-border
                small
                @click="showShareModal = true"
              >
                {{ $t('general.share') }}
              </m-btn>
              <m-dialog
                v-model:value="showShareModal"
                :title="$t('createFormTopBar.analyticAccess')"
                hide-footer
                max-width="80rem"
                no-padding
              >
                <form-analytic-access
                  @cancel="showShareModal = false"
                  @submit="showShareModal = false"
                />
              </m-dialog>
            </div>
          </template>
        </page-header>
      </m-content>
      <m-content
        ref="subHeader"
        boxed
        padding
        class="_sub-header"
      >
        <div class="_title">
          {{ title }}
        </div>
        <list-header class="_filter-bar">
          <template #right>
            <div class="_filter">
              <m-spinner
                v-if="childrenLoading"
                size="xs"
                class="_spinner"
              />
              <group-by
                v-if="showGroupBy"
                v-model:value="groupBy"
                :properties="groupByProperties"
                class="_group-by _select"
              />
              <m-select
                v-if="isHeatmapView"
                v-model:value="heatmapViewMode"
                :items="heatmapViewModeItems"
                :label="$t('formResults.show')"
                hide-border
                small
                light
                btn
                class="_select"
              />
              <list-filter
                v-model:value="filterTree"
                :in-card-menu="$store.state.breakpoint.smAndDown"
                class="_user-filter"
                :props="userProps"
              />
              <time-range-selector
                :value="timeRange"
                @change="updateTimeRange"
              />
            </div>
          </template>
        </list-header>
        <m-divider
          small
          class="_divider"
        />
      </m-content>
      <div
        v-if="isHeatmapView"
        class="_heatmap"
      >
        <heatmap
          :filter-tree="filterTree"
          :interval-config="intervalConfig"
          :group-by="groupBy"
          :view-mode="heatmapViewMode"
          :filter-by-newest-answer="form.pulseCalculation"
          @update-filter="updateFilter"
        />
      </div>
      <m-content
        v-else
        boxed
        padding
      >
        <div
          v-if="isOverview"
          class="_stats-row"
        >
          <stat-fact
            class="_value"
            :label="$t('formResults.employees')"
            :value="`${participantCount}`"
            :loading="participantCountLoading"
          />
          <stat-fact
            class="_value"
            :label="$t('formResults.participation')"
            :value="formattedParticipationRate"
            :loading="participationRateLoading"
          />
          <stat-fact
            class="_value"
            :label="$t('formResults.responses')"
            :value="`${answerCount}`"
            :loading="answerCountLoading"
          />
        </div>
        <div
          v-if="showFactors"
          class="_factors _section"
        >
          <div
            v-if="isOverview"
            class="_sub-heading"
          >
            {{ $t('formResults.factors') }}
          </div>
          <factor-results-card
            class="_card"
            :factors="selectedFactors"
            :filter-tree="filterTree"
            :interval-config="intervalConfig"
            :filter-by-newest-answer="form.pulseCalculation"
            @select="selectFactor"
          />
          <factor-history-card
            v-if="selectedFactor !== null"
            :factor="selectedFactor"
            :filter-tree="filterTree"
            :interval-config="intervalConfig"
            :filter-by-newest-answer="form.pulseCalculation"
          />
        </div>
        <div
          v-if="showQuestions && selectedFields.length > 0"
          class="_questions _section"
        >
          <div
            v-if="isOverview || isFactorView"
            class="_sub-heading"
          >
            {{ $t('formResults.questions') }}
          </div>
          <question-results-card
            class="_card"
            :fields="selectedFields"
            :filter-tree="filterTree"
            :interval-config="intervalConfig"
            :show-comments="isQuestionView"
            :filter-by-newest-answer="form.pulseCalculation"
            @select="selectField"
          />
          <question-history-card
            v-if="selectedField !== null"
            :field="selectedField"
            :filter-tree="filterTree"
            :interval-config="intervalConfig"
            :filter-by-newest-answer="form.pulseCalculation"
          />
        </div>
      </m-content>
    </div>
  </div>
</template>

<script>
import PageHeader from 'shared/components/PageHeader.vue';
import StatFact from '@/components/StatFact.vue';
import { DateTime } from 'luxon';
import { FORM_RESULTS } from '@/route-names';
import { RESULT, TYPE, UID } from 'shared/api/query/constants';
import { createProp } from '@/lib/props';
import { dataAggregation } from 'shared/num_constants.json';
import { fieldType, propertyType, userScopeOperator } from 'shared/constants.json';
/* eslint-disable */
import { doGetChartData, dogma } from '@/api';
/* eslint-enable */
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import FactorHistoryCard from '@/components/dashboard/FactorHistoryCard.vue';
import FactorResultsCard from '@/components/dashboard/FactorResultsCard.vue';
import FormAnalyticAccess from '@/components/FormAnalyticAccess.vue';
import GroupBy from '@/components/list/GroupBy.vue';
import Heatmap from '@/components/dashboard/Heatmap.vue';
import IconNavItems from '@/components/navigation/IconNavItems.vue';
import ListFilter from '@/components/list/ListFilter.vue';
import ListHeader from '@/components/list/ListHeader.vue';
import MSider from '@/components/layout/MSider.vue';
import QuestionHistoryCard from '@/components/dashboard/QuestionHistoryCard.vue';
import QuestionResultsCard from '@/components/dashboard/QuestionResultsCard.vue';
import TimeRangeSelector from '@/components/list/TimeRangeSelector.vue';
import useForm from '@/composables/form/form';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import { ABSOLUTE, DELTA } from '@/lib/constants';
import { HOUR } from '@/lib/time';
import { UserFilterHandler } from '@/lib/filter/user/handler';
import { fieldsOfFactor, writeAccess } from '@/lib/form';
import { getQueryParam, getQueryParamAsInt } from '@/lib/route';
import { getValueAsPercent } from '@/lib/charts/format';
import { mapActions, mapState } from 'vuex';
import { user as userConfig } from 'shared/api/query/configs.json';

const HEATMAP = 'heatmap';

export default {
  name: 'FormResults',
  components: {
    IconNavItems,
    TimeRangeSelector,
    GroupBy,
    Heatmap,
    MSider,
    FactorHistoryCard,
    QuestionHistoryCard,
    FormAnalyticAccess,
    QuestionResultsCard,
    FactorResultsCard,
    ListFilter,
    ListHeader,
    Breadcrumbs,
    PageHeader,
    StatFact,
  },
  setup() {
    const { userProperties } = useProperties();
    const { lang, form, formId, formAvailable } = useForm();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { userProperties, loggedInUser, userLang, loggedInUserAccount, lang, form, formId, formAvailable };
  },
  data() {
    return {
      participationRate: 0,
      participantCount: 0,
      participationRateLoading: false,
      participantCountLoading: false,
      answerCountLoading: false,
      showShareModal: false,
      filterTree: null,
      intervalConfig: null,
      groupBy: null,
      heatmapViewMode: DELTA,
      heatmapViewModeItems: [
        { text: this.$t('formResults.delta'), value: DELTA },
        { text: this.$t('formResults.absolute'), value: ABSOLUTE },
      ],
    };
  },
  computed: {
    ...mapState({
      answerCount: (state) => state.formResultAnswerCount,
      surveyProcess: (state) => state.surveyProcess,
      childrenLoading: (state) => state.formResultLoading,
    }),
    sideNavItems() {
      return [
        {
          to: { name: FORM_RESULTS, params: { formId: this.formId } },
          icon: 'dashboard',
          title: this.$t('formResults.overView'),
          active: !this.isHeatmapView,
        },
        {
          to: { name: FORM_RESULTS, params: { formId: this.formId }, query: { view: HEATMAP } },
          icon: 'appstore',
          title: this.$t('formResults.heatmap'),
          active: this.isHeatmapView,
          hide: !this.hasHeatmap,
        },
      ].filter((i) => !i.hide);
    },
    gqlFilter() {
      const handler = new UserFilterHandler(true);
      return handler.Translate(this.filterTree).filterTree;
    },
    writeAccess() {
      return writeAccess(this.loggedInUser, this.form);
    },
    isHeatmapView() {
      return this.getQueryParam('view') === HEATMAP;
    },
    showFactors() {
      return (this.isOverview || this.isFactorView) && this.form.formFactors.length > 0;
    },
    showQuestions() {
      return true;
    },
    showGroupBy() {
      return this.isHeatmapView && this.groupBy !== null;
    },
    isOverview() {
      return !this.isFactorView && !this.isQuestionView;
    },
    isFactorView() {
      return this.factorId !== 0;
    },
    isQuestionView() {
      return this.fieldId !== 0;
    },
    factorId() {
      return this.getQueryParamAsInt('factorId');
    },
    fieldId() {
      return this.getQueryParamAsInt('fieldId');
    },
    selectedFactor() {
      if (this.factorId === 0) {
        return null;
      }
      const filtered = this.form.formFactors.filter((f) => f.uid === this.factorId);
      if (filtered.length === 0) {
        return null;
      }
      return filtered[0];
    },
    selectedField() {
      if (this.fieldId === 0) {
        return null;
      }
      const filtered = this.form.orderItems.filter((oi) => oi.field.uid === this.fieldId);
      if (filtered.length === 0) {
        return null;
      }
      return filtered[0].field;
    },
    selectedFactors() {
      if (this.isOverview) {
        return this.form.formFactors;
      }
      if (this.isFactorView) {
        return [this.selectedFactor];
      }
      return [];
    },
    selectedFields() {
      if (this.isOverview) {
        return this.form.orderItems.map((o) => o.field);
      }
      if (this.isFactorView) {
        return fieldsOfFactor(this.form, this.selectedFactor);
      }
      return [this.selectedField];
    },
    title() {
      if (this.isHeatmapView) {
        return this.$t('formResults.heatmap');
      }
      if (this.isFactorView) {
        return this.selectedFactor.title[this.lang];
      }
      if (this.isQuestionView) {
        return this.selectedField.title[this.lang];
      }
      return this.$t('formResults.overView');
    },
    hasHeatmap() {
      return this.form.orderItems.filter((item) => item.field.type === fieldType.opinionScale).length > 0;
    },
    breadcrumbs() {
      let firstItem = {
        title: this.$t('formResults.overView'),
        to: { name: FORM_RESULTS, params: { formId: this.formId } },
        disabled: this.isOverview,
      };
      if (this.isHeatmapView) {
        firstItem = {
          title: this.$t('formResults.heatmap'),
          to: { name: FORM_RESULTS, params: { formId: this.formId }, query: { view: HEATMAP } },
          disabled: true,
        };
      }
      const res = [firstItem];
      if (this.isOverview) {
        return res;
      }

      if (this.isFactorView) {
        res.push({
          title: this.selectedFactor.title[this.lang],
          disabled: true,
        });
        return res;
      }

      const factorOfField = this.factorOfField(this.selectedField);
      if (factorOfField !== null) {
        res.push({
          title: factorOfField.title[this.lang],
          to: this.getLinkToFactor(factorOfField),
          disabled: false,
        });
      }

      res.push({
        title: this.selectedField.title[this.lang],
        disabled: true,
      });

      return res;
    },
    userProps() {
      return this.userProperties.map((p) => createProp(p, false, true, true, this.userLang, userConfig.model));
    },
    groupByProperties() {
      return this.userProperties.filter((p) => [propertyType.options, propertyType.singleSelect, propertyType.space].includes(p.type));
    },
    formattedParticipationRate() {
      return getValueAsPercent(this.participationRate, 0);
    },
    formHasItems() {
      return this.form.orderItems.length > 0;
    },
    timeRange() {
      if (this.intervalConfig === null) {
        this.setDefaultTimeRange();
      }
      return { start: this.intervalConfig.from, end: this.intervalConfig.till };
    },
  },
  methods: {
    ...mapActions(['getFormAnswerCount']),
    getQueryParam(name) {
      return getQueryParam(this.$route, name);
    },
    getQueryParamAsInt(name) {
      return getQueryParamAsInt(this.$route, name);
    },
    selectFactor(factor) {
      this.$router.push(this.getLinkToFactor(factor));
    },
    selectField(field) {
      this.$router.push(this.getLinkToField(field));
    },
    getLinkToField(field) {
      return { ...this.$route, query: { fieldId: field.uid } };
    },
    getLinkToFactor(factor) {
      return { ...this.$route, query: { factorId: factor.uid } };
    },
    factorOfField(field) {
      let res = null;
      this.form.formFactors.forEach((factor) => {
        factor.factorFields.forEach((ff) => {
          if (ff.field.uid === field.uid) {
            res = factor;
          }
        });
      });
      return res;
    },
    userAmountByScope(userScopeTree) {
      const handler = new UserFilterHandler(true);
      const { filterTree, varBlocks } = handler.Translate(userScopeTree);
      return [
        {
          model: userConfig.model,
          alias: RESULT,
          func: { name: TYPE, args: [{ value: userConfig.model }] },
          filter: filterTree,
          children: [{ attr: UID, isCount: true }],
        },
        ...varBlocks,
      ];
    },
    getParticipantCount() {
      if (this.surveyProcess === null) {
        return;
      }
      const scope = {
        op: userScopeOperator.and,
        children: [this.surveyProcess.userScopeTree],
      };
      if (this.filterTree !== null) {
        scope.children.push(this.filterTree);
      }
      this.participantCountLoading = true;
      dogma.query(this.userAmountByScope(scope)).then((response) => {
        this.participantCountLoading = false;
        this.participantCount = response.data[RESULT][0].count;
      });
    },
    getAnswerCount() {
      this.answerCountLoading = true;
      this.getFormAnswerCount({
        formId: this.formId,
        userScopeTree: this.filterTree,
        intervalConfig: this.intervalConfig,
      }).then((response) => {
        this.answerCountLoading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
    getParticipationRate() {
      if (!this.formHasItems) {
        return;
      }
      const chart = {
        dataFields: [this.form.orderItems[0].field],
        aggregation: dataAggregation.participationRate,
      };
      this.participationRateLoading = true;
      doGetChartData({
        charts: [chart],
        userScopeTree: this.filterTree,
        intervalConfig: this.intervalConfig,
      }).then((response) => {
        this.participationRateLoading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.participationRate = response.data[0].series[0].data[0].value;
      });
    },
    setDefaultTimeRange() {
      const now = DateTime.local().endOf('day').startOf('second');
      const from = now.minus({ month: 5 }).startOf('month');
      this.updateTimeRange({
        start: from.toISO({ suppressMilliseconds: true }),
        end: now.toISO({ suppressMilliseconds: true }),
      });
    },
    updateTimeRange(val) {
      if (val.start === null) {
        this.setDefaultTimeRange();
        return;
      }
      let prependDuration = 0;
      if (this.form.pulseCalculation === true) {
        prependDuration = HOUR * -24 * 30 * 6;
      }
      this.intervalConfig = {
        from: val.start,
        till: val.end,
        prependDuration,
      };
    },
    getStats() {
      this.getAnswerCount();
      this.getParticipationRate();
      this.getParticipantCount();
    },
    updateFilter(filterTree) {
      this.filterTree = filterTree;
    },
    initGroupBy() {
      if (this.groupByProperties.length > 0) {
        this.groupBy = this.groupByProperties[0];
        return;
      }
      this.groupBy = null;
    },
  },
  watch: {
    gqlFilter() {
      this.getStats();
    },
    intervalConfig() {
      this.getStats();
    },
  },
  mounted() {
    this.initGroupBy();
    this.getStats();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  $width: 1070px + 53px;

  .form-results {
    display: flex;
    flex: 1 1 auto;

    ._nav {
      padding-top: 1.2rem;
    }

    ._content {
      width: 100%;
      height: calc(var(--viewport-height-100) - #{$top-bar-height});
      overflow: auto;

      ._header {
        position: sticky;
        left: 0;
      }

      ._sub-header {
        position: sticky;
        left: 0;

        ._title {
          margin: 3rem 0;
          font-size: $font-size-h2;
          font-weight: $font-weight-semibold;
        }
      }
    }

    ._breadcrumbs {
      margin-left: -.4rem;
    }

    ._filter-bar {
      ._filter {
        display: flex;
        align-items: center;

        ._switch {
          margin-right: 1.6rem;
        }

        ._select {
          margin-right: .4rem;
        }

        ._spinner {
          margin-right: .8rem;
        }
      }
    }

    ._stats-row {
      display: flex;
      margin: 0 0 5rem;

      ._value {
        margin-right: 8rem;

        @media (max-width: $screen-size-sm) {
          margin-right: 3rem;
        }
      }
    }

    ._section {
      margin: 0 0 5rem;
    }

    ._sub-heading {
      margin-bottom: 2rem;
      font-size: $font-size-h3;
      font-weight: $font-weight-semibold;
    }

    ._card {
      margin-bottom: 3rem;
    }

    ._heatmap {
      padding-left: calc((100vw - #{$width}) / 2 - 3rem);
      margin-bottom: 3rem;
    }
  }
</style>
