<template>
  <div class="ms-planner-summary">
    <div class="_item">
      <div class="_label">
        {{ $t('msPlannerSummary.aggregation') }}:
      </div>
      <div class="_content">
        {{ $t(`msPlanner.aggregationMode.${camelCase(query.aggregationMode)}.${camelCase(query.aggregationResource)}.label`) }}
      </div>
    </div>
    <div class="_item">
      <div class="_label">
        {{ $t('msPlannerSummary.plan') }}:
      </div>
      <div class="_content">
        {{ query.planName }}
      </div>
    </div>
    <div
      v-if="query.bucketNames.length > 0"
      class="_item"
    >
      <div class="_label">
        {{ $t('msPlannerSummary.buckets', query.bucketNames.length) }}:
      </div>
      <m-tooltip
        class="_content"
        placement="bottom"
        :disabled="!bucketsIsTruncated"
        :mouse-enter-delay=".5"
      >
        <template #title>
          {{ buckets }}
        </template>
        <div
          ref="bucketsRef"
          class="_content"
        >
          {{ buckets }}
        </div>
      </m-tooltip>
    </div>
    <div
      v-if="query.labelNames.length > 0"
      class="_item"
    >
      <div class="_label">
        {{ $t('msPlannerSummary.labels', query.labelNames.length) }}:
      </div>
      <m-tooltip
        class="_content"
        placement="bottom"
        :disabled="!labelsIsTruncated"
        :mouse-enter-delay=".5"
      >
        <template #title>
          {{ labels }}
        </template>
        <div
          ref="labelsRef"
          class="_content"
        >
          {{ labels }}
        </div>
      </m-tooltip>
    </div>
    <div
      v-if="query.taskNames.length > 0"
      class="_item"
    >
      <div class="_label">
        {{ $t('msPlannerSummary.tasks', query.taskNames.length) }}:
      </div>
      <m-tooltip
        class="_content"
        placement="bottom"
        :disabled="!tasksIsTruncated"
        :mouse-enter-delay=".5"
      >
        <template #title>
          {{ tasks }}
        </template>
        <div
          ref="tasksRef"
          class="_content"
        >
          {{ tasks }}
        </div>
      </m-tooltip>
    </div>
  </div>
</template>

<script setup>
import useElementsTruncate from 'shared/composables/element-truncate';
import { camelCase } from 'lodash-es';
import { ref } from 'vue';

const props = defineProps({
  query: {
    type: Object,
    required: true,
  },
});
const bucketsRef = ref(null);
const { isTruncated: bucketsIsTruncated } = useElementsTruncate([bucketsRef]);
const labelsRef = ref(null);
const { isTruncated: labelsIsTruncated } = useElementsTruncate([labelsRef]);
const tasksRef = ref(null);
const { isTruncated: tasksIsTruncated } = useElementsTruncate([tasksRef]);

const buckets = props.query.bucketNames.join(', ');
const labels = props.query.labelNames.join(', ');
const tasks = props.query.taskNames.join(', ');
</script>

<style scoped lang="scss" type="text/scss">
  .ms-planner-summary {
    ._item {
      display: flex;

      ._label {
        flex: 0 0 9.1rem;
        color: $font-color-secondary;
      }

      ._content {
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
