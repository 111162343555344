import useProperties from '@/composables/property/property';
import useSpaces from '@/composables/space/spaces';

export default function useGoalAccessPolicyLinks() {
  const spacesSvc = useSpaces();
  const { spaceProperty } = useProperties();

  const searchLinkableAccessPolicies = ({ properties }) => {
    const spacePV = properties.find((pv) => pv.property.uid === spaceProperty.value.uid);
    if (spacePV === undefined) {
      return [];
    }
    const spaces = spacesSvc.selectMultiple(spacePV.spaces.map((s) => s.uid));
    return spaces.map((space) => space.permissions);
  };

  return { searchLinkableAccessPolicies };
}
