import useChartSort from '@/composables/chart-sort';
import useCustomBuckets from '@/composables/grid-page/chart/custom-buckets';
import useGoalCycles from '@/composables/goal-cycle/goal-cycle';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { aggregatorType, chartGroupByOption, gridPageChartType } from 'shared/constants.json';
import { computed } from 'vue';
import { copy } from 'shared/lib/copy';
import { getDayFromISOString, getDisplayValue, getMonthFromISOString, getQuarterFromISOString, getWeekFromISOString, getYearFromISOString, roundNumber } from '@/composables/grid-page/chart/utils';
import { goal as goalConfig } from 'shared/api/query/configs.json';

export default function useChartGrouping(chart, resultRaw) {
  const customBucket = computed(() => chart.value.groupBy.customBucket);
  const { findBucketLabel: findCustomBucketLabel } = useCustomBuckets(customBucket, resultRaw, computed(() => chart.value.groupBy));
  const stackedCustomBucket = computed(() => chart.value.aggregationConfig.stackOptions.customBucket);
  const { findBucketLabel: findStackedBucketLabel } = useCustomBuckets(stackedCustomBucket, resultRaw, computed(() => chart.value.aggregationConfig.stackOptions));
  const { userLang } = useLoggedInUser();
  const { selectSingle: selectGoalCycle } = useGoalCycles();

  const { sort } = useChartSort();

  const isStacked = computed(() => [gridPageChartType.line, gridPageChartType.bar, gridPageChartType.column].includes(chart.value.chartType)
      && chart.value.aggregationConfig.stackOptions.groupBy !== chartGroupByOption.none);

  const dateGroupingFunctions = {
    [chartGroupByOption.day]: getDayFromISOString,
    [chartGroupByOption.week]: getWeekFromISOString,
    [chartGroupByOption.month]: getMonthFromISOString,
    [chartGroupByOption.quarter]: getQuarterFromISOString,
    [chartGroupByOption.year]: getYearFromISOString,
  };

  const aggregationFn = {
    [aggregatorType.count]: (acc, item) => ({ count: acc.count + item.count }),
    [aggregatorType.sum]: (acc, item) => ({ sum: acc.sum + item.sum }),
    [aggregatorType.avg]: (acc, item) => {
      const total = (acc.avg * acc.count) + (item.avg * item.count);
      const count = acc.count + item.count;
      return { avg: total / count, count };
    },
    [aggregatorType.min]: (acc, item) => {
      if (item.min < acc.min) {
        return { min: item.min };
      }
      return acc;
    },
    [aggregatorType.max]: (acc, item) => {
      if (item.max > acc.max) {
        return { max: item.max };
      }
      return acc;
    },
    [aggregatorType.countDistinct]: (acc, item) => {
      if (acc.values[item.subGroup] === undefined) {
        acc[aggregatorType.countDistinct] += 1;
        acc.values[item.subGroup] = {};
      }
      return acc;
    },
  };

  const totalAggregator = {
    [aggregatorType.count]: aggregationFn[aggregatorType.count],
    [aggregatorType.sum]: aggregationFn[aggregatorType.sum],
    [aggregatorType.avg]: aggregationFn[aggregatorType.avg],
    [aggregatorType.min]: aggregationFn[aggregatorType.min],
    [aggregatorType.max]: aggregationFn[aggregatorType.max],
    [aggregatorType.countDistinct]: (acc, item) => {
      acc.values = { ...acc.values, ...item.values };
      acc[aggregatorType.countDistinct] = Object.keys(acc.values).length;
      return acc;
    },
  };

  const initialValue = {
    [aggregatorType.count]: { count: 0 },
    [aggregatorType.sum]: { sum: 0 },
    [aggregatorType.avg]: { avg: 0, count: 0 },
    [aggregatorType.min]: { min: Infinity },
    [aggregatorType.max]: { max: -Infinity },
    [aggregatorType.countDistinct]: { [aggregatorType.countDistinct]: 0, values: {} },
  };

  const getGroups = (result, groupBy, valueKey, isStackOptions = false) => {
    const groups = Object.keys(result.reduce((acc, item) => {
      const group = groupByMap(groupBy.groupBy, isStackOptions)(item[valueKey], userLang.value);
      if (!acc[group]) {
        acc[group] = {};
      }
      return acc;
    }, {}));
    if (groupBy.groupBy !== chartGroupByOption.none && groupBy.isProperty === false && groupBy.edge === goalConfig.edges.goalCycle) {
      const entities = groups.map((g) => {
        const gc = selectGoalCycle(parseInt(g, 16));
        return {
          ...gc,
          originalValue: g,
        };
      });
      return entities;
    }
    return groups.map((g) => ({ title: getDisplayValue(g, groupBy, userLang.value), originalValue: g }));
  };

  const groupByMap = (groupBy, isStackOptions = false) => {
    if ([chartGroupByOption.day, chartGroupByOption.week, chartGroupByOption.month, chartGroupByOption.quarter, chartGroupByOption.year].includes(groupBy)) {
      return dateGroupingFunctions[groupBy];
    }
    if (groupBy === chartGroupByOption.bucket) {
      if (isStackOptions) {
        return findStackedBucketLabel;
      }
      return findCustomBucketLabel;
    }
    return (val) => val;
  };

  const groupResult = (result, aggregation, group, subGroup = null) => result.reduce((acc, item) => {
    const g = groupByMap(group.groupBy)(item[group.valueKey], userLang.value);
    if (!acc[g]) {
      acc[g] = { groups: {}, ...copy(initialValue[aggregation]) };
    }
    acc[g] = { ...acc[g], ...aggregationFn[aggregation](acc[g], item) };
    if (subGroup === null) {
      return acc;
    }
    const sg = groupByMap(subGroup.groupBy, true)(item[subGroup.valueKey], userLang.value);
    if (!acc[g].groups[sg]) {
      acc[g].groups[sg] = copy(initialValue[aggregation]);
    }
    acc[g].groups[sg] = { ...acc[g].groups[sg], ...aggregationFn[aggregation](acc[g].groups[sg], item) };
    return acc;
  }, {});

  const processChartData = (result) => {
    const aggregator = chart.value.aggregationConfig.aggregation.aggregator;
    if (isStacked.value) {
      const stackedGroups = getGroups(result, chart.value.aggregationConfig.stackOptions, 'stackGroup', true);
      const groups = getGroups(result, chart.value.groupBy, 'val');
      const groupedRes = groupResult(
        result,
        aggregator,
        { groupBy: chart.value.groupBy.groupBy, valueKey: 'val' },
        { groupBy: chart.value.aggregationConfig.stackOptions.groupBy, valueKey: 'stackGroup' },
      );
      const v = Object.values(groupedRes).reduce(
        totalAggregator[aggregator],
        copy(initialValue[aggregator]),
      );
      const categories = sort(groups, chart.value.groupBy);
      const sortedStackedGroups = sort(stackedGroups, chart.value.aggregationConfig.stackOptions);
      return {
        categories: categories.map((c) => c.title),
        series: sortedStackedGroups.map((sg) => ({
          name: sg.title,
          data: categories.map((g) => {
            if (groupedRes[g.originalValue].groups[sg.originalValue] === undefined) {
              return { y: 0, name: sg.title };
            }
            return { y: roundNumber(groupedRes[g.originalValue].groups[sg.originalValue][aggregator]), name: sg.title };
          }),
        })),
        total: roundNumber(v[aggregator]),
      };
    }
    const r = groupResult(
      result,
      aggregator,
      { groupBy: chart.value.groupBy.groupBy, valueKey: 'val' },
    );
    const v = Object.values(r).reduce(
      totalAggregator[aggregator],
      copy(initialValue[aggregator]),
    );
    const total = roundNumber(v[aggregator]);

    const groups = getGroups(result, chart.value.groupBy, 'val');
    const categories = sort(groups, chart.value.groupBy);

    return {
      categories: categories.map((c) => c.title),
      series: [{
        data: Object.entries(r).map(([group, { [aggregator]: value }]) => ({
          name: categories.find((c) => c.originalValue === group)?.title,
          y: roundNumber(value),
        })),
      }],
      total,
    };
  };

  return { processChartData };
}
