<template>
  <m-dropdown
    ref="input"
    v-model:value="showUrlDropdownInput"
    :title="title"
    placement="onTopLeft"
    :block="fullWidth"
    :disabled="disabled || readOnly"
    :class="['url-input', { '-wrap': wrap }, { '-input-field': !hideBorder }, { '-hide-hover': hideHover }]"
    :match-trigger-width="matchTriggerWidth"
    @hide="hide"
    @click.stop="showUrlDropdownInput = true"
  >
    <m-focusable
      :hide-border="hideBorder"
      :hide-hover="hideHover"
      type="clickable"
      class="_url-field-wrapper"
      :full-width="fullWidth"
      :read-only="readOnly"
      :m-style="mStyle"
      :small="small"
      :placeholder="placeholder"
      :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
      :show-placeholder="!hidePlaceholder && url === ''"
    >
      <div class="_url-field">
        <m-link
          :class="['_url', { '-invalid': !validLink }, { '-disabled': disableLinks }]"
          :href="href"
          target="_blank"
          :underlined="validLink && !disableLinks"
          inherit-color
          :m-style="mStyle"
          :style="resolveStyles(mStyle)"
          @click="handleLinkClick"
        >
          {{ shortenedUrl }}
        </m-link>
        <m-card
          v-if="url !== '' && hideBorder"
          padding-xxxs
          border-radius="small"
          class="_actions"
        >
          <m-tooltip>
            <m-btn
              class="_btn"
              icon="copy"
              hide-border
              fab
              super-light
              xxs
              @click.stop="copyToClipboard"
            />
            <template #title>
              {{ $t('mUrlField.copyLink') }}
            </template>
          </m-tooltip>
        </m-card>
      </div>
    </m-focusable>
    <template #overlay>
      <m-card
        no-padding
        border-radius="small"
        :style="{ minWidth: '300px' }"
      >
        <m-input
          v-model:value="url"
          :placeholder="placeholder"
          full-width
          hide-hover
          hide-placeholder
          :hide-border="hideBorder"
          auto-focus
          @keydown.enter="showUrlDropdownInput = false"
        />
      </m-card>
    </template>
  </m-dropdown>
</template>

<script setup>
import useDebounce from '@/composables/debounce';
import useSnackBar from '@/composables/snackbar';
import { computed, onMounted, ref, toRef, watch } from 'vue';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  ...mStyleProps,
  title: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  small: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  hideBorder: {
    type: Boolean,
    default: false,
  },
  hideHover: {
    type: Boolean,
    default: false,
  },
  hidePlaceholder: {
    type: Boolean,
    default: false,
  },
  showPlaceholderIcon: {
    type: Boolean,
    default: false,
  },
  matchTriggerWidth: {
    type: Boolean,
    default: false,
  },
  autoFocus: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  wrap: {
    type: Boolean,
    default: false,
  },
  disableLinks: {
    type: Boolean,
    default: false,
  },
});

const input = ref(null);

const url = ref(props.value);

const showUrlDropdownInput = ref(false);
const open = () => {
  showUrlDropdownInput.value = true;
};

const emit = defineEmits(['change', 'blur']);

const changeUrl = () => {
  if (url.value === props.value) {
    return;
  }
  emit('change', url.value);
};

const hide = () => {
  emit('blur');
  cancelAll();
  changeUrl();
};

const { debounce, cancelAll } = useDebounce();
watch(url, () => {
  debounce(changeUrl, 500);
});

const validLink = computed(() => url.value !== '' && /^(https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/.test(url.value));
const shortenedUrl = computed(() => {
  const maxLength = 30;
  const displayUrl = url.value.replace(/^(https:\/\/)?(www\.)?/, '');
  return displayUrl.length > maxLength ? `${displayUrl.slice(0, maxLength / 2)}[...]${displayUrl.slice(-maxLength / 2)}` : displayUrl;
});
const href = computed(() => {
  if (props.disableLinks || !validLink.value) {
    return undefined;
  }

  if (url.value.startsWith('http://') || url.value.startsWith('https://')) {
    return url.value;
  }

  return `http://${url.value}`;
});

const { success } = useSnackBar();
const { t } = useI18n();

const copyToClipboard = () => {
  navigator.clipboard.writeText(url.value ?? '');
  success(t('success.copied'));
};

const handleLinkClick = (event) => {
  if (props.disableLinks || !validLink.value) {
    return;
  }
  event.stopPropagation();
};

onMounted(() => {
  if (props.autoFocus) {
    open();
  }
});

const localValue = toRef(props, 'value');
watch(localValue, (value) => {
  url.value = value;
});

defineExpose({ open });
</script>

<style scoped lang="scss" type="text/scss">
.url-input {
  align-items: flex-start;
  position: relative;
  height: 100%;

  ._url-field {
    display: block;
    max-width: 100%;
    color: $secondary-color;

    ._url {
      white-space: nowrap;

      &.-invalid {
        color: $font-color-primary;
      }

      &.-disabled {
        color: $font-color-primary;
      }
    }

    ._actions {
      display: none;
      position: absolute;
      right: .4rem;
      top: 50%;
      transform: translateY(-50%);

      ._actions-inner {
        display: flex;
        align-items: center;
      }
    }
  }

  &:hover {
    ._url-field {
      ._actions {
        display: block;
      }
    }
  }

  &.-wrap {
    ._url-field-wrapper {
      align-items: flex-start;
      padding: .6rem .8rem;
    }

    &.-input-field {
      ._url-field-wrapper {
        padding: .4rem .8rem
      }
    }

    ._url-field {
      ._url {
        white-space: pre-wrap;
        word-break: break-all;
      }

      ._actions {
        top: .5rem;
        transform: translateY(0);
      }
    }
  }

  &.-hide-hover {
    ._url-field {
      padding: .3rem 0;
    }
  }
}
</style>
