import { chartGroupByOption, gridPageChartType } from 'shared/constants.json';
import { computed } from 'vue';

export default function useLineChart(result, chart) {
  const isStacked = computed(() => [gridPageChartType.line, gridPageChartType.bar, gridPageChartType.column].includes(chart.value.chartType)
      && chart.value.aggregationConfig.stackOptions.groupBy !== chartGroupByOption.none);

  const chartType = computed(() => {
    if (isStacked.value) {
      return 'area';
    }
    return 'line';
  });

  const series = computed(() => {
    if (chart.value.chartType !== gridPageChartType.line) {
      return [];
    }

    if (result.value === null) {
      return [];
    }

    return result.value.series;
  });

  const categories = computed(() => {
    if (result.value === null) {
      return [];
    }

    return result.value.categories;
  });

  const chartOptions = computed(() => ({
    chart: {
      animation: false,
      type: chartType.value,
    },
    plotOptions: {
      [chartType.value]: {
        stacking: 'normal',
        dataLabels: { enabled: true },
      },
      series: {
        events: {
          hide(e) {
            const chart = this.chart;
            const visibleCount = chart.series.filter((s) => s.visible).length;

            if (visibleCount === 0 && !this.visible) {
              chart.series[e.target.index].show();
            }
          },
        },
      },
    },
    yAxis: { title: { text: null } },
    legend: {
      enabled: isStacked.value,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      y: 10,
      events: {
        itemClick(e) {
          const chart = this.chart;
          const visibleCount = chart.series.filter((s) => s.visible).length;

          if (visibleCount === 1 && e.legendItem.visible) {
            return false;
          }
          return true;
        },
      },
    },
    xAxis: { categories: categories.value },
    series: series.value,
    tooltip: {
      formatter() {
        return `
              <b>${this.point.name}:</b> ${this.point.y}
            `;
      },
    },
  }));

  return { chartOptions };
}
