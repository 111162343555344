import { chartGroupByOption } from 'shared/constants.json';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { shallowCopy } from 'shared/lib/copy';
import {
  sortAlphaNumeric,
  sortDatetimeInterval,
} from 'shared/lib/sort';

export default function useChartSort() {
  const getType = (groupBy) => {
    if (groupBy.groupBy === chartGroupByOption.none) {
      return '';
    }
    if (groupBy.isProperty === true) {
      return groupBy.property.type;
    }
    if (groupBy.edge === goalConfig.edges.goalCycle) {
      return 'datetimeInterval';
    }
    return groupBy.edgeType;
  };

  // TODO: If we resolve cached properties, we need to extend this
  // to be able to sort by uid order.
  const getSorter = (groupBy) => {
    switch (getType(groupBy)) {
      case 'datetimeInterval':
        return sortDatetimeInterval('start', 'end');
      default:
        return sortAlphaNumeric;
    }
  };

  const sort = (groups, groupBy) => {
    const sorted = shallowCopy(groups);
    sorted.sort(getSorter(groupBy));
    return sorted;
  };

  return { sort };
}
