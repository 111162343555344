<template>
  <page-layout
    :class="['updates-list', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
        class="_header"
      >
        <template #actions>
          <template v-if="!$store.state.breakpoint.smAndDown">
            <favorite-button
              :title-suggestion="currentView.title"
              :icon-suggestion="buildIcon(currentView.emoji)"
            />
          </template>
          <m-dropdown
            v-else
            :title="$t('general.actions')"
          >
            <m-btn
              fab
              icon="ellipsis"
              hide-border
            />
            <template #overlay>
              <m-card
                list
                no-padding
              >
                <favorite-button
                  :title-suggestion="currentView.title"
                  :icon-suggestion="buildIcon(currentView.emoji)"
                />
              </m-card>
            </template>
          </m-dropdown>
        </template>
      </page-top-bar>
    </template>
    <scroll-container>
      <m-content
        padding-x="layout"
        class="_header"
      >
        <editable-page-header
          :key="title"
          :title="title"
          disabled
          size="small"
        />
        <updates-explorer
          :templates-loading="templatesLoading"
          show-create-view
          show-views-selector
          show-onboarding
        />
      </m-content>
    </scroll-container>
  </page-layout>
</template>

<script>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import UpdatesExplorer from '@/components/updates/UpdatesExplorer.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useSelectedViews from '@/composables/saved-views/selected-views';
import useUpdateDefaultProps from '@/composables/saved-views/update-default-props';
import useUpdateTemplates from '@/composables/update-templates/update-templates';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND, CREATED_AT } from 'shared/api/query/constants';
import { VIEWS_SERVICE } from '@/lib/constants';
import { buildIcon } from 'shared/lib/icon';
import { provide, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { viewApplication, viewType } from 'shared/constants.json';

export default {
  name: 'UpdatesList',
  components: {
    ScrollContainer,
    PageTopBar,
    EditablePageHeader,
    PageLayout,
    FavoriteButton,
    UpdatesExplorer,
  },
  setup() {
    const { getSelectedViewViewForApplication } = useSelectedViews();
    const { userHasRight } = useAccess();
    const updateTemplatesSvc = useUpdateTemplates();
    const loggedInUserAccountSvc = useLoggedInUserAccount();

    const isFeedRef = ref(false);

    const defaultPropsSvc = useUpdateDefaultProps({ isFeed: isFeedRef });
    const viewServiceInit = useViewServiceInit();

    const i18n = useI18n();

    const viewApp = viewApplication.update;

    const defaultView = {
      viewType: viewType.feed,
      title: i18n.t('list.feed'),
      viewApplication: viewApp,
      params: {
        filter: { account: { uid: loggedInUserAccountSvc.loggedInUserAccount.value.uid }, children: [], op: AND },
        order: [{ attr: CREATED_AT, desc: true }],
        props: [],
      },
    };

    let viewConfig = viewServiceInit.defaultConfig();
    viewConfig = viewServiceInit.withDefaultProps(viewConfig, defaultPropsSvc.defaultProps);
    viewConfig = viewServiceInit.withDefaultView(viewConfig, defaultView);
    viewConfig = viewServiceInit.withViewApplication(viewConfig, viewApp);

    const viewSvc = viewServiceInit.routeAwareViewService(viewConfig);
    provide(VIEWS_SERVICE, viewSvc);

    watch(viewSvc.currentView, (currentView) => {
      if (currentView.viewType === viewType.feed) {
        isFeedRef.value = true;
        return;
      }
      isFeedRef.value = false;
    }, { immediate: true });

    return {
      getSelectedViewViewForApplication,
      userHasRight,
      fetchUpdateTemplates: updateTemplatesSvc.fetchUpdateTemplates,
      templatesLoading: updateTemplatesSvc.fetchUpdateTemplatesLoading,

      viewApplication: viewApp,
    };
  },
  computed: {
    currentView() {
      return this.getSelectedViewViewForApplication({ application: this.viewApplication }).value || {};
    },
    title() {
      return this.breadcrumbs[this.breadcrumbs.length - 1].title;
    },
    breadcrumbs() {
      return [
        {
          title: this.$t('navigation.updatesExplorer'),
          disabled: true,
          icons: [{ value: buildIcon('History_dark_grey') }],
        },
        {
          title: this.$t('navigation.explorer'),
          disabled: true,
        },
      ];
    },
  },
  methods: { buildIcon },
  created() {
    this.fetchUpdateTemplates();
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .updates-list {
    min-height: var(--viewport-height-100);
    overflow: auto;

    ._layout {
      position: sticky;
      left: 0;
    }

    ._tabs-wrapper {
      position: sticky;
      left: 0;
      margin: 4rem 0 3rem;
    }

    &.-mobile {
      width: 100%;
      max-width: 100vw;
      height: var(--viewport-height-100);
      overflow: auto;

      ._layout {
        position: sticky;
        top: 0;
        z-index: 9;
        background-color: white;
      }
    }
  }
</style>
