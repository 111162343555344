<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="43"
      height="43"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39.3033 22.3903C39.7782 22.3903 40.1715 22.0171 40.1725 21.5422C40.1725 21.5286 40.1725 21.5149 40.1725 21.5012C40.1725 11.1894 31.8131 2.83002 21.5013 2.83002C21.4876 2.83002 21.4739 2.83003 21.4603 2.83006C20.9854 2.83108 20.6122 3.22436 20.6122 3.69921V4.60823V5.69971V9.97654V11.7211V11.9422C20.6122 12.4188 21.0247 12.788 21.5013 12.788V12.788C26.3135 12.788 30.2145 16.689 30.2145 21.5012C30.2145 21.6038 30.2128 21.706 30.2092 21.8078C30.1984 22.1201 30.4412 22.3903 30.7537 22.3903H31.2814C31.2815 22.3903 31.2815 22.3903 31.2815 22.3903V22.3903C31.2815 22.3904 31.2815 22.3904 31.2815 22.3904H38.3943C38.3943 22.3904 38.3943 22.3904 38.3943 22.3903V22.3903C38.3943 22.3903 38.3943 22.3903 38.3943 22.3903H39.3033ZM23.4573 9.3573C23.4573 9.79069 23.7712 10.1573 24.1927 10.2579C28.4146 11.2648 31.7378 14.588 32.7447 18.8098C32.8452 19.2314 33.2119 19.5452 33.6452 19.5452H36.2023C36.7365 19.5452 37.1519 19.0763 37.053 18.5513C35.8511 12.1763 30.8263 7.15145 24.4512 5.94959C23.9263 5.85063 23.4573 6.26608 23.4573 6.80027V9.3573Z"
      fill="#288AA8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.83002 21.5013C2.83002 12.4486 9.27262 4.90058 17.8229 3.19228C18.3535 3.08626 18.8339 3.50333 18.8339 4.04446V5.14809C18.8339 5.58172 18.5203 5.94943 18.0967 6.04229C10.9925 7.59973 5.67516 13.9295 5.67516 21.5013C5.67516 30.2418 12.7607 37.3274 21.5013 37.3274C29.073 37.3274 35.4028 32.01 36.9603 24.9058C37.0531 24.4822 37.4208 24.1686 37.8545 24.1686H38.9581C39.4992 24.1686 39.9163 24.649 39.8103 25.1796C38.102 33.7299 30.554 40.1725 21.5013 40.1725C11.1894 40.1725 2.83002 31.8131 2.83002 21.5013ZM30.4469 24.1686C30.0607 24.1686 29.7228 24.42 29.5775 24.7779C28.2826 27.9663 25.1545 30.2145 21.5013 30.2145C16.6891 30.2145 12.788 26.3135 12.788 21.5013C12.788 17.8481 15.0363 14.72 18.2246 13.4251C18.5825 13.2798 18.8339 12.9419 18.8339 12.5556V11.3758C18.8339 10.8021 18.2972 10.3778 17.7545 10.5637C13.21 12.1201 9.94287 16.4291 9.94287 21.5013C9.94287 27.8848 15.1177 33.0597 21.5013 33.0597C26.5734 33.0597 30.8825 29.7926 32.4389 25.248C32.6247 24.7053 32.2004 24.1686 31.6268 24.1686H30.4469Z"
      fill="#BDBDBD"
    />
  </svg>
</template>

<script>
export default { name: 'PieChartColored' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
