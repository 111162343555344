<template>
  <page-layout
    hide-top-bar
    class="published-view"
  >
    <scroll-container
      no-padding-bottom
      :style="{ width: `${containerWidth}px` }"
    >
      <m-content
        padding-x="layout"
        class="_header"
      >
        <div
          :style="{ width: `${contentWidth}px` }"
        >
          <editable-page-header
            :key="currentView.title"
            :title="currentView.title"
            :icon="buildIcon(currentView.emoji)"
            :show-icon="currentView.emoji !== ''"
            disabled
          />
        </div>
      </m-content>
      <goals-list-wrapper
        :header-slots="headerSlots"
        stretch-content
        fullscreen
        read-only
        :base-filter="publishedAtFilter"
        :selected-cycles="selectedCycles"
        :change-selected-goal-cycles="changeSelectedGoalCycles"
        :is-cycle-locked-on-view="isCycleLockedOnView"
        :default-props="defaultProps"
      />
    </scroll-container>
  </page-layout>
</template>

<script>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import GoalsListWrapper from '@/components/goal/GoalsListWrapper.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import useAdaptiveGoalCycle from '@/composables/goal-cycle/adaptive-goal-cycle';
import useGoalDefaultProps from '@/composables/saved-views/goal-default-props';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useSelectedViews from '@/composables/saved-views/selected-views';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND } from '@/lib/filter/scope-tree';
import { SIDEBAR_SERVICE, SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { buildIcon } from 'shared/lib/icon';
import { computed, inject, provide } from 'vue';
import { useI18n } from 'vue-i18n';
import { viewApplication, viewType } from 'shared/constants.json';

export default {
  name: 'PublishedView',
  components: { ScrollContainer, GoalsListWrapper, PageLayout, EditablePageHeader },
  setup() {
    const { publishedAtFilter } = usePublishedAtFilter();

    const i18n = useI18n();

    const viewApp = viewApplication.goal;
    const defaultView = computed(() => ({
      title: i18n.t('list.cascade'),
      viewType: viewType.cascade,
      viewApplication: viewApp,
      params: {
        filter: { account: { uid: 0 }, children: [], op: AND },
        applyFilterOnFirstLevelOnly: false,
        order: [],
        props: [],
      },
    }));

    const viewServiceInitService = useViewServiceInit();
    const defaultPropsSvc = useGoalDefaultProps();

    let routeAwareConfig = viewServiceInitService.defaultConfig();
    routeAwareConfig = viewServiceInitService.withReadOnly(routeAwareConfig, true);
    routeAwareConfig = viewServiceInitService.withDefaultView(routeAwareConfig, defaultView.value);
    routeAwareConfig = viewServiceInitService.withViewApplication(routeAwareConfig, viewApp);
    routeAwareConfig = viewServiceInitService.withDefaultProps(routeAwareConfig, defaultPropsSvc.defaultProps);

    const viewSvc = viewServiceInitService.routeAwareViewService(routeAwareConfig);
    provide(VIEWS_SERVICE, viewSvc);

    const { isCycleLockedOnView, goalCycleSvc } = useAdaptiveGoalCycle(viewSvc, true);

    const { getSelectedViewViewForApplication } = useSelectedViews();

    const { containerWidth, contentWidth } = inject(SIDEBAR_SERVICE);
    return {
      isCycleLockedOnView,
      goalCycleSvc,

      getSelectedViewViewForApplication,
      publishedAtFilter,

      viewApp,
      defaultProps: defaultPropsSvc.defaultProps,
      containerWidth,
      contentWidth,
    };
  },
  data() {
    return { headerSlots: [{ name: SLOTS.CYCLE_SELECTOR }, { name: SLOTS.FILTER }, { name: SLOTS.SORTER }] };
  },
  computed: {
    selectedCycles() {
      return this.goalCycleSvc.composedSelectedCycles.value;
    },
    changeSelectedGoalCycles() {
      if (!this.isCycleLockedOnView) {
        return this.goalCycleSvc.changeSelectedGoalCycles;
      }
      return undefined;
    },
    currentView() {
      return this.getSelectedViewViewForApplication({ application: this.viewApp }).value || {};
    },
  },
  methods: { buildIcon },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .published-view {
    ._header {
      position: sticky;
      left: 0;
    }
  }
</style>
