<template>
  <div
    class="space-permissions"
  >
    <m-form-item
      class="_form-item"
      :label="$t('spacePermissions.permissions')"
    >
      <div
        class="_permissions-group"
      >
        <access-policy-scope-row
          :access-type="accessPolicyType.full"
          disabled
        >
          <template #name>
            {{ $t('spacePermissions.owners.title') }}
          </template>
          <template #description>
            {{ $t('spacePermissions.owners.subtitle') }}
          </template>
          <template #access-policy-type-selector>
            <div />
          </template>
        </access-policy-scope-row>
        <m-divider none />
        <access-policy-scope-row
          :access-type="permissions.spaceMemberAccess"
          :access-types="accessTypesForMemberAccess"
          :disabled="disabled"
          @change="updateMemberAccess"
        >
          <template #name>
            {{ $t('spacePermissions.members.title') }}
          </template>
          <template #description>
            {{ $t('spacePermissions.members.subtitle') }}
          </template>
        </access-policy-scope-row>
      </div>
      <m-btn
        small
        super-light
        :href="$t('spacePermissions.helpLink')"
        hide-border
        icon="question-circle"
        target="_blank"
      >
        {{ $t('spacePermissions.learnMore') }}
      </m-btn>
    </m-form-item>
    <m-form-item
      class="_form-item"
      :label="$t('spacePermissions.advancedPermissions')"
    >
      <div
        class="_permissions-group"
      >
        <access-policy-scope-row
          :image="companyImage"
          icon="global"
          :access-type="permissions.accountAccess"
          :access-types="accessTypesForAccountAccess"
          :disabled="disabled"
          @change="updateAccountAccess"
        >
          <template #name>
            {{ $t('spacePermissions.accountAccessTitle', {name:account.companyName}) }}
          </template>
        </access-policy-scope-row>
        <div
          v-for="scope in sortedScopes"
          :key="scope.uid"
          class="_btn"
        >
          <template v-if="canRenderAccessPolicyScope(scope)">
            <m-divider none />
            <access-policy-scope-user-row
              v-if="isStaticUserScope(scope)"
              :user="userFromStaticUserScope(scope)"
              :access-type="scope.accessType"
              :access-types="accessTypesWithRemove"
              :disabled="disabled"
              @change="scopeTypeChanged(scope, $event)"
            />
            <access-policy-scope-group-row
              v-else
              :scope="scope"
              :access-type="scope.accessType"
              :access-types="accessTypesWithRemove"
              :disabled="disabled"
              @change="scopeTypeChanged(scope, $event)"
            />
          </template>
        </div>
      </div>
      <m-btn
        v-if="!readOnly"
        color="primary"
        :disabled="disabled"
        @click.stop="showScopeSelector"
      >
        {{ $t('spacePermissions.addGroupOrUser') }}
      </m-btn>
    </m-form-item>
  </div>
  <m-dialog
    v-model:value="showModal"
    hide-footer
    no-padding
  >
    <access-policy-scope-selector
      :button-label="$t('spacePermissions.addGroupOrUser')"
      :access-types="accessTypes"
      @submit="scopeSubmitted"
    />
  </m-dialog>
</template>
<script setup>
import AccessPolicyScopeGroupRow from '@/components/access-policy/AccessPolicyScopeGroupRow.vue';
import AccessPolicyScopeRow from '@/components/access-policy/AccessPolicyScopeRow.vue';
import AccessPolicyScopeSelector from '@/components/access-policy/AccessPolicyScopeSelector.vue';
import AccessPolicyScopeUserRow from '@/components/access-policy/AccessPolicyScopeUserRow.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useSnackBar from '@/composables/snackbar';
import useSpaces from '@/composables/space/spaces';
import { ACCESS_POLICY_TYPE_REMOVE } from '@/lib/constants';
import { DateTime } from 'luxon';
import { UserScopeTreeHasher } from '@/lib/user-scope-tree_hasher';
import { accessPolicyType } from 'shared/constants.json';
import { addOrUpdate } from 'shared/lib/array/write';
import { canRenderAccessPolicyScope, isStaticUserScope, userFromStaticUserScope } from '@/lib/access-policy-scope';
import { cmpAccessRight, spreadAccessPolicyScopes } from '@/lib/access-policy';
import { computed, ref } from 'vue';
import { copy, shallowCopy } from 'shared/lib/copy';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { logCatch } from '@/lib/logger/logger';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const snackbar = useSnackBar();

const props = defineProps({
  space: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const spacesSvc = useSpaces();
const disabled = computed(() => props.readOnly || spacesSvc.updateSpaceLoading.value);
const permissions = computed(() => props.space.permissions);

const accessTypes = [accessPolicyType.read, accessPolicyType.comment, accessPolicyType.write, accessPolicyType.full];
const accessTypesWithDisable = [...accessTypes, accessPolicyType.disabled];
const accessTypesWithRemove = [...accessTypes, ACCESS_POLICY_TYPE_REMOVE];
const accessTypesForMemberAccess = computed(() => accessTypesWithDisable.map((type) => ({
  value: type,
  disabled: cmpAccessRight(type, permissions.value.accountAccess) < 0,
  tooltip: cmpAccessRight(type, permissions.value.accountAccess) < 0 ? t('spacePermissions.disabledSpaceMemberAccessType') : undefined,
})));
const accessTypesForAccountAccess = computed(() => accessTypesWithDisable.filter((type) => cmpAccessRight(type, permissions.value.spaceMemberAccess) <= 0));
const { loggedInUserAccount: account } = useLoggedInUserAccount();
const companyImage = computed(() => {
  if (account.value.companyImage === null) {
    return '';
  }
  return account.value.companyImage.getURL;
});

const sortedScopes = computed(() => shallowCopy(permissions.value.scopes).filter((s) => s?.deletedAt === undefined).sort((a, b) => {
  const aStatic = isStaticUserScope(a);
  const bStatic = isStaticUserScope(b);
  if (aStatic === bStatic) return 0;
  if (aStatic === true) return 1;
  return -1;
}));

const updatePermissions = (permissions) => {
  spacesSvc.updateSpace({ uid: props.space.uid, permissions }, { optimistic: false }).catch(logCatch(() => {
    snackbar.error();
  }));
};
const updateMemberAccess = (newType) => {
  updatePermissions({
    uid: permissions.value.uid,
    spaceMemberAccess: newType,
  });
};
const updateAccountAccess = (newType) => {
  updatePermissions({
    uid: permissions.value.uid,
    accountAccess: newType,
  });
};

const showModal = ref(false);
const showScopeSelector = () => {
  if (disabled.value) {
    return;
  }
  showModal.value = true;
};
const scopeSubmitted = (scope) => {
  showModal.value = false;
  updateScopes(spreadAccessPolicyScopes(scope));
};
const scopeTypeChanged = (scope, newType) => {
  const newScope = copy(scope);
  newScope.accessType = newType;

  if (newType === ACCESS_POLICY_TYPE_REMOVE) {
    newScope.accessType = accessPolicyType.disabled;
    removeScope(newScope);
    return;
  }

  updateScopes([newScope]);
};
const removeScope = (scope) => {
  const deletedAt = DateTime.local().toISO();
  const scopesToDelete = [{ ...scope, deletedAt }];

  updateScopes(scopesToDelete);
};
const updateScopes = (newScopes) => {
  const hasher = new UserScopeTreeHasher();
  const fillHashesToAccessPolicyScope = (aps) => {
    if (!isNullOrUndefined(aps.scope)) {
      const hash = hasher.treeHash(aps.scope);
      return { ...aps, scopeHash: hash, scope: { ...aps.scope, treeHash: hash } };
    }
    return aps;
  };
  let scopes = permissions.value.scopes.map(fillHashesToAccessPolicyScope);
  const newSc = newScopes.map(fillHashesToAccessPolicyScope);
  scopes = addOrUpdate(scopes, newSc, 'scopeHash');
  updatePermissions({
    uid: permissions.value.uid,
    scopes,
  });
};

</script>

<style lang="scss" scoped>
.space-permissions {
  ._permissions-group {
    border: 1px solid $border-color;
    border-radius: .6rem;
    margin-bottom: .4rem;
  }
}
</style>
