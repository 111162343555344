<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.1539 8.00008C5.43345 7.99651 5.70173 8.11012 5.89369 8.31338L9.22702 11.8428C9.60623 12.2443 9.58815 12.8772 9.18663 13.2564C8.78511 13.6356 8.15221 13.6176 7.773 13.216L6.16668 11.5152V18C6.16668 19.1046 7.06211 20 8.16667 20H18.5C19.0523 20 19.5 20.4477 19.5 21C19.5 21.5523 19.0523 22 18.5 22H8.16667C5.95753 22 4.16668 20.2091 4.16668 18V11.612L2.74433 13.1972C2.3755 13.6083 1.74326 13.6426 1.33219 13.2737C0.92111 12.9049 0.88686 12.2727 1.25569 11.8616L4.42235 8.33218C4.60906 8.12409 4.87435 8.00365 5.1539 8.00008Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.8461 15.9999C18.5666 16.0035 18.2983 15.8899 18.1063 15.6866L14.773 12.1572C14.3938 11.7557 14.4118 11.1228 14.8134 10.7436C15.2149 10.3644 15.8478 10.3824 16.227 10.784L17.8333 12.4848V6C17.8333 4.89543 16.9379 4 15.8333 4H5.49999C4.94771 4 4.49999 3.55229 4.49999 3C4.49999 2.44771 4.94771 2 5.49999 2H15.8333C18.0425 2 19.8333 3.79086 19.8333 6V12.388L21.2557 10.8028C21.6245 10.3917 22.2567 10.3574 22.6678 10.7263C23.0789 11.0951 23.1131 11.7273 22.7443 12.1384L19.5776 15.6678C19.3909 15.8759 19.1257 15.9963 18.8461 15.9999Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Wrap' };
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
/>
