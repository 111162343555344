import { aggregatorType, bucketType, chartGroupByOption } from 'shared/constants.json';
import { equalP } from 'shared/lib/array/array';
import { isEqual } from 'lodash-es';
import { textByLang } from 'shared/lib/language';

export const getDayFromISOString = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

const addLeadingZero = (value) => value.toString().padStart(2, '0');

export const getWeekFromISOString = (dateString) => {
  const date = new Date(dateString);

  // The use of Thursday to calculate the week number is based on the ISO 8601 standard for week numbering. Here's why Thursday is used:
  // ISO 8601 Standard: The ISO 8601 standard defines the first week of the year as the week with the first Thursday in it. This means that the week starts on Monday, and Thursday is used as a reference point to determine the week number.
  // Set the date to the nearest Thursday: current date + 4 - current day number
  // Make Sunday (0) the last day of the week
  date.setDate(date.getDate() + 4 - (date.getDay() || 7));

  // Get the first day of the year
  const yearStart = new Date(date.getFullYear(), 0, 1);

  // Calculate full weeks to the nearest Thursday
  const weekNumber = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);

  // Return the year and week number
  return `${date.getFullYear()}-W${addLeadingZero(weekNumber)}`;
};

export const getMonthFromISOString = (dateString) => {
  const date = new Date(dateString);
  return `${date.getFullYear()}-${addLeadingZero(date.getMonth() + 1)}`;
};

export const getYearFromISOString = (dateString) => {
  const date = new Date(dateString);
  return date.getFullYear();
};

export const getQuarterFromISOString = (dateString) => {
  const date = new Date(dateString);
  const quarter = Math.ceil((date.getMonth() + 1) / 3);
  return `${date.getFullYear()}-Q${quarter}`;
};

export const edgeName = (groupBy, t, userLang) => {
  if (!groupBy.isProperty) {
    return t(`edges.${groupBy.edge}`);
  }
  return textByLang(groupBy.property.label, userLang);
};

export const roundNumber = (number, precision = 2) => Number(number.toFixed(precision));

const isEqualCustomBucket = (a, b) => {
  if (a.bucketType !== b.bucketType) {
    return false;
  }
  if (a.bucketType === bucketType.automatic) {
    return true;
  }
  if (a.bucketSize !== b.bucketSize) {
    return false;
  }
  if (a.min !== b.min) {
    return false;
  }
  return a.max === b.max;
};

const isEqualGroupBy = (a, b) => {
  if (a.groupBy !== b.groupBy) {
    return false;
  }
  if (a.groupBy === chartGroupByOption.none) {
    return true;
  }
  if (a.groupBy === chartGroupByOption.bucket && !isEqualCustomBucket(a.customBucket, b.customBucket)) {
    return false;
  }
  if (a.isProperty !== b.isProperty) {
    return false;
  }
  if (a.isProperty) {
    return a.property.uid === b.property.uid;
  }
  if (a.edge !== b.edge) {
    return false;
  }
  if (a.edgeType !== b.edgeType) {
    return false;
  }
  return true;
};

const isEqualAggregation = (a, b) => {
  if (a.aggregator !== b.aggregator) {
    return false;
  }
  if (a.aggregator === aggregatorType.count) {
    return true;
  }
  if (a.isProperty !== b.isProperty) {
    return false;
  }
  if (a.isProperty) {
    return a.property.uid === b.property.uid;
  }
  if (a.edge !== b.edge) {
    return false;
  }
  if (a.edgeType !== b.edgeType) {
    return false;
  }
  return true;
};

export const isEqualGridPageTileChart = (a, b) => {
  const equalUid = a.uid === b.uid;
  const equalChartType = a.chartType === b.chartType;
  const equalSource = a.source === b.source;
  const equalFilter = isEqual(a.filter, b.filter);
  const equalGoalCycles = equalP({ a: a.goalCycles, b: b.goalCycles });
  const equalGroupBy = isEqualGroupBy(a.groupBy, b.groupBy);
  const equalStackOptions = isEqualGroupBy(a.aggregationConfig.stackOptions, b.aggregationConfig.stackOptions);
  const equalAggregation = isEqualAggregation(a.aggregationConfig.aggregation, b.aggregationConfig.aggregation);
  return equalUid && equalChartType && equalSource && equalFilter && equalGoalCycles && equalGroupBy && equalStackOptions && equalAggregation;
};

const CACHED_VALUE_SEPARATOR = '___COMMA___';

export const getDisplayValue = (cachedValue, groupBy, userLang) => {
  if ([chartGroupByOption.day, chartGroupByOption.week, chartGroupByOption.month, chartGroupByOption.quarter, chartGroupByOption.year].includes(groupBy.groupBy)) {
    switch (groupBy.groupBy) {
      case chartGroupByOption.day:
        return new Date(cachedValue).toLocaleDateString(userLang, { day: 'numeric', month: 'long', year: 'numeric' });
      case chartGroupByOption.week: {
        // ISO calendar weeks start on Monday. Week 1 is the week containing the first Thursday of the year.
        // This means Jan 1st could be in week 52/53 of previous year, or week 1 of current year.
        // We find week 1's Monday by checking if Jan 1st is before (<=4) or after first Thursday.
        const [year, weekStr] = cachedValue.split('-W');
        const week = parseInt(weekStr, 10);

        // Get Jan 1st of the year
        const yearStart = new Date(parseInt(year, 10), 0, 1);

        // Get to Monday of week 1 by finding first Monday before first Thursday
        const dayOffset = yearStart.getDay() || 7; // Convert Sunday (0) to 7
        const mondayOffset = dayOffset <= 4 ? 1 - dayOffset : 8 - dayOffset;
        const firstMonday = new Date(yearStart);
        firstMonday.setDate(yearStart.getDate() + mondayOffset);

        // Get to the requested week's Monday by adding weeks
        const startDate = new Date(firstMonday);
        startDate.setDate(firstMonday.getDate() + (week - 1) * 7);

        // End date is 6 days after start
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        return `${startDate.toLocaleDateString(userLang, { month: 'long', day: 'numeric' })} - ${endDate.toLocaleDateString(userLang, { month: 'long', day: 'numeric', year: 'numeric' })}`;
      }
      case chartGroupByOption.month:
        return new Date(cachedValue).toLocaleDateString(userLang, { month: 'long', year: 'numeric' });
      case chartGroupByOption.quarter: {
        const [year, quarter] = cachedValue.split('-Q');
        return `Q${quarter} ${year}`;
      }
      case chartGroupByOption.year:
        return new Date(cachedValue).toLocaleDateString(userLang, { year: 'numeric' });
      default:
        return cachedValue;
    }
  }
  const s = `${cachedValue}`.split(CACHED_VALUE_SEPARATOR);
  return s.join(', ');
};
