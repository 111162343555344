<template>
  <div class="browser-not-supported">
    <div class="_content">
      <div class="_emoji">
        😬
      </div>
      <div class="_text">
        <h1 class="_heading">
          {{ $t('browserNotSupported.title') }}
        </h1>
        <div class="_description">
          {{ $t('browserNotSupported.hint') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default { name: 'BrowserNotSupported' };
</script>

<style scoped lang="scss" type="text/scss">
  .browser-not-supported {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: var(--viewport-height-100);
    text-align: center;

    ._content {
      max-width: 60rem;
      padding: 2rem;

      ._emoji {
        font-size: 6rem;
      }

      ._heading {
        margin-top: 1rem;
      }

      ._description {
        font-size: $font-size-5;
      }
    }
  }
</style>
