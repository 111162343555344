import useChartGrouping from '@/composables/chart-grouping';
import useGoals from '@/composables/goal/goals';
import useParamEnricher from '@/composables/view-params/enrich';
import useProperties from '@/composables/property/property';
import { AND, HAS, NOT, TYPE, UID } from 'shared/api/query/constants';
import { GoalActivityFilterHandler } from '@/lib/filter/goal-activity/handler';
import { GoalFilterHandler } from '@/lib/filter/goal/handler';
import { UpdatesFilterHandler } from '@/lib/filter/updates/handler';
import { aggregatorType, chartGroupByOption, gridPageChartType } from 'shared/constants.json';
import { combine } from 'shared/api/query/filter';
import { computed, ref, watch } from 'vue';
import { copy } from 'shared/lib/copy';
import { createGoalCycleFilter } from '@/api/query/nebula/goal-cascade';
import { dogma } from '@/api';
import { goalActivity as goalActivityConfig, goal as goalConfig, update as updateConfig } from 'shared/api/query/configs.json';
import { logCatch } from '@/lib/logger/logger';

export default function useChartDataQuery(chart) {
  const loading = ref(false);
  const resultRaw = ref(null);
  const error = ref(null);

  const { processChartData } = useChartGrouping(chart, resultRaw);

  const goalFilterHandler = new GoalFilterHandler();
  const updatesFilterHandler = new UpdatesFilterHandler();
  const goalActivityFilterHandler = new GoalActivityFilterHandler();

  const propertySvc = useProperties();
  const goalsSvc = useGoals();
  const paramEnricher = useParamEnricher(propertySvc, goalsSvc);

  const enrichedFilter = computed(() => {
    if (chart.value.filter === null) {
      return null;
    }
    return paramEnricher.enrich(copy(chart.value.filter));
  });

  const filter = computed(() => {
    const goalCycleFilter = createGoalCycleFilter(chart.value.goalCycles);
    if (enrichedFilter.value === null) {
      return { filterTree: goalCycleFilter, varBlocks: [] };
    }

    switch (chart.value.source) {
      case goalConfig.model: {
        const filterObject = goalFilterHandler.Translate(enrichedFilter.value);
        return {
          filterTree: combine(AND, [goalCycleFilter, filterObject.filterTree]),
          varBlocks: filterObject.varBlocks,
        };
      }
      case updateConfig.model: {
        const filterObject = updatesFilterHandler.Translate(enrichedFilter.value);
        const checkInFilter = { op: NOT, child: [{ func: { name: HAS, attr: updateConfig.edges.goal } }] };
        return {
          filterTree: combine(AND, [checkInFilter, filterObject.filterTree]),
          varBlocks: filterObject.varBlocks,
        };
      }
      case goalActivityConfig.model: {
        const filterObject = goalActivityFilterHandler.Translate(enrichedFilter.value);
        return {
          filterTree: filterObject.filterTree,
          varBlocks: filterObject.varBlocks,
        };
      }
      default:
        return { filterTree: null, varBlocks: [] };
    }
  });

  const getEdge = (prop) => {
    if (prop.isProperty) {
      return 'cachedProperty';
    }
    return prop.edge;
  };

  const getLangs = (prop) => {
    if (prop.isProperty) {
      return [`p${prop.property.uid}`];
    }
    return null;
  };

  const appendStackOptions = (groupbyAttrs) => {
    if (![gridPageChartType.line, gridPageChartType.bar, gridPageChartType.column].includes(chart.value.chartType)) {
      return groupbyAttrs;
    }
    if (chart.value.aggregationConfig.stackOptions.groupBy === chartGroupByOption.none) {
      return groupbyAttrs;
    }
    return [
      ...groupbyAttrs,
      {
        alias: 'stackGroup',
        attr: getEdge(chart.value.aggregationConfig.stackOptions),
        langs: getLangs(chart.value.aggregationConfig.stackOptions),
      },
    ];
  };

  const query = computed(() => {
    switch (chart.value.chartType) {
      case gridPageChartType.metric:
        switch (chart.value.aggregationConfig.aggregation.aggregator) {
          case aggregatorType.count: {
            return [
              ...filter.value.varBlocks,
              {
                alias: 'res',
                func: { name: TYPE, args: [{ value: chart.value.source, typ: 1 }] },
                filter: filter.value.filterTree,
                model: chart.value.source,
                children: [
                  { attr: UID, isCount: true },
                ],
              },
            ];
          }
          case aggregatorType.countDistinct:
            return [
              ...filter.value.varBlocks,
              {
                alias: 'res',
                func: { name: TYPE, args: [{ value: chart.value.source, typ: 1 }] },
                filter: filter.value.filterTree,
                model: chart.value.source,
                isGroupby: true,
                groupbyAttrs: [{
                  attr: getEdge(chart.value.aggregationConfig.aggregation),
                  langs: getLangs(chart.value.aggregationConfig.aggregation),
                }],
              },
            ];
          case aggregatorType.avg:
          case aggregatorType.sum:
          case aggregatorType.min:
          case aggregatorType.max: {
            return [
              ...filter.value.varBlocks,
              {
                alias: 'var',
                func: { name: TYPE, args: [{ value: chart.value.source, typ: 1 }] },
                filter: filter.value.filterTree,
                model: chart.value.source,
                children: [
                  {
                    attr: getEdge(chart.value.aggregationConfig.aggregation),
                    langs: getLangs(chart.value.aggregationConfig.aggregation),
                    var: 'edgeVal',
                  },
                ],
              },
              {
                alias: 'res',
                children: [
                  {
                    alias: chart.value.aggregationConfig.aggregation.aggregator,
                    attr: 'val',
                    isInternal: true,
                    needsVar: [
                      {
                        name: 'edgeVal',
                        typ: 2,
                      },
                    ],
                    func: {
                      name: chart.value.aggregationConfig.aggregation.aggregator,
                      needsVar: [
                        {
                          name: 'edgeVal',
                          typ: 2,
                        },
                      ],
                    },
                  },
                ],
              },
            ];
          }
          default:
            return [];
        }
      case gridPageChartType.pie:
      case gridPageChartType.line:
      case gridPageChartType.bar:
      case gridPageChartType.column: {
        switch (chart.value.aggregationConfig.aggregation.aggregator) {
          case aggregatorType.count:
            return [
              ...filter.value.varBlocks,
              {
                alias: 'res',
                func: { name: TYPE, args: [{ value: chart.value.source, typ: 1 }] },
                filter: filter.value.filterTree,
                model: chart.value.source,
                isGroupby: true,
                groupbyAttrs: appendStackOptions([{
                  alias: 'val',
                  attr: getEdge(chart.value.groupBy),
                  langs: getLangs(chart.value.groupBy),
                }]),
                children: [
                  { attr: UID, isCount: true },
                ],
              },
            ];
          case aggregatorType.countDistinct:
            return [
              ...filter.value.varBlocks,
              {
                alias: 'res',
                func: { name: TYPE, args: [{ value: chart.value.source, typ: 1 }] },
                filter: filter.value.filterTree,
                model: chart.value.source,
                isGroupby: true,
                groupbyAttrs: appendStackOptions([
                  {
                    alias: 'val',
                    attr: getEdge(chart.value.groupBy),
                    langs: getLangs(chart.value.groupBy),
                  },
                  {
                    alias: 'subGroup',
                    attr: getEdge(chart.value.aggregationConfig.aggregation),
                    langs: getLangs(chart.value.aggregationConfig.aggregation),
                  },
                ]),
                children: [
                  { attr: UID, isCount: true },
                ],
              },
            ];
          case aggregatorType.avg:
          case aggregatorType.sum:
          case aggregatorType.min:
          case aggregatorType.max: {
            return [
              ...filter.value.varBlocks,
              {
                alias: 'res',
                func: { name: TYPE, args: [{ value: chart.value.source, typ: 1 }] },
                filter: filter.value.filterTree,
                model: chart.value.source,
                isGroupby: true,
                groupbyAttrs: appendStackOptions([{
                  alias: 'val',
                  attr: getEdge(chart.value.groupBy),
                  langs: getLangs(chart.value.groupBy),
                }]),
                children: [
                  { attr: UID, isCount: true },
                  {
                    alias: chart.value.aggregationConfig.aggregation.aggregator,
                    attr: getEdge(chart.value.aggregationConfig.aggregation),
                    func: { name: chart.value.aggregationConfig.aggregation.aggregator },
                  },
                ],
              },
            ];
          }
          default:
            return [];
        }
      }
      default:
        return [];
    }
  });

  const decodeResponse = (data) => {
    switch (chart.value.chartType) {
      case gridPageChartType.metric:
        switch (chart.value.aggregationConfig.aggregation.aggregator) {
          case aggregatorType.count:
            return data.res[0].count;
          case aggregatorType.avg:
            return data.res[0].avg;
          case aggregatorType.sum:
            return data.res[0].sum;
          case aggregatorType.min:
            return data.res[0].min;
          case aggregatorType.max:
            return data.res[0].max;
          case aggregatorType.countDistinct:
            return data.res[0]['@groupby'].length;
          default:
            return 0;
        }
      case gridPageChartType.pie:
      case gridPageChartType.line:
      case gridPageChartType.bar:
      case gridPageChartType.column: {
        if (Object.keys(data).length === 0) {
          return { series: [], total: 0 };
        }
        switch (chart.value.aggregationConfig.aggregation.aggregator) {
          case aggregatorType.count:
          case aggregatorType.avg:
          case aggregatorType.sum:
          case aggregatorType.min:
          case aggregatorType.max:
          case aggregatorType.countDistinct:
            return processChartData(data.res[0]['@groupby']);
          default:
            return { series: [], total: 0 };
        }
      }
      default:
        return { series: [], total: 0 };
    }
  };

  const result = computed(() => {
    if (resultRaw.value === null || loading.value) {
      return null;
    }
    return decodeResponse(resultRaw.value);
  });

  const runQuery = () => {
    if (query.value.length === 0) {
      return;
    }

    loading.value = true;
    dogma.query(query.value)
      .then((res) => {
        resultRaw.value = res.data;
        error.value = null;
      })
      .catch(logCatch((e) => {
        error.value = e;
      }))
      .finally(() => {
        loading.value = false;
      });
  };

  watch(query, (newVal, oldVal) => {
    if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
      return;
    }
    runQuery();
  }, { deep: true });

  runQuery();

  return {
    runQuery,
    result,
    error,
    loading,
  };
}
