import useGoals from '@/composables/goal/goals';
import { UID } from 'shared/api/query/constants';
import { accessPolicyChildren } from '@/api/query/nebula/access-policy';
import {
  accessPolicy as accessPolicyConfig,
  goal as goalConfig,
} from 'shared/api/query/configs.json';
import { logCatch } from '@/lib/logger/logger';

export default function useGoalAccessPolicy() {
  const { updateMultiple, deleteMultiple } = useGoals();

  const simplifiedAttributes = [
    { attr: UID },
    {
      attr: goalConfig.edges.accessPolicy,
      model: accessPolicyConfig.model,
      default: null,
      children: accessPolicyChildren,
    },
  ];

  const updateAccessPolicy = (goals) => updateMultiple(goals, { optimistic: false, attributes: simplifiedAttributes }).then((data) => {
    if (data.length !== goals.length) {
      const ids = data.map((g) => g.uid);
      const filtered = goals.filter((e) => !ids.includes(e.uid));
      deleteMultiple(filtered.map((g) => g.uid), { commitToRemote: false });
    }
  }).catch(logCatch(() => {
    throw new Error('failed to update goal access policy');
  }));

  return { updateAccessPolicy };
}
