import {
  accessPolicyScope as accessPolicyScopeConfig,
  userScopeTree as userScopeTreeConfig,
} from 'shared/api/query/configs.json';
import { accessPolicyType, userScopeOperator } from 'shared/constants.json';
import { composedAccessPolicy } from '@/lib/access-policy-linking';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { shallowCopy } from 'shared/lib/copy';

export const cmpAccessRight = (a, b) => {
  const orderedRights = [
    '',
    accessPolicyType.disabled,
    accessPolicyType.read,
    accessPolicyType.comment,
    accessPolicyType.write,
    accessPolicyType.full,
  ];
  return orderedRights.indexOf(a) - orderedRights.indexOf(b);
};

export const getHighestAccessRight = (rights) => {
  rights = rights.filter((accessRight) => accessRight !== undefined);
  if (rights.length === 0) {
    return accessPolicyType.disabled;
  }

  const cpy = shallowCopy(rights);
  cpy.sort(cmpAccessRight);
  return cpy[cpy.length - 1];
};

const getAccessTypeOfUserForAccessPolicy = (accessPolicy, user) => {
  if (isNullOrUndefined(accessPolicy)) {
    return accessPolicyType.disabled;
  }

  if (accessPolicy.accountAccess === accessPolicyType.full) {
    return accessPolicyType.full;
  }

  const linksRights = accessPolicy.links?.map((link) => getAccessTypeOfUserForAccessPolicy(composedAccessPolicy(link), user)) ?? [];

  const scopeRights = accessPolicy.scopes?.reduce((res, aps) => {
    if (aps.userIsInScope) {
      return [...res, aps.accessType];
    }
    return res;
  }, []) ?? [];
  const rights = [
    accessPolicy.accountAccess,
    ...linksRights,
    ...scopeRights,
  ];
  return getHighestAccessRight(rights);
};

export const getAccessTypeOfUser = (entity, user) => {
  if (!isNullOrUndefined(entity.creator) && !isNullOrUndefined(user) && entity.creator.uid === user.uid) {
    return accessPolicyType.full;
  }

  const { accessPolicy } = entity;
  return getAccessTypeOfUserForAccessPolicy(accessPolicy, user);
};

const copyUserScope = (us) => {
  const res = { type: us.type };
  if (us.property !== undefined) {
    res.property = us.property;
  }
  if (us.selectedOptions !== undefined) {
    res.selectedOptions = us.selectedOptions;
  }
  if (us.spaces !== undefined) {
    res.spaces = us.spaces;
  }
  if (us.staticUsers !== undefined) {
    res.staticUsers = us.staticUsers;
  }
  return res;
};

export const copyUserScopeTree = (ust) => ({
  treeHash: ust.treeHash,
  account: ust.account,
  op: ust.op,
  children: [{
    op: ust.children[0].op,
    children: [{
      op: ust.children[0].children[0].op,
      scope: copyUserScope(ust.children[0].children[0].scope),
    }],
  }],
});

export const copyAccessPolicy = (policy) => {
  if (isNullOrUndefined(policy)) {
    return null;
  }
  const res = { ...policy };
  delete res.uid;

  if (Array.isArray(res.links)) {
    res.links = res.links.map((l) => {
      const cp = { ...l };
      delete cp.uid;
      cp.patch = copyAccessPolicy(cp.patch);
      return cp;
    });
  }

  if (Array.isArray(res.scopes)) {
    res.scopes = res.scopes.map((s) => {
      const cp = { ...s };
      delete cp.uid;
      cp.scope = copyUserScopeTree(cp.scope);
      return cp;
    });
  }

  return res;
};

/* returns scopes, that are common in a list of access policy scopes (array of array of scopes) */
export const accessPolicyCommonScopes = (accessPolicyScopes) => {
  if (accessPolicyScopes.length === 0) {
    return [];
  }

  return accessPolicyScopes.reduce(
    (res, next) => res.filter((r) => next.find((s) => {
      if (r.accessType !== s.accessType) {
        return false;
      }

      if (s.scope !== null && r.scope !== null) {
        return s.scope.treeHash === r.scope.treeHash;
      }

      return false;
    })),
    accessPolicyScopes[0],
  );
};

export const spreadAccessPolicyScopes = (accessPolicyScope) => accessPolicyScope.scope.children.map((c) => c.children[0].scope)
  .reduce((acc, us) => {
    const splitFlatObject = (obj) => {
      const arrayField = Object.keys(obj).find((k) => Array.isArray(obj[k]) && obj[k].length > 0);
      if (arrayField === undefined) {
        return obj;
      }

      return obj[arrayField].map((el) => ({
        ...obj,
        [arrayField]: [el],
      }));
    };
    splitFlatObject(us).forEach((us) => {
      acc.push({
        [userScopeTreeConfig.edges.account]: accessPolicyScope.scope.account,
        [userScopeTreeConfig.edges.op]: userScopeOperator.or,
        [userScopeTreeConfig.edges.children]: [{
          [userScopeTreeConfig.edges.op]: userScopeOperator.and,
          [userScopeTreeConfig.edges.children]: [{
            [userScopeTreeConfig.edges.op]: userScopeOperator.and,
            [userScopeTreeConfig.edges.scope]: us,
          }],
        }],
      });
    });
    return acc;
  }, [])
  .map((ust) => ({
    [accessPolicyScopeConfig.edges.accessType]: accessPolicyScope[accessPolicyScopeConfig.edges.accessType],
    [accessPolicyScopeConfig.edges.scope]: ust,
  }));

const isEqualModel = (a, b) => {
  if (a === null && b === null) {
    return true;
  }
  if (a !== null && b !== null) {
    return a.uid === b.uid;
  }
  return false;
};

export const isEqualAccessPolicy = (a, b) => {
  if (!isEqualModel(a.account, b.account)) {
    return false;
  }
  if (!isEqualModel(a.space, b.space)) {
    return false;
  }

  if (a.accountAccess !== b.accountAccess) { return false; }
  if (a.spaceOwnerAccess !== b.spaceOwnerAccess) { return false; }
  if (a.spaceMemberAccess !== b.spaceMemberAccess) { return false; }

  const fromA = a.scopes.map((aAPS) => {
    const bAPS = b.scopes.find((bAPS) => aAPS.scope.treeHash === bAPS.scope.treeHash);
    if (bAPS === undefined) {
      return false;
    }

    return aAPS.accessType === bAPS.accessType;
  });

  const fromB = b.scopes.filter((bAPS) => {
    const aAPS = a.scopes.find((aAPS) => bAPS.scope.treeHash === aAPS.scope.treeHash);
    return aAPS === undefined;
  });
  return [...fromA, fromB.length === 0].every((el) => el);
};
