<template>
  <div class="file-explorer">
    <div>
      <m-content
        class="_breadcrumbs"
        padding
        :padding-x="6"
      >
        <breadcrumbs
          :breadcrumbs="localPath"
          @selected="navigationClick($event)"
        />
      </m-content>
    </div>
    <div
      v-if="!loading && currentDirectory.length === 0"
      :style="{ height: '30rem' }"
    >
      <m-content
        :style="{ color: $colors.grey.darken1 }"
        padding-small
        :padding-x="9"
      >
        {{ $t('fileExplorer.empty') }}
      </m-content>
    </div>
    <div
      v-if="loading"
      :style="{ height: '30rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }"
    >
      <m-spinner />
    </div>
    <div
      v-else-if="currentDirectory.length > 0"
      class="_file-nodes"
      :style="{height: breakpoint.smAndDown ? 'calc(var(--viewport-height-100) - 13.4rem)' : '30rem'}"
    >
      <div
        v-for="file in currentDirectory"
        :key="file.id"
      >
        <div>
          <m-card-item
            :icon="fileIcon(file)"
            :selected="selected(file)"
            @click="fileClick(file)"
          >
            <div :style="{ display: 'flex', alignItems: 'center' }">
              <div>
                {{ file.title }}
              </div>
              <m-btn
                v-if="file.link !== ''"
                icon="external-link"
                :href="file.link"
                target="_blank"
                hide-border
                xs
                fab
                light
              />
            </div>
          </m-card-item>
        </div>
      </div>
    </div>
    <div class="_actions">
      <m-dialog-actions
        :submit-disabled="selectedFile === null"
        :ok-text="$t('general.select')"
        @ok="$emit('selected', {item: selectedFile, path: localPath})"
        @cancel="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import { mapState } from 'vuex';
import { spreadsheetItemType } from 'shared/spreadsheet_constants.json';

export default {
  name: 'FileExplorer',
  props: {
    files: {
      type: Array,
      required: true,
    },
    rootName: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    path: {
      type: Array,
      default: null,
    },
  },
  emits: ['selected', 'close', 'stepinto'],
  components: { Breadcrumbs },
  data() {
    return {
      localPath: null,
      selectedFile: null,
    };
  },
  computed: {
    ...mapState({ breakpoint: (state) => state.breakpoint }),
    currentDirectory() {
      return this.files.filter((file) => file.parent === this.localPath[this.localPath.length - 1].id);
    },
  },
  methods: {
    fileClick(file) {
      if (file.type === spreadsheetItemType.folder) {
        this.stepInto(file);
        return;
      }

      this.selectedFile = file;
    },
    stepInto(item) {
      this.localPath = [...this.localPath, { title: item.title, id: item.id }];
      this.$emit('stepinto', { item, path: this.localPath });
      this.selectedFile = null;
    },
    navigationClick(item) {
      let newPath = [];
      for (let i = 0; i < this.localPath.length; i++) {
        newPath = [...newPath, this.localPath[i]];
        if (this.localPath[i].id === item.id) {
          break;
        }
      }

      this.localPath = newPath;
      this.$emit('stepinto', { item, path: this.localPath });
      this.selectedFile = null;
    },
    fileIcon(file) {
      const map = {
        [spreadsheetItemType.folder]: 'folder',
        [spreadsheetItemType.document]: 'excel',
      };
      return map[file.type];
    },
    selected(file) {
      if (this.selectedFile === null) {
        return false;
      }

      return file.id === this.selectedFile.id;
    },
  },
  created() {
    this.localPath = [{ title: 'root', id: 'root' }];
    if (this.path !== null) {
      this.localPath = this.path;
    }

    if (this.rootName !== '') {
      this.localPath[0].title = this.rootName;
    }

    if (this.rootName === '') {
      this.localPath[0].title = this.$t('fileExplorer.root');
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .file-explorer {
    ._breadcrumbs {
      margin-bottom: .8rem;
      border-bottom: 1px solid map_get($grey, 'lighten-4');
    }

    ._file-nodes {
      padding-bottom: .8rem;
      overflow: auto;
    }
  }
</style>
