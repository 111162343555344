<template>
  <div class="connection-error">
    <div class="_content">
      <div class="_action">
        <m-btn
          hide-border
          light
          @click="$emit('exit')"
        >
          {{ $t('slack.connectionError.goBack') }}
        </m-btn>
      </div>
      <div class="_header">
        ⚠ {{ $t('slack.connectionError.header') }}
      </div>
      <template v-if="error === slackBotInstallError.unauthorized">
        <i18n-t
          keypath="slack.connectionError.missingAuth"
          tag="div"
          class="_message"
        />
        <div>
          <img
            class="_image"
            src="@/assets/img/slack_connection_error.png"
          >
        </div>
      </template>
      <i18n-t
        v-if="error === slackBotInstallError.default"
        keypath="slack.connectionError.defaultError"
        tag="div"
        class="_message"
      >
        <template #supportmail>
          <a :href="`mailto:${$t('general.supportEmail')}`">{{ $t('general.supportEmail') }}</a>
        </template>
      </i18n-t>
    </div>
  </div>
</template>

<script>
import { slackBotInstallError } from '@/constants.json';

export default {
  name: 'ConnectionError',
  props: {
    error: {
      type: String,
      required: true,
    },
  },
  data() {
    return { slackBotInstallError };
  },
  emits: ['exit'],
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/_box-shadow.scss";

  .connection-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(var(--viewport-height-100) - 10rem);

    ._content {
      max-width: 68rem;

      ._header {
        margin-bottom: 1rem;
        font-size: $font-size-8;
      }

      ._message {
        margin-bottom: 1.2rem;
      }

      ._image {
        border-radius: $default-border-radius;

        @include box_shadow(1);
      }

      ._action {
        margin-bottom: 2rem;
      }
    }
  }
</style>
