<template>
  <div
    :class="['m-checkbox', value ? '-checked' : '', disabled ? '-disabled' : '', clickable ? '-clickable' : '', light ? '-light' : '']"
    @click="change"
  >
    <div
      :class="['_checkbox', small ? '-small' : '']"
    >
      <div
        v-if="value"
        class="_check"
      >
        <m-icon
          v-if="icon !== ''"
          :type="icon"
          color="white"
          :size="iconSize"
        />
      </div>
    </div>
    <slot>
      <div
        :style="{ ...labelStyle, color: light ? $colors.grey.base : labelStyle.color }"
        class="_label"
      >
        {{ label }}
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'MCheckbox',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    labelStyle: {
      type: Object,
      default: () => ({}),
    },
    light: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'check-mark',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'change'],
  computed: {
    iconSize() {
      return this.small ? '10' : '12';
    },
  },
  methods: {
    change() {
      if (this.disabled) {
        return;
      }

      this.$emit('change', !this.value);
      this.$emit('input', !this.value);
      this.$emit('update:value', !this.value);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

  .m-checkbox {
    display: flex;
    align-items: center;
    margin: .2rem 0;
    cursor: pointer;

    &:not(.-clickable) {
      pointer-events: none;
    }

    &.-disabled {
      cursor: default;
    }

    ._checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.6rem;
      min-width: 1.6rem;
      height: 1.6rem;
      min-height: 1.6rem;
      margin-right: .8rem;
      border: 1.5px solid map_get($grey, 'lighten-1');
      border-radius: .2rem;
      transition: background-color 20ms ease-in 0s;

      &.-small {
        width: 1.4rem;
        min-width: 1.4rem;
        height: 1.4rem;
        min-height: 1.4rem;
        border-width: 1px;
      }

    }

    &.-light {
      ._checkbox {
        border-color: map_get($grey, 'lighten-1');
      }
    }

    &.-checked {
      ._checkbox {
        background-color: $primary-color;
        border-color: $primary-color;
        border-radius: .2rem;
      }
    }

    &:not(.-disabled) {
      ._checkbox {
        &:hover {
          background-color: $hover-color;
        }
      }

      &.-checked {
        ._checkbox {
          &:hover {
            background-color: map_get($blue, 'darken-1');
            border-color: map_get($blue, 'darken-1');
          }
        }
      }
    }

    ._label {
      overflow: hidden;
      line-height: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
