import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePlanningsRepo from '@/composables/planning/plannings-repo';
import useProperties from '@/composables/property/property';
import { AND } from '@/lib/filter/scope-tree';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { accessPolicyType, planningStatus, userScopeType } from 'shared/constants.json';

export default function usePlannings() {
  const repo = usePlanningsRepo();

  const { loggedInUser } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();
  const { spaceProperty } = useProperties();

  const createPlanning = ({ title, properties, goalCycles }) => {
    const propertyValue = properties.find((v) => v.property.uid === spaceProperty.value.uid);
    return repo.createSingle({
      creator: { uid: loggedInUser.value.uid },
      title,
      goalCycles,
      properties,
      accessPolicy: {
        account: { uid: loggedInUserAccount.value.uid },
        accountAccess: accessPolicyType.disabled,
        scopes: [
          {
            accessType: accessPolicyType.full,
            scope: {
              op: AND,
              account: { uid: loggedInUserAccount.value.uid },
              children: [
                {
                  op: AND,
                  children: [
                    {
                      op: AND,
                      scope: {
                        isEmpty: false,
                        property: { uid: propertyValue.property.uid },
                        spaces: propertyValue.spaces,
                        type: userScopeType.property,
                        staticUsers: [],
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      status: planningStatus.open,
    }).then((planning) => {
      EventBus.$emit(EVENTS.PLANNING.PLANNING_CREATED);
      return planning;
    });
  };

  return {
    createPlanningLoading: repo.createLoading,
    createPlanning,
    updatePlanningLoading: repo.updateLoading,
    updatePlanning: repo.updateSingle,
    deletePlanningLoading: repo.deleteLoading,
    deletePlanning: repo.deleteSingle,
    deletePlanningsLoading: repo.deleteLoading,
    deletePlannings: repo.deleteMultiple,
    fetchPlanningsLoading: repo.getListLoading,
    fetchPlannings: repo.getList,
    fetchPlanningLoading: repo.selectLoading,
    fetchPlanning: (id) => repo.selectSingle(id, { commitToRemote: true }),
    selectSingle: repo.selectSingle,

    updatePlanningAccessPolicy: (entity) => repo.updateSingle(entity, { optimistic: false }),

    plannings: repo.entityList,
  };
}
