<template>
  <div class="ms-error">
    <div class="_content">
      <div class="_action">
        <m-btn
          hide-border
          light
          @click="$emit('exit')"
        >
          {{ $t('msError.goBack') }}
        </m-btn>
      </div>
      <div class="_header">
        ⚠ {{ $t('msError.header') }}
      </div>
      <i18n-t
        keypath="msError.message"
        tag="div"
        class="_message"
      >
        <template #email>
          <strong>{{ email }}</strong>
        </template>
      </i18n-t>
      <i18n-t
        keypath="msError.message2"
        tag="div"
        class="_message"
      >
        <template #email>
          {{ email }}
        </template>
        <template #supportmail>
          <a :href="`mailto:${$t('general.supportEmail')}`">{{ $t('general.supportEmail') }}</a>
        </template>
      </i18n-t>
      <div>
        <img
          class="_image"
          src="@/assets/img/msteams_configuration.png"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { routeName } from 'shared/constants.json';

export default {
  name: 'MSError',
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  emits: ['exit'],
  data() {
    return { routeName };
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/_box-shadow.scss";

  .ms-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100vw;
    min-height: var(--viewport-height-100);

    ._content {
      max-width: 68rem;

      ._header {
        margin-bottom: 1rem;
        font-size: $font-size-8;
      }

      ._message {
        margin-bottom: 1.2rem;
      }

      ._image {
        width: 100%;
        border-radius: $default-border-radius;

        @include box_shadow(1);
      }

      ._action {
        margin-bottom: 2rem;
      }
    }
  }
</style>
