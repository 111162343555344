import { MAX_WIDTH_SIDE_PEEK, MIN_WIDTH_SIDE_PEEK } from '@/lib/constants';

export const constrainWidth = (width, windowWidth) => {
  if (width < MIN_WIDTH_SIDE_PEEK) {
    return MIN_WIDTH_SIDE_PEEK;
  }
  if (width >= windowWidth * MAX_WIDTH_SIDE_PEEK) {
    return Math.round(windowWidth * MAX_WIDTH_SIDE_PEEK);
  }
  return width;
};
