<template>
  <div :class="['m-table-wrapper', layoutPadding ? '-layout-padding' : '']">
    <table class="m-table">
      <thead>
        <tr>
          <th
            v-for="column in columns"
            :key="column.key"
            :class="['_head', column.sorter !== undefined ? '-clickable' : '', hideVerticalBorder ? '-hide-vertical-border' : '']"
            :style="{width: column.width === undefined ? null : `${column.width}px`}"
            @click="handleHeaderClick(column)"
          >
            <div
              :class="['_header-cell']"
              :style="{ marginRight: headerCellPadding(column) }"
            >
              <div
                v-if="column.scopedSlots?.title === undefined"
                class="_title"
              >
                <div class="_title-left">
                  <div
                    v-if="column.icon !== '' && column.icon !== undefined"
                    class="_title-icon"
                  >
                    <m-icon :type="column.icon" />
                  </div>
                  <div class="_title-text">
                    {{ column.title }}
                  </div>
                  <span
                    class="_title-sorter"
                  >
                    <m-icon
                      v-if="localOrder[column.key] === 'up'"
                      :color="$colors.grey.lighten2"
                      type="caret-up"
                    />
                    <m-icon
                      v-if="localOrder[column.key] === 'down'"
                      :color="$colors.grey.lighten2"
                      type="caret-down"
                    />
                  </span>
                </div>
                <div
                  v-if="column.scopedSlots?.titleRight !== undefined"
                  class="_title-right"
                >
                  <slot :name="column.scopedSlots.titleRight" />
                </div>
              </div>
              <template v-else>
                <slot :name="column.scopedSlots.title" />
              </template>
              <div
                v-if="column.onFilter !== undefined"
                class="_actions"
              >
                <m-dropdown
                  :title="$t('mTable.search', { title: column.title })"
                  class="_filter"
                  stop-trigger-event-propagation
                >
                  <m-btn
                    :icon-color="iconColor(column)"
                    :button-style="{ height: '3.9rem', width: '3.9rem', borderRadius: 0 }"
                    icon="search"
                    small
                    fab
                    hide-border
                    light
                  />
                  <template #overlay>
                    <m-card
                      padding-xs
                      class="_filter-overlay"
                    >
                      <m-text-field
                        v-model:value="search[column.key]"
                        :placeholder="`${$t('mTable.search', { title: column.title })}`"
                        auto-focus
                        allow-clear
                      />
                    </m-card>
                  </template>
                </m-dropdown>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody :class="['m-table-tbody', loading ? '-loading' : '']">
        <tr v-if="paginatedData.length === 0">
          <td
            :colspan="columns.length"
            class="_empty"
          >
            {{ $t('mTable.noData') }}
          </td>
        </tr>
        <tr
          v-for="row in paginatedData"
          :key="byString(row, rowKey)"
          :class="[
            'm-table-row',
            rowsClickable || row.clickable ? '-clickable' : '',
            rowClassName,
            selectedRows.includes(byString(row, rowKey)) ? '-selected' : '',
            row.disabled ? '-disabled' : '',
            hideVerticalBorder ? '-hide-vertical-border' : '',
          ]"
          :data-id="byString(row, rowKey)"
          @click="handleRowClick(row)"
          @contextmenu="handleContextMenu($event, row)"
        >
          <td
            v-for="(col, index) in columns"
            :key="col.key"
          >
            <template v-if="index === 0 && showDragOverTopTargets.includes(byString(row, rowKey))">
              <slot
                name="dragzoneTop"
                :row="row"
              >
                <m-dragzone class="_dragzone-top" />
              </slot>
            </template>
            <div class="_cell">
              <template v-if="col.scopedSlots === undefined">
                {{ byString(row, `${col.dataIndex}`) }}
              </template>
              <slot
                v-else
                :name="col.scopedSlots.customRender"
                :[`${col.scopedSlots.customRender}`]="byString(row, `${col.dataIndex}`)"
                :row="row"
              />
            </div>
            <template v-if="index === 0 && showDragOverBottomTargets.includes(byString(row, rowKey))">
              <slot
                name="dragzoneBottom"
                :row="row"
              >
                <m-dragzone class="_dragzone-bottom" />
              </slot>
            </template>
          </td>
        </tr>
        <tr
          v-if="showLoadMoreButton"
          @click="handleLoadMoreClick"
        >
          <td
            colspan="100%"
            :class="[
              '_row-btn',
              rowsClickable ? '-clickable' : '',
            ]"
          >
            <div class="_content">
              <m-icon
                :color="$colors.grey.lighten1"
                type="down"
                class="_icon"
              />
              {{ $t('general.loadMore') }}
            </div>
          </td>
        </tr>
        <tr
          v-if="showNewButton"
          @click="$emit('new')"
        >
          <td
            colspan="100%"
            :class="[
              '_row-btn',
              newButtonLoading ? '-loading' : '',
              rowsClickable ? '-clickable' : '',
            ]"
          >
            <div class="_content">
              <m-icon
                :color="$colors.grey.lighten1"
                :type="newButtonLoading ? 'loading' : 'plus'"
                :spin="newButtonLoading"
                class="_icon"
              />
              {{ newButtonLabel }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="_bottom">
      <div
        v-if="showCount"
        class="_count"
      >
        <div class="_label">
          {{ $t('mTable.count') }}
        </div>
        <div class="_value">
          {{ preparedDataSource.length }}
        </div>
      </div>
      <div
        v-if="showPagination"
        class="_pagination"
      >
        <m-pagination
          v-model:value="page"
          :total="preparedDataSource.length"
          :items-per-page="pagination.pageSize"
          hide-border
        />
      </div>
    </div>
  </div>
</template>

<script>
import MBtn from 'shared/components/base/MBtn.vue';
import MCard from 'shared/components/base/MCard.vue';
import MDragzone from 'shared/components/base/MDragzone.vue';
import MDropdown from 'shared/components/base/MDropdown.vue';
import MIcon from 'shared/components/base/MIcon.vue';
import MPagination from 'shared/components/base/MPagination.vue';
import MTextField from 'shared/components/base/MTextField.vue';
import { copy } from 'shared/lib/copy';
import { paginationType } from 'shared/constants.json';

export default {
  name: 'MTable',
  props: {
    columns: {
      type: Array,
      required: true,
    },
    dataSource: {
      type: Array,
      required: true,
    },
    customRow: {
      type: Function,
      default: () => ({
        on: {
          click() {
          },
        },
      }),
    },
    layoutPadding: {
      type: Boolean,
      default: false,
    },
    rowsClickable: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: [Boolean, Object],
      default: false,
    },
    paginationType: {
      type: String,
      default: paginationType.replace,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: String,
      default: 'key',
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    rowClassName: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showNewButton: {
      type: Boolean,
      default: false,
    },
    newButtonTitle: {
      type: String,
      default: '',
    },
    newButtonLoading: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
    order: {
      type: Object,
      default: () => ({}),
    },
    hideVerticalBorder: {
      type: Boolean,
      default: false,
    },
    showDragOverTopTargets: {
      type: Array,
      default: () => [],
    },
    showDragOverBottomTargets: {
      type: Array,
      default: () => [],
    },
  },
  components: { MDragzone, MCard, MBtn, MDropdown, MIcon, MPagination, MTextField },
  data() {
    return { search: {}, localOrder: {}, page: 1 };
  },
  emits: [
    'input:filter',
    'update:filter',
    'input:order',
    'update:order',
    'new',
    'contextmenu',
  ],
  computed: {
    newButtonLabel() {
      if (this.newButtonTitle === '') {
        return this.$t('general.new');
      }

      return this.newButtonTitle;
    },
    showLoadMoreButton() {
      return this.paginationType === paginationType.append
          && this.paginatedData.length < this.preparedDataSource.length;
    },
    showPagination() {
      return this.paginationType === paginationType.replace
          && this.pagination !== false
          && this.dataSource.length > this.pagination.pageSize;
    },
    preparedDataSource() {
      if ((Object.keys(this.search).length === 0 || Object.keys(this.search).filter((k) => this.search[k] !== '').length === 0)
          && (Object.keys(this.localOrder).length === 0 || Object.keys(this.localOrder).filter((k) => this.localOrder[k] !== '').length === 0)) {
        return this.dataSource;
      }

      const d = this.dataSource.filter((item) => {
        let ok = true;
        Object.keys(this.search).forEach((key) => {
          const column = this.getColumn(key);
          if (!column.onFilter(this.search[key], item)) {
            ok = false;
          }
        });

        return ok;
      });

      if (Object.keys(this.localOrder).length === 0) {
        return d;
      }

      Object.keys(this.localOrder).forEach((key) => {
        const column = this.getColumn(key);
        d.sort(column.sorter);
        if (this.localOrder[key] === 'down') {
          d.reverse();
        }
      });

      return d;
    },
    paginatedData() {
      if (this.pagination === false) {
        return this.preparedDataSource;
      }
      return this.paginate(this.preparedDataSource);
    },
  },
  methods: {
    paginate(data) {
      if (this.paginationType === paginationType.append) {
        return data.slice(0, this.page * this.pagination.pageSize);
      }
      return data.slice((this.page - 1) * this.pagination.pageSize, this.page * this.pagination.pageSize);
    },
    byString(o, s) {
      const str = s.replace(/\[(\w+)\]/g, '.$1').replace(/^\./, '');
      const a = str.split('.');
      let obj = o;
      for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in obj) {
          obj = obj[k];
        }
      }

      return obj;
    },
    handleLoadMoreClick() {
      this.page += 1;
    },
    handleHeaderClick(column) {
      if (column.sorter === undefined) {
        return;
      }

      Object.keys(this.localOrder).filter((key) => key !== column.key).forEach((key) => {
        delete this.localOrder[key];
      });

      if (this.localOrder[column.key] === 'up') {
        this.localOrder[column.key] = 'down';
        return;
      }
      if (this.localOrder[column.key] === 'down') {
        this.localOrder[column.key] = '';
        return;
      }
      this.localOrder[column.key] = 'up';
    },
    iconColor(column) {
      if (this.search[column.key] !== '' && this.search[column.key] !== undefined) {
        return this.$colors.blue.base;
      }
      return this.$colors.grey.lighten2;
    },
    getColumn(key) {
      const f = this.columns.filter((c) => c.key === key);
      if (f.length === 0) {
        throw new Error(`column not found: ${key}`);
      }

      return f[0];
    },
    handleReset(column) {
      delete this.search[column.key];
    },
    headerCellPadding(column) {
      let marginRight = 0;
      if (column.onFilter !== undefined) {
        marginRight += 2;
      }
      return `${marginRight}rem`;
    },
    handleRowClick(row) {
      if (row.disabled) {
        return;
      }
      this.customRow(row).on.click();
    },
    handleContextMenu(event, row) {
      if (row.disabled) {
        return;
      }
      this.$emit('contextmenu', event, row);
    },
  },
  watch: {
    localOrder: {
      handler() {
        if (JSON.stringify(this.localOrder) !== JSON.stringify(this.order)) {
          this.$emit('input:order', this.localOrder);
          this.$emit('update:order', this.localOrder);
        }
      },
      deep: true,
    },
    order: {
      handler() {
        if (JSON.stringify(this.localOrder) !== JSON.stringify(this.order)) {
          this.localOrder = copy(this.order);
        }
      },
      deep: true,
    },
    search: {
      handler() {
        if (JSON.stringify(this.search) !== JSON.stringify(this.filter)) {
          this.$emit('input:filter', this.search);
          this.$emit('update:filter', this.search);
        }
      },
      deep: true,
    },
    filter: {
      handler() {
        if (JSON.stringify(this.search) !== JSON.stringify(this.filter)) {
          this.search = copy(this.filter);
        }
      },
      deep: true,
    },
  },
  created() {
    this.localOrder = copy(this.order);
    this.search = copy(this.filter);
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import "shared/assets/scss/padding";
  @import "shared/assets/scss/loading";

  .m-table-wrapper {
    .m-table {
      width: 100%;
      text-align: left;
      border-spacing: 0;
      border-collapse: separate;
      border-radius: 4px 4px 0 0;

      ._head {
        position: relative;
        height: 4rem;
        padding: .6rem 1.2rem;
        font-weight: normal;
        color: $font-color-secondary;
        white-space: nowrap;
        border-top: 1px solid $border-color;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-radius: 0;

        &.-clickable {
          cursor: pointer;

          &:hover {
            background-color: $hover-color;
          }
        }

        &:last-of-type {
          border-right: none;
        }

        &.-hide-vertical-border {
          border-right: none;
        }

        ._header-cell {
          display: flex;
          align-items: center;

          ._title {
            display: flex;
            justify-content: space-between;
            width: 100%;

            ._title-left {
              display: flex;
              align-items: center;

              ._title-icon {
                margin-right: .6rem;
              }

              ._title-text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              ._title-sorter {
                width: 1rem;
                margin-right: 2rem;
                margin-left: .4rem;
              }
            }

            ._title-right {
              display: flex;
              align-items: center;
              margin-left: auto;
            }
          }

          ._actions {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;

            ._sort {
              position: relative;

              ._up {
                position: absolute;
                top: .5rem;
                left: .8rem;
              }

              ._down {
                position: absolute;
                top: 1.2rem;
                left: .8rem;
              }
            }
          }

          ._filter {
            margin-left: auto;
          }
        }
      }

      td {
        height: 4rem;
        padding: .6rem 1.2rem;
        overflow-wrap: break-word;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;

        &:last-of-type {
          border-right: none;
        }
      }

      .m-table-tbody {
        tr {
          &.m-table-row {
            position: relative;

            &.-clickable {
              cursor: pointer;

              &:hover {
                &:not(.-selected) {
                  background-color: $hover-color;
                }

                &.-selected {
                  background-color: $highlighted-color-light;
                }
              }
            }

            &.-selected {
              background-color: $highlighted-color;
            }

            &.-disabled {
              ._cell {
                opacity: .4;
              }
            }
          }

          &.-hide-vertical-border {
            td {
              border-right: none;
            }
          }
        }

        ._dragzone-top {
          top: -2px;
        }

        ._dragzone-bottom {
          bottom: -2px;
        }

        &.-loading {
          @include loading;
        }
      }
    }

    ._empty {
      padding: 1rem 1.2rem;
      color: $font-color-tertiary;

      &:hover {
        background-color: white;
      }
    }

    ._bottom {
      position: sticky;
      left: 0;

      ._count {
        display: flex;
        align-items: center;
        margin: .8rem 1.2rem .2rem;

        ._label {
          margin: .2rem .4rem 0 0;
          font-size: $font-size-1;
          line-height: 1;
          color: $font-color-secondary;
          text-transform: uppercase;
        }
      }

      ._pagination {
        margin-top: 1.2rem;
      }
    }

    &.-layout-padding {
      .m-table {
        @include layoutPaddingX;
      }

      ._bottom {
        @include layoutPaddingX;
      }
    }

    ._row-btn {
      cursor: pointer;

      &.-loading {
        pointer-events: none;
        cursor: default;
      }

      ._content {
        display: flex;
        align-items: center;
        color: $font-color-secondary;

        ._icon {
          margin-right: .8rem;
        }
      }

      &.-clickable {
        cursor: pointer;

        &:hover {
          background-color: $hover-color;
        }
      }
    }
  }

  ._filter-overlay {
    ._filter-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      ._btn {
        flex: 1 1 auto;

        &:first-of-type {
          margin-right: .8rem;
        }
      }
    }
  }
</style>
