<template>
  <m-dropdown
    ref="input"
    v-model:value="showTextDropdownInput"
    :title="title"
    :class="['text-input', { '-wrap': wrap }, { '-input-field': !hideBorder }, { '-hide-hover': hideHover }]"
    placement="onTopLeft"
    :block="fullWidth"
    :disabled="disabled || readOnly"
    :match-trigger-width="matchTriggerWidth"
    @hide="hide"
    @click.stop
  >
    <m-focusable
      class="_text-wrapper"
      :hide-border="hideBorder"
      :hide-hover="hideHover"
      type="clickable"
      :read-only="readOnly"
      :full-width="fullWidth"
      :m-style="mStyle"
      :small="small"
      :show-placeholder="!hidePlaceholder && text === ''"
      :placeholder="placeholder"
      :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
    >
      <div
        class="_text"
        :style="resolveStyles(mStyle)"
      >
        {{ text }}
      </div>
    </m-focusable>
    <template #overlay>
      <m-card
        no-padding
        border-radius="small"
        :style="{ minWidth: '300px' }"
      >
        <m-textarea
          v-model:value="text"
          :placeholder="placeholder"
          :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
          wrap
          :allow-clear="clearable"
          hide-hover
          :hide-placeholder="hidePlaceholder"
          :hide-border="hideBorder"
          full-width
          auto-size
          auto-focus
          @keydown.enter="handleTextareaEnter"
        />
      </m-card>
    </template>
  </m-dropdown>
</template>

<script setup>
import useDebounce from '@/composables/debounce';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';
import { onMounted, ref, toRef, watch } from 'vue';

const props = defineProps({
  ...mStyleProps,
  title: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  small: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  hideBorder: {
    type: Boolean,
    default: false,
  },
  hideHover: {
    type: Boolean,
    default: false,
  },
  hidePlaceholder: {
    type: Boolean,
    default: false,
  },
  showPlaceholderIcon: {
    type: Boolean,
    default: false,
  },
  matchTriggerWidth: {
    type: Boolean,
    default: false,
  },
  autoFocus: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  wrap: {
    type: Boolean,
    default: false,
  },
});

const handleTextareaEnter = (event) => {
  if (event.shiftKey) {
    return;
  }
  event.preventDefault();
  showTextDropdownInput.value = false;
};

const input = ref(null);

const text = ref(props.value);

const showTextDropdownInput = ref(false);
const open = () => {
  showTextDropdownInput.value = true;
};

const emit = defineEmits(['change', 'blur']);

const changeText = () => {
  if (text.value === props.value) {
    return;
  }
  emit('change', text.value);
};

const { debounce, cancelAll } = useDebounce();
watch(text, () => {
  debounce(changeText, 500);
});

const hide = () => {
  emit('blur');
  cancelAll();
  changeText();
};

onMounted(() => {
  if (props.autoFocus) {
    open();
  }
});

const localValue = toRef(props, 'value');
watch(localValue, (value) => {
  text.value = value;
});

defineExpose({ open });
</script>

<style scoped lang="scss" type="text/scss">
.text-input {
  ._text {
    white-space: nowrap;
  }

  &.-wrap {
    ._text-wrapper {
      align-items: flex-start;
      padding: .6rem .8rem;
    }

    &.-input-field {
      ._text-wrapper {
        padding: .4rem .8rem;
      }
    }

    ._text {
      white-space: pre-wrap;
    }
  }

  &.-hide-hover {
    ._text {
      padding: .3rem 0;
    }
  }
}

</style>
