import {
  AND,
  DEFAULT_ATTRIBUTES,
  RESULT,
  SOFT_DELETED_AT, TYPE,
} from 'shared/api/query/constants';
import { combine } from 'shared/api/query/filter';
import { goal as goalConfig, propertyValue as propertyValueConfig } from 'shared/api/query/configs.json';
import { propertyValueEdges } from 'shared/api/query/edges';
import { searchTermFilter } from '@/lib/filter/search-term';

export const goalList = ({
  pagination,
  filter = null,
  searchTerm = '',
  order = [],
  alias = RESULT,
}) => {
  const f = combine(AND, [
    searchTermFilter({ searchTerm, edgeName: goalConfig.edges.title }),
    filter,
  ]);

  return [
    {
      model: goalConfig.model,
      alias,
      func: { name: TYPE, args: [{ value: goalConfig.model }] },
      order,
      filter: f,
      pagination,
      default: [],
      children: [
        ...DEFAULT_ATTRIBUTES,
        { attr: SOFT_DELETED_AT },
        { attr: goalConfig.edges.title, default: '' },
        { attr: goalConfig.edges.icon, default: '' },
        {
          attr: goalConfig.edges.properties,
          default: [],
          model: propertyValueConfig.model,
          children: propertyValueEdges,
        },
      ],
    },
  ];
};
