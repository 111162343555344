<template>
  <div :class="['m-placeholder', wrap ? '-wrap' : '']">
    <div class="_inner">
      <div
        v-if="placeholderIcon !== ''"
        class="_placeholder-icon"
      >
        <m-icon
          :type="placeholderIcon"
          :color="$colors.grey.lighten2"
        />
      </div>
      <div class="_placeholder-text">
        {{ placeholder }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MPlaceholder',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-placeholder {
    ._inner {
      display: flex;
      align-items: center;
    }

    ._placeholder-icon {
      margin-right: .6rem;
    }

    ._placeholder-text {
      color: $font-color-tertiary;
      white-space: nowrap;
    }

    &.-wrap {
      ._placeholder-text {
        white-space: pre-wrap;
      }
    }
  }
</style>
