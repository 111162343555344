import useGoalModifiers from '@/composables/goal/modifiers';
import useGoals from '@/composables/goal/goals';
import { initChildrenSort } from '@/lib/goal/sort';
import { useI18n } from 'vue-i18n';

export default function useDuplicateGoal() {
  const goalsSvc = useGoals();

  const { t } = useI18n();
  const titleModifier = (goal) => ({
    ...goal,
    title: `${goal.title} - ${t('general.copy')}`,
  });

  const goalModifiers = useGoalModifiers();

  const prepareDuplicate = (input) => {
    const { rids, entities } = input.reduce((res, g) => {
      res.rids[g.uid] = g.uid;
      goalModifiers.setDuplicate(g);
      res.entities.push(goalModifiers.applyModifiers(goalModifiers.getModifiers()));
      return res;
    }, { rids: {}, entities: [] });

    const getParents = (goal) => goal.parents.reduce((res, next) => {
      if (typeof rids[next.uid] === 'undefined') {
        res.push({ ...next });
        return res;
      }

      res.push({ uid: undefined, rid: rids[next.uid] });
      return res;
    }, []);

    const getPaysOnto = (goal) => {
      if (typeof goal.paysOnto === 'undefined') {
        return goal.paysOnto;
      }

      return goal.paysOnto.map((g) => {
        if (typeof rids[g.uid] === 'undefined') {
          if (typeof g.uid !== 'undefined') {
            return { uid: g.uid, 'paysOnto|weight': g['paysOnto|weight'] };
          }
          return g;
        }

        return { uid: undefined, rid: rids[g.uid], 'paysOnto|weight': g['paysOnto|weight'] };
      });
    };

    return entities.map((g) => ({
      ...g,
      childrenSort: initChildrenSort(g, rids),
      paysOnto: getPaysOnto(g),
      parents: getParents(g),
    }));
  };

  const duplicateSingle = (goal) => duplicate([goal]);
  const duplicate = (goals) => {
    const preparedGoals = prepareDuplicate(goals).map(titleModifier);

    return goalsSvc.createMultiple(preparedGoals);
  };

  return {
    duplicateLoading: goalsSvc.createLoading,
    duplicateSingle,
    duplicate,
    goalModifiers,
    prepareDuplicate,
  };
}
