import useProperties from '@/composables/property/property';
import { computed } from 'vue';
import { propertyType } from 'shared/constants.json';

export default function useGoalProperty() {
  const { goalProperties } = useProperties();

  const goalTypeProperty = computed(() => goalProperties.value.find((p) => p.isGoalType));

  const lookupProperties = computed(() => goalProperties.value.filter((p) => p.type === propertyType.lookup));

  const statusProperty = computed(() => goalProperties.value.find((p) => p.type === propertyType.status));

  return {
    properties: goalProperties,
    goalTypeProperty,
    lookupProperties,
    statusProperty,
  };
}
