<template>
  <div class="new-form">
    <full-screen-spinner v-if="loading" />
    <template v-else>
      <new-form-sidebar :nav-items="navItems" />
      <div class="_right">
        <page-header
          class="_top"
          small
        >
          <template #actions>
            <div

              class="_actions"
            >
              <m-btn
                large
                icon="close"
                fab
                hide-border
                to="/surveys"
              />
            </div>
          </template>
        </page-header>
        <new-form-content
          :form-templates="filteredFormTemplates"
          :title="title"
        />
      </div>
    </template>
  </div>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import NewFormContent from '@/components/survey/NewFormContent.vue';
import NewFormSidebar from '@/components/survey/NewFormSidebar.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import { NEW_FORM } from '@/route-names';
import { formTemplateType } from 'shared/constants.json';
import { mapActions, mapState } from 'vuex';
import { releasedFormTemplates } from 'shared/api/query/form-template';

export default {
  name: 'NewForm',
  components: { NewFormContent, PageHeader, NewFormSidebar, FullScreenSpinner },
  data() {
    return { loading: true };
  },
  computed: {
    ...mapState({ formTemplates: (state) => state.formTemplates }),
    navItems() {
      const items = [
        {
          title: this.$t('newForm.all'),
          to: { name: NEW_FORM, query: {} },
        }];

      const accounts = [];
      this.formTemplates.forEach((t) => {
        if (accounts.map((i) => i.uid).indexOf(t.account.uid) === -1) {
          accounts.push(t.account);
        }
      });

      accounts.forEach((a) => {
        items.push({
          title: this.$t('newForm.templatesFrom').replace('%s', a.companyName),
          to: { name: NEW_FORM, query: { accountId: a.uid } },
        });
      });

      return items;
    },
    filteredFormTemplates() {
      if (this.selected === null || Object.keys(this.selected.to.query).length === 0) {
        return this.formTemplates;
      }

      return this.formTemplates.filter((t) => this.selected.to.query.accountId === t.account.uid);
    },
    selected() {
      const selected = this.navItems
        .filter((item) => JSON.stringify(item.to.query) === JSON.stringify(this.$route.query));
      if (selected.length !== 1) {
        return null;
      }

      return selected[0];
    },
    title() {
      if (this.selected === null) {
        return this.$t('newForm.allSurveyTemplates');
      }

      return this.selected.title;
    },
  },
  methods: {
    ...mapActions([
      'getFormTemplates',
    ]),
    retrieveFormTemplateList() {
      this.loading = true;
      this.getFormTemplates({ query: releasedFormTemplates({ type: formTemplateType.survey }) }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
  },
  mounted() {
    this.retrieveFormTemplateList();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .new-form {
    display: flex;
    height: var(--viewport-height-100);

    ._right {
      flex: 1 1 auto;
      width: 100%;
      margin-left: auto;
      background-color: map_get($grey, 'lighten-6');
    }

    ._top {
      display: flex;

      ._actions {
        margin-left: auto;
      }
    }
  }
</style>
