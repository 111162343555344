import { UID } from 'shared/api/query/constants';
import { dataSource as dataSourceConfig, goal as goalConfig, msPlannerQuery as msPlannerQueryConfig } from 'shared/api/query/configs.json';
import { msPlannerAggregationMode, msPlannerAggregationResource, msPlannerFilterMode } from 'shared/constants.json';

export const msPlannerQueryChildren = [
  { attr: UID },
  {
    attr: msPlannerQueryConfig.edges.dataSource,
    model: dataSourceConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: msPlannerQueryConfig.edges.goal,
    model: goalConfig.model,
    children: [{ attr: UID }],
  },
  { attr: msPlannerQueryConfig.edges.alwaysSync, default: false },
  { attr: msPlannerQueryConfig.edges.error, default: null },
  { attr: msPlannerQueryConfig.edges.aggregationMode, default: msPlannerAggregationMode.percentage },
  { attr: msPlannerQueryConfig.edges.aggregationResource, default: msPlannerAggregationResource.task },
  { attr: msPlannerQueryConfig.edges.filterMode, default: msPlannerFilterMode.filter },
  { attr: msPlannerQueryConfig.edges.planId, default: null },
  { attr: msPlannerQueryConfig.edges.planName, default: null },
  { attr: msPlannerQueryConfig.edges.bucketIDs, default: [] },
  { attr: msPlannerQueryConfig.edges.bucketNames, default: [] },
  { attr: msPlannerQueryConfig.edges.labelIDs, default: [] },
  { attr: msPlannerQueryConfig.edges.labelNames, default: [] },
  { attr: msPlannerQueryConfig.edges.taskIDs, default: [] },
  { attr: msPlannerQueryConfig.edges.taskNames, default: [] },
];
