<template>
  <div
    id="bumblebee"
    class="settings-layout"
  >
    <m-layout class="_content">
      <settings-navigation
        v-if="!collapsed || !$store.state.breakpoint.smAndDown"
        :last-visit="lastVisit"
      />
      <page-layout
        :class="['_body', collapsed ? '' : '-show', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
        :hide-top-bar="!$store.state.breakpoint.smAndDown"
        @click="handleBodyClick"
      >
        <template #topBar>
          <div
            class="_top"
          >
            <m-btn
              class="_menu-btn"
              icon="hamburger"
              fab
              hide-border
              large
              icon-size="24"
              @click.stop="toggleCollapse"
            />
            <m-btn
              icon="left"
              :to="lastVisit"
              hide-border
              block
              large
              :button-style="{ borderRadius: 0, justifyContent: 'flex-start' }"
              :icon-color="$colors.grey.lighten1"
              icon-size="24"
            >
              <div class="_btn-text">
                {{ $t('settingsNavigation.title') }}
              </div>
            </m-btn>
          </div>
        </template>
        <scroll-container>
          <slot />
        </scroll-container>
        <div
          v-show="!collapsed"
          class="_dragbar"
          @click.stop="collapsed = true"
          @touchstart="handleTouchStart"
          @touchend="handleTouchEnd"
          @touchmove="handleTouchMove"
        />
      </page-layout>
    </m-layout>
  </div>
</template>

<script>
import PageLayout from '@/components/page/PageLayout.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import SettingsNavigation from '@/components/navigation/SettingsNavigation.vue';
import usePageVisits from '@/composables/page-visits';
import { SETTINGS_LAYOUT } from '@/lib/layout';

export default {
  name: 'SettingsLayout',
  components: { ScrollContainer, PageLayout, SettingsNavigation },
  setup() {
    const { pageVisits } = usePageVisits();
    return { pageVisits };
  },
  data() {
    return {
      collapsed: true,
      touchStart: 0,
      touchMove: 0,
      screenWidth: 0,
    };
  },
  computed: {
    mobileContentStyle() {
      if (!this.collapsed) {
        return {
          transform: 'translateY(0) translateX(calc(100vw - 5rem))',
          width: '100vw',
          overflow: 'hidden',
          position: 'fixed',
        };
      }

      return { width: '100vw' };
    },
    lastVisit() {
      for (let i = 0; i < this.pageVisits.length; i++) {
        const route = this.$router.options.routes.find((r) => this.pageVisits[i].routeName !== undefined && r.name === this.pageVisits[i].routeName);
        if (typeof route === 'undefined' || route.meta.layout === SETTINGS_LAYOUT) {
          continue;
        }

        const params = this.pageVisits[i].params;
        const query = this.pageVisits[i].query;

        return { name: this.pageVisits[i].routeName, params, query };
      }

      return '/';
    },
  },
  methods: {
    handleTouchStart(event) {
      this.touchStart = event.touches[0].pageX;
      this.touchMove = event.touches[0].pageX;
    },
    handleTouchMove(event) {
      this.touchMove = event.touches[0].pageX;
    },
    handleTouchEnd() {
      if (Math.abs(this.touchMove - this.touchStart) > 100) {
        this.showMenu = false;
      }
      this.touchStart = 0;
      this.touchMove = 0;
    },
    setScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
    handleBodyClick() {
      if (!this.collapsed) {
        this.toggleCollapse();
      }
    },
  },
  watch: {
    $route() {
      this.collapsed = true;
    },
  },
  created() {
    this.setScreenWidth();
    window.addEventListener('resize', this.setScreenWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setScreenWidth);
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._content {
    display: flex;
    flex-direction: row;
    width: 100vw;
    overflow: hidden;

    ._top {
      display: flex;
      align-items: center;
      min-height: 4.2rem;
      margin-top: .1rem;
      margin-left: 1rem;

      ._btn-text {
        font-size: $font-size-6;
      }
    }

    ._dragbar {
      position: fixed;
      top: 0;
      left: calc(100vw - 5rem);
      z-index: 9999;
      width: calc(100vw - 5rem);
      height: var(--viewport-height-100);
      overflow: hidden;
    }

    ._body {
      min-height: var(--viewport-height-100);
      transition: all .3s ease-in-out;

      &.-mobile {
        min-width: 100vw;
        background-color: white;
        box-shadow: rgba(15, 15, 15, .05) 0 0 0 1px, rgba(15, 15, 15, .1) 0 3px 6px, rgba(15, 15, 15, .2) 0 9px 24px;
      }

      &.-show {
        overflow-x: hidden;
      }
    }
  }
</style>
