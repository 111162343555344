<template>
  <m-dialog
    ref="dialog"
    class="search-dialog"
    :value="value"
    hide-header
    hide-footer
    no-padding
    max-height="100%"
    top="12vh"
    @input="input"
  >
    <div :class="['_content', $store.state.breakpoint.smAndDown ? '-mobile' : '']">
      <div class="_search">
        <m-text-field
          v-model:value="search"
          icon="search"
          hide-border
          xl
          allow-clear
          auto-focus
          @blur="$refs.dialog.focus()"
        >
          <template #prefix>
            <m-icon
              :type="loading ? 'loading' : 'search'"
              :color="$colors.grey.base"
              size="18"
            />
          </template>
        </m-text-field>
        <div
          v-if="$store.state.breakpoint.smAndDown"
          class="_m-btn"
        >
          <m-btn
            hide-border
            light
            @click="hide"
          >
            {{ $t('general.close') }}
          </m-btn>
        </div>
      </div>
      <div class="_results">
        <template v-if="search === ''">
          <m-card-item
            class="_heading"
            :clickable="false"
          >
            {{ $t('searchDialog.recentlyVisited') }}
          </m-card-item>
          <template
            v-for="(item, i) in recentlyVisitedItems"
            :key="item.uid"
          >
            <m-card-item
              :focused="i === focusedItem"
              padding-small
              right-on-focus-only
              no-hover
              class="_item -result"
              large
              @click="goToFocusedItem"
              @mouseover="focusedItem = i"
            >
              <div class="_inner">
                <div class="_title">
                  <item-title
                    :title="formatTitle({ ...item, [DGRAPH_TYPE]: goalConfig.model, })"
                    :icons="[{ value: buildIconFromEntity(item) }]"
                  />
                </div>
                <span class="_tag">— {{ tagTitle(item) }}</span>
              </div>
              <template #right>
                <m-icon
                  type="enter"
                  :color="$colors.grey.base"
                />
              </template>
            </m-card-item>
          </template>
        </template>
        <template
          v-for="(item, index) in results"
          v-else
          :key="item.uid"
        >
          <m-card-item
            :focused="index === focusedItem"
            right-on-focus-only
            padding-small
            class="_item -result"
            large
            no-hover
            @click="goToFocusedItem"
            @mouseover="focusedItem = index"
          >
            <div class="_inner">
              <template v-if="item[DGRAPH_TYPE] === userConfig.model">
                <user-display :user="item" />
              </template>
              <template v-else>
                <div class="_title">
                  <item-title
                    :title="formatTitle(item)"
                    :icons="[{ value: buildIconFromEntity(item) }]"
                  />
                </div>
              </template>
              <span class="_tag"> — {{ tagTitle(item) }}</span>
            </div>
            <template #right>
              <m-icon

                type="enter"
                :color="$colors.grey.base"
              />
            </template>
          </m-card-item>
        </template>
      </div>
      <m-content
        v-if="search !== ''"
        class="_footer"
      >
        <div class="_content">
          <div class="_text">
            <span class="_number-of-results">{{ results.length }}</span> {{ $t('searchDialog.results') }}
          </div>
          <m-icon
            v-if="loading"
            type="loading"
            size="14"
          />
        </div>
      </m-content>
    </div>
  </m-dialog>
</template>

<script>
import ItemTitle from '@/components/ItemTitle.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useDebounce from '@/composables/debounce';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePageVisits from '@/composables/page-visits';
import useSpaces from '@/composables/space/spaces';
import useUsers from '@/composables/user/users';
import { DGRAPH_TYPE, RESULT } from 'shared/api/query/constants';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed, ref } from 'vue';
import { dogma } from '@/api';
import { findInArray } from 'shared/lib/array/array';
import {
  goal as goalConfig,
  space as spaceConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { goalsByTitle } from '@/api/query/nebula/goal';
import { isEmpty } from 'shared/lib/object/object';
import { routeName } from 'shared/constants.json';

export default {
  name: 'SearchDialog',
  props: {
    value: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['update:value'],
  setup() {
    const { debounce } = useDebounce();
    const { getEnrichedPageVisits } = usePageVisits();
    const { userLang } = useLoggedInUser();
    const search = ref('');
    const { filteredSpaces } = useSpaces(search);
    const { getUsersByName } = useUsers();

    const filteredUsers = computed(() => getUsersByName(search.value).map((u) => ({ ...u, [DGRAPH_TYPE]: userConfig.model })));
    const { loggedInUserAccount } = useLoggedInUserAccount();

    return {
      loggedInUserAccount,
      debounce,
      getEnrichedPageVisits,
      filteredSpaces: computed(() => filteredSpaces.value.map((s) => ({ ...s, [DGRAPH_TYPE]: spaceConfig.model }))),
      search,
      filteredUsers,
      userLang,
    };
  },
  components: { UserDisplay, ItemTitle },
  data() {
    return {
      DGRAPH_TYPE,
      userConfig,
      results: [],
      loading: false,
      focusedItem: 0,
      goalConfig,
      recentlyVisitedItems: [],
    };
  },
  methods: {
    buildIconFromEntity,
    buildRecentlyVisitedItems() {
      const targets = [
        { type: 'goal', model: goalConfig.model },
      ];
      this.getEnrichedPageVisits().then((pageVisits) => {
        this.recentlyVisitedItems = pageVisits.reduce((res, next) => {
          const target = targets.find((t) => next.node && next.node.type === t.type);
          if (typeof target === 'undefined') {
            return res;
          }
          if (findInArray({ haystack: res, needle: next.node.uid }) !== null) {
            return res;
          }

          res.push({
            title: next.node.title,
            icon: next.node.icon,
            uid: next.node.uid,
            [DGRAPH_TYPE]: target.model,
          });
          return res;
        }, []);
      });
    },
    tagTitle(item) {
      return this.$t(`type.${item[DGRAPH_TYPE]}`);
    },
    input(value) {
      this.$emit('update:value', value);
    },
    formatTitle(item) {
      switch (item[DGRAPH_TYPE]) {
        case userConfig.model:
          if (item.firstName !== '' && item.lastName !== '') {
            return `${item.firstName} ${item.lastName}`;
          }

          return item.email;
        case spaceConfig.model:
        case goalConfig.model:
          if (item.title !== '') {
            return item.title;
          }

          return this.$t('list.noTitle');
        default:
          return '';
      }
    },
    retrieveResults() {
      if (isEmpty(this.search)) {
        return;
      }

      this.loading = true;
      dogma.query([
        ...goalsByTitle(this.search, this.loggedInUserAccount.uid),
      ]).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.results = [
          ...response.data[RESULT].map((g) => ({ ...g, [DGRAPH_TYPE]: goalConfig.model })),
          ...this.filteredUsers,
          ...this.filteredSpaces,
        ];
      }).finally(() => {
        this.loading = false;
      });
    },
    moveFocusDown() {
      if (this.search !== '' && this.focusedItem === (this.results.length - 1)) {
        return;
      }
      if (this.search === '' && this.focusedItem === (this.recentlyVisitedItems.length - 1)) {
        return;
      }

      this.focusedItem += 1;
    },
    moveFocusUp() {
      if (this.focusedItem === 0) {
        return;
      }
      this.focusedItem -= 1;
    },
    hide() {
      this.focusedItem = 0;
      this.$refs.dialog.focus();
      this.$refs.dialog.hide();
    },
    goToFocusedItem() {
      let items = this.results;
      if (this.search === '') {
        items = this.recentlyVisitedItems;
      }

      if (typeof items[this.focusedItem] === 'undefined') {
        return;
      }

      this.search = '';

      if (items[this.focusedItem][DGRAPH_TYPE] === userConfig.model) {
        this.$router.push({ name: routeName.profile, params: { userId: items[this.focusedItem].uid } });
        this.hide();
        return;
      }
      if (items[this.focusedItem][DGRAPH_TYPE] === spaceConfig.model) {
        this.$router.push({ name: routeName.spaceDetails, params: { optionId: items[this.focusedItem].uid } });
        this.hide();
        return;
      }

      this.$router.push({ name: routeName.goalDetails, params: { goalId: items[this.focusedItem].uid } });
      this.hide();
    },
    handleKeyDown(event) {
      if (!this.value) {
        return;
      }

      if (event.key === 'Enter') {
        this.goToFocusedItem();
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      if (event.code === 'ArrowDown' || event.which === 40) {
        event.preventDefault();
        event.stopPropagation();
        this.moveFocusDown();
        return;
      }

      if (event.code === 'ArrowUp' || event.which === 38) {
        event.preventDefault();
        event.stopPropagation();
        this.moveFocusUp();
      }
    },
  },
  watch: {
    search() {
      this.focusedItem = 0;
      this.debounce(this.retrieveResults, 1000);
    },
    value(val) {
      if (val) {
        this.buildRecentlyVisitedItems();
      }
    },
  },
  created() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  $max-height: 70vh;
  $input-height: 5rem;
  $footer-height: 2.8rem;

  ._content {
    ._results {
      max-height: calc(#{$max-height} - #{$input-height} - #{$footer-height});
      overflow: auto;
      border-top: 1px solid $border-color;
      padding: .4rem 0;

      ._item {
        ._inner {
          display: flex;
          align-items: center;

          ._title {
            max-width: 35rem;
          }

          ._tag {
            margin-left: .8rem;
            font-size: $font-size-1;
            color: $font-color-tertiary;
            text-transform: uppercase;
          }
        }
      }

      ._heading {
        font-size: $font-size-2;
        color: $font-color-secondary;
        text-transform: uppercase;
      }
    }

    &.-mobile {
      ._results {
        max-height: calc(var(--viewport-height-100) - #{$input-height} - #{$footer-height});
      }
    }
  }

  ._footer {
    display: flex;
    align-items: center;
    height: 2.8rem;

    ._content {
      display: flex;
      margin-left: 2rem;
      font-size: $font-size-4;
      color: $font-color-tertiary;

      ._text {
        margin-right: .4rem;

        ._number-of-results {
          font-weight: $font-weight-semibold;
          color: $font-color-secondary;
        }
      }
    }
  }

  ._search {
    display: flex;
    align-items: center;
  }
</style>
