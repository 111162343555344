<template>
  <m-dropdown
    id="goal-cycle-selector"
    ref="dropdown"
    v-model:value="showCycleSelector"
    class="goal-cycle-selector"
    :title="$t('goalCycleSelector.title')"
    :block="inCardMenu"
    :disabled="readOnly"
    :relocate-key="relocateKey"
  >
    <goal-cycle-navigator
      :in-card-menu="inCardMenu"
      :items="sortedCycles"
      :selected="selected"
      :read-only="readOnly"
      :is-cycle-locked-on-view="isCycleLockedOnView"
      @previous="setCycles"
      @next="setCycles"
    />
    <template #overlay>
      <m-card
        no-padding
        list
        class="_overlay"
      >
        <m-content
          :padding-x="7"
          :padding-y="5"
        >
          <m-text-field
            v-model:value="search"
            auto-focus
            :placeholder="$t('goalCycleSelector.searchPlaceholder')"
            has-background
            @blur="focusDropdown"
          />
        </m-content>
        <goal-cycle-list
          :cycle-groups="timeGroupedGoalCycles"
          :selected="selected"
          :selectable="!readOnly"
          :with-unassigned="withUnassigned"
          :expand-all="search !== ''"
          :is-cycle-locked-on-view="isCycleLockedOnView"
          :show-lock-cycle-option="showLockCycleOption"
          multiple
          @select="setCycles"
          @set-lock-cycle-on-view="lockCycleOnView"
          @toggle-show="handleToggleShow"
        />
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import GoalCycleList from '@/components/goal/cycle/GoalCycleList.vue';
import GoalCycleNavigator from '@/components/goal/cycle/GoalCycleNavigator.vue';
import useAccess from '@/composables/access/access';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import { DateTime } from 'luxon';
import { EventBus } from '@/lib/event-bus';
import { shallowCopy } from 'shared/lib/copy';
import { sortDatetimeInterval } from 'shared/lib/sort';

export default {
  name: 'GoalCycleAppSettingsSelector',
  props: {
    inCardMenu: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      required: true,
    },
    isCycleLockedOnView: {
      type: Boolean,
      required: true,
    },
    changeSelectedGoalCycles: {
      type: Function,
      required: true,
    },
    lockCycleOnView: {
      type: Function,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    withUnassigned: {
      type: Boolean,
      default: false,
    },
    showLockCycleOption: {
      type: Boolean,
      default: true,
    },
  },
  components: { GoalCycleNavigator, GoalCycleList },
  data() {
    return { search: '', showCycleSelector: false, relocateKey: 0 };
  },
  setup() {
    const { userHasRight } = useAccess();
    const { goalCycles } = useGoalCycle();

    return {
      goalCycles,
      userHasRight,
    };
  },
  computed: {
    filteredGoalCycles() {
      return this.goalCycles
        .filter((c) => this.search === '' || c.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
    },
    filteredAndSortedGoalCycles() {
      return shallowCopy(this.filteredGoalCycles)
        .sort(sortDatetimeInterval('start', 'end'));
    },
    timeGroupedGoalCycles() {
      return this.goalCyclesGroupByTime(this.filteredAndSortedGoalCycles);
    },
    sortedCycles() {
      const flatten = (acc, node) => {
        acc.push(node);
        node.children.reduce(flatten, acc);
        return acc;
      };
      return shallowCopy(this.goalCycles)
        .sort(sortDatetimeInterval('start', 'end'))
        .reduce(this.goalCyclesTreeReducer, [])
        .reduce(flatten, []);
    },
  },
  methods: {
    handleToggleShow() {
      setTimeout(() => {
        this.relocateKey += 1;
      }, 300);
    },
    setCycles(value) {
      this.search = '';
      this.changeSelectedGoalCycles(value);
    },
    goalCyclesTreeReducer(acc, c) {
      const cycle = { ...c, children: [] };
      if (acc.length === 0) {
        acc.push(cycle);
        return acc;
      }

      // Find out if an existing element is a child of c
      cycle.children = acc.filter((el) => (DateTime.fromISO(c.start).diff(DateTime.fromISO(el.start)) <= 0
        && DateTime.fromISO(c.end).diff(DateTime.fromISO(el.end)) >= 0));
      acc = acc.filter((el) => !(DateTime.fromISO(c.start).diff(DateTime.fromISO(el.start)) <= 0
        && DateTime.fromISO(c.end).diff(DateTime.fromISO(el.end)) >= 0));

      // Find out that c is a child of an existing element
      for (let i = 0; i < acc.length; i++) {
        if (DateTime.fromISO(acc[i].start).diff(DateTime.fromISO(c.start)) <= 0
          && DateTime.fromISO(acc[i].end).diff(DateTime.fromISO(c.end)) >= 0) {
          acc[i].children = this.goalCyclesTreeReducer(acc[i].children, cycle);
          return acc;
        }
      }

      acc.push(cycle);
      return acc;
    },
    goalCyclesGroupByTime(goalCycles) {
      const now = DateTime.local();

      const timeMap = goalCycles.reduce((acc, c) => {
        const start = DateTime.fromISO(c.start);
        const end = DateTime.fromISO(c.end);

        if (now.diff(start) < 0) {
          acc.upcoming.push(c);
          return acc;
        }

        if (now.diff(end) > 0) {
          acc.past.push(c);
          return acc;
        }

        acc.current.push(c);
        return acc;
      }, {
        current: [],
        past: [],
        upcoming: [],
      });
      return ['current', 'upcoming', 'past'].reduce((acc, timeKey) => {
        if (timeMap[timeKey].length > 0) {
          acc.push({ title: this.$t(`goalCycleSelector.${timeKey}`), children: timeMap[timeKey].reduce(this.goalCyclesTreeReducer, []) });
        }
        return acc;
      }, []);
    },
    focusDropdown() {
      if (typeof this.$refs.dropdown === 'undefined') {
        return;
      }

      this.$refs.dropdown.focus();
    },
    openMenu() {
      this.showCycleSelector = true;
    },
  },
  mounted() {
    EventBus.$on('applyCycle', this.openMenu);
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._overlay {
    min-width: 20rem;
  }
</style>
