<template>
  <dashboard-card
    class="measurement-types-card"
    :loading="loading"
  >
    <template #header>
      <dashboard-card-header
        :title="$t('goalInsightsHealth.measurementTypes.cardTitle')"
        text-key="goalInsightsHealth.measurementTypes.cardHelp"
        :can-edit="canEdit"
        :link="settingsLink"
        :loading="loading"
      />
    </template>
    <distribution-chart
      :chart-data="distribution"
      clickable
      @click="handler($event)"
    />
  </dashboard-card>
</template>

<script>
import DashboardCard from '@/components/dashboard/DashboardCard.vue';
import DashboardCardHeader from '@/components/goal-insights/health/DashboardCardHeader.vue';
import DistributionChart from '@/components/dashboard/DistributionChart.vue';
import useAccess from '@/composables/access/access';
import useDebounce from '@/composables/debounce';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useMeasurementTypeDistribution from '@/composables/goal-insights/measurement-type-distribution';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useSubscription from '@/composables/subscription/subscription';
import { AND } from 'shared/api/query/filter';
import { DIRECT_PROPERTY_PROGRESS_KEY } from '@/lib/constants';
import { GoalFilterHandler } from '@/lib/filter/goal/handler';
import { accessGroupFlag, goalProgressMeasurement, routeName } from 'shared/constants.json';
import { applyBaseFilter } from '@/lib/filter/base-filter';
import { dogma } from '@/api';
import { goalTypesFilter } from '@/lib/filter/goal/goals-type-filter';
import { measurementTypesFilter } from '@/lib/filter/goal/measurement-types-filter';
import { queryMeasurementTypes } from '@/api/query/goal-insights-health';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'MeasurementTypesCard',
  props: {
    selectedCycles: {
      type: Array,
      required: true,
    },
    viewFilter: {
      type: Object,
      required: true,
    },
    goalType: {
      type: Object,
      required: true,
    },
  },
  emits: ['show-goal-list'],
  setup(props) {
    const { userLang } = useLoggedInUser();
    const { debounce } = useDebounce();
    const { subscribe } = useSubscription();

    const { distribution, setup, update } = useMeasurementTypeDistribution();
    setup(props.goalType);

    const { goalTypeProperty } = useGoalTypeProperty();
    const { publishedAtFilter } = usePublishedAtFilter();
    const { userHasRight } = useAccess();
    return {
      debounce,
      subscribe,

      distribution,
      setup,
      update,

      userLang,
      goalTypeProperty,
      publishedAtFilter,
      userHasRight,
    };
  },
  components: { DashboardCardHeader, DashboardCard, DistributionChart },
  data() {
    return {
      loading: false,
      series: [],
    };
  },
  computed: {
    canEdit() {
      return this.userHasRight([accessGroupFlag.goalCycleWriteAccess]);
    },
    settingsLink() {
      return { name: routeName.goalTypes, params: { optionId: this.goalType.uid }, query: { propKey: DIRECT_PROPERTY_PROGRESS_KEY } };
    },
    gqlFilterObject() {
      const handler = new GoalFilterHandler();
      return handler.Translate(this.viewFilter);
    },
    gqlFilter() {
      return this.gqlFilterObject.filterTree;
    },
  },
  methods: {
    handler(bucket) {
      this.$emit('show-goal-list', {
        filter: {
          op: AND,
          children: [
            this.viewFilter,
            goalTypesFilter(this.goalTypeProperty, this.goalType),
            measurementTypesFilter([bucket.value]),
          ],
        },
        title: this.$t('goalInsightsHealth.measurementTypes.dialogTitle', { label: bucket.x, goalType: textByLang(this.goalType.label, this.userLang) }),
      });
    },
    retrieveMeasurementTypesRestriction() {
      this.loading = true;
      dogma.query(applyBaseFilter({
        queries: [
          ...queryMeasurementTypes({
            goalCycles: this.selectedCycles,
            filter: this.gqlFilter,
            varBlocks: this.gqlFilterObject.varBlocks,
            goalTypeOption: this.goalType,
          }),
        ],
        operator: AND,
        baseFilter: this.publishedAtFilter,
      })).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        const res = goalProgressMeasurement.all.reduce((res, measurementType) => {
          res.series.push(...response.data[`${measurementType}`]);
          res.counters[measurementType] = response.data[`${measurementType}`].length;
          return res;
        }, { series: [], counters: {} });
        this.update(res.counters);
        this.series = res.series;
      });
    },
    fetch(wait) {
      this.debounce(this.retrieveMeasurementTypesRestriction, wait, 'retrieveMeasurementTypesRestriction');
    },
  },
  watch: {
    selectedCycles(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.fetch(1000);
    },
    gqlFilterObject: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.fetch(1000);
      },
      deep: true,
    },
  },
  created() {
    this.fetch(0);

    const fn = this.fetch;
    this.subscribe({
      queryFn: Promise.resolve(),
      syncFn({ isSelfEvent }) {
        const delay = isSelfEvent ? 1000 : 30000;
        fn(delay);
      },
      model: 'goal',
      includeSelfEvents: true,
    });
  },
};
</script>
