<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17.625 5.625H3.375C2.96016 5.625 2.625 5.96016 2.625 6.375V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H17.625C18.0398 21.375 18.375 21.0398 18.375 20.625V6.375C18.375 5.96016 18.0398 5.625 17.625 5.625ZM16.6875 19.6875H4.3125V7.3125H16.6875V19.6875ZM20.625 2.625H6.1875C6.08437 2.625 6 2.70937 6 2.8125V4.125C6 4.22813 6.08437 4.3125 6.1875 4.3125H19.6875V17.8125C19.6875 17.9156 19.7719 18 19.875 18H21.1875C21.2906 18 21.375 17.9156 21.375 17.8125V3.375C21.375 2.96016 21.0398 2.625 20.625 2.625Z"
      fill="currentColor"
    />
    <path
      d="M6.7832 14.2109H9.90039V17.3281C9.90039 17.4184 9.97422 17.4922 10.0645 17.4922H11.0488C11.1391 17.4922 11.2129 17.4184 11.2129 17.3281V14.2109H14.3301C14.4203 14.2109 14.4941 14.1371 14.4941 14.0469V13.0625C14.4941 12.9723 14.4203 12.8984 14.3301 12.8984H11.2129V9.78125C11.2129 9.69102 11.1391 9.61719 11.0488 9.61719H10.0645C9.97422 9.61719 9.90039 9.69102 9.90039 9.78125V12.8984H6.7832C6.69297 12.8984 6.61914 12.9723 6.61914 13.0625V14.0469C6.61914 14.1371 6.69297 14.2109 6.7832 14.2109Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>export default { name: 'AddGroup' }; </script>
