<template>
  <div :class="['scope-filter', viewMode ? '-view-mode': '']">
    <m-spinner
      v-if="loading"
      size="sm"
    />
    <div
      v-else
    >
      <div class="_inner">
        <template
          v-for="(child, index) in userScopeTree.children"
          :key="getKey(child)"
        >
          <div class="_filter-item">
            <div
              v-if="index === 0 && allowGrouping"
              class="_group-label"
            >
              <m-focusable
                hide-border
                hide-hover
              >
                {{ $t('userScopeFilter.where') }}
              </m-focusable>
            </div>
            <div
              v-if="child.isGroup && index !== 0"
              class="_group-label"
            >
              <m-select
                v-model:value="selectedOperator"
                :items="opItems"
                :read-only="index !== 1"
                :disabled="disabled"
                :placeholder="$t('leafNode.placeholder')"
              />
            </div>
            <div
              v-if="child.isGroup"
              class="_group"
            >
              <scope-filter
                :value="child"
                :props="props"
                :account="account"
                :max-tag-text-length="15"
                :has-dynamic-dates="hasDynamicDates"
                :disabled="disabled"
                :multi-model="multiModel"
                :open-with-prop="openWithProp"
                :depth="depth + 1"
                :allow-grouping="allowGrouping"
                show-empty-info
                can-remove-all
                @input="updateGroupFilter"
              />
            </div>
            <div
              v-if="child.isGroup && !disabled"
              class="_group-menu"
            >
              <m-dropdown
                :title="$t('general.actions')"
                close-on-click
              >
                <m-btn
                  icon="ellipsis"
                  hide-border
                  fab
                  light
                />

                <template #overlay>
                  <m-card
                    no-padding
                    list
                  >
                    <m-card-item
                      v-if="userScopeTree.children.length > 1 || canRemoveAll"
                      data-cy="delete-filter"
                      icon="delete"
                      @click="remove(index)"
                    >
                      {{ $t('userScopeFilter.remove') }}
                    </m-card-item>
                    <m-card-item
                      icon="copy"
                      @click="duplicate(index)"
                    >
                      {{ $t('general.duplicate') }}
                    </m-card-item>
                    <m-card-item
                      v-if="child.children.length === 1"
                      icon="wrap"
                      @click="ungroup(index)"
                    >
                      <template v-if="calculateDepth(child) > 2">
                        {{ $t('userScopeFilter.resolveGroup') }}
                      </template>
                      <template v-else>
                        {{ $t('userScopeFilter.turnIntoFilter') }}
                      </template>
                    </m-card-item>
                    <m-card-item
                      v-if="calculateDepth(child) + depth < 3"
                      icon="wrap"
                      @click="group(index)"
                    >
                      <div>
                        <div class="_wrap-in-group-title">
                          {{ $t('userScopeFilter.wrapInGroup') }}
                        </div>
                        <div class="_wrap-in-group-sub-title">
                          {{ $t('userScopeFilter.wrapInGroupSubTitle') }}
                        </div>
                      </div>
                    </m-card-item>
                  </m-card>
                </template>
              </m-dropdown>
            </div>
            <leaf-node
              v-if="!child.isGroup"
              :class="['_leaf', disabled ? '-disabled':'']"
              :node="child"
              :op="selectedOperator"
              :hide-op="hideOp || index === 0"
              :can-select-op="index === 1"
              :hide-leaf-op="hideLeafOp"
              :can-delete="userScopeTree.children.length > 1 || canRemoveAll"
              :scope-items="scopeItems"
              :read-only="disabled"
              :max-tag-text-length="maxTagTextLength"
              :has-dynamic-dates="hasDynamicDates"
              :multi-model="multiModel"
              :allow-grouping="allowGrouping && depth < 2"
              @change-operator="changeOperator"
              @change="updateChild(index, $event)"
              @delete="remove(index)"
              @duplicate="duplicate(index)"
              @group="group(index)"
            />
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="!disabled && firstUnusedScopeItem !== null"
      class="_add"
    >
      <m-btn
        v-if="depth > 1 || !allowGrouping"
        class="_btn"
        icon="plus"
        :disabled="disabled"
        light
        hide-border
        small
        @click="addNode(firstUnusedScopeItem)"
      >
        <template v-if="addBtnLabel === ''">
          {{ $t('userScopeFilter.addFilter') }}
        </template>
        <template v-else>
          {{ addBtnLabel }}
        </template>
      </m-btn>
      <m-dropdown
        v-else
        close-on-click
        :title="$t('general.add')"
      >
        <m-btn
          class="_btn"
          icon="plus"
          :disabled="disabled"
          light
          hide-border
          small
        >
          <template v-if="addBtnLabel === ''">
            {{ $t('userScopeFilter.addFilter') }}
          </template>
          <template v-else>
            {{ addBtnLabel }}
          </template>
          <m-icon
            class="_down-icon"
            type="down"
            :color="$colors.grey.lighten1"
            size="11"
          />
        </m-btn>

        <template #overlay>
          <m-card
            no-padding
            list
          >
            <m-card-item
              data-cy="add-filter"
              icon="plus"
              @click="addNode(firstUnusedScopeItem)"
            >
              {{ $t('userScopeFilter.addFilter') }}
            </m-card-item>
            <m-card-item
              icon="add-group"
              @click="addGroupNode(firstUnusedScopeItem, userScopeTree.key)"
            >
              {{ $t('userScopeFilter.addGroupFilter') }}
            </m-card-item>
          </m-card>
        </template>
      </m-dropdown>
    </div>
    <div
      v-if="showEmptyInfo && disabled && userScopeTree.children.length === 0"
      class="_empty"
    >
      {{ $t('userScopeFilter.empty') }}
    </div>
  </div>
</template>

<script>
import LeafNode from '@/components/filter/LeafNode.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { copy } from 'shared/lib/copy';
import { guid } from 'shared/lib/uuid';
import { iconByType } from '@/lib/property';
import { isEqual } from 'lodash-es';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { isValidTree } from '@/lib/filter/scope-tree';
import { propertyType, userScopeOperator, userScopeType } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'ScopeFilter',
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    props: {
      type: Array,
      default: () => [],
    },
    account: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showEmptyInfo: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    showStaticUserSelection: {
      type: Boolean,
      default: false,
    },
    openWithStaticUserSelection: {
      type: Boolean,
      default: false,
    },
    canRemoveAll: {
      type: Boolean,
      default: false,
    },
    maxTagTextLength: {
      type: Number,
      default: 0,
    },
    hasDynamicDates: {
      type: Boolean,
      default: false,
    },
    multiModel: {
      type: Boolean,
      default: false,
    },
    initWithProp: {
      type: Object,
      default: () => null,
    },
    openWithProp: {
      type: Object,
      default: () => null,
    },
    hideOp: {
      type: Boolean,
      default: false,
    },
    hideLeafOp: {
      type: Boolean,
      default: false,
    },
    addBtnLabel: {
      type: String,
      default: '',
    },
    depth: {
      type: Number,
      default: 0,
    },
    allowGrouping: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  emits: ['input', 'update:value'],
  components: { LeafNode },
  data() {
    return {
      userScopeTree: null,
      selectedOperator: userScopeOperator.and,
      staticUserItem: {
        value: guid(),
        icon: 'user',
        text: this.$t('userScope.staticUserLabel'),
        type: userScopeType.staticUsers,
      },
      opItems: [
        {
          text: this.$t('general.and'),
          value: userScopeOperator.and,
        },
        {
          text: this.$t('general.or'),
          value: userScopeOperator.or,
        },
      ],
    };
  },
  computed: {
    allowedProps() {
      const notAllowed = [
        propertyType.text,
        propertyType.url,
        propertyType.richText,
      ];

      return this.props.filter((p) => !notAllowed.includes(p.property.type));
    },
    scopeItems() {
      const items = this.allowedProps.map((p) => (this.scopeItemByProp(p)));
      if (this.showStaticUserSelection) {
        items.push(this.staticUserItem);
      }
      return items;
    },
    scopeItemsInScopeTree() {
      const res = [];
      const scopeTypeByScope = (scope) => {
        if (scope.type !== undefined) {
          return scope.type;
        }
        if (!isNullOrUndefined(scope.staticUsers)) {
          return userScopeType.staticUsers;
        }
        if (!isNullOrUndefined(scope.directProperty)) {
          return userScopeType.directProperty;
        }
        return userScopeType.property;
      };
      const scopeItemByScope = (scope) => {
        const type = scopeTypeByScope(scope);
        switch (type) {
          case userScopeType.staticUsers:
            return this.scopeItems.find((item) => item.type === type);
          case userScopeType.directProperty:
            return this.scopeItems.find((item) => item.type === type && item.directProperty.edgeName === scope.directProperty.edgeName);
          case userScopeType.property:
            return this.scopeItems.find((item) => item.type === type && item.property.uid === scope.property.uid);
          default:
            throw new Error('unsupported user scope type');
        }
      };

      const recursiveCheck = (children, depth) => {
        if (depth > 3) {
          return;
        }
        for (let i = 0; i < children.length; i++) {
          if (children[i].children.length === 0) {
            continue;
          }
          if (children[i].children[0].scope === undefined) {
            recursiveCheck(children[i].children, depth + 1);
            continue;
          }
          const scopeItem = scopeItemByScope(children[i].children[0].scope);
          if (scopeItem !== undefined && res.find((item) => item.value === scopeItem.value) === undefined) {
            res.push(scopeItem);
          }
        }
      };

      recursiveCheck(this.userScopeTree.children, 1);
      return res;
    },
    firstUnusedScopeItem() {
      const unusedScopeItem = this.scopeItems.filter((item) => !this.scopeItemsInScopeTree.map(({ value }) => value).includes(item.value));
      if (unusedScopeItem.length === 0) {
        return null;
      }
      return unusedScopeItem[0];
    },
  },
  methods: {
    calculateDepth(node) {
      if (node.children === undefined || node.children.length === 0) {
        return 0;
      }
      let maxDepth = 0;
      for (let i = 0; i < node.children.length; i++) {
        const depth = this.calculateDepth(node.children[i]);
        if (depth > maxDepth) {
          maxDepth = depth;
        }
      }
      return 1 + maxDepth;
    },
    scopeItemByProp(prop) {
      const getKey = (key, property) => (this.multiModel ? `${property.model}-${key}` : key);
      const getPropText = (property) => (property.label === undefined ? '' : textByLang(property.label, this.userLang));
      const scopeItemByProperty = (property) => ({
        value: getKey(property.uid, property),
        text: getPropText(property),
        icon: iconByType(property),
        type: userScopeType.property,
        property,
      });
      const scopeItemByDirectProperty = (property) => ({
        value: getKey(property.edgeName, property),
        text: property.label,
        icon: iconByType({ type: property.type, edgeName: property.edgeName }),
        type: userScopeType.directProperty,
        directProperty: property,
      });

      if (prop.isDirect) {
        return scopeItemByDirectProperty(prop.property);
      }
      return scopeItemByProperty(prop.property);
    },
    changeOperator(val) {
      this.selectedOperator = val;
      this.emitUpdate({
        ...this.userScopeTree,
        op: val,
      });
    },
    updateChild(index, val) {
      this.userScopeTree.children.splice(index, 1, val);
    },
    init() {
      this.userScopeTree = {
        account: { uid: this.account.uid },
        op: userScopeOperator.and,
        scope: null,
        children: [],
      };
      if (this.initWithProp !== null) {
        const prop = this.allowedProps.find((p) => p.key === this.initWithProp.key);
        this.addNode(prop);
      }
    },
    getKey(node) {
      if (typeof node.uid !== 'undefined') {
        return node.uid;
      }
      if (typeof node.key !== 'undefined') {
        return node.key;
      }
      return 0;
    },
    nodeAlreadyInTree(scopeItem) {
      return this.scopeItemsInScopeTree.find((p) => p.value === scopeItem.value) !== undefined;
    },
    addNode(scopeItem = null) {
      if (scopeItem === null || this.nodeAlreadyInTree(scopeItem)) {
        return;
      }
      const scopeFromScopeItem = (scopeItem) => {
        const scope = { type: scopeItem.type };
        const propertyScope = {
          users: [],
          selectedOptions: [],
          spaces: [],
          numberRange: null,
          timeRange: null,
          relation: null,
          isEmpty: false,
        };
        switch (scope.type) {
          case userScopeType.staticUsers:
            return { ...scope, staticUsers: [] };
          case userScopeType.directProperty:
            return {
              ...scope,
              ...propertyScope,
              directProperty: scopeItem.directProperty,
            };
          case userScopeType.property:
            return {
              ...scope,
              ...propertyScope,
              property: scopeItem.property,
            };
          default:
            return scope;
        }
      };
      const scope = scopeFromScopeItem(scopeItem);
      this.userScopeTree.children.push({
        key: guid(),
        op: userScopeOperator.and,
        children: [
          {
            op: userScopeOperator.and,
            scope,
          },
        ],
      });
    },
    addGroupNode(scopeItem) {
      const scopeFromScopeItem = (scopeItem) => {
        const scope = { type: scopeItem.type };
        const propertyScope = {
          users: [],
          selectedOptions: [],
          spaces: [],
          numberRange: null,
          timeRange: null,
          relation: null,
          isEmpty: false,
        };
        switch (scope.type) {
          case userScopeType.staticUsers:
            return { ...scope, staticUsers: [] };
          case userScopeType.directProperty:
            return {
              ...scope,
              ...propertyScope,
              directProperty: scopeItem.directProperty,
            };
          case userScopeType.property:
            return {
              ...scope,
              ...propertyScope,
              property: scopeItem.property,
            };
          default:
            return scope;
        }
      };
      const scope = scopeFromScopeItem(scopeItem);
      this.userScopeTree.children.push({
        key: guid(),
        op: userScopeOperator.and,
        isGroup: true,
        children: [
          {
            op: userScopeOperator.and,
            key: guid(),
            children: [
              {
                op: userScopeOperator.and,
                key: guid(),
                scope,
              },
            ],
          },
        ],
      });
    },
    updateGroupFilter(value) {
      for (let i = 0; i < this.userScopeTree.children.length; i++) {
        if (this.userScopeTree.children[i].key === value.key) {
          this.userScopeTree.children[i] = value;
        }
      }
    },
    remove(index) {
      this.userScopeTree.children.splice(index, 1);
      if (this.userScopeTree.children.length === 0 && this.depth === 0) {
        this.emitUpdate(null);
      }
    },
    recreateKey(node) {
      node.key = guid();
      if (node.children === undefined || node.children.length === 0) {
        return node;
      }
      for (let i = 0; i < node.children.length; i++) {
        this.recreateKey(node.children[i]);
      }
      return node;
    },
    duplicate(index) {
      const node = this.recreateKey(copy(this.userScopeTree.children[index]));
      this.userScopeTree.children.splice(index + 1, 0, node);
    },
    group(index) {
      const node = this.recreateKey(copy(this.userScopeTree.children[index]));
      this.userScopeTree.children.splice(index, 1, {
        key: guid(),
        op: userScopeOperator.and,
        isGroup: true,
        children: [node],
      });
    },
    ungroup(index) {
      const node = this.recreateKey(copy(this.userScopeTree.children[index]));
      this.userScopeTree.children.splice(index, 1, ...node.children);
    },
    emitUpdate(userScopeTree, key) {
      if (this.depth === 0 && !isValidTree(userScopeTree)) {
        return;
      }
      this.$emit('input', copy(userScopeTree), key);
      this.$emit('update:value', copy(userScopeTree));
    },
  },
  watch: {
    userScopeTree: {
      handler(val) {
        if (isEqual(val, this.value)) {
          return;
        }
        this.emitUpdate(val);
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (JSON.stringify(this.userScopeTree) === JSON.stringify(val)) {
          return;
        }
        if (this.value === null || Object.keys(this.value).length === 0) {
          this.init();
          return;
        }
        this.userScopeTree = copy(val);
      },
      deep: true,
    },
    selectedOperator: {
      handler(val) {
        this.emitUpdate({
          ...this.userScopeTree,
          op: val,
        });
      },
    },
  },
  created() {
    if (this.value === null || Object.keys(this.value).length === 0) {
      this.init();
      this.emitUpdate(this.userScopeTree);
      return;
    }
    this.userScopeTree = copy(this.value);
    this.selectedOperator = this.userScopeTree.op;

    if (this.showStaticUserSelection === true && this.openWithStaticUserSelection === true) {
      this.addNode(this.staticUserItem);
    }
    if (this.openWithProp !== null) {
      // TODO: Maybe there's a bug here with multi-modal
      const filterToAdd = this.allowedProps.find((p) => p.key === this.openWithProp.key);
      if (filterToAdd !== undefined) {
        this.addNode(this.scopeItemByProp(filterToAdd));
      }
    }
  },
};
</script>

<style lang="scss" type="text/scss">
.scope-filter {
  ._add {
    min-width: 30rem;

    ._btn {
      ._down-icon {
        padding-left: .4rem;
      }
    }
  }

  ._filter-item {
    display: flex;
    padding-bottom: 0.8rem;

    ._group-label {
      padding-right: 0.8rem;
      width: 7rem;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }

    ._group-menu {
      padding-left: 0.8rem;
    }

    ._group {
      display: flex;
      padding: 0.8rem;
      border: 1px solid $border-color;
      border-radius: $input-field-border-radius;
      background-color: map_get($grey, 'lighten-7');
    }

    ._inner {
      width: 100%;
    }
  }

  ._empty {
    color: $font-color-tertiary;
  }
}

  ._wrap-in-group-title {
    margin-bottom: 0.4rem;
  }

  ._wrap-in-group-sub-title {
    font-size: 1.2rem;
    color: $font-color-secondary;
  }
</style>
