<template>
  <div class="m-confirm">
    <m-dialog
      v-model:value="showConfirm"
      :max-width="$modalSizes.sm"
      :title="title"
      :fullscreen-on-mobile="false"
      hide-header
      hide-footer
      fade-in
      center
      @close="hide"
    >
      <div :class="['_heading']">
        {{ title }}
      </div>
      <m-alert
        v-if="message"
        :type="message.type ?? 'warning'"
        class="_message"
      >
        {{ message.text }}
      </m-alert>
      <m-form-item
        v-if="isSecure"
        class="_security"
        :label="secure.question"
      >
        <m-input
          v-model:value="secureAnswer"
          :placeholder="secure.answer"
          full-width
        />
      </m-form-item>
      <div class="_actions">
        <div class="_confirm">
          <m-btn
            :color="isSecure && secureDeny ? undefined : okType"
            :large="$store.state.breakpoint.smAndDown"
            :loading="okLoading"
            :disabled="isSecure && secureDeny"
            block
            tabindex="0"
            :outlined="okType !== 'primary'"
            @click="submit"
          >
            {{ okText }}
          </m-btn>
        </div>
        <div class="_cancel">
          <m-btn
            v-if="!hideCancel"
            :color="cancelType"
            :large="$store.state.breakpoint.smAndDown"
            :loading="cancelLoading"
            outlined
            block
            tabindex="0"
            @click="cancel"
          >
            {{ cancelText }}
          </m-btn>
        </div>
      </div>
    </m-dialog>
  </div>
</template>

<script>

import MAlert from 'shared/components/base/MAlert.vue';
import MBtn from 'shared/components/base/MBtn.vue';
import MDialog from 'shared/components/base/MDialog.vue';
import MFormItem from 'shared/components/base/MFormItem.vue';
import MInput from 'shared/components/base/MInput.vue';

export default {
  name: 'MConfirm',
  props: {
    eventBus: {
      type: Object,
      required: true,
    },
  },
  components: { MFormItem, MDialog, MAlert, MBtn, MInput },
  data() {
    return {
      showConfirm: false,
      title: '',
      message: undefined,
      secure: undefined,
      secureAnswer: '',
      okText: '',
      okType: '',
      cancelText: '',
      cancelType: '',
      hideCancel: false,
      okLoading: false,
      cancelLoading: false,
      hideOnSubmit: true,
      hideOnCancel: true,
      onCancel: () => {
      },
      onOk: () => {
      },
    };
  },
  computed: {
    isSecure() {
      return this.secure !== undefined && this.secure.answer !== undefined;
    },
    secureDeny() {
      return this.secure.answer === '' || this.secure.answer.trim() !== this.secureAnswer.trim();
    },
  },
  methods: {
    reset() {
      this.cancelLoading = false;
      this.okLoading = false;
      this.secureAnswer = '';
    },
    submit() {
      if (this.hideCancel) {
        this.hide();
        return;
      }

      this.onOk(this.setOkLoading);

      if (this.hideOnSubmit) {
        this.hide();
      }
    },
    cancel() {
      this.onCancel(this.setCancelLoading);
      if (this.hideOnCancel) {
        this.hide();
      }
    },
    setOkLoading(value) {
      this.okLoading = value;
    },
    setCancelLoading(value) {
      this.cancelLoading = value;
    },
    show({
      title,
      message = undefined,
      secure = undefined,
      okText = this.$t('general.okay'),
      cancelText = this.$t('general.cancel'),
      onOk = () => ({}),
      okType = 'primary',
      cancelType = 'default',
      hideCancel = false,
      hideOnSubmit = true,
      hideOnCancel = true,
      onCancel = () => ({}),
    }) {
      this.title = title;
      this.message = message; // { text, type }
      this.secure = secure; // { question, answer }
      this.okText = okText;
      this.cancelText = cancelText;
      this.okType = okType;
      this.cancelType = cancelType;
      this.onOk = onOk;
      this.hideCancel = hideCancel;
      this.hideOnCancel = hideOnCancel;
      this.hideOnSubmit = hideOnSubmit;
      this.onCancel = onCancel;
      this.showConfirm = true;
    },
    hide() {
      this.showConfirm = false;
      this.reset();
    },
  },
  created() {
    this.eventBus.$on('show-confirm', this.show);
    this.eventBus.$on('hide-confirm', this.hide);
  },
  beforeUnmount() {
    this.eventBus.$off('show-confirm', this.show);
    this.eventBus.$off('hide-confirm', this.hide);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._heading {
    margin-bottom: 2rem;
    font-size: $font-size-5;
  }

  ._message {
    margin-bottom: 2rem;
    font-size: $font-size-4;
    white-space: pre-line;
  }

  ._actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    ._cancel {
      margin-top: 1.2rem;
    }
  }
</style>
