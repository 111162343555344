<template>
  <div :class="['notification-feed', $store.state.breakpoint.smAndDown ? '-mobile' : '']">
    <div class="_header">
      <div class="_tabs">
        <m-tab
          :title="$t('notificationFeed.tabTasks')"
          :active="activeTab === 'tasks'"
          :badge="unreadTaskCount !== 0 ? `${unreadTaskCount}` : `${badge(unfulfilledTasks)}`"
          :color="badgeColor"
          @click="activeTab = 'tasks'"
        />
        <m-tab
          :title="$t('notificationFeed.tabNotifications')"
          :active="activeTab === 'notifications'"
          :badge="notificationBadge"
          @click="activeTab = 'notifications'"
        />
        <m-tab
          :title="$t('general.all')"
          :active="activeTab === 'all'"
          @click="activeTab = 'all'"
        />
      </div>
    </div>
    <div
      :key="activeTab"
      class="_body"
    >
      <div
        v-if="items.length === 0"
        class="_empty"
      >
        {{ $t(`notificationFeed.noItems.${activeTab}`) }}
      </div>
      <m-endless-scroll-list @visible="loadMore">
        <div
          v-for="feedEntry in items"
          :id="feedEntry.uid"
          :key="feedEntry.uid"
          class="_item"
        >
          <feed-entry-item :feed-entry="feedEntry" />
        </div>
        <div
          v-if="loadingMore"
          class="_spinner"
        >
          <m-spinner size="xs" />
        </div>
      </m-endless-scroll-list>
    </div>
  </div>
</template>

<script>
import FeedEntryItem from '@/components/inbox/FeedEntryItem.vue';
import useInbox from '@/composables/inbox/inbox';
import { feedEntryList } from '@/api/query/nebula/feed-entry';
import { feedEntryType } from 'shared/constants.json';
import { sortByAttr, sortDatetime, sortReverse } from 'shared/lib/sort';

export default {
  name: 'NotificationFeed',
  emits: ['close'],
  components: { FeedEntryItem },
  setup() {
    const { types: inboxTypes, notifications, tasks, query, unreadTaskCount, unreadNotificationCount } = useInbox();
    return { inboxTypes, notifications, tasks, query, unreadTaskCount, unreadNotificationCount };
  },
  data() {
    return {
      activeTab: 'tasks',
      allLoaded: {
        notifications: false,
        tasks: false,
        all: false,
      },
      loadingMore: false,
      maxCounter: 50,
    };
  },
  computed: {
    notificationBadge() {
      if (this.unreadNotificationCount === 0) {
        return '';
      }

      if (this.unreadNotificationCount > this.maxCounter) {
        return `${this.maxCounter}+`;
      }

      return `${this.unreadNotificationCount}`;
    },
    items() {
      const sorter = sortReverse(sortByAttr('createdAt', sortDatetime));

      let entries = [];
      switch (this.activeTab) {
        case 'notifications':
          entries = this.notifications;
          break;
        case 'tasks':
          entries = this.tasks;
          break;
        default:
          entries = [...this.tasks, ...this.notifications];
      }
      return entries.filter((feedEntry) => !this.containsDeletedData(feedEntry)).sort(sorter);
    },
    badgeColor() {
      if (this.unreadActionNotifications.length === 0) {
        return this.$colors.grey.darken1;
      }

      return '';
    },
    readActionNotifications() {
      return this.tasks.filter((feedEntry) => feedEntry.readAt !== null);
    },
    unreadActionNotifications() {
      return this.tasks.filter((feedEntry) => feedEntry.readAt === null);
    },
    unfulfilledTasks() {
      return [...this.readActionNotifications, ...this.unreadActionNotifications].filter((feedEntry) => feedEntry.fulfilledAt === null && !this.containsDeletedData(feedEntry));
    },
    types() {
      if (this.activeTab === 'all') {
        return Object.values(this.inboxTypes).flat();
      }
      return this.inboxTypes[this.activeTab];
    },
    offset() {
      if (this.activeTab === 'all') {
        return [...this.tasks, ...this.notifications].length;
      }

      return this[this.activeTab].length;
    },
  },
  methods: {
    loadMore() {
      if (this.allLoaded[this.activeTab] || this.items.length === 0) {
        return;
      }

      const countAlias = `${this.activeTab}Count`;
      this.loadingMore = true;
      const page = Math.ceil(this.offset / 10 + 1);
      this.query(
        feedEntryList(
          {
            pagination: { itemsPerPage: 10, page, countAlias },
            alias: this.activeTab,
            types: this.types,
          },
        ),
      ).then((data) => {
        this.loadingMore = false;
        if (data[countAlias][0].count === this.offset) {
          this.allLoaded[this.activeTab] = true;
        }
      });
    },
    containsDeletedData(feedEntry) {
      if ([feedEntryType.updateNotification].includes(feedEntry.type) && feedEntry.notification === null) {
        return true;
      }
      if ([feedEntryType.survey].includes(feedEntry.type) && feedEntry.form === null) {
        return true;
      }
      if ([feedEntryType.goalPropertyValueReferenced, feedEntryType.goalUpdate, feedEntryType.goalComment, feedEntryType.goalUpdateMention].includes(feedEntry.type) && feedEntry.goal === null) {
        return true;
      }
      if ([feedEntryType.updateComment, feedEntryType.updateMention].includes(feedEntry.type) && feedEntry.update === null) {
        return true;
      }
      if ([feedEntryType.goalUpdateComment].includes(feedEntry.type) && (feedEntry.goal === null || feedEntry.update === null)) {
        return true;
      }

      return false;
    },
    badge(feedEntries) {
      if (feedEntries.length === 0) {
        return '';
      }

      const unread = feedEntries.filter((fe) => fe.readAt === null);
      if (unread.length > 0) {
        return unread.length;
      }

      return feedEntries.length;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .notification-feed {
    ._header {
      padding: 0 1.2rem;
      border-bottom: 1px solid $border-color;
    }

    ._body {
      max-height: calc(var(--viewport-height-100) - 30rem);
      overflow-x: hidden;
      overflow-y: auto;

      ._empty {
        padding: 1.6rem;
        color: $font-color-secondary;
      }

      ._item {
        padding: 1.2rem 1.2rem 1.2rem .6rem;
        border-bottom: 1px solid $border-color;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &.-mobile {
      ._body {
        max-height: calc(var(--viewport-height-100) - 11.2rem);
      }
    }

    ._spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 6rem;
    }
  }
</style>
