import useRepo from '@/nebula/repo';
import { RESULT, UID } from 'shared/api/query/constants';
import { customDatasourceProperty } from 'shared/constants.json';
import {
  dataSource as dataSourceConfig,
} from 'shared/api/query/configs.json';
import { ref } from 'vue';

export default function useSpreadsheetDatasourceRepo() {
  const repo = useRepo(dataSourceConfig.model);

  const getSingleLoading = ref(false);
  const getSingle = (entity) => {
    getSingleLoading.value = true;
    return repo.query(
      [{
        alias: RESULT,
        func: { name: UID },
        model: dataSourceConfig.model,
        uid: [entity.uid],
        children: [
          { attr: UID },
          { attr: customDatasourceProperty.dataSourceError, default: null },
          {
            attr: customDatasourceProperty.spreadsheetDocuments,
            filter: {
              func: {
                name: 'eq',
                attr: 'id',
                args: [{ value: entity[customDatasourceProperty.spreadsheetDocuments][0].id }],
              },
            },
            default: [],
          },
        ],
      }],
    ).then(() => repo.selectSingle(entity.uid)).finally(() => {
      getSingleLoading.value = false;
    });
  };

  const navigateFolderLoading = ref(false);
  const navigateFolder = (entity, folder) => {
    navigateFolderLoading.value = true;
    return repo.query([{
      alias: RESULT,
      func: { name: UID },
      model: dataSourceConfig.model,
      uid: [entity.uid],
      children: [
        { attr: UID },
        { attr: customDatasourceProperty.dataSourceError, default: null },
        {
          attr: customDatasourceProperty.spreadsheetDocuments,
          filter: { func: { name: 'eq', attr: 'itemId', args: [{ value: folder.id }] } },
          default: [],
        },
      ],
    }]).then(() => repo.selectSingle(entity.uid)).finally(() => {
      navigateFolderLoading.value = false;
    });
  };

  return {
    getSingleLoading,
    getSingle,
    navigateFolderLoading,
    navigateFolder,
  };
}
