<template>
  <m-dropdown
    v-model:value="showNotifications"
    class="inbox"
    :title="$t('mainNavigation.inbox')"
    block
    :style="{ display: 'block' }"
    placement="topRight"
  >
    <nav-item
      :title="$t('mainNavigation.inbox')"
      icon="inbox"
      :active="showNotifications"
      :badge="badge"
      hover-color="beige"
      @click="showNotifications = true"
    />
    <template #overlay>
      <m-card
        no-padding
        :class="['_notifications-card', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
      >
        <notification-feed
          @close="showNotifications = false"
        />
      </m-card>
    </template>
  </m-dropdown>
</template>
<script>
import NavItem from '@/components/navigation/NavItem.vue';
import useInbox from '@/composables/inbox/inbox';
import { EventBus } from '@/lib/event-bus';
import { defineAsyncComponent } from 'vue';

export default {
  name: 'Inbox',
  components: {
    NavItem,
    NotificationFeed: defineAsyncComponent(() => import('@/components/inbox/NotificationFeed.vue')),
  },
  setup() {
    const {
      countUnread,
      markAsRead,
      notifications,
      tasks,
      unreadCount,
    } = useInbox();
    return {
      countUnread,
      markAsRead,
      notifications,
      tasks,
      unreadCount,
    };
  },
  data() {
    return { showNotifications: false, maxCounter: 50 };
  },
  computed: {
    badge() {
      if (this.unreadCount === 0) {
        return '';
      }

      if (this.unreadCount > this.maxCounter) {
        return `${this.maxCounter}+`;
      }

      return `${this.unreadCount}`;
    },
  },
  methods: {
    doReadNotificationFeed() {
      this.markAsRead().catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    pollNotifications() {
      this.countUnread().catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
  watch: {
    showNotifications(val) {
      if (!val) {
        this.doReadNotificationFeed();
      }
    },
  },
  created() {
    this.pollNotifications();
    EventBus.$on('show-notifications', () => {
      this.showNotifications = true;
    });
  },
  beforeUnmount() {
    EventBus.$off('show-notifications', () => {
      this.showNotifications = true;
    });
  },
  unmounted() {
    if (this.showNotifications) {
      this.doReadNotificationFeed();
    }
  },
};

</script>
<style
    scoped
    lang="scss"
    type="text/scss"
>

  ._notifications-card {
    width: 50rem;
    max-height: calc(var(--viewport-height-100) - 22rem);
  }
</style>
