<template>
  <div :class="['m-dropdown', block ? '-block' : '']">
    <div
      ref="trigger"
      :class="['_trigger']"
      :style="triggerStyle"
      @click="handleClick"
      @mouseenter="handleMouseEnter"
    >
      <slot />
    </div>
    <teleport
      v-if="val"
      to="#overlay"
    >
      <transition
        :name="transitionName"
        mode="out-in"
      >
        <div
          v-if="valInner"
          ref="overlay"
          :class="['_dropdown-overlay', val ? '-visible' : '', `-${kebabCase(placement)}`, fullscreen ? '-fullscreen' : '', triggerType === 'hover' ? '-hover' : '']"
          @mousedown.stop="handleOverlayClick"
        >
          <div
            ref="inner"
            :style="contentStyle"
            class="__inner"
            tabindex="0"
            @click="handleContentClick"
            @keydown="$emit('keydown', $event)"
            @mousedown.stop
          >
            <m-content
              v-if="fullscreen"
              padding-small
              class="_dropdown-header"
            >
              <div class="__inner">
                <div class="_left" />
                <div class="_center">
                  {{ title }}
                </div>
                <div class="_right">
                  <m-link
                    @click="close"
                  >
                    {{ $t('general.close') }}
                  </m-link>
                </div>
              </div>
            </m-content>
            <slot name="overlay" />
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import kebabCase from 'lodash-es/kebabCase';
import { modalSizes } from 'shared/modal-sizes';
import { upToContainerWithClass } from 'shared/lib/dom';
/* eslint-disable import/no-unresolved */
import { Transition, nextTick } from 'vue';
/* eslint-enable import/no-unresolved */
import { breakpoints } from 'shared/breakpoints';
import { debounce } from 'lodash-es';
import { guid } from 'shared/lib/uuid';

const distanceFromTrigger = 5;
const transitionDuration = window.Cypress ? 0 : 300;

export default {
  name: 'MDropdown',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottomCenter',
    },
    title: {
      type: String,
      required: true,
    },
    trigger: {
      type: String,
      default: 'click',
      validator: (value) => ['click', 'hover'].includes(value),
    },
    triggerContainerClass: {
      type: String,
      default: 'm-card',
    },
    block: {
      type: Boolean,
      default: false,
    },
    matchTriggerWidth: {
      type: Boolean,
      default: false,
    },
    closeOnClick: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentMayResize: {
      type: Boolean,
      default: false,
    },
    triggerStyle: {
      type: Object,
      default: () => {},
    },
    relocateKey: {
      type: [String, Number, Boolean, Array, Object],
      default: '',
    },
    stopTriggerEventPropagation: {
      type: Boolean,
      default: false,
    },
    keepOpenOnMaskClick: {
      type: Boolean,
      default: false,
    },
    fullscreenOnMobile: {
      type: Boolean,
      default: true,
    },
    getTriggerDimensions: {
      type: Function,
      default: (trigger) => {
        if (trigger === undefined || trigger === null) {
          return null;
        }
        return trigger.getBoundingClientRect();
      },
    },

  },
  emits: ['hide', 'input', 'update:value', 'change', 'overlay-clicked', 'keydown'],
  components: { Transition },
  data() {
    return {
      val: false,
      valInner: false,
      current: [],
      id: guid(),
      overlayWidth: 0,
      overlayHeight: 0,
      windowWidth: 0,
      windowHeight: 0,
      triggerDimensions: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
      hoverCloseArea: null,
      debouncedClose: null,
      debouncedOpen: null,
      debouncedSetVal: null,
    };
  },
  computed: {
    transitionName() {
      return window.Cypress || this.trigger === 'hover' || this.$store.state.breakpoint.smAndDown ? 'none' : 'dropdown-bounce';
    },
    triggerType() {
      if (this.$store.state.breakpoint.smAndDown) {
        return 'click';
      }
      return this.trigger;
    },
    fullscreen() {
      return this.windowWidth <= breakpoints.sm && this.fullscreenOnMobile;
    },
    contentStyle() {
      return this.fixVerticalPosition(this.fixHorizontalPosition(this.overlayStyle));
    },
    overlayStyle() {
      if (this.fullscreen) {
        return {
          top: '0px',
          left: '0px',
          position: 'fixed',
          width: '100vw',
          height: 'var(--viewport-height-100)',
          overflow: 'auto',
        };
      }

      let width = null;
      if (this.matchTriggerWidth) {
        width = `${this.triggerDimensions.width}px`;
      }
      const top = this.triggerDimensions.y + this.triggerDimensions.height + distanceFromTrigger;
      const style = {
        top: `${top}px`,
        position: 'fixed',
        width,
        maxWidth: '100vw',
      };

      if (this.placement === 'topRight') {
        return {
          ...style,
          top: `${this.triggerDimensions.y - distanceFromTrigger}px`,
          left: `${this.triggerDimensions.x + this.triggerDimensions.width + distanceFromTrigger}px`,
        };
      }
      if (this.placement === 'aboveRight') {
        return {
          ...style,
          top: `${this.triggerDimensions.y - distanceFromTrigger - this.overlayHeight}px`,
          left: `${this.triggerDimensions.x + this.triggerDimensions.width}px`,
        };
      }
      if (this.placement === 'topLeft') {
        return {
          ...style,
          top: `${this.triggerDimensions.y - distanceFromTrigger}px`,
          left: `${this.triggerDimensions.x - this.overlayWidth - distanceFromTrigger}px`,
        };
      }
      if (this.placement === 'bottomLeft') {
        return {
          ...style,
          top: `${this.triggerDimensions.y + this.triggerDimensions.height + distanceFromTrigger}px`,
          left: `${this.triggerDimensions.x}px`,
        };
      }

      if (this.placement === 'bottomRight') {
        return {
          ...style,
          top: `${this.triggerDimensions.y + this.triggerDimensions.height + distanceFromTrigger}px`,
          left: `${this.triggerDimensions.x - (this.overlayWidth - this.triggerDimensions.width) - distanceFromTrigger}px`,
        };
      }

      if (this.placement === 'onTopCenter') {
        return {
          top: `${this.triggerDimensions.y}px`,
          left: `${this.triggerDimensions.x - (this.overlayWidth / 2 - this.triggerDimensions.width / 2)}px`,
          position: 'fixed',
          width,
        };
      }

      if (this.placement === 'onTopLeft') {
        return {
          top: `${this.triggerDimensions.y}px`,
          left: `${this.triggerDimensions.x}px`,
          position: 'fixed',
          width,
        };
      }

      // default bottomCenter
      const distanceLeft = this.triggerDimensions.x - (this.overlayWidth / 2 - this.triggerDimensions.width / 2);
      const distanceRight = this.windowWidth - distanceLeft - this.overlayWidth;
      if (distanceRight < distanceLeft) {
        if (this.matchTriggerWidth) {
          return {
            ...style,
            right: `${this.windowWidth - (this.triggerDimensions.x + this.triggerDimensions.width)}px`,
          };
        }
        return {
          ...style,
          right: `${Math.max(15, distanceRight)}px`,
        };
      }
      if (this.matchTriggerWidth) {
        return {
          ...style,
          left: `${this.triggerDimensions.x}px`,
        };
      }
      return {
        ...style,
        left: `${Math.max(15, distanceLeft)}px`,
      };
    },
  },
  methods: {
    kebabCase,
    fixHorizontalPosition(style) {
      const left = parseInt(style.left, 10);
      if (left < modalSizes.margin) {
        style.left = `${modalSizes.margin}px`;
      }

      const distanceRight = left + this.overlayWidth;
      if (distanceRight > this.windowWidth) {
        style.left = `${this.windowWidth - this.overlayWidth - modalSizes.margin}px`;
      }

      return style;
    },
    fixVerticalPosition(style) {
      if (this.fullscreen) {
        return {
          ...style,
          top: 0,
          left: 0,
        };
      }

      const top = parseInt(style.top, 10);
      const bottom = top + this.overlayHeight;
      const missing = this.windowHeight - bottom;

      if (missing > 0) {
        return style;
      }

      return {
        ...style,
        top: `${top + missing - modalSizes.margin}px`,
      };
    },
    handleMouseMove(event) {
      const handle = () => {
        if (event.clientX <= Math.floor(this.triggerDimensions.x) || event.clientX >= Math.ceil(this.triggerDimensions.x + this.triggerDimensions.width) || event.clientY <= Math.floor(this.triggerDimensions.y) || event.clientY >= Math.ceil(this.triggerDimensions.y + this.triggerDimensions.height)) {
          this.close();
          this.hoverCloseArea?.removeEventListener('mousemove', this.handleMouseMove);
          this.hoverCloseArea = null;
        }
      };

      if (this.debouncedOpen !== null) {
        this.debouncedOpen.cancel();
      }

      if (this.debouncedClose !== null) {
        this.debouncedClose.cancel();
      }

      this.debouncedClose = debounce(handle, 30);
      this.debouncedClose();
    },
    focus() {
      if (this.$refs.inner === null) {
        return;
      }

      this.$refs.inner.focus();
    },
    handleClick(event) {
      if (this.triggerType !== 'click') {
        return;
      }

      if (this.stopTriggerEventPropagation) {
        event.stopPropagation();
      }

      this.open();
    },
    handleMouseEnter(event) {
      if (this.triggerType !== 'hover') {
        return;
      }

      if (this.stopTriggerEventPropagation) {
        event.stopPropagation();
      }

      const handle = () => {
        this.open();
        this.$nextTick(() => {
          this.hoverCloseArea = upToContainerWithClass(this.$refs.trigger, this.triggerContainerClass);
          this.hoverCloseArea.addEventListener('mousemove', this.handleMouseMove);
        });
      };

      if (this.debouncedClose !== null) {
        this.debouncedClose.cancel();
      }

      if (this.debouncedOpen !== null) {
        this.debouncedOpen.cancel();
      }

      this.debouncedOpen = debounce(handle, 300);
      this.debouncedOpen();
    },
    open() {
      if (this.disabled) {
        return;
      }

      if (this.debouncedSetVal !== null) {
        this.debouncedSetVal.cancel();
      }

      this.val = true;
      this.$nextTick(() => {
        this.setTriggerDimensions();
        this.valInner = true;
        this.$nextTick(() => {
          if (typeof this.$refs.inner !== 'undefined' && this.$refs.inner !== null) {
            this.recalculateOverlaySize();
            this.$refs.inner.addEventListener('keydown', this.handleKeyDown);
            this.$refs.inner.focus();
          }
        });
      });
    },
    close() {
      // We need to wait for the animation to finish before setting the value to false
      const updateVal = () => {
        this.val = false;
      };
      this.debouncedSetVal = debounce(updateVal, transitionDuration);
      this.debouncedSetVal();

      if (typeof this.$refs.inner !== 'undefined' && this.$refs.inner !== null) {
        this.$refs.inner.removeEventListener('keydown', this.handleKeyDown);
      }

      this.$emit('hide');
      this.valInner = false;
    },
    calculateWindowSize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    handleOverlayClick() {
      this.$emit('overlay-clicked');
      if (this.keepOpenOnMaskClick) {
        return;
      }
      this.close();
    },
    handleContentClick(event) {
      if (this.closeOnClick) {
        this.close();
        return;
      }

      event.stopPropagation();
    },
    setTriggerDimensions() {
      const d = this.getTriggerDimensions(this.$refs.trigger);
      if (d === null) {
        return;
      }

      this.triggerDimensions = {
        x: d.x,
        y: d.y,
        width: d.width,
        height: d.height,
      };
    },
    recalculateOverlaySize() {
      this.setOverlaySize();
      this.setTriggerDimensions();
    },
    handleKeyDown(event) {
      if (event.key === 'Escape') {
        event.stopPropagation();
        this.close();
      }
    },
    setOverlaySize(callNum = 1) {
      if (this.$refs.inner === null || typeof this.$refs.inner === 'undefined') {
        return;
      }
      if (this.$refs.inner.clientWidth === 0 && callNum < 10) {
        nextTick(() => this.setOverlaySize(callNum + 1));
        return;
      }
      this.overlayWidth = this.$refs.inner.clientWidth;
      if (this.contentMayResize) {
        this.overlayHeight = window.innerHeight / 2 - 100;
        return;
      }
      this.overlayHeight = this.$refs.inner.clientHeight;
    },
  },
  watch: {
    relocateKey() {
      // Since we use vue-portal, $refs are only available in the second flush of the rendering queue: https://github.com/LinusBorg/portal-vue/issues/119
      nextTick(() => {
        nextTick(() => {
          this.recalculateOverlaySize();
        });
      });
    },
    val(val) {
      if (val !== this.value) {
        this.$emit('input', val);
        this.$emit('update:value', val);
        this.$emit('change', val);
      }
    },
    value(val) {
      if (this.val === val) {
        return;
      }

      if (!val) {
        this.close();
        return;
      }

      if (this.disabled) {
        return;
      }

      this.open();
    },
  },
  created() {
    if (this.value) {
      this.open();
    }
  },
  mounted() {
    this.calculateWindowSize();
    window.addEventListener('resize', this.calculateWindowSize);
    window.addEventListener('resize', this.setTriggerDimensions);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateWindowSize);
    window.removeEventListener('resize', this.setTriggerDimensions);
  },
};
</script>

<style
    lang="scss"
    type="text/scss"
>
  @import "shared/assets/scss/padding";
  @import "shared/assets/scss/box-shadow";

  .m-dropdown {
    position: relative;
    display: inline-flex;
    min-height: inherit;

    ._trigger {
      display: inline-flex;
      width: 100%;
      min-height: inherit;
      color: $font-color-primary;

      &:focus {
        outline: none;
      }
    }

    &.-block {
      display: flex;
      width: 100%;

      ._trigger {
        display: flex;
        flex: 1 1 auto;
      }
    }
  }

  ._dropdown-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: var(--viewport-height-100);
    overflow: hidden;

    &.-hover {
      pointer-events: none;
    }

    &:focus {
      outline: none;
    }

    .__inner {
      display: none;
      pointer-events: auto;
      backface-visibility: hidden;

      &.-scrolling {
        @include box_shadow(2);

        border-radius: $border-radius-sm;
      }
    }

    &.-on-top-left,
    &.-on-top-center {
      .__inner {
        animation: none;
      }
    }

    &.-visible {
      .__inner {
        display: block;
        opacity: 1;

        &:focus {
          outline: none;
        }

        & > .m-card {
          max-height: calc(var(--viewport-height-100) - 6rem);
          overflow: auto;
        }
      }

      &.-fullscreen {
        ._dropdown-header {
          height: 6rem;
          background-color: white;
          border-bottom: 1px solid $border-color;

          .__inner {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            backface-visibility: hidden;

            ._left {
              flex: 0 0 25%;
            }

            ._center {
              display: block;
              flex: 1 1 auto;
              max-width: 80%;
              overflow: hidden;
              font-size: $font-size-5;
              font-weight: $font-weight-bold;
              text-align: center;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            ._right {
              right: 0;
              display: flex;
              flex: 0 0 25%;
              justify-content: flex-end;
              margin-left: auto;
            }
          }
        }

        .__inner > .m-card {
          width: 100vw;
          max-width: 100vw;
          height: calc(var(--viewport-height-100) - 6rem);
          max-height: var(--viewport-height-100);
          border-radius: 0;
          box-shadow: none;

          &.-list {
            background-color: $side-nav-background-color;

            &:not(._m-select-overlay) {
              padding-top: 2rem;
            }
          }

          .m-card-item {
            &:first-of-type {
              width: 100%;
              border-top: 1px solid $border-color;
            }
          }
        }
      }
    }
  }
</style>
