<template>
  <div
    :data-draggable-id="index"
    :data-id="entity.uid"
    :class="rowClasses"
    @contextmenu.prevent="$emit('context-menu-click', $event)"
    @click="handleRowClick"
  >
    <div
      v-if="showDragOverTopTargets"
      class="_top-alignment"
      :style="topDragTargetStyle"
    >
      <div
        class="_bar"
        :style="{ backgroundColor: $colors.blue.lighten1 }"
      />
    </div>
    <div class="_content">
      <div
        class="_checkbox"
      >
        <m-checkbox
          v-if="(showSelectBox || isSelected) && !isDisabled"
          :value="isSelected"
          small
          @mousedown.stop="handleCheckBoxClick"
          @click.stop
        />
      </div>
      <div
        v-for="column in props"
        :key="column.key"
        :class="['_item', column.hideBorder ? '-hide-border' : '', column.isTitle ? '-is-title' : '', Object.keys(errorMessages).length > 0 ? '-has-error' : '', canEditCell(column) ? '-can-edit-cell' : '', isColumnHighlighted(column) ? '-highlighted' : '']"
        :style="columnStyle(column)"
        @click="focusInput($event, column)"
      >
        <template v-if="column.isTitle">
          <m-dropdown
            :value="isInlineEditing"
            placement="onTopLeft"
            match-trigger-width
            block
            :relocate-key="localTitle"
            :title="$t('cascadeTableRow.editTitle')"
            @hide="hideEditTitle(column)"
          >
            <div
              ref="title"
              class="_title"
              :style="titleStyle(column)"
              @click.stop="handleRowClick"
            >
              <div
                v-for="i in indentationLevel"
                :key="i"
                class="_indentation-elements"
                :style="alignItemStyle"
              >
                <div
                  v-if="showDragOverBottomTargets && !expanded && isLastItem"
                  class="_bar -align-top"
                  :style="alignItemBarStyle(i - 1)"
                />
              </div>
              <div
                class="_expand"
                @mouseenter="scheduleExpand"
                @mouseleave="cancelExpand"
              >
                <m-btn
                  hide-border
                  small
                  fab
                  :disabled="!hasChildren"
                  @mouseover="scheduleExpand"
                  @mouseleave="cancelExpand"
                  @click.stop="toggleExpand"
                  @mousedown.stop
                >
                  <!--m-spinner class is needed to detect loading state for pdf export-->
                  <m-icon
                    :type="expandIconType"
                    :spin="loading"
                    :class="['_icon', loading ? 'm-spinner' : '']"
                    :style="expandIconStyle"
                  />
                </m-btn>
              </div>
              <item-title
                :title="entity.title"
                :icons="[{ value: goalTypeIcon, showBackground: true, size: 18, tooltip: goalTypeIconTooltip }, { value: icon }]"
                :wrap-title="wrapCells"
                class="_title-inner"
              />
              <div
                class="_title-actions"
              >
                <m-tooltip
                  v-if="canEditInline"
                  class="_edit-btn"
                >
                  <m-btn
                    small
                    fab
                    light
                    hide-border
                    icon="edit"
                    @click.stop="editTitle"
                  />
                  <template #title>
                    {{ $t('cascadeTableRow.editTitle') }}
                  </template>
                </m-tooltip>
                <error-indicator-dropdown
                  class="_error-btn"
                  :can-edit="canEditInline"
                  :error-messages="errorMessages"
                  :has-errors="hasErrors"
                  :goal="entity"
                  :update-property="updateProperty"
                  @select="$emit('select-row', entity )"
                />
              </div>
            </div>
            <template #overlay>
              <m-card
                no-padding
                class="_overlay"
                :style="editingTitleCardStyle"
                border-radius="small"
              >
                <div
                  class="_inner"
                >
                  <simple-editor
                    :initial-value="entity.title"
                    :placeholder="$t('list.addTitle')"
                    full-width
                    auto-focus
                    :read-only="false"
                    @enter="blur"
                    @escape="blur"
                    @blur="blur"
                    @update:value="updateTitle"
                  />
                </div>
              </m-card>
            </template>
          </m-dropdown>
        </template>
        <component-prop
          v-if="column.property.component !== undefined"
          :entity="entity"
          :prop="column"
          align-diff-right
          :show-progress-diff="showProgressDiff"
          :read-only="readOnly"
          :style="{ padding: '0 .8rem', marginTop: '1rem' }"
          progress-bar-width="6rem"
          :progress-display-option="progressDisplayOption"
          :progress-course-diff="progressCourseDiff"
          @click="handlePropClick(column)"
        />
        <div
          v-else-if="column.property.type === propertyType.status"
          :style="{ display: 'flex' }"
        >
          <status-prop
            :style="{ padding: '0 .8rem', marginTop: '.8rem' }"
            :goal="entity"
            @click="handlePropClick(column)"
          />
          <status-auto-update-hint
            v-if="entity.disableStatusAutoUpdate && entity.progressMeasurement === alignedItems"
            :goal="entity"
            :style="{ marginTop: '.8rem' }"
          />
        </div>
        <goal-property-form-item
          v-else-if="!column.isTitle"
          :ref="`${column.key}`"
          :property="column.property"
          :goal-picker-service="{}"
          :update-properties="(val) => updateProperty(val, column.property, entity)"
          :wrap="wrapCells"
          full-width
          popup
          hide-hover
          hide-border
          hide-placeholder
          :goal="entity"
          :read-only="readOnly || !canEdit || column.property.type === propertyType.goalRelation"
          @blur="highlightCell(column)"
        />
      </div>
      <div
        v-if="hasEmptyRow"
        class="_empty-row"
      />
      <div
        :style="propertyColumnStyle"
      />
    </div>
    <div
      v-if="showDragOverBottomTargets && (!expanded || !hasChildren)"
      class="_bottom-alignment"
      :style="bottomDragTargetStyle"
    >
      <div
        class="_bar"
        :style="{ backgroundColor: $colors.blue.lighten1 }"
      />
    </div>
    <m-dialog
      v-if="showUpdateProgressModal"
      v-model:value="showUpdateProgressModal"
      hide-footer
      :fullscreen-on-mobile="false"
      :body-style="{ overflow: 'visible' }"
      :card-style="{ overflow: 'visible' }"
      :max-width="$modalSizes.lg"
    >
      <goal-update-editor
        ref="goalUpdateEditor"
        allow-goal-activity
        :goal="entity"
        :goal-children="[entity]"
        auto-add-goal-activity
        auto-focus
        @created="showUpdateProgressModal = false"
      />
    </m-dialog>
  </div>
</template>

<script>
import ComponentProp from '@/components/list/ComponentProp.vue';
import ErrorIndicatorDropdown from '@/components/goal/ErrorIndicatorDropdown.vue';
import GoalPropertyFormItem from '@/components/goal/GoalPropertyFormItem.vue';
import GoalUpdateEditor from '@/components/goal/GoalUpdateEditor.vue';
import ItemTitle from '@/components/ItemTitle.vue';
import SimpleEditor from '@/components/SimpleEditor.vue';
import StatusAutoUpdateHint from '@/components/goal/StatusAutoUpdateHint.vue';
import StatusProp from '@/components/property/StatusProp.vue';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { CREATED_AT } from 'shared/api/query/constants';
import { GOAL_RELATION, TIME_DIFF } from '@/lib/props/custom-types';
import { INDENTATION_WIDTH } from 'shared/constants';
import { accessPolicyType, goalProgressMeasurement, propertyType } from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { logCatch } from '@/lib/logger/logger';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'GoalCascadeTableItem',
  props: {
    entityId: {
      type: Number,
      required: true,
    },
    guid: {
      type: String,
      required: true,
    },
    hasChildren: {
      type: Boolean,
      required: true,
    },
    isParent: {
      type: Boolean,
      required: true,
    },
    isLastItem: {
      type: Boolean,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    updateProperty: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    indentationLevel: {
      type: Number,
      default: 0,
    },
    dragDepth: {
      type: Number,
      default: 0,
    },
    showDragOverTargets: {
      type: Boolean,
      default: false,
    },
    showDragOverTopTargets: {
      type: Boolean,
      default: false,
    },
    showDragOverBottomTargets: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Array,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
    highlightedColumns: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hasEmptyRow: {
      type: Boolean,
      default: false,
    },
    itemClass: {
      type: String,
      required: true,
    },
    validate: {
      type: Function,
      default: () => ({}),
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    wrapCells: {
      type: Boolean,
      default: false,
    },
    isInlineEditing: {
      type: Boolean,
      default: false,
    },
    showProgressDiff: {
      type: Boolean,
      default: false,
    },
    progressCourseDiff: {
      type: Array,
      default: () => [],
    },
    fillerColumnWidth: {
      type: Number,
      default: 40,
    },
    showSelectBox: {
      type: Boolean,
      default: false,
    },
    progressDisplayOption: { type: String, default: undefined },
  },
  emits: ['set-inline-editing', 'reset-inline-editing', 'on-click', 'context-menu-click', 'select-row', 'schedule-expand', 'cancel-expand', 'toggle-expand', 'highlight-column'],
  components: {
    GoalUpdateEditor,
    ErrorIndicatorDropdown,
    ItemTitle,
    GoalPropertyFormItem,
    SimpleEditor,
    StatusAutoUpdateHint,
    StatusProp,
    ComponentProp,
  },
  setup() {
    const { properties: goalProperties } = useGoalProperty();
    const { goalTypeIcon, goalTypeOption } = useGoalTypeProperty();
    const { userLang } = useLoggedInUser();
    const { updateSingle, selectSingle } = useGoals();
    return {
      goalProperties,
      userLang,
      goalTypeIconFn: goalTypeIcon,
      goalTypeOption,
      updateSingle,
      selectSingle,
    };
  },
  data() {
    return {
      propertyType: { ...propertyType, goalRelation: GOAL_RELATION },
      TIME_DIFF,
      localTitle: '',
      focusedColumn: '',
      alignedItems: goalProgressMeasurement.alignedItems,
      showUpdateProgressModal: false,
      showContextMenu: false,
      accessPolicyType,
    };
  },
  computed: {
    rowClasses() {
      return [
        'cascade-table-row',
        this.itemClass,
        this.wrapCells ? '-wrap' : '',
        this.isSelected || this.showDragOverTargets ? '-selected' : '',
        this.isSelected && this.entity.accessRight === accessPolicyType.read ? '-selected-secondary' : '',
        this.isDisabled ? '-disabled' : '',
        this.isInlineEditing ? '-inline-edit-mode' : '',
        this.isParent ? '-is-parent' : '',
        this.canEditInline ? '-can-edit-inline' : '',
        this.isHighlighted ? '-is-highlighted' : '',
      ];
    },
    entity() {
      return this.selectSingle(this.entityId);
    },
    errorMessages() {
      if (!this.showErrors) {
        return {};
      }
      return this.validate({ toValidate: this.entity, selectRulesFrom: this.entity });
    },
    canComment() {
      return !this.readOnly && this.entity.publishedAt !== null && [accessPolicyType.full, accessPolicyType.write, accessPolicyType.comment].includes(this.entity.accessRight);
    },
    goalTypeIcon() {
      return this.goalTypeIconFn(this.entity);
    },
    goalTypeIconTooltip() {
      const type = this.goalTypeOption(this.entity);
      if (type === null) {
        return '';
      }
      return textByLang(type.label, this.userLang);
    },
    topDragTargetStyle() {
      return {
        position: 'absolute',
        top: '-2px',
        left: 0,
        height: '4px',
        width: `calc(100% - ${this.indentationLevel * INDENTATION_WIDTH}px)`,
        marginLeft: `${this.indentationLevel * 4}rem`,
      };
    },
    bottomDragTargetStyle() {
      return {
        position: 'absolute',
        bottom: '-2px',
        left: 0,
        height: '4px',
        width: `calc(100% - ${this.indentationLevel * INDENTATION_WIDTH}px)`,
        marginLeft: `${this.indentationLevel * 4}rem`,
      };
    },
    alignItemStyle() {
      return {
        minWidth: `${INDENTATION_WIDTH}px`,
        width: `${INDENTATION_WIDTH}px`,
        height: '100%',
        position: 'relative',
      };
    },
    editingTitleCardStyle() {
      if (this.$refs.title) {
        return { minHeight: `${this.$refs.title.offsetHeight}px` };
      }
      return {};
    },
    hasErrors() {
      return Object.keys(this.errorMessages).length > 0;
    },
    canEdit() {
      return !this.readOnly && [accessPolicyType.full, accessPolicyType.write].includes(this.entity.accessRight);
    },
    canEditInline() {
      return !this.readOnly && this.canEdit && !this.$store.state.breakpoint.smAndDown;
    },
    expandIconStyle() {
      if (!this.expanded) {
        return { marginLeft: '.2rem' };
      }
      return '';
    },
    expandIconType() {
      if (!this.hasChildren) {
        return 'dot';
      }
      if (this.loading) {
        return 'loading';
      }
      if (this.expanded) {
        return 'chevron-down';
      }
      return 'chevron-right';
    },
    icon() {
      return buildIconFromEntity(this.entity);
    },
    propertyColumnStyle() {
      return { flex: `0 0 ${this.fillerColumnWidth}px`, width: `${this.fillerColumnWidth}px` };
    },
  },
  methods: {
    highlightCell(column) {
      this.$emit('highlight-column', column, this.index);
    },
    isColumnHighlighted(column) {
      return this.highlightedColumns.includes(column.key);
    },
    canEditCell(column) {
      if (!this.canEditInline) {
        return false;
      }

      if (column.property.type === this.propertyType.lookup
          || column.property.type === this.propertyType.goalRelation
          || column.key === goalConfig.edges.cachedLastUpdatedAt
          || column.key === CREATED_AT
      ) {
        return false;
      }
      return !(this.entity.progressMeasurement === goalProgressMeasurement.none
          && ([this.propertyType.status].includes(column.property.type) || column.key === goalConfig.edges.cachedCalculatedCurrent));
    },
    focusInput(event, column) {
      if (this.canEdit) {
        event.stopPropagation();
      }
      if (column.isTitle) {
        this.handleRowClick();
      }
      this.focusedColumn = '';
      if (column.property.type === this.propertyType.goalRelation) {
        return;
      }
      if (column.property.component !== undefined) {
        this.handlePropClick(column);
        return;
      }
      if (column.property.type === this.propertyType.lookup) {
        return;
      }
      if (column.property.type === this.propertyType.status) {
        this.handlePropClick(column);
        return;
      }
      if (this.$refs[`${column.key}`][0] === undefined) {
        return;
      }
      this.$refs[`${column.key}`][0].focus();
    },
    alignItemBarStyle(v) {
      if (this.dragDepth <= v) {
        return { backgroundColor: this.$colors.blue.lighten1 };
      }

      return { backgroundColor: 'transparent' };
    },
    titleStyle(column) {
      return { width: `${column.width}px` };
    },
    editTitle() {
      this.$emit('set-inline-editing');
    },
    updateTitle(val) {
      this.localTitle = val;
    },
    hideEditTitle(column) {
      this.saveTitle();
      this.highlightCell(column);
    },
    saveTitle() {
      if (this.entity.title === this.localTitle) {
        return;
      }
      this.updateSingle({ uid: this.entity.uid, title: this.localTitle }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    blur() {
      this.$emit('reset-inline-editing');
    },
    columnStyle(column) {
      return { flex: `0 0 ${column.width}px`, width: `${column.width}px` };
    },
    handleRowClick() {
      if (!this.readOnly) {
        this.$emit('on-click', this.entity);
        return;
      }
      if (this.selectable) {
        if (this.isDisabled) {
          return;
        }

        this.$emit('select-row', this.entity, true);
        return;
      }
      this.$emit('on-click', this.entity);
    },
    handlePropClick(prop) {
      if (this.readOnly || !this.canComment) {
        this.handleRowClick();
        return;
      }

      if (this.entity.progressMeasurement !== goalProgressMeasurement.none && ([goalConfig.edges.cachedCalculatedCurrent].includes(prop.key) || prop.property.type === propertyType.status)) {
        this.showUpdateProgressModal = true;
        this.highlightCell(prop);
      }
    },
    scheduleExpand() {
      this.$emit('schedule-expand', { index: this.index });
    },
    cancelExpand() {
      this.$emit('cancel-expand', { index: this.index });
    },
    toggleExpand() {
      this.$emit('toggle-expand', { index: this.index });
    },
    handleCheckBoxClick() {
      this.$emit('select-row', this.entity, true);
    },
  },
  created() {
    this.localTitle = this.entity.title;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
@import "shared/assets/scss/box-shadow";

$height: 4rem;

.cascade-table-row {
  position: relative;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid $border-color;

  ._bar {
    width: 100%;
    height: 100%;

    &.-align-top {
      position: absolute;
      bottom: -2px;
      left: 0;
      height: 4px;
    }
  }

  ._content {
    display: flex;
    align-items: center;
    min-width: 100%;
    min-height: $height;

    ._checkbox {
      display: flex;
      position: absolute;
      left: -2rem;
      top: 1.1rem;
    }

    ._empty-row {
      flex: 1 0 $height;
      min-width: $height;
    }

    ._item {
      position: relative;
      display: flex;
      align-items: flex-start;
      height: 100%;
      min-height: inherit;
      border-right: 1px solid $border-color;

      &:not(.-is-title) {
        overflow: hidden;
      }

      ._title {
        display: flex;
        align-items: flex-start;
        min-height: 4rem;

        ._title-inner {
          font-weight: $font-weight-medium;
          line-height: 1.6;
          padding: .8rem .8rem .8rem .4rem;
        }

        ._expand {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          width: 2.8rem;
          margin-top: .6rem;
          margin-left: .6rem;
          overflow: hidden;
          pointer-events: auto;

          ._icon {
            color: $font-color-secondary;
          }
        }

        ._title-actions {
          position: absolute;
          top: .6rem;
          right: .8rem;
          display: flex;
          align-items: center;

          ._edit-btn {
            display: none;
            background-color: white;
            border-radius: $btn-border-radius;

            @include box_shadow(1);
          }

          ._error-btn {
            background-color: map_get($red, 'lighten-5');
            border-radius: $btn-border-radius;
            margin-left: .4rem;
          }
        }

        &:hover {
          ._title-actions {
            ._error-btn {
              @include box_shadow(1);
            }

            @media (min-width: $screen-size-md) {
              ._edit-btn {
                display: flex;
              }
            }
          }
        }
      }

      &:hover {
        &.-can-edit-cell {
          &:not(.-highlighted) {
            background-color: $hover-color;
          }
        }
      }

      &.-is-title {
        align-items: stretch;

        &.-has-error {
          ._title {
            padding-right: 6rem;
          }
        }
      }

      &.-hide-border {
        border-right: none;
      }

      &.-highlighted {
        background-color: $highlighted-color-light;
        box-shadow: inset 0 0 0 2px $primary-color;
        border-radius: 2px;
      }

      &:last-of-type {
        border-right: none;
      }

      &:first-of-type {
        &:not(.-is-title) {
          padding-left: .6rem;
        }
      }
    }
  }

  &.-disabled {
    cursor: default;
    opacity: .5;
  }

  &:not(.-wrap) {
    max-height: $height;
  }

  &:hover {
    &:not(.-can-edit-inline) {
      background-color: $hover-color;
    }

  }

  &.-is-highlighted {
    ._content {
      background-color: $highlighted-color-light;
      box-shadow: inset 0 0 0 1px $primary-color;
    }
  }

  &.-selected-secondary {
    &:not(.-inline-edit-mode) {
      ._content {
        background-color: $highlighted-color-light;
      }
    }
  }

  &.-selected {
    &:not(.-inline-edit-mode) {
      ._content {
        background-color: $highlighted-color;
      }

    }
  }
}

._overlay {
  display: flex;
  align-items: center;
  min-height: 4rem;

  ._inner {
    display: flex;
    align-items: center;
    padding: .8rem;
    line-height: 1.6;
  }
}
</style>
