<template>
  <div class="success-view">
    <div class="_container">
      <thumbs-up />
      <div class="_bottom">
        <div class="_description">
          {{ $t('successView.thankYou') }}
        </div>
        <div class="_submit">
          <m-btn
            rounded
            large
            class="primary"
            to="/"
          >
            {{ $t('successView.goToHomescreen') }}
          </m-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThumbsUp from 'shared/components/ThumbsUp.vue';

export default {
  name: 'SuccessView',
  components: { ThumbsUp },
};
</script>

<style scoped lang="scss" type="text/scss">
  .success-view {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: var(--viewport-height-100);

    ._container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 1.6rem;

      ._bottom {
        animation: slide-up ease-in-out .8s;

        @keyframes slide-up {
          0% {
            transform: translateY(3rem);
          }

          100% {
            transform: translateY(0);
          }
        }
      }

      ._description {
        margin: 4rem 0 6rem;
        text-align: center;
      }

      ._submit {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .btn {
          @media only screen and (min-width: $screen-size-md) {
            margin-right: 2rem;
          }
        }
      }
    }
  }
</style>
