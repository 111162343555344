<template>
  <m-context-menu
    ref="contextMenu"
    class="spaces-context-menu"
    @hide="reset"
  >
    <m-card
      list
      no-padding
    >
      <m-card-item
        icon="apartment"
        :tooltip="createTooltip"
        :disabled="!canCreate"
        :loading="createLoading"
        @click="showAddModal = true"
      >
        {{ $t('spacesContextMenu.createSubSpace') }}
      </m-card-item>
      <template v-if="canOpen">
        <m-card-item
          icon="central-preview"
          @click="openSpace"
        >
          {{ $t('spacesContextMenu.openSpace') }}
        </m-card-item>
      </template>
      <m-divider xxs />
      <m-card-item
        v-if="showArchive"
        icon="archive"
        :tooltip="archiveTooltip"
        :loading="archiveLoading"
        @click="archiveItems"
      >
        {{ $t('general.archive') }}
        <template
          v-if="restorableSpaces.length > 0"
          #right
        >
          <div class="_right">
            <m-tooltip>
              <m-icon
                type="warning"
                class="_icon"
              />
              <template #title>
                {{ $t('spacesContextMenu.archiveSomeHint') }}
              </template>
            </m-tooltip>
          </div>
        </template>
      </m-card-item>
      <m-card-item
        v-else
        icon="archive"
        :tooltip="restoreTooltip"
        :loading="restoreLoading"
        @click="restoreItems"
      >
        {{ $t('general.restore') }}
      </m-card-item>
      <m-card-item
        icon="delete"
        :tooltip="deleteTooltip"
        :disabled="!canDelete"
        :loading="deleteLoading"
        :color="colors.red.base"
        @click="deleteItems"
      >
        {{ $t('general.delete') }}
      </m-card-item>
    </m-card>
  </m-context-menu>
  <m-dialog
    :value="showAddModal"
    :title="$t('spaceEditor.create')"
    hide-footer
    no-padding
    @close="hide"
  >
    <space-editor
      :space="emptySubspace"
      @created="hide"
      @deleted="hide"
      @close="showAddModal = false"
    />
  </m-dialog>
</template>

<script>
import SpaceEditor from '@/components/spaces/SpaceEditor.vue';
import colors from 'shared/colors';
import useAccess from '@/composables/access/access';
import useSpaces from '@/composables/space/spaces';
import { DateTime } from 'luxon';
import { accessGroupFlag, accessPolicyType, routeName } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'SpaceContextMenu',
  props: {
    spaces: {
      type: Array,
      required: true,
    },
  },
  components: { SpaceEditor },
  emits: ['hide'],
  data() {
    return {
      showAddModal: false,
      archiveLoading: false,
      restoreLoading: false,
    };
  },
  setup() {
    const { userHasRight } = useAccess();
    const { maxLevel, deleteSpace, deleteSpacesLoading, createSpace, createSpaceLoading, updateSpaces } = useSpaces();
    return {
      userHasRight,
      maxLevel,
      createSpace,
      createLoading: createSpaceLoading,
      deleteSpace,
      deleteLoading: deleteSpacesLoading,
      updateSpaces,
    };
  },
  computed: {
    colors() {
      return colors;
    },
    singleSpace() {
      return this.spaces[0];
    },
    emptySubspace() {
      return { parents: [this.singleSpace] };
    },
    archivableSpaces() {
      return this.spaces.filter((s) => s.archivedAt === null);
    },
    restorableSpaces() {
      return this.spaces.filter((s) => s.archivedAt !== null);
    },
    showArchive() {
      return this.archivableSpaces.length > 0;
    },
    multiOperation() {
      return this.spaces.length > 1;
    },
    canAddLevel() {
      return this.singleSpace.level + 1 < this.maxLevel;
    },
    canCreate() {
      return !this.multiOperation && this.userHasRight([accessGroupFlag.spaceWriteAccess]) && this.canAddLevel;
    },
    createTooltip() {
      if (this.canCreate) {
        return '';
      }
      if (this.multiOperation) {
        return this.$t('spacesContextMenu.cannotCreateMultiple');
      }
      if (this.canAddLevel) {
        return this.$t('spacesContextMenu.cannotCreateLevel', { maxLevel: this.maxLevel });
      }

      return this.$t('spacesContextMenu.cannotCreate');
    },
    archiveTooltip() {
      return this.$t('spaceEditor.archiveSubtext');
    },
    restoreTooltip() {
      return this.$t('spaceEditor.restoreSubtext');
    },
    canDelete() {
      return !this.multiOperation && [accessPolicyType.write, accessPolicyType.full].includes(this.singleSpace.accessRight);
    },
    deleteTooltip() {
      if (this.canDelete) {
        return this.$t('spaceEditor.deleteSubtext');
      }

      if (this.multiOperation) {
        return this.$t('spacesContextMenu.cannotDeleteMultiple');
      }

      return this.$t('spacesContextMenu.cannotDelete');
    },
    canOpen() {
      return !this.multiOperation && (this.$route.name !== routeName.spaceDetails || this.$route.params.optionId !== `${this.singleSpace.uid}`);
    },
  },
  methods: {
    reset() {
      this.showAddModal = false;
      this.$emit('hide');
    },
    hide() {
      this.$refs.contextMenu.hide();
    },
    show(event) {
      this.$refs.contextMenu.show(event);
    },
    archiveItems() {
      this.archiveLoading = true;
      this.updateSpaces(this.archivableSpaces.map((s) => ({ uid: s.uid, archivedAt: DateTime.local().toISO() }))).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.archived') });
        this.hide();
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.archiveLoading = false;
      });
    },
    restoreItems() {
      this.restoreLoading = true;
      this.updateSpaces(this.restorableSpaces.map((s) => ({ uid: s.uid, archivedAt: null }))).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.restored') });
        this.hide();
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.restoreLoading = false;
      });
    },
    deleteItems() {
      const deleteMethod = () => this.deleteSpace(this.singleSpace).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
        this.hide();
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));

      this.$confirm({
        title: this.$t('spaceEditor.deleteModal.title', { optionTitle: this.singleSpace.title }),
        message: { text: this.$t('spaceEditor.deleteModal.message') },
        secure: {
          question: this.$t('spaceEditor.deleteModal.securityQuestion'),
          answer: this.singleSpace.title,
        },
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        onOk() {
          deleteMethod();
        },
      });
    },
    openSpace() {
      this.$router.push({ name: routeName.spaceDetails, params: { optionId: this.singleSpace.uid } });
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>
