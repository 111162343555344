<template>
  <li
    :data-type="node.type.name"
    :data-done="node.attrs.done"
    data-drag-handle
  >
    <span
      class="todo-checkbox"
      contenteditable="false"
      @click="onChange"
    >
      <m-icon
        v-if="node.attrs.done"
        type="check"
        color="white"
        size="14"
      />
    </span>
    <div
      ref="content"
      :class="['todo-content', node.content.size === 2 ? '-empty' : '']"
      :contenteditable="view.editable"
    />
  </li>
</template>

<script>
export default {
  name: 'ToDoItemComponent',
  props: {
    node: {
      type: Object,
      required: true,
    },
    updateAttrs: {
      type: Function,
      required: true,
    },
    view: {
      type: Object,
      required: true,
    },
    getPos: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onChange() {
      this.updateAttrs({ done: !this.node.attrs.done });
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  li[data-type="todo_item"] {
    display: flex;
    flex-direction: row;
    padding-left: .6rem;
  }

  .todo-checkbox {
    box-sizing: border-box;
    width: 1.6rem;
    height: 1.6rem;
    margin-top: .7rem;
    margin-right: 1rem;
    cursor: pointer;
    background-color: transparent;
    border: 1.5px solid map_get($grey, 'darken-3');
    border-radius: 0;
    transition: .4s background;
  }

  .todo-content {
    position: relative;
    flex: 1;

    &.-empty {
      p {
        &::before {
          position: absolute;
          top: .3rem;
          left: .3rem;
          color: $font-color-tertiary;
          content: 'To-Do';
        }
      }
    }
  }

  li[data-done="true"] {
    > .todo-content {
      > p {
        color: $font-color-tertiary;
        text-decoration: line-through;
      }
    }

    > .todo-checkbox {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

  li[data-done="false"] {
    text-decoration: none;
  }
</style>
