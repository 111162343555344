<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9946 3.07504C12.1821 3.07504 12.3551 3.1147 12.5114 3.19523C12.6677 3.27816 12.8359 3.38754 13.0186 3.52456L19.3468 8.63278C19.4884 8.74221 19.6022 8.88346 19.6791 9.04509C19.7559 9.20671 19.7936 9.38417 19.7891 9.56307C19.7941 9.79491 19.7319 10.0232 19.61 10.2205C19.491 10.408 19.3348 10.5571 19.1413 10.6713C18.9553 10.7773 18.7447 10.8324 18.5307 10.8311C18.3662 10.8304 18.2034 10.7982 18.0511 10.7362C17.8891 10.6667 17.7375 10.5753 17.6004 10.4645L11.9946 5.96689L6.39837 10.4645C6.25717 10.5744 6.10246 10.6657 5.93803 10.7362C5.76302 10.8068 5.57446 10.8375 5.38609 10.8261C5.19771 10.8146 5.01425 10.7613 4.84907 10.6701C4.66226 10.5587 4.50451 10.4046 4.38873 10.2205C4.26729 10.0231 4.20551 9.79479 4.21085 9.56307C4.21085 9.18086 4.35748 8.87076 4.65196 8.63278L10.9801 3.52456C11.1628 3.38754 11.3311 3.27936 11.4874 3.19643C11.6439 3.11479 11.818 3.0739 11.9946 3.07504ZM11.9946 20.9237C11.8182 20.9256 11.6441 20.8843 11.4874 20.8036C11.3067 20.7128 11.1366 20.6023 10.9801 20.4742L4.65196 15.366C4.51157 15.2556 4.39868 15.1141 4.32214 14.9527C4.24561 14.7913 4.20752 14.6143 4.21085 14.4357C4.2112 14.2126 4.27039 13.9936 4.38245 13.8007C4.49451 13.6078 4.65546 13.4479 4.84907 13.3371C5.01217 13.2406 5.19561 13.1835 5.3847 13.1706C5.5738 13.1577 5.7633 13.1892 5.93803 13.2626C6.08827 13.3251 6.24212 13.4165 6.39837 13.5343L11.9946 18.0331L17.6004 13.5355C17.7374 13.4243 17.8891 13.3325 18.0511 13.2626C18.2074 13.2001 18.3672 13.1689 18.5307 13.1689C18.7458 13.1675 18.9572 13.2258 19.1413 13.3371C19.3384 13.4449 19.5028 13.6041 19.6168 13.7977C19.7309 13.9913 19.7904 14.2122 19.7891 14.4369C19.7891 14.8119 19.6413 15.122 19.3468 15.366L13.0186 20.4838C12.8605 20.6066 12.6906 20.7133 12.5114 20.8024C12.3517 20.8847 12.1743 20.9268 11.9946 20.925V20.9237Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'UpDown' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
