<template>
  <div
    class="overlay-spinner"
  >
    <m-card>
      <div class="_inner">
        <div class="_loading-screen-text">
          {{ props.text }}
        </div>
        <m-spinner size="xs" />
      </div>
    </m-card>
  </div>
</template>

<script setup>
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
});
</script>

<style scoped lang="scss">
.overlay-spinner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: var(--viewport-height-100);
  pointer-events: none;
  background-color: map_get($grey, 'darken-2');

  ._inner {
    min-width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;

    ._loading-screen-text {
      margin-right: 1rem;
    }
  }
}
</style>
