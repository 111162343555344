<template>
  <component
    :is="component"
    :class="classes"
    :style="resolveStyles(mStyle)"
    :target="target"
    :rel="rel"
    :draggable="false"
    v-bind="attrs"
  >
    <slot />
  </component>
</template>

<script>
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';

export default {
  name: 'MLink',
  props: {
    ...mStyleProps,
    to: {
      type: [Object, String],
      default: () => null,
    },
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    inheritColor: {
      type: Boolean,
      default: false,
    },
    underlineOnHover: {
      type: Boolean,
      default: false,
    },
    noPointer: {
      type: Boolean,
      default: false,
    },
    backgroundOnHover: {
      type: Boolean,
      default: false,
    },
    underlined: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        'm-link',
        this.inheritColor ? '-inherit-color' : '',
        this.underlineOnHover ? '-underline-on-hover' : '',
        this.noPointer ? '-no-pointer' : '',
        this.backgroundOnHover ? '-background-on-hover' : '',
        this.underlined ? '-underlined' : '',
        this.inline ? '-inline' : '',
        this.disabled ? '-disabled' : '',
      ];
    },
    component() {
      if (this.disabled) {
        return 'span';
      }
      if (this.to !== null && this.to !== '') {
        return 'router-link';
      }
      return 'a';
    },
    attrs() {
      if (this.href === '') {
        return { to: this.to };
      }
      return { href: this.href };
    },
    rel() {
      if (this.target === '_blank') {
        return 'noopener';
      }
      return null;
    },
  },
  methods: { resolveStyles },
};
</script>

<style lang="scss" type="text/scss">
  .m-link {
    display: inline;
    max-width: 100%;
    overflow: hidden;
    color: $link-color;
    text-overflow: ellipsis;
    border-radius: $btn-border-radius;

    &.-inline {
      display: inline-block;
    }

    &.-no-pointer {
      cursor: inherit;
    }

    &.-underlined {
      text-decoration: underline;
    }

    &:not(.-disabled) {
      &.-underline-on-hover {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.-inherit-color {
      color: inherit;
    }

    &.-background-on-hover {
      &:hover {
        background-color: $hover-color;
        box-shadow: 0 0 0 2px $hover-color;
      }
    }

    &.-disabled {
      &:hover {
        background-color: inherit !important;
      }
    }
  }
</style>
