<template>
  <not-found-page
    v-if="!loading && Object.keys(goal).length === 0"
    :to="{ name: routeName.goalsExplorer }"
    :title="
      $t('notFoundPage.title', {
        type: goalSettings.featureNameSingular,
      })
    "
    :anchor-text="
      $t('notFoundPage.buttonText', {
        types: goalSettings.featureNamePlural,
      })
    "
  />
  <page-layout
    v-else
    :id="`goal_${goal.uid}`"
    :class="['goal', openInModal ? '-modal' : '']"
    :loading="loading"
    :modal="openInModal"
    :width="width"
  >
    <template
      #topBar
    >
      <div class="_top">
        <detail-header
          :goal="goal"
          :modal="openInModal"
          :side-peek="openInSidePeek"
          :route-after-delete="routeAfterDelete"
          :read-only="readOnly"
          @deleted="deleted"
          @go-to-details="goToDetails"
          @close="close"
        />
      </div>
    </template>
    <scroll-container>
      <m-content
        v-if="goal.publishedAt === null"
        padding-small
        class="_draft"
      >
        <div class="_draft-inner">
          <div class="_text">
            {{ $t('goalPage.notPublishedHint', {title: goalType}) }}
          </div>
          <m-btn
            v-if="goal.planning !== null"
            :to="planningLink"
            light
          >
            {{ $t('goalPage.openPlanning') }}
          </m-btn>
        </div>
      </m-content>
      <m-content
        boxed-small
        :padding-x="padding"
        class="_content"
      >
        <goal-editor
          ref="goalEditor"
          :key="`${goal.uid}-${$route.name}`"
          :goal="goal"
          :goal-children="goalChildren"
          :goal-updates="goalUpdates"
          :goal-cycles="goalCycles"
          :open-in-modal="openInModal || openInSidePeek"
          class="_editor"
          :read-only="readOnly"
          :update-property="updateProp"
          @progress-click="progressClick"
          @change-parent="$emit('change-parent')"
        />
        <m-divider
          no-border
          small
        />
        <div
          v-if="showSpinner"
          class="_spinner"
        >
          <m-spinner size="sm" />
        </div>
        <template v-else>
          <goal-children-list
            v-if="showGoalChildren"
            :aligned-items-grouped-by="goal.alignedItemsGroupedBy"
            :goals="goalChildren"
            :parent="goal"
            :read-only="readOnly"
            :can-edit="canEdit"
            select-area-class="goal"
            :allowed-sub-item-goal-types="allowedSubItemGoalTypes"
            :update-property="updateProp"
            :goal-creator="goalCreator"
            :open-in-modal="openChildrenInModal"
            @change-group-by="changeGroupBy"
            @progress-click="progressClick"
            @open="open"
          />
          <h6 class="_title">
            {{ $t('goalDetails.updates') }}
          </h6>
          <m-divider
            xs
            class="_divider"
          />
          <goal-update-editor
            v-if="canComment"
            :goal="goal"
            goals-clearable
            allow-comments
            :goal-children="goalChildren"
            class="_write-update"
          />
          <goal-update-list
            :goal="goal"
            :goal-children="goalChildren"
            :updates="goalUpdates"
            :read-only="readOnly"
          />
        </template>
      </m-content>
    </scroll-container>
    <m-dialog
      v-model:value="showUpdateProgressModal"
      hide-footer
      :max-width="$modalSizes.lg"
      :fullscreen-on-mobile="false"
      :center="$store.state.breakpoint.smAndDown"
      :body-style="{ overflow: 'visible', padding: '2rem' }"
    >
      <goal-update-editor
        :key="goalToUpdateId"
        :goal="goalToUpdate"
        :goal-children="[goalToUpdate]"
        allow-goal-activity
        auto-add-goal-activity
        auto-focus
        @created="showUpdateProgressModal = false"
      />
    </m-dialog>
  </page-layout>
</template>

<script>
import DetailHeader from '@/components/goal/DetailHeader.vue';
import GoalChildrenList from '@/components/goal/GoalChildrenList.vue';
import GoalEditor from '@/components/goal/GoalEditor.vue';
import GoalUpdateEditor from '@/components/goal/GoalUpdateEditor.vue';
import GoalUpdateList from '@/components/goal/GoalUpdateList.vue';
import NotFoundPage from '@/components/NotFoundPage.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import useAccess from '@/composables/access/access';
import useDeleteGoal from '@/composables/goal/delete-goal';
import useGoal from '@/composables/goal/goal';
import useGoalCreator from '@/composables/goal/creator';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalDetailSort from '@/composables/sort/goal-detail-sort';
import useGoalModifiers from '@/composables/goal/modifiers';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useSnackBar from '@/composables/snackbar';
import { CASCADE_CONTEXT_PUBLISHED_VIEW } from '@/lib/constants';
import { GOAL_DETAILS } from '@/route-names';
import { accessPolicyType,
  msTabAction,
  routeName,
  validationError } from 'shared/constants.json';
import { computed, toRef } from 'vue';
import {
  getAllowedSubItemGoalTypes,
  goalTypeOption,
} from '@/composables/goal/allowed-sub-items';
import { logCatch } from '@/lib/logger/logger';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'GoalPage',
  props: {
    goalId: {
      type: Number,
      required: true,
    },
    openInModal: {
      type: Boolean,
      default: false,
    },
    openInSidePeek: {
      type: Boolean,
      default: false,
    },
    openChildrenInModal: {
      type: Boolean,
      default: true,
    },
    routeAfterDelete: {
      type: Object,
      required: true,
    },
    goalModifiers: {
      type: Array,
      default: () => [],
    },
    context: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { goalCycles } = useGoalCycle();
    const snackbar = useSnackBar();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const goalsSvc = useGoals();
    const { goalSettings } = useGoalSettings();
    const goalTypeSvc = useGoalTypeProperty();
    const goalDetailSorter = useGoalDetailSort(goalsSvc);

    const { goal, goalUpdates, goalChildren } = useGoal(props.context, toRef(props, 'goalId'));

    const readOnly = computed(() => {
      const goal = goalsSvc.selectSingle(props.goalId);
      if (goal === undefined) {
        return true;
      }

      if (props.context === CASCADE_CONTEXT_PUBLISHED_VIEW) {
        return () => true;
      }

      return goal.accessRight === accessPolicyType.read;
    });

    let goalModifiers = null;
    if (props.context !== CASCADE_CONTEXT_PUBLISHED_VIEW) {
      goalModifiers = useGoalModifiers(toRef(props, 'goalModifiers'));
    }

    const goalCreator = useGoalCreator(
      goalsSvc,
      {},
      goalDetailSorter,
      goalModifiers,
      {},
      null,
    );
    const { accountHasFeature } = useAccess();
    const { deleteLoading, showDeleteGoalModal } = useDeleteGoal();
    return {
      snackbar,
      accountHasFeature,
      goalTypeProperty: goalTypeSvc.goalTypeProperty,
      goalTypeIcon: goalTypeSvc.goalTypeIcon,
      updateProperty: goalsSvc.updateProperty,
      goalCreator,
      readOnly,
      goalCycles,
      loggedInUser,
      loggedInUserAccount,
      goalSettings,
      goal,
      goalUpdates,
      goalChildren,
      deleteLoading,
      showDeleteGoalModal,
      userLang,
      goalsSvc,
    };
  },
  emits: ['change-parent', 'close', 'deleted', 'open'],
  components: {
    ScrollContainer,
    NotFoundPage,
    PageLayout,
    GoalUpdateList,
    GoalUpdateEditor,
    DetailHeader,
    GoalEditor,
    GoalChildrenList,
  },
  data() {
    return {
      loading: true,
      showUpdateProgressModal: false,
      GOAL_DETAILS,
      childrenLoading: false,
      goalToUpdateId: 0,
      routeName,
    };
  },
  computed: {
    childrenLoaded() {
      const childrenIds = this.goal.children.map((c) => c.uid);
      return this.goalsSvc.selectMultiple(childrenIds).length === childrenIds.length;
    },
    allowedSubItemGoalTypes() {
      return getAllowedSubItemGoalTypes(
        goalTypeOption(this.goal, this.goalTypeProperty),
        this.goalTypeProperty,
      );
    },
    canEdit() {
      return (
        !this.readOnly
          && [accessPolicyType.full, accessPolicyType.write].includes(
            this.goal.accessRight,
          )
      );
    },
    canComment() {
      return (
        !this.readOnly
          && [
            accessPolicyType.full,
            accessPolicyType.write,
            accessPolicyType.comment,
          ].includes(this.goal.accessRight)
      );
    },
    planningLink() {
      if (this.goal.planning === null) {
        return {};
      }
      return {
        name: routeName.planningDetails,
        params: { id: this.goal.planning.uid },
        query: { viewId: this.$route.query.viewId },
      };
    },
    goalType() {
      const t = this.goal.properties.find((p) => p.property.isGoalType);
      if (t.selectedOptions.length === 0) {
        return this.loggedInUserAccount.goalSettings.featureNameSingular;
      }
      return textByLang(t.selectedOptions[0].label, this.userLang);
    },
    showSpinner() {
      return this.childrenLoading || !this.goal.updatesLoaded;
    },
    goalToUpdate() {
      if (this.goalToUpdateId === 0) {
        return null;
      }

      return this.goalsSvc.selectSingle(this.goalToUpdateId);
    },
    showGoalChildren() {
      if (!this.readOnly && this.allowedSubItemGoalTypes.length > 0) {
        return true;
      }

      return this.goalChildren.length > 0;
    },
    padding() {
      if (this.openInSidePeek) {
        return 'layout-side-peek';
      }
      return 'layout-detail';
    },
  },
  methods: {
    updateProp(value, property, goal) {
      this.updateProperty(value, property, [goal]).catch(logCatch((err) => {
        if (err.message.includes(validationError.circularReferenceNotAllowed)) {
          this.snackbar.error(this.$t('goalMutationErrors.circularReference'));
          return;
        }

        this.snackbar.error();
      }));
    },
    deleted() {
      this.$emit('close');
      this.$emit('deleted');
    },
    changeGroupBy(value) {
      this.goalsSvc.updateSingle(
        {
          ...this.goal,
          alignedItemsGroupedBy: value,
        },
      );
    },
    progressClick(goal) {
      if (this.readOnly) {
        return;
      }

      this.goalToUpdateId = goal.uid;
      this.showUpdateProgressModal = true;
    },
    goToDetails({ to }) {
      this.$router.push(to);
    },
    close() {
      this.$emit('close');
    },
    open(goal) {
      this.$emit('open', goal, this.openChildrenInModal);
    },
    get() {
      if (this.goal.completelyLoaded && this.childrenLoaded && this.goal.updatesLoaded === true) {
        this.loading = false;
        this.childrenLoading = false;
        return new Promise((resolve) => { resolve(); });
      }

      if (Object.keys(this.goal).length === 0) {
        this.loading = true;
      }
      if (Object.keys(this.goal).length > 0) {
        this.loading = false;
      }
      if (typeof this.goal.progressCourse === 'undefined') {
        this.childrenLoading = true;
      }

      return this.goalsSvc.getGoal(this.goalId).then((goal) => {
        this.loading = false;
        this.childrenLoading = false;
        return goal;
      }).catch(logCatch((err) => {
        this.snackbar.error();
        throw err;
      }));
    },
    checkToDeleteEmpty(callback, redirect) {
      if (typeof this.$refs.goalEditor === 'undefined' || this.$refs.goalEditor === null) {
        callback();
        return;
      }

      if (typeof this.$refs.goalEditor.flush !== 'undefined') {
        this.$refs.goalEditor.flush();
      }

      if (Object.keys(this.goal).length === 0) {
        callback();
        return;
      }

      if (
        this.goal.creator !== null
          && this.goal.creator.uid !== this.loggedInUser.uid
      ) {
        callback();
        return;
      }

      if (
        this.goal.title !== ''
          || this.goalChildren.length > 0
          || this.goalUpdates.length > 0
      ) {
        callback();
        return;
      }

      let r = redirect;
      if (typeof r === 'undefined') {
        r = this.routeAfterDelete;
      }

      const title = this.$t('goalDetails.deleteEmptyPrompt', { title: this.loggedInUserAccount.goalSettings.featureNameSingular });
      this.showDeleteGoalModal({
        goals: [this.goal],
        redirect: r,
        title,
        onCancel: callback,
        okText: this.$t('goalDetails.deleteOkText'),
        cancelText: this.$t('goalDetails.deleteCancelText'),
        hardDelete: true,
      }).then(() => {
        callback();
      }).catch(logCatch(() => {
        this.snackbar.error();
      }));
    },
  },
  watch: {
    goalId() {
      this.get();
    },
  },
  created() {
    this.get().then(() => {
      if (typeof this.$route.query.action === 'undefined') {
        return;
      }

      if (this.$route.query.action === msTabAction.update) {
        this.goalToUpdateId = this.goal.uid;
        this.showUpdateProgressModal = true;
      }
    });
  },
};
</script>

<style
    lang="scss"
    scoped
    type="text/scss"
>
.goal {
  background-color: white;

  ._title {
    margin: .4rem .8rem 0 .4rem;
    font-weight: $font-weight-semibold;
  }

  ._draft {
    display: flex;
    justify-content: center;
    background-color: map_get($yellow, 'lighten-4');

    ._draft-inner {
      display: flex;
      align-items: center;

      ._text {
        margin-right: 1.2rem;
        color: $font-color-secondary;
      }
    }
  }

  ._key-result-list {
    ._item {
      margin-bottom: 1.6rem;
    }
  }

  ._write-update {
    margin-bottom: 2rem;
  }

  ._divider {
    margin-bottom: 2.2rem;
  }

  ._spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
  }
}
</style>
