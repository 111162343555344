<template>
  <div class="progress-measurement">
    <m-dropdown
      v-model:value="progressMeasurementOpen"
      :title="$t('progressMeasurement.measurement')"
      block
      match-trigger-width
    >
      <m-btn
        block
        hide-border
        small
        class="_trigger"
      >
        <div class="_left">
          {{ $t('progressMeasurement.measurement') }}
        </div>
        <div class="_right">
          <m-icon
            :type="selectedProgressMeasurementOption.icon"
            class="_icon"
          />
          <div>
            {{ selectedProgressMeasurementOption.text }}
          </div>
          <m-icon
            class="_down"
            type="down"
          />
        </div>
      </m-btn>
      <template #overlay>
        <m-card list>
          <m-card-item
            v-for="option in progressMeasurementOptions.filter((option) => option.disabled === undefined || !option.disabled)"
            :key="option.value"
            :icon="option.icon"
            :tooltip="$t(`metricForm.tooltip.${option.value}`)"
            tooltip-placement="right"
            @click="emitUpdateProgressMeasurement(option.value)"
          >
            {{ option.text }}
          </m-card-item>
        </m-card>
      </template>
    </m-dropdown>
    <m-dropdown
      v-if="props.goal.progressMeasurement === goalProgressMeasurement.alignedItems"
      v-model:value="aggregationMethodOpen"
      :title="$t('progressMeasurement.calculateBy')"
      block
      match-trigger-width
      class="_aggregation-method"
    >
      <m-btn
        block
        hide-border
        small
        class="_trigger"
      >
        <div class="_left">
          {{ $t('progressMeasurement.calculateBy') }}
        </div>
        <div class="_right">
          <div>
            {{ selectedAggregationMethod.text }}
          </div>
          <m-icon
            class="_down"
            type="down"
          />
        </div>
      </m-btn>
      <template #overlay>
        <m-card list>
          <m-card-item
            v-for="option in aggregationMethods"
            :key="option.value"
            :icon="option.icon"
            @click="emitUpdateAggregationMethod(option.value)"
          >
            {{ option.text }}
          </m-card-item>
        </m-card>
      </template>
    </m-dropdown>
  </div>
</template>

<script setup>
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useProgressMeasurement from '@/composables/goal/progress-measurement';
import { computed, ref } from 'vue';
import { goalProgressMeasurement } from 'shared/constants.json';

const emit = defineEmits(['update-progress-measurement', 'update-aggregation-method']);

const props = defineProps({ goal: { type: Object, required: true } });

const { goalTypeOption } = useGoalTypeProperty();

const { allowedProgressMeasurementOptions, allowedAggregationMethodOptions } = useProgressMeasurement();

const progressMeasurementOptions = computed(() => allowedProgressMeasurementOptions(goalTypeOption(props.goal)));

const selectedProgressMeasurementOption = computed(() => progressMeasurementOptions.value.find((option) => option.value === props.goal.progressMeasurement));

const aggregationMethods = computed(() => allowedAggregationMethodOptions());

const selectedAggregationMethod = computed(() => aggregationMethods.value.find((option) => option.value === props.goal.aggregationMethod));

const progressMeasurementOpen = ref(false);
const aggregationMethodOpen = ref(false);

const emitUpdateProgressMeasurement = (val) => {
  progressMeasurementOpen.value = false;
  emit('update-progress-measurement', val);
};

const emitUpdateAggregationMethod = (val) => {
  aggregationMethodOpen.value = false;
  emit('update-aggregation-method', val);
};

</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._trigger {
    justify-content: space-between;

    ._right {
      display: inherit;
      flex-direction: row;
      color: $font-color-secondary;

      ._icon {
        padding-right: .4rem;
      }

      ._down {
        padding-left: 1.2rem;
      }
    }
  }

  ._aggregation-method {
    padding-top: .8rem;
  }
</style>
