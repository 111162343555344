<template>
  <div class="oauth-code-grant-redirect">
    <div
      v-if="error !== null"
      class="_content"
    >
      <div class="_header">
        <item-title
          :title=" $t('oauthCodeGrantRedirect.header')"
          :icons="[{value: buildIcon('Warning_yellow'), size: 24}]"
        />
      </div>
      <i18n-t
        keypath="oauthCodeGrantRedirect.message"
        tag="div"
        class="_message"
      />
      <i18n-t
        keypath="oauthCodeGrantRedirect.message2"
        tag="div"
        class="_message"
      >
        <template #supportmail>
          <a :href="`mailto:${$t('general.supportEmail')}`">{{ $t('general.supportEmail') }}</a>
        </template>
      </i18n-t>
      <div class="_message">
        {{ $t('oauthCodeGrantRedirect.internalError') }}:
        <m-card
          border-radius="small"
          :style="{marginTop: '1.2rem'}"
          flat
          bordered
        >
          <pre class="_message">{{ error }}</pre>
        </m-card>
      </div>
    </div>
  </div>
</template>

<script>

import ItemTitle from '@/components/ItemTitle.vue';
import { buildIcon } from 'shared/lib/icon';

export default {
  name: 'OAuthCodeGrantRedirect',
  components: { ItemTitle },
  data() {
    return { error: null };
  },
  methods: { buildIcon },
  created() {
    const params = new URLSearchParams(window.location.search);
    if (params.get('error') !== null) {
      this.error = params.get('error');
      return;
    }

    window.location = `/api/v1/oauth2/authorize${window.location.search}`;
  },
};
</script>

<style lang="scss" type="text/scss" scoped>
  .oauth-code-grant-redirect {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100vw;
    min-height: var(--viewport-height-100);

    ._content {
      max-width: 68rem;

      ._header {
        margin-bottom: 1rem;
        font-size: $font-size-8;
      }

      ._message {
        margin-bottom: 1.2rem;
      }
    }
  }
</style>
