<template>
  <div :class="['grid-item-header', 'grid-item-drag-handle', props.readOnly ? '-read-only': '', hasHeader ? '-has-header' : '']">
    <m-tooltip
      v-if="!hideTitle"
      :disabled="anyEditMode || !isTitleEllipsisActive"
      :mouse-enter-delay=".5"
    >
      <simple-editor
        ref="titleRef"
        :class="['_title', titleEditMode ? '-editing':'']"
        auto-focus
        full-width
        :read-only="!titleEditMode"
        :initial-value="title"
        :no-title-text="noTitleText"
        :placeholder="placeholder"
        @enter="disableTitleEditMode"
        @blur="disableTitleEditMode"
        @update:value="updateTitle"
        @mousedown="handleTitleMouseDown"
      />
      <template #title>
        {{ title !== '' ? title : noTitleText }}
      </template>
    </m-tooltip>
    <m-tooltip
      v-if="!hideDescription"
      :disabled="anyEditMode || !isDescriptionEllipsisActive"
      :mouse-enter-delay=".5"
    >
      <div
        ref="descriptionRef"
        :class="['_description', descriptionEditMode ? '-editing':'']"
      >
        <span
          v-if="!descriptionEditMode && !!$slots.staticDescription"
          ref="staticDescriptionRef"
          class="_static-description"
        >
          <slot name="staticDescription" /><span v-if="description !== ''">・</span>
        </span>
        <simple-editor
          ref="variableDescriptionRef"
          tag="span"
          class="_variable-description"
          :read-only="!descriptionEditMode"
          :initial-value="description"
          no-title-text=""
          :full-width="descriptionEditMode"
          :placeholder="descriptionEditMode ? $t('gridPage.tile.header.addDescription') : ''"
          @enter="disableDescriptionEditMode"
          @blur="disableDescriptionEditMode"
          @update:value="updateDescription"
          @mousedown="handleDescriptionMouseDown"
        />
      </div>
      <template #title>
        <span v-if="!!$slots.staticDescription">
          <slot name="staticDescription" /><span v-if="description !== ''">・</span>
        </span>
        {{ description }}
      </template>
    </m-tooltip>
    <div :class="['_actions', dropdownActivated? '-active':'']">
      <m-dropdown
        v-if="!props.readOnly || !!$slots.extraActions"
        v-model:value="dropdownActivated"
        :relocate-key="menu"
        :title="$t('general.select')"
        @hide="menu = 'actionList'"
      >
        <drag-or-click-btn
          draggable
          clickable
          :style="{ cursor: 'pointer' }"
        />
        <template #overlay>
          <m-card
            v-if="menu === 'actionList'"
            no-padding
            list
          >
            <template v-if="!props.readOnly">
              <m-card-item
                icon="delete"
                :loading="deleteLoading"
                @click="deleteTile"
              >
                {{ $t('general.delete') }}
              </m-card-item>
              <m-card-item
                icon="copy"
                :loading="duplicateLoading"
                @click="duplicate"
              >
                {{ $t('gridPage.tile.header.duplicate') }}
              </m-card-item>
              <m-divider xxs />
              <m-card-item
                v-if="canTitleEdit"
                icon="edit-square"
                @click="menu = 'updateTitle'"
              >
                <template v-if="title === ''">
                  {{ $t('gridPage.tile.header.addTitle') }}
                </template>
                <template v-else>
                  {{ $t('gridPage.tile.header.editTitle') }}
                </template>
              </m-card-item>
              <m-card-item
                v-if="canDescriptionEdit"
                icon="edit"
                @click="menu = 'updateDescription'"
              >
                <template v-if="description === ''">
                  {{ $t('gridPage.tile.header.addDescription') }}
                </template>
                <template v-else>
                  {{ $t('gridPage.tile.header.editDescription') }}
                </template>
              </m-card-item>
              <m-card-item
                v-if="canEditBorder"
                icon="border"
                @click.stop="toggleBorder"
              >
                <m-switch
                  :label="$t('gridPage.tile.header.showBorder')"
                  small
                  :value="!tile.hideBorder"
                />
              </m-card-item>
            </template>
            <template v-if="!!$slots.extraActions">
              <slot
                name="extraActions"
                :close="hideMenu"
              />
            </template>
          </m-card>
          <m-card
            v-if="menu === 'updateTitle'"
            padding-xxs
            :style="{ width: '40rem' }"
          >
            <m-focusable>
              <simple-editor
                :style="{ width: '100%' }"
                :initial-value="title"
                :read-only="false"
                auto-focus
                auto-select
                @enter="disableTitleEditMode"
                @update:value="updateTitle"
              />
            </m-focusable>
          </m-card>
          <m-card
            v-if="menu === 'updateDescription'"
            padding-xxs
            :style="{ width: '40rem' }"
          >
            <m-focusable>
              <simple-editor
                :style="{ width: '100%' }"
                :initial-value="description"
                :read-only="false"
                auto-focus
                auto-select
                @enter="disableDescriptionEditMode"
                @update:value="updateDescription"
              />
            </m-focusable>
          </m-card>
        </template>
      </m-dropdown>
    </div>
  </div>
</template>

<script setup>
import DragOrClickBtn from 'shared/components/DragOrClickBtn.vue';
import SimpleEditor from '@/components/SimpleEditor.vue';
import i18n from '@/lang';
import useDebounce from '@/composables/debounce';
import useElementsTruncate from 'shared/composables/element-truncate';
import useGridPage from '@/composables/grid-page/grid-page';
import useInlineEditing from '@/composables/inline-editing';
import useSnackBar from '@/composables/snackbar';
import { computed, ref, watch } from 'vue';
import { logCatch } from '@/lib/logger/logger';
import { useI18n } from 'vue-i18n';

const snackBar = useSnackBar();
const { t } = useI18n();
const debounceSvc = useDebounce();

const gridPageService = useGridPage();

const menu = ref('actionList');

const props = defineProps({
  tile: {
    type: Object,
    required: true,
  },
  gridRow: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  titleDisableEdit: {
    type: Boolean,
    default: false,
  },
  hideTitle: {
    type: Boolean,
    default: false,
  },
  hideDescription: {
    type: Boolean,
    default: false,
  },
  canEditBorder: {
    type: Boolean,
    default: false,
  },
  titleNoText: {
    type: String,
    default: i18n.t('list.noTitle'),
  },
  titlePlaceholder: {
    type: String,
    default: i18n.t('list.noTitle'),
  },
  descriptionDisableEdit: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
});

const gridPageTile = computed(() => {
  const res = gridPageService.gridPageTiles.value.find((tile) => tile.uid === props.tile.uid);
  if (res === undefined) {
    return null;
  }
  return res;
});

const handleTitleMouseDown = (event) => {
  if (titleEditMode.value) {
    event.stopPropagation();
  }
};
const handleDescriptionMouseDown = (event) => {
  if (descriptionEditMode.value) {
    event.stopPropagation();
  }
};

const dropdownActivated = ref(false);
const hideMenu = () => {
  dropdownActivated.value = false;
};

const hasHeader = computed(() => !(props.hideTitle && props.hideDescription && props.titleDisableEdit && props.descriptionDisableEdit));

const deleteLoading = ref(false);
function deleteTile() {
  deleteLoading.value = true;
  gridPageService.deleteGridPageTile(props.tile)
    .catch(() => { snackBar.error(t('error.default')); }).finally(() => { dropdownActivated.value = false; deleteLoading.value = false; });
}

const canTitleEdit = computed(() => gridPageTile.value !== null && !props.titleDisableEdit);
const title = computed(() => {
  if (props.title !== '') {
    return props.title;
  }

  if (gridPageTile.value === null) {
    return '';
  }
  return gridPageTile.value.title;
});

const noTitleText = computed(() => {
  if (!canTitleEdit.value) {
    return '';
  }
  return props.titleNoText;
});
const placeholder = computed(() => {
  if (!canTitleEdit.value) {
    return '';
  }
  return props.titlePlaceholder;
});

const inlineEditingSvc = useInlineEditing();
const titleEditMode = inlineEditingSvc.isInlineEditing(props.tile.uid);

const disableTitleEditMode = () => {
  debounceSvc.flushAll();
  dropdownActivated.value = false;
  inlineEditingSvc.reset();
};
const updateTitle = (val) => {
  debounceSvc.debounce(() => gridPageService.updateGridPageTile({ uid: props.tile.uid, title: val }), 300);
};

const canDescriptionEdit = computed(() => gridPageTile.value !== null && !props.descriptionDisableEdit);
const description = computed(() => {
  if (!canDescriptionEdit.value) {
    return '';
  }
  return gridPageTile.value.description;
});
const descriptionEditMode = ref(false);

const canEditBorder = computed(() => gridPageTile.value !== null && !props.readOnly && props.canEditBorder);
const toggleBorder = () => {
  gridPageService.updateGridPageTile({ uid: props.tile.uid, hideBorder: !gridPageTile.value.hideBorder });
};
function enableDescriptionEditMode() {
  descriptionEditMode.value = true;
}

function disableDescriptionEditMode() {
  debounceSvc.flushAll();
  descriptionEditMode.value = false;
  dropdownActivated.value = false;
}

if (titleEditMode.value) {
  enableDescriptionEditMode();
}

watch(titleEditMode, (val) => {
  if (val) {
    enableDescriptionEditMode();
  }
});

function updateDescription(val) {
  debounceSvc.debounce(() => gridPageService.updateGridPageTile({ uid: props.tile.uid, description: val }), 300);
}

const anyEditMode = computed(() => titleEditMode.value || descriptionEditMode.value || dropdownActivated.value);
const titleRef = ref(null);
const descriptionRef = ref(null);
const variableDescriptionRef = ref(null);
const staticDescriptionRef = ref(null);
const { isTruncated: isTitleEllipsisActive } = useElementsTruncate([titleRef]);
const { isTruncated: isDescriptionEllipsisActive } = useElementsTruncate([descriptionRef, variableDescriptionRef, staticDescriptionRef]);

const duplicateLoading = ref(false);
const duplicate = () => {
  duplicateLoading.value = true;
  return gridPageService.duplicateGridPageTile(gridPageTile.value)
    .catch(logCatch(() => { snackBar.error(); }))
    .finally(() => { dropdownActivated.value = false; duplicateLoading.value = false; });
};

</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
@import "shared/assets/scss/box-shadow";

.grid-item-header {
  z-index: 1;

  &.grid-item-drag-handle {
    cursor: grab;
  }

  &.-has-header {
    padding: .8rem 1.6rem;
  }

  &:not(.-has-header) {
    height: .5rem;
  }

  &.-read-only {
    cursor: inherit;
  }

  ._actions {
    display: none;
    position: absolute;
    top: .8rem;
    right: .8rem;
    background-color: white;
    border-radius: $btn-border-radius;

    @include box_shadow(1);

    &.-active {
      display: block;
    }
  }

  ._title {
    font-size: $font-size-5;
    font-weight: $font-weight-semibold;

    &:not(.-editing) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ._description {
    min-height: 2.1rem;
    font-size: $font-size-4;
    color: $font-color-secondary;
    display: flex;
    width: 100%;

    &:not(.-editing) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ._variable-description {
      flex: 1 1 auto;

      &:not(.-editing) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    ._static-description {
      flex: 0 0 auto;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }
  }
}
</style>
