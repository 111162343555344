<template>
  <m-content
    class="info-page"
    boxed-small
  >
    <div class="_content">
      <div class="_heading">
        {{ heading }}
      </div>
      <div class="_text">
        {{ text }}
      </div>
      <div
        v-if="errorText !== ''"
        class="_error"
      >
        {{ errorText }}
      </div>
      <div
        v-if="buttonText !== ''"
        class="_links"
      >
        <m-link :to="to">
          <m-btn>
            {{ buttonText }}
          </m-btn>
        </m-link>
      </div>
    </div>
  </m-content>
</template>

<script>

export default {
  name: 'InfoPage',
  props: {
    heading: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    to: {
      type: [String, Object],
      default: '/',
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .info-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: var(--viewport-height-100);

    ._content {
      ._heading {
        margin-bottom: 2rem;
        font-size: $font-size-10;

        @media (max-width: $screen-size-sm) {
          font-size: $font-size-8;
        }
      }

      ._text {
        margin-bottom: 3.2rem;
        font-size: $font-size-5;
      }

      ._error {
        margin-bottom: 3.2rem;
      }

      .btn {
        margin: 0;
      }
    }
  }
</style>
