<template>
  <div class="option-scope">
    <m-select
      v-if="!hideOp"
      v-model:value="selectedType"
      :items="operatorTypes"
      :read-only="readOnly"
      :disabled="disabled"
      class="_op"
    />
    <template
      v-if="!isEmpty"
    >
      <m-select
        v-model:value="selectedOptions"
        class="_select"
        full-width
        :items="optionsWithIcon"
        :read-only="readOnly"
        :disabled="disabled"
        :value-key="valueKey"
        :item-text="itemText"
        :max-tag-text-length="maxTagTextLength"
        multiple
        :placeholder="$t('optionScope.placeholder')"
        keep-open-on-click
        return-object
        show-search
        tags
      />
    </template>
  </div>
</template>

<script>
import { AND, EMPTY, NOT, NOT_EMPTY } from '@/lib/filter/scope-tree';
import { buildIconFromEntity } from 'shared/lib/icon';
import { copy } from 'shared/lib/copy';

export default {
  name: 'OptionScope',
  props: {
    value: {
      type: Object,
      required: true,
    },
    isNot: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideOp: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'uid',
    },
    itemText: {
      type: String,
      default: 'label.en',
    },
    maxTagTextLength: {
      type: Number,
      default: 0,
    },
    hideIsEmpty: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'change-not', 'change'],
  data() {
    return {
      selectedOptions: [],
      isEmpty: false,
    };
  },
  computed: {
    optionsWithIcon() {
      return this.options.map((o) => ({ ...o, icon: buildIconFromEntity(o) }));
    },
    operatorTypes() {
      if (this.hideIsEmpty) {
        return [
          { text: this.$t('optionScope.contains'), value: AND },
          { text: this.$t('optionScope.notContains'), value: NOT },
        ];
      }
      return [
        { text: this.$t('optionScope.contains'), value: AND },
        { text: this.$t('optionScope.notContains'), value: NOT },
        { text: this.$t('optionScope.isEmpty'), value: EMPTY },
        { text: this.$t('optionScope.isNotEmpty'), value: NOT_EMPTY },
      ];
    },
    selectedType: {
      get() {
        if (this.isNot) {
          if (this.isEmpty) {
            return NOT_EMPTY;
          }
          return NOT;
        }
        if (this.isEmpty) {
          return EMPTY;
        }
        return AND;
      },
      set(val) {
        this.updateEmpty(val);
        if ([NOT, NOT_EMPTY].includes(val)) {
          this.$emit('change-not', NOT);
          return;
        }
        this.$emit('change-not', AND);
      },
    },
    scope() {
      return {
        ...this.value,
        selectedOptions: this.selectedOptions,
        isEmpty: this.isEmpty,
      };
    },
  },
  methods: {
    updateEmpty(type) {
      if ([EMPTY, NOT_EMPTY].includes(type)) {
        this.isEmpty = true;
        return;
      }
      this.isEmpty = false;
    },
    updateValues() {
      let isEmpty = false;
      if (this.value !== null && typeof this.value.isEmpty !== 'undefined') {
        isEmpty = this.value.isEmpty;
      }
      this.isEmpty = isEmpty;

      if (this.value === null
        || this.value.selectedOptions === null
        || typeof this.value.selectedOptions === 'undefined'
      ) {
        this.selectedOptions = [];
        return;
      }
      this.selectedOptions = this.value.selectedOptions;
    },
    emitUpdate(val) {
      this.$emit('input', val);
      this.$emit('update:value', val);
      this.$emit('change', val);
    },
  },
  watch: {
    scope: {
      handler(newVal, oldVal) {
        if (JSON.stringify(oldVal) === JSON.stringify(newVal)) {
          return;
        }

        const cp = copy(newVal);
        this.emitUpdate(cp);
      },
      deep: true,
    },
    value: {
      handler() {
        this.updateValues();
      },
      deep: true,
    },
  },
  created() {
    this.updateValues();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .option-scope {
    display: flex;

    ._op {
      display: block;
      margin-right: .8rem;
    }

    ._select {
      min-width: 15rem;
    }
  }
</style>
