<template>
  <page-layout
    :class="['goals-explorer', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
        class="_header"
      >
        <template #actions>
          <div
            class="_actions"
          >
            <template
              v-if="!$store.state.breakpoint.smAndDown"
            >
              <favorite-button
                :title-suggestion="titleSuggestion"
                :icon-suggestion="iconSuggestion"
              />
            </template>
            <m-dropdown
              v-else
              :title="$t('general.actions')"
              class="actions-menu"
            >
              <m-btn
                icon="ellipsis"
                hide-border
                fab
              />
              <template #overlay>
                <m-card
                  no-padding
                  list
                >
                  <favorite-button
                    :title-suggestion="titleSuggestion"
                    :icon-suggestion="iconSuggestion"
                  />
                  <export-button
                    :selected-view="currentView"
                  />
                  <publish-button
                    v-if="currentView.isPublic"
                    :disabled="!canPublish"
                    :selected-view="currentView"
                  />
                </m-card>
              </template>
            </m-dropdown>
            <m-dropdown
              v-if="!$store.state.breakpoint.smAndDown"
              v-model:value="showFurtherActions"
              :title="$t('general.actions')"
              class="actions-menu"
            >
              <m-btn
                icon="ellipsis"
                hide-border
                fab
                small
              />

              <template #overlay>
                <m-card
                  no-padding
                  list
                >
                  <export-button
                    :selected-view="currentView"
                  />
                  <template
                    v-if="currentView.isPublic"
                  >
                    <m-divider xxs />
                    <publish-button
                      :selected-view="currentView"
                      :disabled="!canPublish"
                      @close="showFurtherActions = false"
                    />
                  </template>
                </m-card>
              </template>
            </m-dropdown>
          </div>
        </template>
      </page-top-bar>
    </template>
    <scroll-container
      :style="{ width: `${containerWidth}px` }"
      no-padding-bottom
    >
      <m-content
        padding-x="layout"
        class="_header"
      >
        <div
          :style="{ width: `${contentWidth}px` }"
        >
          <editable-page-header
            :key="title"
            :title="title"
            disabled
            size="small"
          />
        </div>
      </m-content>
      <goals-list-wrapper
        :header-slots="headerSlots"
        :can-create-goals="canCreate"
        :allowed-view-types="allowedViewTypes"
        show-views-selector
        show-create-view
        :selected-cycles="selectedCycles"
        :is-cycle-locked-on-view="isCycleLockedOnView"
        :change-selected-goal-cycles="changeSelectedGoalCycles"
        :lock-cycle-on-view="lockCycleOnView"
        show-errors
        fullscreen
        :base-filter="publishedAtFilter"
        stretch-content
        :default-props="defaultProps"
      />
    </scroll-container>
  </page-layout>
  <product-tour
    v-if="adminProductTourActive && !$store.state.breakpoint.smAndDown"
    :steps="productTourSteps"
    @finish="setAdminProductTourDone"
    @close="closeAdminProductTour"
  />
  <after-account-space-demo-modal
    :value="showAfterDemoModal"
    @created="showAfterDemoModal = false"
    @close="showAfterDemoModal = false"
  />
</template>

<script>
import AfterAccountSpaceDemoModal from '@/components/onboarding/AfterAccountSpaceDemoModal.vue';
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import ExportButton from '@/components/favorite/ExportButton.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import GoalsListWrapper from '@/components/goal/GoalsListWrapper.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ProductTour from '@/components/onboarding/ProductTour.vue';
import PublishButton from '@/components/favorite/PublishButton.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useAdaptiveGoalCycle from '@/composables/goal-cycle/adaptive-goal-cycle';
import useGoalDefaultProps from '@/composables/saved-views/goal-default-props';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND } from 'shared/api/query/constants';
import { SIDEBAR_SERVICE, SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { accessGroupFlag, featureFlag, onboardingGuideSteps, viewApplication, viewType } from 'shared/constants.json';
import { buildIcon } from 'shared/lib/icon';
import { computed, inject, provide, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'GoalsExplorer',
  components: {
    AfterAccountSpaceDemoModal,
    ProductTour,
    EditablePageHeader,
    ScrollContainer,
    PageLayout,
    GoalsListWrapper,
    ExportButton,
    FavoriteButton,
    PageTopBar,
    PublishButton,
  },
  setup() {
    const { publishedAtFilter } = usePublishedAtFilter();
    const { accountHasFeature, userHasRight } = useAccess();
    const { loggedInUser } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { goalSettings } = useGoalSettings();
    const accountSettingsSvc = useAccountSettings();
    const { markAsDone } = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
    const i18n = useI18n();

    const viewApp = viewApplication.goal;

    const defaultView = computed(() => ({
      title: i18n.t('list.cascade'),
      viewType: viewType.cascade,
      viewApplication: viewApp,
      params: {
        filter: { account: { uid: loggedInUserAccount.value.uid }, children: [], op: AND },
        applyFilterOnFirstLevelOnly: false,
        order: [],
        props: [],
      },
    }));

    const viewServiceInitService = useViewServiceInit();
    const defaultPropsSvc = useGoalDefaultProps();

    let routeAwareConfig = viewServiceInitService.defaultConfig();
    routeAwareConfig = viewServiceInitService.withDefaultView(routeAwareConfig, defaultView.value);
    routeAwareConfig = viewServiceInitService.withViewApplication(routeAwareConfig, viewApp);
    routeAwareConfig = viewServiceInitService.withDefaultProps(routeAwareConfig, defaultPropsSvc.defaultProps);

    const viewSvc = viewServiceInitService.routeAwareViewService(routeAwareConfig);
    provide(VIEWS_SERVICE, viewSvc);

    const titleSuggestion = computed(() => viewSvc.currentView.value.title);
    const iconSuggestion = computed(() => buildIcon(viewSvc.currentView.value.emoji));

    const { isCycleLockedOnView, goalCycleSvc, lockCycleOnView } = useAdaptiveGoalCycle(viewSvc);
    const { containerWidth, contentWidth } = inject(SIDEBAR_SERVICE);

    return {
      isCycleLockedOnView,
      goalCycleSvc,
      lockCycleOnView,

      publishedAtFilter,
      accountHasFeature,
      userHasRight,
      loggedInUser,
      account: loggedInUserAccount,

      goalSettings,

      defaultProps: defaultPropsSvc.defaultProps,

      currentView: viewSvc.currentView,
      viewService: viewSvc,
      showFurtherActions: ref(false),

      titleSuggestion,
      iconSuggestion,

      markAsDone,
      containerWidth,
      contentWidth,
    };
  },
  data() {
    return {
      showAfterDemoModal: false,
      headerSlots: [{ name: SLOTS.CYCLE_SELECTOR }, { name: SLOTS.FILTER }, { name: SLOTS.SORTER }, { name: SLOTS.STRING_FILTER }, { name: SLOTS.PROPERTIES }, { name: SLOTS.COMPARE_TO }, { name: SLOTS.PROGRESS_DISPLAY_OPTION }],
    };
  },
  computed: {
    selectedCycles() {
      return this.goalCycleSvc.composedSelectedCycles.value;
    },
    changeSelectedGoalCycles() {
      return this.goalCycleSvc.changeSelectedGoalCycles;
    },
    adminProductTourActive() {
      return this.$route.query.showTour === true || this.$route.query.showTour === 'true';
    },
    productTourSteps() {
      const steps = [
        {
          highlightedElement: 'help-and-support',
          heading: this.$t('productTour.home.masteringMooncampStep.heading'),
          content: this.$t('productTour.home.masteringMooncampStep.content'),
          placement: 'rightBottom',
        },
        {
          highlightedElement: 'home',
          heading: this.$t('productTour.home.personalHomePageStep.heading'),
          content: this.$t('productTour.home.personalHomePageStep.content'),
          placement: 'rightCenter',
        },
        {
          highlightedElement: 'spaces',
          heading: this.$t('productTour.home.spacesStep.heading'),
          content: this.$t('productTour.home.spacesStep.content'),
          placement: 'rightCenter',
        },
        {
          highlightedElement: 'goals',
          heading: this.$t('productTour.home.goalExplorerStep.heading'),
          content: this.$t('productTour.home.goalExplorerStep.content'),
          placement: 'rightCenter',
        },
        {
          highlightedElement: 'goal-cycle-selector',
          heading: this.$t('productTour.home.goalCycleStep.heading'),
          content: this.$t('productTour.home.goalCycleStep.content'),
          placement: 'bottomRight',
        },
        {
          highlightedElement: 'updates',
          heading: this.$t('productTour.home.checkInStep.heading'),
          content: this.$t('productTour.home.checkInStep.content'),
          placement: 'rightCenter',
        },
        {
          highlightedElement: 'planning',
          heading: this.$t('productTour.home.planningStep.heading'),
          content: this.$t('productTour.home.planningStep.content'),
          placement: 'rightCenter',
        },
      ];
      if (this.userHasRight([accessGroupFlag.goalDashboardView])) {
        steps.push(
          {
            highlightedElement: 'dashboard',
            heading: this.$t('productTour.home.dashboardStep.heading'),
            content: this.$t('productTour.home.dashboardStep.content'),
            placement: 'rightCenter',
          },
        );
      }
      if (this.loggedInUser.isAccountAdmin) {
        steps.push(
          {
            highlightedElement: 'settings',
            heading: this.$t('productTour.home.settingsStep.heading'),
            content: this.$t('productTour.home.settingsStep.content'),
            placement: 'rightTop',
          },
        );
      }
      return steps;
    },
    allowedViewTypes() {
      return [viewType.cascade, viewType.list, viewType.atlas, viewType.tree];
    },
    title() {
      return this.breadcrumbs[this.breadcrumbs.length - 1].title;
    },
    breadcrumbs() {
      return [
        {
          title: this.goalSettings.featureNamePlural,
          disabled: true,
          icons: [{ value: buildIcon('CompassOutlined_dark_grey') }],
        },
        {
          title: this.$t('navigation.explorer'),
          disabled: true,
        },
      ];
    },
    canCreate() {
      return this.userHasRight([accessGroupFlag.createGoal]);
    },
    canPublish() {
      if (!this.accountHasFeature([featureFlag.publishedViews])) {
        return false;
      }

      return this.userHasRight([accessGroupFlag.publicSavedViews]);
    },
  },
  methods: {
    closeAdminProductTour() {
      this.$router.push({ query: { ...this.$route.query, showTour: false } });
    },
    setAdminProductTourDone() {
      this.closeAdminProductTour();
      this.showAfterDemoModal = true;
      this.markAsDone([onboardingGuideSteps.productTour]);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .goals-explorer {
    ._actions {
      display: flex;
    }

    ._header {
      position: sticky;
      left: 0;
    }
  }
</style>
