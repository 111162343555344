<template>
  <div class="ms-teams-account-details">
    <div
      v-if="noAccountSpace"
      class="_not-found"
    >
      <div class="_title">
        🧐 {{ $t('msTeamsAccountDetails.unableToFindAccountSpace') }}
      </div>
      <m-btn
        :to="toTeamOkrs"
        color="primary"
      >
        {{ $t('msTeamsAccountDetails.goToBtn') }}
      </m-btn>
    </div>
    <template v-else>
      <space-details-template
        :key="space.uid"
        :tabs="tabs"
        :property="property"
        :space="space"
        :allow-edit="false"
        :show-top-bar="false"
        :show-mobile-menu-toggle="false"
        show-logout
      />
    </template>
  </div>
</template>

<script setup>
import SpaceDetailsTemplate from '@/views/SpaceDetailsTemplate.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import useSpaces from '@/composables/space/spaces';
import useViewNavigator from '@/composables/saved-views/navigator';
import {
  GOAL_FEED_VIEW,
  GOAL_INSIGHT_VIEW,
  GOAL_VIEW,
  UPDATE_VIEW,
  USER_VIEW,
} from '@/route-params';
import { accessGroupFlag, moduleFlag, propertyApplication, routeName, viewApplication } from 'shared/constants.json';
import { computed } from 'vue';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { accountHasFeature, userHasRight } = useAccess();
const { loggedInUserAccount: account } = useLoggedInUserAccount();
const { linkToView } = useViewNavigator();
const { accountSpace, activeSpacesTreeFlat } = useSpaces();
const { spaceProperty: property } = useProperties();

const space = computed(() => {
  if (!isNullOrUndefined(accountSpace.value)) {
    return accountSpace.value;
  }
  if (activeSpacesTreeFlat.value.length > 0) {
    return activeSpacesTreeFlat.value[0];
  }
  return null;
});
const noAccountSpace = computed(() => isNullOrUndefined(space.value));
const toTeamOkrs = { name: routeName.teamsTeamOkrs };

const tabs = computed(() => {
  const res = [];
  if (isNullOrUndefined(property.value)) {
    return res;
  }
  if (property.value.usedFor.includes(propertyApplication.goal) && accountHasFeature([moduleFlag.goals])) {
    res.push({
      title: account.value.goalSettings.featureNamePlural,
      to: linkToView(GOAL_VIEW, viewApplication.goalSpace, space.value),
      view: GOAL_VIEW,
    });
  }
  if (property.value.usedFor.includes(propertyApplication.goal) && accountHasFeature([moduleFlag.goals])) {
    res.push({
      title: t('navigation.feed'),
      to: linkToView(GOAL_FEED_VIEW, viewApplication.updateFeedSpace, space.value),
      view: GOAL_FEED_VIEW,
    });
  }
  if (property.value.usedFor.includes(propertyApplication.update) && accountHasFeature([moduleFlag.updates])) {
    res.push({
      title: t('navigation.updatesExplorer'),
      to: linkToView(UPDATE_VIEW, viewApplication.updateSpace, space.value),
      view: UPDATE_VIEW,
    });
  }
  if (property.value.usedFor.includes(propertyApplication.goal) && accountHasFeature([moduleFlag.goals]) && userHasRight([accessGroupFlag.goalDashboardView])) {
    res.push({
      title: t('navigation.goalInsights'),
      to: linkToView(GOAL_INSIGHT_VIEW, viewApplication.insightSpace, space.value),
      view: GOAL_INSIGHT_VIEW,
    });
  }
  if (property.value.usedFor.includes(propertyApplication.user)) {
    res.push({
      title: t('navigation.users'),
      to: linkToView(USER_VIEW),
      view: USER_VIEW,
    });
  }
  return res;
});

onBeforeRouteLeave((to) => {
  if ([routeName.teamsAccountOkrs, routeName.teamsTeamOkrs, routeName.logIn, routeName.logout, routeName.teamsLogin].includes(to.name)) {
    return true;
  }
  window.open(`/#${to.fullPath}`);
  return false;
});

</script>

<style scoped lang="scss" type="text/scss">
.ms-teams-account-details {
  ._not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: var(--viewport-height-100);

    ._title {
      font-size: $font-size-8;
      margin-top: 1.6rem;
      margin-bottom: 2rem;
    }
  }
}
</style>
