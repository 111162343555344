<template>
  <full-screen-spinner v-if="loading" />
  <div
    v-else
    class="page-layout"
    :style="{ 'grid-template-rows': hasTopBar ? '4.5rem 1fr' : '1fr' }"
  >
    <div
      v-if="hasTopBar"
      class="_top-bar"
    >
      <slot name="topBar" />
    </div>
    <div
      class="_frame"
      :style="{ width: calculatedWidth, height }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import useExport from '@/composables/export/export';
import { SETTINGS_LAYOUT } from '@/lib/layout';
import { SIDEBAR_SERVICE } from '@/lib/constants';
import { inject } from 'vue';
import { routeName } from 'shared/constants.json';

export default {
  name: 'PageLayout',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 0,
    },
    hideTopBar: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isPrint } = useExport();
    const { desktopSidebarHidden } = inject(SIDEBAR_SERVICE);
    return { isPrint, desktopSidebarHidden };
  },
  components: { FullScreenSpinner },
  data() {
    return { headerHeight: '4.5rem' };
  },
  computed: {
    hasTopBar() {
      return !this.hideTopBar;
    },
    height() {
      if (this.isPrint) {
        return 'auto';
      }

      if (!this.hasTopBar) {
        return 'var(--viewport-height-100)';
      }

      if (this.modal) {
        return `calc(var(--viewport-height-100) - 14.4rem - ${this.headerHeight})`;
      }

      return `calc(var(--viewport-height-100) - ${this.headerHeight}`;
    },
    calculatedWidth() {
      if (this.isPrint) {
        return 'calc(100vw - 6rem)';
      }

      if (this.$store.state.breakpoint.smAndDown) {
        return '100vw';
      }

      if (this.modal) {
        return this.$modalSizes.xl;
      }

      if (this.width !== 0) {
        return `${this.width}px`;
      }

      const withSidebar = 'calc(100vw - 24rem)';
      if (typeof this.$route.meta !== 'undefined' && typeof this.$route.meta.layout !== 'undefined' && this.$route.meta.layout === SETTINGS_LAYOUT) {
        return withSidebar;
      }

      if (this.desktopSidebarHidden || [routeName.teamsTeamOkrs, routeName.teamsAccountOkrs, routeName.publishedView].includes(this.$route.name)) {
        return '100vw';
      }

      return withSidebar;
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .page-layout {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
    border-radius: $default-border-radius;

    ._frame {
      position: relative;
      max-height: 100%;
    }
  }
</style>
