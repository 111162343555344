import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import { camelCase } from 'lodash-es';
import { computed } from 'vue';
import { dataSourceIcon } from '@/lib/data-source';
import { dataSourceType, goalProgressMeasurement } from 'shared/constants.json';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { useI18n } from 'vue-i18n';

export default function useGoalDatasource(goal) {
  const { accountSettings } = useAccountSettings();
  const i18n = useI18n();

  const query = computed(() => {
    if (![goalProgressMeasurement.continuous, goalProgressMeasurement.threshold].includes(goal.value.progressMeasurement)) {
      return null;
    }

    if ((accountSettings.value.usesSheets || accountSettings.value.usesExcel) && !isNullOrUndefined(goal.value.spreadsheetCell)) {
      return goal.value.spreadsheetCell;
    }
    if (accountSettings.value.usesJira && !isNullOrUndefined(goal.value.jiraQuery)) {
      return goal.value.jiraQuery;
    }
    if (accountSettings.value.usesHubspot && !isNullOrUndefined(goal.value.hubspotQuery)) {
      return goal.value.hubspotQuery;
    }
    if (accountSettings.value.usesAsana && !isNullOrUndefined(goal.value.asanaQuery)) {
      return goal.value.asanaQuery;
    }
    if (accountSettings.value.usesSalesforce && !isNullOrUndefined(goal.value.salesforceQuery)) {
      return goal.value.salesforceQuery;
    }
    if (accountSettings.value.usesMSPowerBI && !isNullOrUndefined(goal.value.msPowerBiQuery)) {
      return goal.value.msPowerBiQuery;
    }
    if (accountSettings.value.usesMSPlanner && !isNullOrUndefined(goal.value.msPlannerQuery)) {
      return goal.value.msPlannerQuery;
    }
    return null;
  });

  const dataSource = computed(() => {
    if (query.value !== null) {
      return query.value.dataSource;
    }
    return null;
  });

  const type = computed(() => {
    if (![goalProgressMeasurement.continuous, goalProgressMeasurement.threshold].includes(goal.value.progressMeasurement)) {
      return null;
    }

    if (!isNullOrUndefined(goal.value.spreadsheetCell)) {
      if (!isNullOrUndefined(goal.value.spreadsheetCell.dataSource)) {
        return goal.value.spreadsheetCell.dataSource.type;
      }
      return dataSourceType.sheets;
    }
    if (!isNullOrUndefined(goal.value.jiraQuery)) {
      return dataSourceType.jira;
    }
    if (!isNullOrUndefined(goal.value.hubspotQuery)) {
      return dataSourceType.hubspot;
    }
    if (!isNullOrUndefined(goal.value.asanaQuery)) {
      return dataSourceType.asana;
    }
    if (!isNullOrUndefined(goal.value.salesforceQuery)) {
      return dataSourceType.salesforce;
    }
    if (!isNullOrUndefined(goal.value.msPowerBiQuery)) {
      return dataSourceType.msPowerBi;
    }
    if (!isNullOrUndefined(goal.value.msPlannerQuery)) {
      return dataSourceType.msPlanner;
    }

    return null;
  });

  const title = computed(() => {
    if (type.value !== null) {
      return i18n.t(`dataSources.${camelCase(type.value)}`);
    }

    return '';
  });

  const icon = computed(() => {
    if (type.value !== null) {
      return dataSourceIcon({ type: type.value });
    }

    return '';
  });

  return {
    query,
    dataSource,
    type,
    title,
    icon,
  };
}
