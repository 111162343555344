<template>
  <m-dropdown
    ref="dropdown"
    v-model:value="showMenu"
    :match-trigger-width="matchTriggerWidth"
    :class="['m-select', small ? '-small' : '', xs ? '-xs' : '']"
    :placement="popup ? 'onTopLeft' : 'bottomCenter'"
    :disabled="disabled || readOnly || loading"
    :title="title"
    :block="fullWidth"
    @hide="hide"
    @keydown="handleKeyDown"
  >
    <m-focusable
      ref="trigger"
      :hide-border="hideBorder"
      class="_input"
      type="clickable"
      :has-error="hasError"
      :placeholder="placeholder === '' ? $t('general.empty') : placeholder"
      :placeholder-icon="placeholderIcon"
      :show-placeholder="valueIsEmpty && !hidePlaceholder"
      :hide-hover="hideHover"
      :small="small"
      :xs="xs"
      :disabled="disabled"
      :read-only="readOnly"
      :full-width="fullWidth"
      :light="light"
      :super-light="superLight"
      :m-style="mStyle"
      :wrap="!nowrap"
      has-tags
      tabindex="0"
      @keydown.enter="show"
      @click="show"
    >
      <div
        v-if="loaded"
        :class="['_value', nowrap ? '-nowrap' : '']"
      >
        <span
          v-if="label !== ''"
          class="_label"
        >
          {{ label }}
        </span>
        <m-tag-list
          :small="small"
          :xs="xs"
          :wrap="!nowrap"
        >
          <template
            v-for="(v, j) in iterableValue"
          >
            <template
              v-if="j < truncateSelectedItems || truncateSelectedItems === -1"
            >
              <m-tag
                :key="j"
                :color="getOption(v) === null || (!tags && !multiple) || hasCustomItem ? 'none' : getOption(v).item.color"
                :custom-color="getOption(v)?.item?.customColor"
                :automatic-color-fallback="automaticColor"
                :type="getType(v)"
                :title="getSelectedLabel(v)"
                :icon="getOption(v) === null ? '' : getOption(v).icon"
                :style="triggerStyle"
                :m-style="{ ...defaultMStyle, ...mStyle }"
                :small="!small && !xs"
                :xs="small"
                :has-default-slot="hasCustomItem"
                :xxs="xs"
              >
                <slot
                  v-if="hasCustomItem"
                  :item="v"
                  :is-trigger="true"
                  :index="j"
                  name="item"
                />
              </m-tag>
            </template>
          </template>
          <template v-if="iterableValue.length > truncateSelectedItems && truncateSelectedItems !== -1">
            <m-tag
              :color="(!tags && !multiple) || hasCustomItem ? 'none' : (light ? 'light' : 'default')"
              :title="`+${iterableValue.length - truncateSelectedItems} ${$t('mSelect.more', iterableValue.length - truncateSelectedItems)}`"
              :style="triggerStyle"
              :m-style="{ ...defaultMStyle, ...mStyle }"
              :small="!small && !xs"
              :xs="small"
              :xxs="xs"
            />
          </template>
        </m-tag-list>
      </div>
      <template
        v-if="!hideArrow && !disabled && !readOnly"
        #suffix
      >
        <m-icon
          class="_icon"
          :size="iconSize"
          :color="$colors.grey.lighten1"
          :type="loading ? 'loading': 'down'"
          :spin="loading"
        />
      </template>
    </m-focusable>
    <template #overlay>
      <m-card
        ref="overlay"
        :has-header="popup"
        :style="overlayStyle"
        :class="['_m-select-overlay', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
        list
        no-padding
        tabindex="-1"
        :border-radius="popup ? 'small' : 'default'"
      >
        <m-focusable
          v-if="popup"
          hide-border
          hide-hover
          has-tags
          :full-width="fullWidth"
          :class="['_input', '-popup']"
        >
          <m-tag-list
            wrap
          >
            <m-tag
              v-if="valueIsEmpty && !showSearch && !hidePlaceholder"
              :title="emptyPlaceholder"
              color="light"
              :style="triggerStyle"
              small
            />
            <template
              v-for="(v, j) in iterableValue"
              :key="j"
            >
              <m-tag
                :title="!hasCustomItem ? getLabel(v) : ''"
                :icon="getOption(v) === null ? '' : getOption(v).icon"
                :color="(!tags && !multiple) || hasCustomItem ? 'none' : v.color"
                :automatic-color-fallback="automaticColor"
                :style="triggerStyle"
                :type="v.type"
                :has-default-slot="hasCustomItem"
                small
                :closeable="clearable || tags"
                @close="deselect(getOption(v))"
              >
                <slot
                  v-if="hasCustomItem"
                  :item="v"
                  name="item"
                />
              </m-tag>
            </template>
            <div class="_search">
              <m-text-field
                v-if="showSearch"
                ref="search"
                v-model:value="search"
                :placeholder="valueIsEmpty ? emptyPlaceholder : ''"
                inherit-styling
                auto-focus
                @keydown="handleSearchKeyDown"
                @input="changeSearch"
                @blur="focusDropdown"
              />
            </div>
          </m-tag-list>
        </m-focusable>
        <div class="_body">
          <template v-if="showSearch">
            <m-content
              v-if="!popup"
              padding-xs
              class="_m-select-search"
            >
              <m-text-field
                v-model:value="search"
                :placeholder="$t('mSelect.searchPlaceholder')"
                full-width
                auto-focus
                has-background
                @input="changeSearch"
                @blur="focusDropdown"
              />
            </m-content>
          </template>
          <m-card-item
            v-if="showDescription"
            :clickable="false"
            :color="$colors.grey.lighten1"
            no-hover
            class="_sub-heading"
          >
            <template v-if="description === ''">
              {{ $t('mSelect.pickAnItem') }}
            </template>
            <template v-else>
              {{ description }}
            </template>
          </m-card-item>
          <m-card-item
            v-if="filteredOptions.length === 0"
            :clickable="false"
            no-hover
            class="_no-items"
            light
          >
            {{ $t('mSelect.noItems') }}
          </m-card-item>
          <m-endless-scroll-list @visible="loadMore">
            <template
              v-for="(item, index) in list"
              :key="item.key"
            >
              <sub-heading
                v-if="groups[index] !== undefined && search === ''"
                :title="groups[index].label"
              />
              <m-select-item
                :focused="index === focusedItem"
                :item="item"
                :has-custom-item="hasCustomItem"
                :trigger-style="triggerStyle"
                :automatic-color="automaticColor"
                :tag-background-color="tagBackgroundColor"
                :tag-text-color="tagTextColor"
                :val="val"
                :tags="tags"
                :tooltip-placement="tooltipPlacement"
                :has-tooltip="hasTooltip"
                no-hover
                class="_card-item"
                @click="select(item)"
                @focus="focusedItem = index"
              >
                <slot
                  v-if="hasCustomItem"
                  :item="item.value"
                  name="item"
                />
                <template #tooltip>
                  <slot
                    :item="item"
                    name="item-tooltip"
                  />
                </template>
              </m-select-item>
            </template>
          </m-endless-scroll-list>
        </div>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import MEndlessScrollList from 'shared/components/base/MEndlessScrollList.vue';
import MSelectItem from 'shared/components/base/MSelectItem.vue';
import MTextField from 'shared/components/base/MTextField.vue';
import SubHeading from 'shared/components/SubHeading.vue';
import stringify from 'fast-json-stable-stringify';
import { copy } from 'shared/lib/copy';
import { getColor } from 'shared/lib/color-map';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';
import { uniqBy } from 'lodash-es';

const itemsPerPage = 20;

export default {
  name: 'MSelect',
  props: {
    ...mStyleProps,
    items: {
      type: Array,
      default: () => ([]),
    },
    groupedItems: {
      type: Array,
      default: () => ([]),
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    superLight: {
      type: Boolean,
      default: false,
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    tags: {
      type: Boolean,
      default: false,
    },
    hasCustomItem: {
      type: Boolean,
      default: false,
    },
    hideSelectedValues: {
      type: Boolean,
      default: false,
    },
    matchTriggerWidth: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Object, Array, Number, Boolean],
      default: () => [],
    },
    small: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    maxTagTextLength: {
      type: Number,
      default: 0,
    },
    iconSize: {
      type: String,
      default: '11',
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    optionKey: {
      type: String,
      default: '',
    },
    itemText: {
      type: String,
      default: 'text',
    },
    selectedLabel: {
      type: String,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    filterFunction: {
      type: Function,
      default: (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    popup: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    automaticColor: {
      type: Boolean,
      default: false,
    },
    cacheItems: {
      type: Boolean,
      default: false,
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    keepOpenOnClick: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    triggerStyle: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },
    emptyItem: {
      type: Object,
      default: () => ({ text: '', value: '' }),
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    tooltipPlacement: {
      type: String,
      default: 'top',
    },
    truncateSelectedItems: {
      type: Number,
      default: -1,
    },
    nowrap: {
      type: Boolean,
      default: false,
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'input',
    'update:value',
    'change',
    'dropdown-visible-change',
    'search',
    'close',
    'item-click',
  ],
  components: { SubHeading, MEndlessScrollList, MTextField, MSelectItem },
  data() {
    return {
      localValue: [],
      showMenu: false,
      search: '',
      focusedItem: 0,
      localItems: [],
      loaded: false,
      amountOfItems: itemsPerPage,
      defaultMStyle: { fontStyle: { fontSize: 'regular' } },
    };
  },
  computed: {
    title() {
      if (this.description !== '') {
        return this.description;
      }

      return this.$t('mSelect.title');
    },
    emptyPlaceholder() {
      if (this.hidePlaceholder) {
        return '';
      }

      if (this.placeholder === '') {
        return this.$t('mSelect.placeholder');
      }

      return this.placeholder;
    },
    iterableValue() {
      if (Array.isArray(this.localValue)) {
        return this.localValue;
      }

      if (this.localValue === null) {
        return [];
      }

      if (this.localValue === '') {
        return [];
      }

      return [this.localValue];
    },
    overlayStyle() {
      const style = {};
      if (!this.matchTriggerWidth) {
        if (this.$store.state.breakpoint.smAndDown) {
          style.maxWidth = '100vw';
        } else {
          style.maxWidth = '40rem';
        }
      }

      return style;
    },
    valueIsEmpty() {
      if (this.val === null) {
        return true;
      }

      return this.val.length === 0;
    },
    val() {
      return this.getValue(this.localValue);
    },
    mode() {
      if (this.multiple) {
        return 'multiple';
      }

      return 'default';
    },
    list() {
      return this.filteredOptions.slice(0, this.amountOfItems);
    },
    filteredOptions() {
      const filteredOptions = this.options.filter((o) => {
        if (typeof o.item === 'undefined') {
          return true;
        }

        if (typeof o.item.disabled === 'undefined') {
          return true;
        }

        return !o.item.disabled;
      }).filter((o) => this.filterFunction(this.search, o));
      if (this.hideSelectedValues) {
        return filteredOptions
          .filter((o) => !this.keyIsSelected(o.key));
      }
      return filteredOptions;
    },
    options() {
      return this.localItems.map((item) => this.createOption(item));
    },
    groups() {
      if (this.groupedItems.length === 0) {
        return {};
      }

      let counter = 0;
      return this.groupedItems.reduce((res, next) => {
        if (next.options === undefined) {
          return res;
        }
        res[counter] = { label: next.label };
        counter += next.options.length;
        return res;
      }, {});
    },
    calculatedItems() {
      if (this.groupedItems.length === 0) {
        return this.items;
      }

      return this.groupedItems.reduce((res, next) => {
        if (next.options === undefined) {
          res.push(next);
          return res;
        }
        res.push(...next.options);
        return res;
      }, []);
    },
  },
  methods: {
    resolveStyles,
    loadMore() {
      this.amountOfItems += itemsPerPage;
    },
    hide() {
      this.amountOfItems = itemsPerPage;
      this.showMenu = false;
      this.$refs.trigger.$el.focus();
      this.$emit('close');
    },
    tagTextColor(v = { color: null }) {
      if (v === null || v.color !== null) {
        return this.$colors.grey.darken4;
      }

      if (this.light) {
        return this.$colors.grey.lighten1;
      }

      return '';
    },
    tagBackgroundColor(v = { color: null }) {
      if (v.color !== null && typeof v.color !== 'undefined') {
        return getColor(v.color);
      }

      if (this.light) {
        return 'transparent';
      }

      if (!this.tags) {
        return 'transparent';
      }

      return '';
    },
    show() {
      if (this.disabled || this.loading) {
        return;
      }

      this.showMenu = true;
    },
    focusDropdown() {
      if (typeof this.$refs.dropdown === 'undefined') {
        return;
      }

      this.$refs.dropdown.focus();
    },
    clear() {
      if (this.multiple) {
        this.$emit('input', []);
        this.$emit('update:value', []);
        this.$emit('change', []);
        this.localValue = [];
        return;
      }
      this.localValue = null;
      this.$emit('input', null);
      this.$emit('update:value', null);
      this.$emit('change', null);
    },
    handleSearchKeyDown(event) {
      if (event.key !== 'Backspace' || this.search !== '') {
        return;
      }
      if (this.multiple) {
        if (this.localValue.length === 0) {
          return;
        }
        const item = this.getOption(
          this.localValue[this.localValue.length - 1],
        );
        if (item === null) {
          return;
        }

        this.select(item);
        return;
      }

      if (this.localValue === null) {
        return;
      }
      this.deselect(this.getOption(this.localValue));
    },
    getOption(value) {
      if (value === null) {
        return null;
      }

      if (this.returnObject) {
        const filtered = this.options.filter((o) => this.equalP(o.value, value[this.valueKey]));
        if (filtered.length !== 1) {
          return null;
        }

        return filtered[0];
      }

      const filtered = this.options.filter((o) => this.equalP(o.value, value));
      if (filtered.length !== 1) {
        return null;
      }

      return filtered[0];
    },
    equalP(a, b) {
      if (typeof a === 'number' && typeof b === 'number') {
        return a === b;
      }

      if (typeof a === 'string' && typeof b === 'string') {
        return a === b;
      }

      return this.base64(a) === this.base64(b);
    },
    getType(value) {
      const option = this.getOption(value);
      if (option === null) {
        return '';
      }

      return option.type;
    },
    getSelectedLabel(value) {
      const option = this.getOption(value);
      if (option === null) {
        return '';
      }

      if (
        this.maxTagTextLength !== 0
        && option.selectedLabel.length > this.maxTagTextLength
      ) {
        return `${option.selectedLabel.slice(0, this.maxTagTextLength)}...`;
      }

      return option.selectedLabel;
    },
    getLabel(value) {
      const option = this.getOption(value);
      if (option === null) {
        return '';
      }

      if (
        this.maxTagTextLength !== 0
        && option.label.length > this.maxTagTextLength
      ) {
        return `${option.label.slice(0, this.maxTagTextLength)}...`;
      }

      return option.label;
    },
    createOption(item) {
      if (!(item instanceof Object)) {
        return {
          label: item,
          selectedLabel: item,
          value: item,
          key: this.base64(item),
          selectable: true,
          text: item,
          icon: '',
          type: '',
        };
      }

      const selectedLabelKey = this.selectedLabel === '' ? this.itemText : this.selectedLabel;
      const optionKey = this.optionKey === '' ? this.valueKey : this.optionKey;
      return {
        label: this.byString(item, this.itemText),
        selectedLabel: this.byString(item, selectedLabelKey),
        value: item[this.valueKey],
        key: this.base64(item[optionKey]),
        selectable: item.selectable === undefined ? true : item.selectable,
        icon: item.icon,
        type: item.type,
        color: item.color,
        item,
      };
    },
    selectFocusedItem() {
      this.select(this.filteredOptions[this.focusedItem]);
    },
    deselect(item) {
      if (!this.multiple) {
        this.clear();
        return;
      }

      this.select(item);
    },
    select(item) {
      this.emitItemClick(item.key);

      if (item.selectable) {
        this.change(item.key);
      }
      this.search = '';

      if (this.keepOpenOnClick || this.multiple) {
        return;
      }
      this.hide();
    },
    dropdownVisibleChange(value) {
      this.$emit('dropdown-visible-change', value);
    },
    base64(obj) {
      if (typeof obj === 'undefined' || obj === null) {
        return -1;
      }

      return btoa(stringify(obj));
    },
    valueByKey(key) {
      const filtered = this.options.filter((i) => i.key === key);
      if (filtered.length === 0) {
        return null;
      }
      return filtered[0];
    },
    change(key) {
      this.updateVal(key);
    },
    changeSearch(value) {
      this.search = value;
      this.$emit('search', this.search);
    },
    byString(o, s) {
      const str = s.replace(/\[(\w+)\]/g, '.$1').replace(/^\./, '');
      const a = str.split('.');
      let obj = o;
      for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in obj) {
          obj = obj[k];
        }
      }

      return obj;
    },
    getValue(values) {
      const getVal = (value) => {
        let valueObject = value;
        if (this.returnObject && value !== null) {
          valueObject = value[this.valueKey];
        }

        if (this.returnObject && value === null) {
          valueObject = null;
        }

        const val = this.valueByKey(this.base64(valueObject));
        if (val === null) {
          return null;
        }
        return val.key;
      };

      if (!this.multiple) {
        return getVal(values);
      }

      return values.map((v) => getVal(v)).filter((v) => v !== null);
    },
    getReturnValue(key) {
      if (this.options.length === 0) {
        return this.localValue;
      }

      if (!this.multiple) {
        const val = this.valueByKey(key);
        if (!this.returnObject) {
          return val.value;
        }

        return this.localItems.filter(
          (i) => this.base64(i[this.valueKey]) === key,
        )[0];
      }

      if (this.keyIsSelected(key)) {
        const val = this.valueByKey(key);
        if (!this.returnObject) {
          return this.localValue.filter((v) => v !== val.value);
        }

        return this.localValue.filter((v) => v[this.valueKey] !== val.value);
      }

      if (!this.returnObject) {
        const val = [...this.localValue];
        val.push(
          ...this.options.filter((i) => i.key === key).map((i) => i.value),
        );
        return val;
      }

      const val = [...this.localValue];
      val.push(
        ...this.localItems.filter((i) => this.base64(i[this.valueKey]) === key),
      );
      return val;
    },
    keyIsSelected(key) {
      if (this.valueIsEmpty) {
        return false;
      }

      if (this.val === null) {
        return false;
      }

      return this.val === key || this.val.includes(key);
    },
    emitItemClick(key) {
      let returnVal = this.getReturnValue(key);
      if (typeof returnVal === 'undefined') {
        returnVal = null;
      }
      this.$emit('item-click', returnVal);
    },
    updateVal(key) {
      let returnVal = this.getReturnValue(key);
      if (typeof returnVal === 'undefined') {
        returnVal = null;
      }
      this.$emit('input', returnVal);
      this.$emit('update:value', returnVal);
      this.$emit('change', returnVal);
      this.localValue = copy(returnVal);
    },
    moveFocusDown() {
      if (this.focusedItem === this.filteredOptions.length - 1) {
        return;
      }

      this.focusedItem += 1;
    },
    moveFocusUp() {
      if (this.focusedItem === 0) {
        return;
      }

      this.focusedItem -= 1;
    },
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        if (this.filteredOptions.length === 0) {
          return;
        }
        this.selectFocusedItem();
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      if (event.code === 'ArrowDown' || event.which === 40) {
        event.preventDefault();
        event.stopPropagation();
        this.moveFocusDown();
        return;
      }

      if (event.code === 'ArrowUp' || event.which === 38) {
        event.preventDefault();
        event.stopPropagation();
        this.moveFocusUp();
      }
    },
  },
  watch: {
    search() {
      this.focusedItem = 0;
    },
    showMenu(value) {
      this.dropdownVisibleChange(value);
    },
    items() {
      if (this.cacheItems) {
        this.localItems = uniqBy(
          [...this.localItems, ...this.calculatedItems],
          (el) => el[this.valueKey],
        );
        return;
      }

      this.localItems = this.calculatedItems;
    },
    value() {
      if (stringify(this.localValue) === stringify(this.value)) {
        return;
      }
      this.localValue = copy(this.value);
    },
    loading() {
      this.loaded = true;
    },
    filteredOptions(val) {
      if (this.focusedItem === 0) {
        return;
      }
      if (val.length === 0) {
        this.focusedItem = 0;
        return;
      }
      if (val.length - 1 < this.focusedItem) {
        this.focusedItem = this.filteredOptions.length - 1;
      }
    },
  },
  mounted() {
    if (!this.loading) {
      this.loaded = true;
    }
    if (this.autoFocus) {
      this.show();
    }
  },
  created() {
    this.localValue = copy(this.value);
    this.localItems = this.calculatedItems;
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import "shared/assets/scss/box-shadow";

  ._input {
    align-items: center;
    width: 100%;
    border-radius: $input-field-border-radius;

    &:focus {
      outline: none;
    }

    ._value {
      display: flex;
      align-items: baseline;

      ._label {
        margin-right: .4rem;
        color: $font-color-tertiary;
      }
    }

    ._search {
      display: flex;
      flex: 1 1 10rem;
      align-items: center;
      padding-left: .4rem;
      margin-top: -.2rem;
      background-color: inherit;
      border: none;

      &:focus {
        outline: none;
      }
    }

    &.-popup {
      background-color: map_get($grey, "lighten-5");
      border-bottom: 1px solid $border-color;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .m-select {
    border-radius: $input-field-border-radius;
  }

  ._m-select-overlay {
    ._body {
      max-height: 30rem;
      overflow: auto;

      ._card-item {
        line-height: 1.15;

        ._pre {
          margin-right: .6rem;
        }

        ._item {
          display: inline-flex;
          align-items: center;
        }
      }
    }

    ._sub-heading {
      font-size: $font-size-2;
    }

    ._no-items {
      color: $font-color-secondary;
    }

    &.-mobile {
      ._input {
        margin-bottom: 2rem;
        background-color: white;
        border-radius: 0;

        &.-popup {
          padding: .8rem;
        }
      }
    }
  }
</style>
