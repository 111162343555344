import useAccess from '@/composables/access/access';
import useGoalAccessPolicyLinks from '@/composables/goal/access-policy-links';
import useRepo from '@/nebula/repo';
import useSetParents from '@/composables/goal/set-parents';
import useSnackBar from '@/composables/snackbar';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { RESULT } from 'shared/api/query/constants';
import { experimentFlag } from 'shared/experiments.json';
import { goalById, goalChildren, goalParents } from '@/api/query/nebula/goal';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { intersection, unique } from 'shared/lib/array/array';
import { stripPropertyValue } from '@/lib/stripper';
import { updateListForGoal } from '@/api/query/nebula/update';
import { useI18n } from 'vue-i18n';

export default function useGoals() {
  const repo = useRepo(goalConfig.model);
  const i18n = useI18n();
  const snackbar = useSnackBar();

  const { searchLinkableAccessPolicies } = useGoalAccessPolicyLinks();

  const getGoals = ({
    queries,
    customFuncHandler = [],
  }) => repo.query(queries, { interceptors: customFuncHandler });

  const getGoal = (id) => repo.query(
    [
      ...goalById(id, RESULT),
      ...goalParents(id, 'goalParents'),
      ...goalChildren(id, 'children'),
      ...updateListForGoal(id, 'updates'),
    ],
  ).then((data) => {
    if (data[RESULT].length === 0) {
      return data;
    }

    if (typeof data[RESULT][0] === 'undefined') {
      return data;
    }

    repo.updateSingle({ uid: id, updatesLoaded: true }, { commitToRemote: false });

    return data;
  });

  const progressRecalculationTriggers = [
    goalConfig.edges.progressMeasurement,
    goalConfig.edges.start,
    goalConfig.edges.end,
    goalConfig.edges.threshold,
    goalConfig.edges.thresholdTargetArea,
    goalConfig.edges.disableStatusAutoUpdate,
    goalConfig.edges.goalCycle,
    goalConfig.edges.paysOnto,
    goalConfig.edges.publishedAt,
    goalConfig.edges.unboundedProgress,
  ];
  const updateSingle = (entity, options, hookParameter) => {
    if (intersection(Object.keys(entity), progressRecalculationTriggers).length > 0) {
      return repo.updateSingle(entity, { ...options, ignoreResponse: false }, hookParameter);
    }
    return repo.updateSingle(entity, options, hookParameter);
  };

  const updateMultiple = (entities, options, hookParameter) => {
    const extraOptions = {};
    if (intersection(unique(entities.map((e) => Object.keys(e)).flat()), progressRecalculationTriggers).length > 0) {
      extraOptions.ignoreResponse = false;
    }
    if (intersection(unique(entities.map((e) => Object.keys(e)).flat()), [goalConfig.edges.accessPolicy]).length > 0) {
      extraOptions.ignoreResponse = false;
    }
    return repo.updateMultiple(entities, { ...options, ...extraOptions }, hookParameter);
  };

  const { changeParents } = useSetParents({
    goalsSvc: { selectMultiple: repo.selectMultiple, updateMultiple },
    i18n,
    EventBus,
    snackbar,
  });

  const createMultiple = (goals) => repo.createMultiple(goals).then((goals) => {
    EventBus.$emit(EVENTS.GOAL.GOALS_CREATED, { goals });
    return goals;
  });

  const mutateMultiple = (goals) => {
    const goalsToCreate = goals.filter((g) => g.uid === undefined || g.uid === 0);
    return repo.mutateMultiple(goals).then((goals) => {
      EventBus.$emit(EVENTS.GOAL.GOALS_CREATED, { goals: goalsToCreate });
      return goals;
    });
  };

  const getEdgeName = (property) => {
    if (property.edgeName !== undefined) {
      return property.edgeName;
    }
    if (property.type !== undefined) {
      return property.type;
    }
    throw new Error('edge name of property not found');
  };

  const transformProperties = ({ properties, value }) => {
    delete value.uid;
    return properties.map((pv) => {
      if (pv.property.uid !== value.property.uid) {
        return pv;
      }
      return { ...pv, ...value };
    });
  };

  const { accountHasFeature } = useAccess();
  const updateProperty = (value, property, goals) => {
    const edge = getEdgeName(property);
    if ([goalConfig.edges.description, goalConfig.edges.goalCycle].includes(edge)) {
      return updateMultiple(goals.map((goal) => ({ uid: goal.uid, [edge]: value })));
    }
    if (edge === goalConfig.edges.parents) {
      return changeParents({ goals, parents: value.map((uid) => ({ uid })) }).then(() => goals);
    }
    return updateMultiple(goals.map((goal) => {
      const properties = transformProperties({ properties: goal.properties, value }).map(stripPropertyValue);
      const updatedGoal = {
        uid: goal.uid,
        properties,
      };
      if (accountHasFeature([experimentFlag.accessRights2])) {
        const linkableAPs = searchLinkableAccessPolicies({ properties });
        const existingAPLinks = goal.accessPolicy.links.filter((apLink) => linkableAPs.find((ap) => ap.uid === apLink.link.uid) !== undefined);
        const deletedAPLinks = goal.accessPolicy.links.filter((apLink) => linkableAPs.find((ap) => ap.uid === apLink.link.uid) === undefined).map((apLink) => ({ uid: apLink.uid, deletedAt: new Date() }));
        const missingAPLinks = linkableAPs.filter((ap) => goal.accessPolicy.links.find((apLink) => apLink.link.uid === ap.uid) === undefined).map((ap) => ({ link: ap }));
        updatedGoal.accessPolicy = { ...goal.accessPolicy, links: [...existingAPLinks, ...deletedAPLinks, ...missingAPLinks] };
      }
      return updatedGoal;
    }));
  };

  return {
    ...repo,
    updateSingle,
    updateMultiple,
    goals: repo.entityList,
    getGoals,
    getGoal,
    createMultiple,
    mutateMultiple,
    updateProperty,
    changeParents,
  };
}
