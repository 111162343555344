<template>
  <teleport
    to="#overlay"
  >
    <div
      v-if="showSuggestions && !disabled"
      :class="['_overlay', $attrs.class]"
      @click="reset"
      @mousemove="navigatingWith = 'mouse'"
    >
      <m-card
        ref="card"
        class="m-context-menu"
        :style="style"
        no-padding
        list
        placement="bottomLeft"
      >
        <m-card-item
          v-for="(item, index) in filteredItems"
          :key="item.key"
          :ref="`item_${index}`"
          :focused="itemIndex === index"
          class="_item"
          no-hover
          @mouseover="handleMouseover(index)"
          @click="handleClick(item, $event)"
        >
          <template #left>
            <div
              class="_left"
            >
              <div class="_img">
                <m-icon
                  v-if="typeof item.icon !== 'undefined'"
                  :type="item.icon"
                  :size="typeof item.iconSize === 'undefined' ? '26' : item.iconSize"
                  :colors="$colors.grey.darken1"
                />
                <img
                  v-else
                  :src="item.src"
                  :alt="item.title"
                >
              </div>
              <div class="_text">
                <div class="_title">
                  {{ item.title }}
                </div>
                <div class="_sub-title">
                  {{ item.subTitle }}
                </div>
              </div>
            </div>
          </template>
        </m-card-item>
        <m-card-item
          v-if="filteredItems.length === 0"
          :clickable="false"
          no-hover
          class="_empty"
        >
          {{ $t('mContextMenu.noItems') }}
        </m-card-item>
      </m-card>
    </div>
  </teleport>
</template>

<script>
import ComponentList from '@/components/editor/ComponentList';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUsers from '@/composables/user/users';
import { ContextMenuExtension } from '@/components/editor/context-menu-extension';
import { markRaw } from 'vue';

export default {
  name: 'MContextMenu',
  props: {
    editor: {
      type: Object,
      default: () => ({}),
    },
    allowedContent: {
      type: Array,
      default: () => [],
    },
    customItems: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { users } = useUsers();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { users, account: loggedInUserAccount };
  },
  data() {
    return {
      navigatingWith: 'mouse',
      loading: false,
      query: null,
      schema: null,
      view: null,
      suggestionRange: null,
      filteredItems: [],
      itemIndex: 0,
      disabled: false,
      command: () => {
      },
      x: 0,
      y: 0,
      bottom: 0,
    };
  },
  computed: {
    style() {
      if (this.bottom !== 0) {
        return {
          width: '32rem',
          position: 'fixed',
          left: `${this.x}px`,
          bottom: `${this.bottom}px`,
          zIndex: 1070,
          maxHeight: this.$store.state.breakpoint.smAndDown ? 'var(--viewport-height-100)' : '40vh',
          overflow: 'auto',
        };
      }
      return {
        width: '32rem',
        position: 'fixed',
        left: `${this.x}px`,
        top: `${this.y + 20}px`,
        zIndex: 1070,
        maxHeight: this.$store.state.breakpoint.smAndDown ? 'var(--viewport-height-100)' : '40vh',
        overflow: 'auto',
      };
    },
    hasResults() {
      return this.filteredItems.length > 0;
    },
    showSuggestions() {
      return this.query !== null;
    },
  },
  methods: {
    handleMouseover(index) {
      if (this.navigatingWith !== 'mouse') {
        return;
      }
      this.itemIndex = index;
    },
    blur() {
      this.disabled = true;
      this.reset();
    },
    reset() {
      this.query = null;
      this.filteredItems = [];
      this.suggestionRange = null;
      this.itemIndex = 0;
    },
    newMenu() {
      return new ContextMenuExtension({
        matcher: {
          char: '/',
          allowSpaces: false,
          startOfLine: false,
        },
        appendText: ' ',
        suggestionClass: 'context-menu',
        command: this.command,
        items: this.items,
        element: this.$el,
        onEnter: ({ items, query, range, virtualNode, view }) => {
          this.disabled = false;
          this.schema = markRaw(view.state.schema);
          this.view = markRaw(view);
          this.query = query;
          this.filteredItems = items;
          this.suggestionRange = range;
          this.renderPopup(virtualNode);
        },
        onChange: ({ items, query, range, virtualNode }) => {
          this.query = query;
          this.filteredItems = items;
          this.suggestionRange = range;
          this.itemIndex = 0;
          this.renderPopup(virtualNode);
        },
        onExit: () => {
          this.reset();
        },
        onKeyDown: ({ event }) => {
          if (event.key === 'ArrowUp') {
            this.upHandler();
            return true;
          }
          if (event.key === 'ArrowDown') {
            this.downHandler();
            return true;
          }
          if (event.key === 'Enter') {
            this.enterHandler();
            return true;
          }
          return false;
        },
        onFilter: (items, query) => {
          if (query === null || query === '') {
            return this.items;
          }

          this.filteredItems = this.items.filter((item) => {
            let found = true;
            for (let i = 0; i < query.length; i++) {
              if (item.title.toLowerCase().indexOf(query.charAt(i).toLowerCase()) === -1) {
                found = false;
                break;
              }
            }

            return found;
          });

          return this.filteredItems;
        },
      });
    },
    upHandler() {
      this.navigatingWith = 'keyboard';
      const oldVal = this.itemIndex;
      this.itemIndex = ((this.itemIndex + this.filteredItems.length) - 1) % this.filteredItems.length;
      this.setScrollPosition(this.itemIndex, oldVal);
    },
    downHandler() {
      this.navigatingWith = 'keyboard';
      const oldVal = this.itemIndex;
      this.itemIndex = (this.itemIndex + 1) % this.filteredItems.length;
      this.setScrollPosition(this.itemIndex, oldVal);
    },
    enterHandler() {
      const item = this.filteredItems[this.itemIndex];
      if (typeof item === 'undefined') {
        return;
      }

      this.addBlock(item, this.suggestionRange.from, this.suggestionRange.to);
      this.query = null;
    },
    handleClick(item, event) {
      event.stopPropagation();
      event.preventDefault();
      this.addBlock(item, this.suggestionRange.from, this.suggestionRange.to);
    },
    renderPopup(node) {
      if (this.popup) {
        return;
      }
      const dimensions = node.getBoundingClientRect();
      this.x = dimensions.x;
      this.y = dimensions.y;
    },
    setScrollPosition(val, oldVal) {
      const key = `item_${val}`;
      const ref = this.$refs[key][0];

      if (oldVal < val && ref.$el.clientHeight + ref.$el.offsetTop > this.$refs.card.$el.clientHeight) {
        this.$refs.card.$el.scrollTop = ref.$el.clientHeight * (val + 1) - this.$refs.card.$el.clientHeight + 8;
        return;
      }
      if (ref.$el.offsetTop < this.$refs.card.$el.scrollTop) {
        this.$refs.card.$el.scrollTop = ref.$el.clientHeight * val;
      }
    },
  },
  watch: {
    showSuggestions(show) {
      if (show) {
        this.call += 1;
        this.$nextTick(() => {
          if (typeof this.$refs.card !== 'undefined' && this.y + this.$refs.card.$el.clientHeight > document.body.clientHeight) {
            this.bottom = document.body.clientHeight - this.y + 5;
          }
        });
        return;
      }

      this.bottom = 0;
    },
  },
  mixins: [ComponentList],
};
</script>

<style scoped lang="scss" type="text/scss">
.m-context-menu {
  z-index: 1;

  ._sub-heading {
    height: 3.1rem;
    font-size: $font-size-2;
    color: $font-color-tertiary;
  }

  ._empty {
    color: $font-color-secondary;
  }

  ._item {
    ._left {
      display: flex;
      align-items: center;

      ._img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.5rem;
        height: 4.5rem;
        margin-right: 1.2rem;
        overflow: hidden;
        background-color: white;
        border: 1px solid $border-color;
        border-radius: $border-radius-sm;

        img {
          width: 4.5rem;
          height: 4.5rem;
          border-radius: $border-radius-sm;
        }
      }

      ._text {
        ._sub-title {
          font-size: $font-size-2;
          color: $font-color-secondary;
        }
      }
    }
  }
}

._overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: var(--viewport-height-100);
}
</style>
