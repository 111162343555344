<template>
  <m-content
    padding-x="layout"
    :class="['grid-page', isResizing ? '-is-resizing': '', isDragging ? '-is-dragging' : '']"
  >
    <m-draggable
      class="_rows"
      :recreate-key="recreateKey"
      draggable-item-class="grid-item-drag-handle"
      ghost-item-class="grid-item-content"
      dragover-item-class="grid-item-drop"
      scroll-container-class="scroll-container"
      can-drag-over
      :disabled="props.readOnly"
      @set-drag-item="setDragItem"
      @over="setDragOver"
      @drag-drop="dropItem"
      @cancel="cancelDragging"
    >
      <grid-row
        v-for="row in rows"
        :key="row.uid"
        :grid-page="gridPage"
        :grid-row="row"
        :read-only="props.readOnly"
      />
    </m-draggable>
    <add-row-btn
      v-if="!props.readOnly && !isPrint"
      :grid-page="gridPage"
    />
  </m-content>
</template>

<script setup>
import AddRowBtn from '@/components/custom-grid/AddRowBtn.vue';
import GridRow from '@/components/custom-grid/GridRow.vue';
import useExport from '@/composables/export/export';
import useGridPage from '@/composables/grid-page/grid-page';
import { computed } from 'vue';
import { sortByArray } from 'shared/lib/sort';

const props = defineProps({
  gridPage: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const { isPrint } = useExport();

const { isDragging, initDragging, cancelDragging, setDragTarget, commitDragTarget, ...gridPageService } = useGridPage();
const isResizing = gridPageService.isResizing;

const gridPage = computed(() => {
  if (props.gridPage === null) {
    return null;
  }

  return gridPageService.selectSingleGridPage(props.gridPage.uid);
});

const rows = computed(() => {
  if (gridPage.value === null) {
    return [];
  }

  return gridPage.value.rows.map(({ uid }) => gridPageService.gridPageRows.value.find((row) => row.uid === uid))
    .filter((row) => row !== undefined)
    .sort(sortByArray(gridPage.value.rowOrder));
});

const recreateKey = computed(() => rows.value.map((row) => [row.uid, ...row.tileOrder.map(({ uid }) => uid)]).flat().join('_'));

function setDragItem(dataId) {
  initDragging(JSON.parse(dataId).uid);
}

function setDragOver(dataId) {
  if (Array.isArray(dataId) && dataId.length === 0) {
    setDragTarget(null);
    return;
  }
  setDragTarget(JSON.parse(dataId[0]));
}

function dropItem() {
  const dragTarget = gridPageService.dragTarget.value;

  if (dragTarget === null) {
    cancelDragging();
    return;
  }

  if (dragTarget.position === 'below') {
    gridPageService.commitDragBelow(gridPage.value);
    return;
  }

  if (dragTarget.position === 'top') {
    gridPageService.commitDragTop(gridPage.value);
    return;
  }

  commitDragTarget();
}

</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
.grid-page {
  &.-is-resizing > * {
    pointer-events: none;
  }
}
</style>
