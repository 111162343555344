<template>
  <teleport
    v-if="value"
    to="#overlay"
  >
    <div
      v-if="value"
      ref="overlay"
      :style="overlayStyle"
      :class="['m-drawer','__overlay-container']"
      @mousedown.stop
    >
      <div
        ref="body"
        :style="{ width, maxWidth: '100vw', ...bodyS }"
        class="_content"
      >
        <m-content
          padding
          class="_title"
        >
          <div class="_left">
            {{ title }}
          </div>
          <div class="_close-btn">
            <m-btn
              icon="close"
              hide-border
              fab
              light
              @click="hide"
            />
          </div>
        </m-content>
        <slot />
      </div>
    </div>
  </teleport>
</template>

<script>
import useClickOutside from 'shared/composables/click-outside';
import { ref, toRef } from 'vue';

export default {
  name: 'MDrawer',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '60rem',
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    bodyStyle: {
      type: Object,
      default: () => {
      },
    },
    hovering: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'close'],
  setup(props, { emit }) {
    const handleOverlayClick = (event) => {
      event.stopPropagation();
      hide();
    };
    const hide = () => {
      emit('close');
      emit('input', false);
      emit('update:value', false);
    };
    const element = ref(null);
    useClickOutside(handleOverlayClick, toRef(props, 'value'), element);
    return { hide, element };
  },
  computed: {
    bodyS() {
      const middlewares = [
        this.paddingM,
        this.positionM,
      ];

      return middlewares.reduce((res, next) => next(res), this.bodyStyle);
    },
    overlayStyle() {
      return {
        zIndex: 1050,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: 'var(--viewport-height-100)',
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, .4)',
      };
    },
  },
  methods: {
    positionM(style) {
      if (this.hovering) {
        return {
          ...style,
          margin: '2rem',
          height: 'calc(var(--viewport-height-100) - 4rem)',
          borderRadius: '.4rem',
        };
      }
      return style;
    },
    paddingM(style) {
      if (this.noPadding) {
        return { ...style, padding: 0 };
      }
      return style;
    },
    focus() {
      if (this.$refs.body === null) {
        return;
      }
      this.$refs.body.focus();
    },
    init() {
      this.focus();
      if (this.$refs.body === null) {
        return;
      }
      this.element = this.$refs.body;
    },
  },
  watch: {
    value(val) {
      if (!val) {
        return;
      }

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.init();
        });
      });
    },
  },
  mounted() {
    if (this.value) {
      this.init();
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";

  .m-drawer {
    max-width: 100vw;

    ._content {
      position: fixed;
      top: 0;
      right: 0;
      height: var(--viewport-height-100);
      overflow: auto;
      background-color: white;
      animation: slide-in .2s ease-in-out;

      @include box_shadow(1);

      @keyframes slide-in {
        0% {
          transform: translateX(50rem) translateY(0);
        }

        100% {
          transform: translateX(0) translateY(0);
        }
      }

      ._title {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $border-color;

        ._left {
          font-size: $font-size-5;
          font-weight: $font-weight-semibold;
        }

        ._close-btn {
          margin-left: auto;
        }
      }
    }
  }
</style>
