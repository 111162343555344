<template>
  <div
    class="sub-heading"
    :style="resolveStyles(mStyle)"
  >
    <div class="_title">
      {{ title }}
    </div>
    <div class="_action">
      <slot name="action" />
    </div>
  </div>
</template>

<script>
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';

export default {
  name: 'SubHeading',
  props: {
    ...mStyleProps,
    title: {
      type: String,
      required: true,
    },
  },
  methods: { resolveStyles },
};
</script>

<style scoped lang="scss" type="text/scss">
  .sub-heading {
    display: flex;
    align-items: center;
    margin: 1.4rem 0 .2rem 1.6rem;

    ._title {
      font-size: $font-size-2;
      font-weight: $font-weight-semibold;
      color: $font-color-secondary;
    }

    ._action {
      margin-left: auto;
    }
  }
</style>
