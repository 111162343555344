<template>
  <div
    :class="['nav-item-inner', $store.state.breakpoint.smAndDown ? '-mobile' : '', active ? '-active' : '']"
  >
    <div
      :class="['_inner', disabled ? '-disabled': '']"
    >
      <div
        v-if="showAvatar || !isEmpty(icon)"
        :class="['_icon']"
      >
        <m-avatar
          v-if="showAvatar"
          :text="avatarTitle !== ''? avatarTitle : title"
          :size="parseInt(iconSize, 10)"
          :amount-chars="avatarChars"
          :shape="avatarShape"
          :color="avatarColor"
          :black-and-white="avatarBlackAndWhite"
          bordered
        />
        <m-icon-display
          v-else-if="typeof icon === 'object' && !isEmptyIcon(icon)"
          :icon="icon"
          :size="parseInt(iconSize, 10)"
          show-background
        />
        <m-icon
          v-else-if="typeof icon === 'string' && icon !== ''"
          :size="`${parseInt(iconSize, 10)}`"
          :color="iconColor"
          :type="icon"
        />
      </div>
      <div
        ref="titleRef"
        class="_title"
      >
        {{ localTitle }}
      </div>
      <m-tag
        v-if="tag !== null"
        class="_tag"
        xxs
        strong
        :icon="tag.icon"
        :custom-color="{ color: tag.color, backgroundColor: tag.backgroundColor }"
        :title="tag.title"
      />
      <plan-tag
        v-if="planTag !== null"
        class="_tag"
        :feature-flag="planTag.featureFlag"
        disabled
      />
      <m-badge
        v-if="badge !== ''"
        :number="badge"
        :background-color="$colors.red.base"
        class="_badge"
      />
      <div
        v-if="!!$slots.actions"
        :class="['_actions', forceShowActions?'-show':'']"
        @click.stop.prevent
      >
        <slot
          name="actions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlanTag from '@/components/plans/PlanTag.vue';
import useElementsTruncate from 'shared/composables/element-truncate';
import { Icon, isEmptyIcon } from 'shared/lib/icon';
import { ref } from 'vue';

export default {
  name: 'NavItemInner',
  props: {
    title: {
      type: String,
      required: true,
    },
    avatarTitle: {
      type: String,
      default: '',
    },
    avatarChars: {
      type: Number,
      default: 1,
    },
    avatarColor: {
      type: String,
      default: '',
    },
    avatarBlackAndWhite: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [Icon, String],
      default: null,
    },
    avatarShape: {
      type: String,
      default: 'circle',
    },
    iconSize: {
      type: String,
      default: '14',
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: String,
      default: '',
    },
    tag: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    planTag: {
      type: Object,
      default: () => null,
    },
    forceShowActions: {
      type: Boolean,
      default: false,
    },
    isTitleTruncated: {
      type: Boolean,
      default: false,
    },
  },
  components: { PlanTag },
  emits: ['update:isTitleTruncated'],
  setup() {
    const titleRef = ref(null);
    const { isTruncated: localIsTitleTruncated } = useElementsTruncate([titleRef]);
    return { titleRef, localIsTitleTruncated };
  },
  data() {
    return { visible: false };
  },
  computed: {
    iconColor() {
      if (this.active) {
        return this.$colors.grey.darken2;
      }
      return this.$colors.grey.base;
    },
    localTitle() {
      return this.title === '' ? this.$t('general.untitled') : this.title;
    },
  },
  methods: {
    isEmptyIcon,
    isEmpty(icon) {
      if (typeof icon === 'string') {
        return icon === '';
      }
      return isEmptyIcon(icon);
    },
  },
  watch: {
    localIsTitleTruncated(val) {
      this.$emit('update:isTitleTruncated', val);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .nav-item-inner {
    width: 100%;
    min-width: 0;

    ._inner {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      min-width: 3.6rem;
      height: 2.8rem;
      cursor: pointer;

      ._icon {
        display: flex;
        flex: 0 0 2rem;
        align-items: center;
        justify-content: center;

        &.-narrow {
          margin-right: 0;
        }
      }

      ._title {
        display: block;
        width: 100%;
        margin-left: .4rem;
        overflow: hidden;
        font-weight: $font-weight-medium;
        line-height: 1.6;
        color: map_get($grey, 'darken-2');
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      ._tag {
        margin-right: .4rem;
        margin-left: auto;
      }

      ._badge {
        margin-right: .4rem;
        margin-left: auto;
      }

      ._actions {
        display: none;
        margin-left: auto;

        &.-show {
          display: block;
        }
      }
    }

    ._inner.-disabled {
      cursor: default;
    }

    &.-active {
      ._title {
        font-weight: $font-weight-semibold;
        color: $font-color-primary;
      }

      ._avatar {
        font-weight: $font-weight-medium;
      }
    }

    &:hover {
      ._actions {
        display: block;
      }
    }

    &.-mobile {
      ._actions {
        display: block;
      }
    }
  }
</style>
