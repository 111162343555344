import useGoalProperty from '@/composables/property/goal-property';
import { buildIcon, buildIconFromEntity } from 'shared/lib/icon';
import { getColoredIcon } from 'shared/lib/color-map';
import { iconType } from 'shared/constants.json';

export default function useGoalTypeProperty() {
  const { goalTypeProperty } = useGoalProperty();

  const goalTypeOption = (goal) => {
    const val = goal.properties.find((p) => p.property.uid === goalTypeProperty.value.uid);
    if (val === undefined) {
      throw new Error(`property value not found for goal: ${goal.uid}`);
    }
    if (val.selectedOptions.length === 0) {
      return null;
    }

    const type = goalTypeProperty.value.options.find((o) => val.selectedOptions[0].uid === o.uid);
    if (type === undefined) {
      return null;
    }

    return type;
  };

  const goalTypeIcon = (goal) => {
    const type = goalTypeOption(goal);
    if (type === null) {
      return buildIcon('', iconType.icon);
    }
    return buildIconFromEntity({ icon: getColoredIcon(type), image: type.image });
  };

  return { goalTypeProperty, goalTypeOption, goalTypeIcon };
}
