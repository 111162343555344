import { UID } from 'shared/api/query/constants';
import { dataSource as dataSourceConfig, goal as goalConfig, msPowerBiQuery as msPowerBiQueryConfig } from 'shared/api/query/configs.json';

export const msPowerBiQueryChildren = [
  { attr: UID },
  {
    attr: msPowerBiQueryConfig.edges.dataSource,
    model: dataSourceConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: msPowerBiQueryConfig.edges.goal,
    model: goalConfig.model,
    children: [{ attr: UID }],
  },
  { attr: msPowerBiQueryConfig.edges.alwaysSync, default: false },
  { attr: msPowerBiQueryConfig.edges.error, default: null },
  { attr: msPowerBiQueryConfig.edges.groupId, default: null },
  { attr: msPowerBiQueryConfig.edges.groupName, default: null },
  { attr: msPowerBiQueryConfig.edges.datasetId, default: null },
  { attr: msPowerBiQueryConfig.edges.datasetName, default: null },
  { attr: msPowerBiQueryConfig.edges.dax, default: null },
];
