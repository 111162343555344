import { chartGroupByOption, gridPageChartType } from 'shared/constants.json';
import { computed } from 'vue';

export default function useBarChart(result, chart) {
  const isStacked = computed(() => [gridPageChartType.line, gridPageChartType.bar, gridPageChartType.column].includes(chart.value.chartType)
      && chart.value.aggregationConfig.stackOptions.groupBy !== chartGroupByOption.none);

  const series = computed(() => {
    if (![gridPageChartType.bar, gridPageChartType.column].includes(chart.value.chartType)) {
      return [];
    }

    if (result.value === null) {
      return [];
    }

    return result.value.series;
  });

  const categories = computed(() => {
    if (result.value === null) {
      return [];
    }

    return result.value.categories;
  });

  const chartOptions = computed(() => ({
    chart: {
      animation: false,
      type: chart.value.chartType,
    },
    plotOptions: {
      [chart.value.chartType]: {
        dataLabels: {
          enabled: true,
          formatter() {
            return this.y === 0 ? null : this.y;
          },
        },
        stacking: 'normal',
      },
    },
    legend: {
      enabled: isStacked.value,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      y: 10,
      events: {
        itemClick(e) {
          const chart = this.chart;
          const visibleCount = chart.series.filter((s) => s.visible).length;

          if (visibleCount === 1 && e.legendItem.visible) {
            return false;
          }
          return true;
        },
      },
    },
    xAxis: {
      categories: categories.value,
      showEmpty: series.value.length > 0,
    },
    yAxis: { title: { text: null } },
    series: series.value,
    tooltip: {
      formatter() {
        return `
              <b>${this.point.name}:</b> ${this.point.y}
            `;
      },
    },
  }));

  return { chartOptions };
}
