import {
  accessPolicy as accessPolicyConfig,
  accessPolicyLink as accessPolicyLinkConfig,
  accessPolicyScope as accessPolicyScopeConfig,
  appIntegration as appIntegrationConfig,
  asanaEntity as asanaEntityConfig,
  asanaQuery as asanaQueryConfig,
  comment as commentConfig,
  dataSource as dataSourceConfig,
  favorite as favoriteConfig,
  feedEntry as feedEntryConfig,
  goalActivity as goalActivityConfig,
  goal as goalConfig,
  goalSubscription as goalSubscriptionConfig,
  gridPage as gridPageConfig,
  gridPageRow as gridPageRowConfig,
  gridPageTile as gridPageTileConfig,
  gridPageTileSingleGoal as gridPageTileSingleGoalConfig,
  gridPageTileText as gridPageTileTextConfig,
  hubspotQuery as hubspotQueryConfig,
  invitation as invitationConfig,
  jiraQuery as jiraQueryConfig,
  msPlannerQuery as msPlannerQueryConfig,
  msPowerBiQuery as msPowerBiQueryConfig,
  notification as notificationConfig,
  notificationSetting as notificationSettingConfig,
  numberRange as numberRangeConfig,
  oauthCodeGrantAccessToken as oauthCodeGrantAccessTokenConfig,
  oauthCodeGrantClient as oauthCodeGrantClientConfig,
  pin as pinConfig,
  planning as planningConfig,
  printable as printableConfig,
  property as propertyConfig,
  propertyOption as propertyOptionConfig,
  propertySettings as propertySettingsConfig,
  propertyValue as propertyValueConfig,
  reaction as reactionConfig,
  report as reportConfig,
  salesforceQuery as salesforceQueryConfig,
  savedView as savedViewConfig,
  selectedView as selectedViewConfig,
  space as spaceConfig,
  spreadsheetCell as spreadsheetCellConfig,
  storageObject as storageObjectConfig,
  timeRange as timeRangeConfig,
  update as updateConfig,
  updateTemplate as updateTemplateConfig,
  user as userConfig,
  userProvisioning as userProvisioningConfig,
  userProvisioningMapping as userProvisioningMappingConfig,
  userScope as userScopeConfig,
  userScopeTree as userScopeTreeConfig,
  viewCollection as viewCollectionConfig,
} from 'shared/api/query/configs.json';
import { feedEntryType } from 'shared/constants.json';
import { reverseEdge } from 'shared/api/query/edges';

export default {
  [asanaQueryConfig.model]: [
    { type: asanaEntityConfig.model },
    { type: feedEntryConfig.model, condition: (entity) => entity.type !== feedEntryType.dataSourceQueryError },
  ],
  [spreadsheetCellConfig.model]: [
    { type: feedEntryConfig.model, condition: (entity) => entity.type !== feedEntryType.dataSourceQueryError },
  ],
  [salesforceQueryConfig.model]: [
    { type: feedEntryConfig.model, condition: (entity) => entity.type !== feedEntryType.dataSourceQueryError },
  ],
  [jiraQueryConfig.model]: [
    { type: feedEntryConfig.model, condition: (entity) => entity.type !== feedEntryType.dataSourceQueryError },
  ],
  [hubspotQueryConfig.model]: [
    { type: feedEntryConfig.model, condition: (entity) => entity.type !== feedEntryType.dataSourceQueryError },
  ],
  [msPowerBiQueryConfig.model]: [
    { type: feedEntryConfig.model, condition: (entity) => entity.type !== feedEntryType.dataSourceQueryError },
  ],
  [msPlannerQueryConfig.model]: [
    { type: feedEntryConfig.model, condition: (entity) => entity.type !== feedEntryType.dataSourceQueryError },
  ],
  [accessPolicyConfig.model]: [
    { type: accessPolicyLinkConfig.model },
    { type: accessPolicyScopeConfig.model },
  ],
  [accessPolicyLinkConfig.model]: [
    { type: accessPolicyConfig.model, edge: accessPolicyLinkConfig.edges.patch },
  ],
  [accessPolicyScopeConfig.model]: [
    { type: userScopeTreeConfig.model },
  ],
  [userScopeTreeConfig.model]: [
    { type: userScopeConfig.model },
    { type: userScopeTreeConfig.model, edge: userScopeTreeConfig.edges.children },
  ],
  [userScopeConfig.model]: [
    { type: timeRangeConfig.model },
    { type: numberRangeConfig.model },
  ],
  [propertyConfig.model]: [
    { type: propertyOptionConfig.model },
    { type: propertyValueConfig.model },
    { type: userProvisioningMappingConfig.model },
    { type: propertySettingsConfig.model },
    { type: propertyConfig.model, edge: reverseEdge(propertyConfig.edges.lookupProperty) },
    { type: propertyConfig.model, edge: reverseEdge(propertyConfig.edges.lookupRelation) },
  ],
  [propertyOptionConfig.model]: [
    { type: storageObjectConfig.model },
    { type: propertySettingsConfig.model },
  ],
  [spaceConfig.model]: [
    { type: accessPolicyConfig.model },
    { type: storageObjectConfig.model },
    { type: propertyValueConfig.model },
    { type: pinConfig.model },
    { type: savedViewConfig.model },
    { type: selectedViewConfig.model },
    { type: viewCollectionConfig.model },
  ],
  [propertyValueConfig.model]: [
    { type: feedEntryConfig.model },
  ],
  [goalConfig.model]: [
    { type: accessPolicyConfig.model },
    { type: goalSubscriptionConfig.model },
    { type: propertyValueConfig.model },
    { type: feedEntryConfig.model },
    { type: goalActivityConfig.model },
    { type: asanaQueryConfig.model },
    { type: salesforceQueryConfig.model },
    { type: jiraQueryConfig.model },
    { type: spreadsheetCellConfig.model },
    { type: hubspotQueryConfig.model },
    { type: msPowerBiQueryConfig.model },
    { type: msPlannerQueryConfig.model },
  ],
  [goalActivityConfig.model]: [
    { type: propertyValueConfig.model },
  ],
  [updateConfig.model]: [
    { type: feedEntryConfig.model },
    { type: commentConfig.model },
    { type: propertyValueConfig.model },
  ],
  [updateTemplateConfig.model]: [
    { type: accessPolicyConfig.model },
    { type: storageObjectConfig.model },
    { type: propertyValueConfig.model },
  ],
  [dataSourceConfig.model]: [
    { type: asanaQueryConfig.model },
    { type: salesforceQueryConfig.model },
    { type: jiraQueryConfig.model },
    { type: spreadsheetCellConfig.model },
    { type: hubspotQueryConfig.model },
    { type: msPowerBiQueryConfig.model },
    { type: msPlannerQueryConfig.model },
    { type: feedEntryConfig.model },
  ],
  [gridPageConfig.model]: [
    { type: accessPolicyConfig.model },
    { type: storageObjectConfig.model },
    { type: gridPageRowConfig.model },
    { type: printableConfig.model },
  ],
  [printableConfig.model]: [
    { type: reportConfig.model },
  ],
  [gridPageRowConfig.model]: [
    { type: gridPageTileConfig.model },
  ],
  [gridPageTileConfig.model]: [
    { type: gridPageTileTextConfig.model },
    { type: gridPageTileSingleGoalConfig.model },
    { type: savedViewConfig.model },
    { type: viewCollectionConfig.model },
  ],
  [notificationConfig.model]: [
    { type: accessPolicyConfig.model },
    { type: userScopeTreeConfig.model },
    { type: feedEntryConfig.model },
  ],
  [planningConfig.model]: [
    { type: accessPolicyConfig.model },
    { type: savedViewConfig.model },
    { type: viewCollectionConfig.model },
    { type: propertyValueConfig.model },
  ],
  [userConfig.model]: [
    { type: storageObjectConfig.model },
    { type: notificationSettingConfig.model },
    { type: appIntegrationConfig.model },
    { type: dataSourceConfig.model, condition: (entity) => entity.shared !== true },
    { type: savedViewConfig.model, edge: reverseEdge(savedViewConfig.edges.creator), condition: (entity) => entity.isPublic !== true },
    { type: selectedViewConfig.model },
    { type: propertyValueConfig.model },
    { type: goalSubscriptionConfig.model },
    { type: reactionConfig.model },
    { type: invitationConfig.model, edge: reverseEdge(invitationConfig.edges.recipient) },
  ],
  [commentConfig.model]: [
    { type: feedEntryConfig.model },
  ],
  [userProvisioningConfig.model]: [
    { type: userProvisioningMappingConfig.model },
    { type: oauthCodeGrantClientConfig.model },
  ],
  [oauthCodeGrantClientConfig.model]: [
    { type: oauthCodeGrantAccessTokenConfig.model },
  ],
  [favoriteConfig.model]: [
    { type: storageObjectConfig.model },
  ],
};
