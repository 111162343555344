<template>
  <div class="maintenance">
    <div class="_top">
      <div class="_logo">
        <a :href="$t('general.websiteBaseUrl')">
          <img
            src="@/assets/img/logo.svg"
            alt="Mooncamp"
            :style="{ width: '100%' }"
          >
        </a>
      </div>
    </div>
    <div
      class="_content"
    >
      <div class="_header">
        <item-title
          :title=" $t('maintenance.header')"
          :icons="[{value: buildIcon('CloudServer'), size: 30 }]"
        />
      </div>
      <div
        class="_message"
      >
        {{ $t('maintenance.message') }}
      </div>
    </div>
  </div>
</template>

<script>
import ItemTitle from '@/components/ItemTitle.vue';
import { buildIcon } from 'shared/lib/icon';

export default {
  name: 'Maintenance',
  components: { ItemTitle },
  methods: { buildIcon },
};
</script>

<style  scoped lang="scss" type="text/scss">
  .maintenance {
    ._top {
      position: absolute;
      top: 0;
      left: 0;

      ._logo {
        width: 20rem;
        padding: 1.4rem;
      }
    }

    ._content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 100vw;
      max-width: 68rem;
      min-height: var(--viewport-height-100);

      ._logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2.8rem;

        img {
          width: 30%;
          height: auto;
        }
      }

      ._header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        font-size: $font-size-8;
      }

      ._message {
        margin-bottom: 1.2rem;
        color: $font-color-secondary;
      }
    }
  }
</style>
