<template>
  <m-dropdown
    v-model:value="open"
    class="group-by-selector"
    :title="$t('general.actions')"
    match-trigger-width
    keep-open-on-click
    block
  >
    <m-focusable
      type="clickable"
      full-width
    >
      <template v-if="selectedGroupBy === null">
        {{ $t('general.empty') }}
      </template>
      <template v-else-if="selectedGroupBy.groupBy === chartGroupByOption.none">
        {{ $t('general.none') }}
      </template>
      <template v-else>
        <m-tag
          :icon="getIcon(selectedGroupBy)"
          :title="getValueText(selectedGroupBy)"
          color="none"
        />
      </template>
      <template #suffix>
        <m-icon
          :type="open ? 'down' : 'right'"
          :color="$colors.grey.lighten1"
          size="11"
        />
      </template>
    </m-focusable>
    <template #overlay>
      <m-card
        no-padding
        list
      >
        <m-card-item
          v-if="showNone"
          @click="selectGroupBy(null, chartGroupByOption.none)"
        >
          {{ $t('general.none') }}
          <template
            v-if="selectedGroupBy.groupBy === chartGroupByOption.none"
            #right
          >
            <m-icon type="check" />
          </template>
        </m-card-item>
        <template v-for="option in properties">
          <m-card-item
            v-if="![propertyType.date, propertyType.number].includes(getType(option))"
            :key="getKey(option)"
            :icon="getIcon(option)"
            @click="selectGroupBy(option)"
          >
            {{ getLabel(option) }}
            <template
              v-if="isSelected(option, chartGroupByOption.unique)"
              #right
            >
              <m-icon type="check" />
            </template>
          </m-card-item>
          <m-dropdown
            v-else
            :key="getKey(option)"
            block
            placement="topRight"
            trigger="hover"
            :title="getLabel(option)"
          >
            <m-card-item
              :icon="getIcon(option)"
              @click="selectGroupBy(option)"
            >
              {{ getLabel(option) }}
              <template #right>
                <m-icon
                  type="right"
                  :color="$colors.grey.lighten1"
                  size="11"
                />
              </template>
            </m-card-item>
            <template #overlay>
              <m-card
                no-padding
                list
              >
                <m-card-item
                  v-for="groupingOption in groupingOptions[getType(option)]"
                  :key="groupingOption.value"
                  @click="selectGroupBy(option, groupingOption.value)"
                >
                  {{ groupingOption.text }}
                  <template
                    v-if="isSelected(option, groupingOption.value)"
                    #right
                  >
                    <m-icon type="check" />
                  </template>
                </m-card-item>
              </m-card>
            </template>
          </m-dropdown>
        </template>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script setup>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { chartGroupByOption, propertyType } from 'shared/constants.json';
import { iconByType } from '@/lib/property';
import { ref } from 'vue';
import { textByLang } from 'shared/lib/language';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  value: {
    type: Object,
    default: () => ({}),
  },
  properties: {
    type: Array,
    required: true,
  },
  showNone: {
    type: Boolean,
    default: false,
  },
  hiddenGroupByOptions: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:value']);

const open = ref(false);

const selectedGroupBy = ref(props.value);

const { t } = useI18n();

const getKey = (option) => {
  if (option === null) {
    return 'none';
  }
  if (option.isProperty) {
    return option.property.uid;
  }
  return option.edge;
};

const getIcon = (option) => {
  if (option === null) {
    return '';
  }
  if (option.isProperty) {
    return iconByType({ type: option.property.type });
  }

  return iconByType({ type: option.edgeType, edgeName: option.edge });
};

const getType = (option) => {
  if (option === null) {
    return '';
  }
  if (option.isProperty) {
    return option.property.type;
  }
  return option.edgeType;
};

const isSelected = (option, groupBy) => {
  if (option.isProperty !== selectedGroupBy.value.isProperty) {
    return false;
  }
  if (groupBy !== selectedGroupBy.value.groupBy) {
    return false;
  }
  if (option.isProperty) {
    return selectedGroupBy.value.property.uid === option.property.uid;
  }
  return selectedGroupBy.value.edge === option.edge;
};

const { userLang } = useLoggedInUser();

const getLabel = (option) => {
  if (option === null) {
    return t('general.none');
  }
  if (option.isProperty) {
    return textByLang(option.property.label, userLang.value);
  }
  return t(`edges.${option.edge}`);
};

const getValueText = (option) => {
  const label = getLabel(option);
  if (!props.hiddenGroupByOptions.includes(option.groupBy)) {
    return `${label} (${t(`groupByOption.${option.groupBy}`)})`;
  }
  return label;
};

const dateGranularityOptions = [
  { value: chartGroupByOption.day, text: t('groupByOption.day') },
  { value: chartGroupByOption.week, text: t('groupByOption.week') },
  { value: chartGroupByOption.month, text: t('groupByOption.month') },
  { value: chartGroupByOption.quarter, text: t('groupByOption.quarter') },
  { value: chartGroupByOption.year, text: t('groupByOption.year') },
];

const numericGranularityOptions = [
  { value: chartGroupByOption.unique, text: t('groupByOption.unique') },
  { value: chartGroupByOption.bucket, text: t('groupByOption.bucket') },
];

const groupingOptions = {
  [propertyType.date]: dateGranularityOptions,
  [propertyType.number]: numericGranularityOptions,
};

function selectGroupBy(option, groupBy = chartGroupByOption.unique) {
  if (option === null) {
    selectedGroupBy.value = { groupBy };
  } else {
    selectedGroupBy.value = { ...option, groupBy };
  }
  emit('update:value', selectedGroupBy.value);
  open.value = false;
}

if (selectedGroupBy.value.groupBy === chartGroupByOption.none && !props.showNone) {
  const prop = props.properties.find((p) => ![propertyType.number, propertyType.date].includes(getType(p)));
  selectGroupBy(prop, chartGroupByOption.unique);
}

</script>

<style scoped>
</style>
