<template>
  <m-dropdown
    class="bucket-selector"
    match-trigger-width
    :relocate-key="selectedBucketType"
  >
    <m-focusable
      type="clickable"
      full-width
    >
      {{ displayVal }}
    </m-focusable>
    <template #overlay>
      <m-card
        padding-xs
      >
        <m-form-item :label="$t('bucketSelector.bucketType')">
          <m-select
            v-model:value="selectedBucketType"
            :items="bucketTypes"
            full-width
            match-trigger-width
            @change="handleChange"
          />
        </m-form-item>
        <template v-if="selectedBucketType === 'custom'">
          <m-divider small />
          <div class="_min-max-bucket-size">
            <div class="_min-max-bucket-size-inputs">
              <m-form-item
                :label="$t('bucketSelector.minValue')"
                class="_form-item"
              >
                <m-input-number
                  v-model:value="minValue"
                  :placeholder="$t('bucketSelector.minValue')"
                  full-width
                  :has-error="invalidMinOrMax"
                  @change="handleChange"
                />
              </m-form-item>
              <m-form-item
                :label="$t('bucketSelector.maxValue')"
                class="_form-item"
              >
                <m-input-number
                  v-model:value="maxValue"
                  :placeholder="$t('bucketSelector.maxValue')"
                  full-width
                  :has-error="invalidMinOrMax"
                  @change="handleChange"
                />
              </m-form-item>
            </div>
            <div
              v-if="invalidMinOrMax"
              class="_error"
            >
              {{ $t('bucketSelector.minValueError') }}
            </div>
          </div>
          <m-form-item :label="$t('bucketSelector.bucketSize')">
            <m-input-number
              v-model:value="bucketSize"
              :placeholder="$t('bucketSelector.bucketSize')"
              full-width
              :has-error="invalidBucketSize"
              @change="handleChange"
            />
          </m-form-item>
          <div
            v-if="invalidBucketSize"
            class="_error"
          >
            {{ $t('bucketSelector.bucketSizeError') }}
          </div>
        </template>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script setup>
import { bucketType } from 'shared/constants.json';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  value: {
    type: Object,
    default: () => null,
  },
  result: {
    type: Object,
    default: () => null,
  },
});

const emit = defineEmits(['update:value']);

const handleChange = () => {
  if (hasErrors.value) {
    return;
  }
  emit('update:value', toStore.value);
};

const bucketTypes = ref([
  { text: t('bucketSelector.automatic'), value: bucketType.automatic },
  { text: t('bucketSelector.custom'), value: bucketType.custom },
]);

const selectedBucketType = ref(bucketType.automatic);
const minValue = ref(0);
const maxValue = ref(100);
const bucketSize = ref(20);

if (props.value !== null) {
  selectedBucketType.value = props.value.bucketType;
  minValue.value = props.value.min;
  maxValue.value = props.value.max;
  bucketSize.value = props.value.bucketSize;
}

const displayVal = computed(() => {
  if (selectedBucketType.value === bucketType.automatic) {
    return t('bucketSelector.automatic');
  }

  return t('bucketSelector.custom');
});

const invalidMinOrMax = computed(() => maxValue.value <= minValue.value);
const invalidBucketSize = computed(() => bucketSize.value <= 0);
const hasErrors = computed(() => invalidMinOrMax.value || invalidBucketSize.value);

const toStore = computed(() => ({
  bucketType: selectedBucketType.value,
  bucketSize: bucketSize.value,
  min: minValue.value,
  max: maxValue.value,
}));

watch(selectedBucketType, (newVal) => {
  if (newVal !== bucketType.custom) {
    return;
  }
  if (minValue.value === undefined) {
    minValue.value = 0;
  }
  if (maxValue.value === undefined) {
    maxValue.value = 100;
  }
  if (bucketSize.value === undefined) {
    bucketSize.value = 20;
  }
});
</script>

<style scoped lang="scss">
.bucket-selector {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

._min-max-bucket-size {
  margin-bottom: 1rem;

  ._min-max-bucket-size-inputs {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;

    ._form-item {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

  ._error {
    color: $error-color;
  }
</style>
