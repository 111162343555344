<template>
  <div class="planning-form">
    <div class="_content">
      <m-form-item
        v-for="property in planningProperties"
        :key="property.uid"
        :label="textByLang(property.label, userLang)"
      >
        <property-form-item
          :property="property"
          :property-values="properties"
          full-width
          wrap
          restrict-foreign-entity-selection
          @change="updateProperties"
        />
      </m-form-item>
      <m-form-item :label="$t('goal.goalEditor.goalCycle')">
        <goal-cycle-selector
          v-model:value="selectedGoalCycles"
          full-width
          wrap
          :placeholder="$t('planningForm.cyclePlaceholder')"
        />
      </m-form-item>
      <m-form-item :label="$t('planningForm.titleLabel')">
        <m-text-field
          v-model:value="title"
          :placeholder="$t('planningForm.titlePlaceholder')"
          @focus="titleDirty = true"
        />
      </m-form-item>
    </div>
    <div class="_footer">
      <div class="_right">
        <m-btn
          class="_btn"
          hide-border
          light
          @click="$emit('cancel')"
        >
          {{ $t('general.cancel') }}
        </m-btn>
        <m-btn
          class="_btn"
          color="primary"
          :loading="createPlanningLoading"
          @click="create"
        >
          {{ $t('general.save') }}
        </m-btn>
      </div>
    </div>
  </div>
</template>

<script>
import GoalCycleSelector from '@/components/goal/cycle/GoalCycleSelector.vue';
import PropertyFormItem from '@/components/PropertyFormItem.vue';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePlannings from '@/composables/planning/plannings';
import useProperties from '@/composables/property/property';
import useSpaces from '@/composables/space/spaces';
import { copy } from 'shared/lib/copy';
import { emptyValues } from '@/lib/property';
import { mergePropertyValues } from '@/lib/goal-grouping';
import { propertyType, routeName } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';
import { uniqBy } from 'lodash-es';

export default {
  name: 'PlanningForm',
  setup() {
    const { goalCycles, nextCycle } = useGoalCycle();
    const { myTeams, userLang } = useLoggedInUser();
    const { createPlanning, createPlanningLoading } = usePlannings();
    const { planningProperties } = useProperties();
    const spacesSvc = useSpaces();

    return {
      userLang,
      goalCycles,
      createPlanning,
      createPlanningLoading,
      myTeams,
      nextCycle: nextCycle(),
      planningProperties,
      spacesSvc,
    };
  },
  components: { PropertyFormItem, GoalCycleSelector },
  data() {
    return {
      title: '',
      selectedGoalCycles: [],
      textByLang,
      titleDirty: false,
      properties: [],
    };
  },
  emits: ['cancel'],
  methods: {
    updateProperties(value) {
      this.properties = uniqBy([value, ...this.properties], (item) => item.property.uid);
    },
    predictTitle() {
      if (this.titleDirty) {
        return;
      }
      const selectedSpaces = this.properties.reduce((res, next) => {
        if (next.property.type === propertyType.space) {
          return this.spacesSvc.selectMultiple(next.spaces.map(({ uid }) => uid));
        }

        return res;
      }, []);
      const s = selectedSpaces.reduce((res, next) => {
        if (res === '') {
          res = next.title;
          return res;
        }

        res += `, ${next.title}`;
        return res;
      }, '');
      const t = this.selectedGoalCycles.reduce((res, next) => {
        if (res === '') {
          res = next.title;
          return res;
        }

        res += `, ${next.title}`;
        return res;
      }, '');

      this.title = [s, t].filter((t) => t !== '').join(' - ');
    },
    create() {
      this.createPlanning({
        title: this.title,
        properties: this.properties,
        goalCycles: this.selectedGoalCycles,
      }).then((planning) => {
        this.$router.push({ name: routeName.planningDetails, params: { id: planning.uid }, query: { afterCreate: 'true' } });
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
  watch: {
    selectedGoalCycles() {
      this.predictTitle();
    },
    properties() {
      this.predictTitle();
    },
  },
  created() {
    this.properties = emptyValues(this.planningProperties);
    if (this.myTeams.length === 1) {
      const spacePropertyValue = {
        property: this.planningProperties.find((p) => p.type === propertyType.space),
        spaces: copy(this.myTeams),
      };
      this.properties = mergePropertyValues([spacePropertyValue], this.properties);
    }
    if (this.nextCycle !== null) {
      this.selectedGoalCycles = [this.nextCycle];
    }
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .planning-form {
    ._footer {
      display: flex;

      ._right {
        display: flex;
        margin-left: auto;

        ._btn {
          margin-left: .8rem;
        }
      }
    }
  }
</style>
