<template>
  <div :class="['scroll-container', props.noPaddingBottom || isPrint ? '':'-padding-bottom']">
    <slot />
  </div>
</template>

<script setup>
import useExport from '@/composables/export/export';

const props = defineProps({
  noPaddingBottom: {
    type: Boolean,
    default: false,
  },
});
const { isPrint } = useExport();
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .scroll-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    transition: width 0.2s ease-in-out;

    &.-padding-bottom {
      padding-bottom: 30vh;
    }
  }
</style>
