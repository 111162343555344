<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="43"
      height="43"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.505 33.1129L5.49498 33.1129L5.49498 35.03L37.505 35.03L37.505 33.1129ZM40.475 30.9679C40.475 30.5122 40.1056 30.1429 39.65 30.1429L3.34998 30.1429C2.89434 30.1429 2.52498 30.5122 2.52498 30.9679L2.52498 37.175C2.52498 37.6307 2.89434 38 3.34998 38L39.65 38C40.1056 38 40.475 37.6307 40.475 37.175L40.475 30.9679Z"
      fill="#288AA8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5299 20.5413L5.49494 20.5413L5.49494 22.4585L18.5299 22.4585L18.5299 20.5413ZM21.4999 18.3964C21.4999 17.9407 21.1306 17.5714 20.6749 17.5714L3.34994 17.5713C2.89431 17.5713 2.52494 17.9407 2.52494 18.3963L2.52494 24.6035C2.52494 25.0591 2.89431 25.4285 3.34994 25.4285L20.6749 25.4285C21.1306 25.4285 21.4999 25.0591 21.4999 24.6035L21.4999 18.3964Z"
      fill="#BDBDBD"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.9632 7.97L5.4949 7.97L5.4949 9.88714L26.9632 9.88714L26.9632 7.97ZM29.9332 5.825C29.9332 5.36937 29.5639 5 29.1082 5L3.3499 5C2.89426 5 2.5249 5.36936 2.5249 5.825L2.5249 12.0321C2.5249 12.4878 2.89426 12.8571 3.3499 12.8571L29.1082 12.8571C29.5639 12.8571 29.9332 12.4878 29.9332 12.0321L29.9332 5.825Z"
      fill="#BDBDBD"
    />
  </svg>
</template>

<script>
export default { name: 'HorizontalBarChart' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
